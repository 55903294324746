import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { Dropdown, MenuProps, Table } from "antd";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { ConfirmationModal } from "../../../components";
import { UploadSubDocModal } from "../../uploadSubDocModal";
import SkeletonTable from "./skeletonTable/RfpDocSkeletonTable";
import { getRFPDetailsByRFPId } from "../../../services/allRfps";
import { AssetUploadStatus, RFPStatus } from "../../../constants";
import { getRfpDocuments } from "../../../store/allRfps/allRfpSlice";
import { ISubFileDataType } from "../../../store/allRfps/allRfp.interface";
import { UploadSubRfp, deleteRfpSubFiles } from "../../../services/uploadRfp";
import { setCurrentRfpIdForPdf } from "../../../store/pdfViewer/pdfViewerSlice";
import {
  IMAGES,
  PrimaryButton,
  formateDate,
  handleOpenPdfDoc,
  onDownloadPdf,
} from "../../../shared";
import {
  resetUploadStates,
  setCurrentSubUploading,
  setSubFiles,
} from "../../../store/rfpUpload/rfpUploadSlice";

interface DataType {
  key: React.Key;
  originalFileName: string;
  UploadedBy: string;
  UploadedOn: string;
  rfpId: number;
  preSignedUrl: string;
}

const RfpDocuments = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const [isRfpDocUploading, setIsRfpDocUploading] = useState(false);
  const [isRfpDocumentsLoading, setIsRfpDocumentsLoading] = useState(false);
  const [isrfpIsDownloading, setIsrfpIsDownloading] = useState(false);
  const [currentRfpDownloadId, setCurrentRfpDownloadId] = useState(-1);
  const [deleteSubFileId, setDeleteSubFileId] = useState(-1);
  const [isDeleteSubFileModalOpen, setIsDeleteSubFileModalOpen] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { rfpId } = useParams();

  const inputSubFileRef = useRef<HTMLInputElement>(null);

  const { userId } = useSelector((state: RootState) => state.authSlice);
  const { currentRfpIdForPdf } = useSelector(
    (state: RootState) => state.pdfViewer
  );
  const { rfpDocuments, isSubFileDelete } = useSelector(
    (state: RootState) => state.allRfps
  );

  const currentRFPId = rfpId ? parseInt(rfpId) : -1;

  useEffect(() => {
    const getRfpFiles = async () => {
      try {
        setIsRfpDocumentsLoading(true);
        const res = await getRFPDetailsByRFPId(currentRFPId);
        dispatch(getRfpDocuments(res));
      } catch {
        console.error("getRfpFiles api error");
      } finally {
        setIsRfpDocumentsLoading(false);
      }
    };

    getRfpFiles();
  }, [currentRFPId]);

  const handleDownloadPdf = async (
    preSignedUrl: string,
    originalFileName: string,
    rfpId: number
  ) => {
    try {
      setCurrentRfpDownloadId(rfpId);
      await onDownloadPdf(
        preSignedUrl,
        setIsrfpIsDownloading,
        originalFileName
      );
    } catch {
      console.log("Failed to download");
    } finally {
      setCurrentRfpDownloadId(-1);
    }
  };

  const mainFileColumns: ColumnsType<DataType> = [
    {
      title: "File name",
      render: (v) => (
        <Styles.RfpTitleText
          onClick={(e) => {
            e.stopPropagation();
            handleOpenPdfDoc(v?.originalFileName, v?.preSignedUrl);
            dispatch(setCurrentRfpIdForPdf(v?.rfpId));
          }}
          $active={currentRfpIdForPdf === v?.rfpId}
        >
          {v?.originalFileName}
        </Styles.RfpTitleText>
      ),
    },
    {
      title: "Uploaded by",
      dataIndex: "UploadedBy",
      key: "UploadedBy",
    },
    {
      title: "Uploaded on",
      dataIndex: "UploadedOn",
      key: "UploadedOn",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (v) => (
        <Styles.ActionIconWrap>
          <Styles.DownloadRfpButton
            type="link"
            icon={<img src={IMAGES.downloadContentIcon} alt="download" />}
            loading={isrfpIsDownloading && currentRfpDownloadId === v?.rfpId}
            disabled={isrfpIsDownloading && currentRfpDownloadId !== v?.rfpId}
            onClick={() =>
              handleDownloadPdf(v?.preSignedUrl, v?.originalFileName, v?.rfpId)
            }
          />
        </Styles.ActionIconWrap>
      ),
    },
  ];

  const mainRfpData: DataType[] = [
    {
      key: "1",
      originalFileName: rfpDocuments?.original_file_name,
      UploadedBy: rfpDocuments?.user?.name,
      UploadedOn: formateDate(rfpDocuments?.uploaded_on),
      rfpId: rfpDocuments?.rfp_id,
      preSignedUrl: rfpDocuments?.pre_signed_url,
    },
  ];

  const subFileColumns: ColumnsType<ISubFileDataType> = [
    {
      title: "File name",
      render: (v) => (
        <Styles.RfpTitleText
          $active={currentRfpIdForPdf === v?.sub_file_id}
          onClick={(e) => {
            if (v?.status !== RFPStatus.PROCESSING) {
              e.stopPropagation();
              handleOpenPdfDoc(v?.original_file_name, v?.pre_signed_url);
              dispatch(setCurrentRfpIdForPdf(v?.sub_file_id));
            }
          }}
        >
          {v?.original_file_name}
        </Styles.RfpTitleText>
      ),
      width: "30%",
    },
    {
      title: "Uploaded by",
      render: (v) => <>{v?.user?.name}</>,
      width: "30%",
    },
    {
      title: "Uploaded on",
      width: "30%",
      render: (v) => <>{formateDate(v?.uploaded_on)}</>,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (v) => renderRfpStatus(v),
    },
  ];

  const handleDeleteSubFile = async () => {
    await deleteRfpSubFiles(deleteSubFileId);
    setIsDeleteSubFileModalOpen(false);
    setDeleteSubFileId(-1);
  };

  const handleSubFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsRfpDocUploading(true);

    const files: FileList | null = e.target.files && e.target.files;
    const filesArray: File[] = files ? Array.from(files) : [];
    dispatch(setSubFiles(filesArray));

    if (filesArray) {
      for (let i = 0; i < filesArray.length; i++) {
        try {
          setUploadModal(true);
          const formData = new FormData();
          formData.append("file", filesArray[i]);
          dispatch(setCurrentSubUploading(filesArray[i]));

          await UploadSubRfp(
            formData,
            currentRFPId,
            filesArray[i]?.name,
            userId ?? -1
          );
        } catch (error) {
          console.log("Error:", error);
        } finally {
          setUploadModal(false);
          setIsRfpDocUploading(false);
          dispatch(setCurrentSubUploading(null));
          dispatch(resetUploadStates());
        }
      }
    }
  };

  const handleSubFileUploadButton = () => {
    if (inputSubFileRef.current != null) {
      inputSubFileRef.current.click();
    }
  };

  const renderRfpStatus = (subFile: ISubFileDataType) => {
    const items: MenuProps["items"] = [
      {
        key: "1",
        label: (
          <div onClick={() => setIsDeleteSubFileModalOpen(true)}>Delete</div>
        ),
      },
    ];

    switch (subFile?.status) {
      case AssetUploadStatus.PROCESSING:
      default:
        return (
          <Styles.RfpStatusWrap>
            <img
              src={IMAGES.loadingSpinner}
              alt="loading"
              className="spinnerSvg"
            />
            In processing
          </Styles.RfpStatusWrap>
        );

      case AssetUploadStatus.LOADED_INTO_LLM:
      case AssetUploadStatus.COMPLETED:
        return (
          <Styles.ActionIconWrap>
            <Dropdown
              menu={{
                onClick: () => {
                  setDeleteSubFileId(subFile?.sub_file_id);
                },
                items,
              }}
              trigger={["click"]}
              overlayClassName="table-action-dropdown table-action-dropdown-user"
              placement="bottomRight"
            >
              <img src={IMAGES.actionMenu} alt="actionMenu" />
            </Dropdown>

            <Styles.DownloadRfpButton
              type="link"
              icon={<img src={IMAGES.downloadContentIcon} alt="download" />}
              loading={
                isrfpIsDownloading &&
                currentRfpDownloadId === subFile?.sub_file_id
              }
              disabled={
                isrfpIsDownloading &&
                currentRfpDownloadId !== subFile?.sub_file_id
              }
              onClick={() => {
                handleDownloadPdf(
                  subFile?.pre_signed_url,
                  subFile?.original_file_name,
                  subFile?.sub_file_id
                );
              }}
            />
          </Styles.ActionIconWrap>
        );

      case AssetUploadStatus.FAILED:
      case AssetUploadStatus.FAILED_IN_LLM:
        return <Styles.RfpStatusWrap>Failed</Styles.RfpStatusWrap>;
    }
  };

  return (
    <>
      <Styles.RfpDocumentsContainer>
        <Styles.RfpDocumentsHeader $active={true}>
          <Styles.RfpHeaderText>RFP Documents</Styles.RfpHeaderText>
          <Styles.CloseText onClick={() => navigate(-1)}>
            Close
          </Styles.CloseText>
        </Styles.RfpDocumentsHeader>
        {isRfpDocumentsLoading ? (
          <SkeletonTable />
        ) : (
          <Table
            pagination={false}
            dataSource={mainRfpData}
            columns={mainFileColumns}
            loading={isRfpDocumentsLoading}
          />
        )}

        <Styles.RfpBorder />

        <Styles.AdditionalDocumentsContainer>
          <Styles.AdditionalDocumentsHeader>
            <Styles.AdditionalDocumentsText>
              Additional Documents
            </Styles.AdditionalDocumentsText>
            <PrimaryButton
              onClick={handleSubFileUploadButton}
              loading={isRfpDocUploading}
            >
              Upload
            </PrimaryButton>
            <input
              type="file"
              ref={inputSubFileRef}
              accept=".pdf"
              onChange={handleSubFileUpload}
              hidden
              multiple
            />
          </Styles.AdditionalDocumentsHeader>

          {isRfpDocumentsLoading ? (
            <SkeletonTable />
          ) : (
            <Table
              pagination={false}
              columns={subFileColumns}
              dataSource={rfpDocuments?.sub_file}
              tableLayout="fixed"
              scroll={{ y: "calc(100vh - 485px)" }}
            />
          )}
        </Styles.AdditionalDocumentsContainer>
      </Styles.RfpDocumentsContainer>

      <UploadSubDocModal
        isModalOpen={uploadModal}
        setUploadModal={setUploadModal}
        afterClose={() => {
          if (inputSubFileRef.current) {
            inputSubFileRef.current.value = "";
          }
        }}
      />

      <ConfirmationModal
        title="Delete document"
        text="Are you sure you want to delete this document?"
        isOpen={isDeleteSubFileModalOpen}
        handleOk={handleDeleteSubFile}
        handleCancel={() => {
          setIsDeleteSubFileModalOpen(false), setDeleteSubFileId(-1);
        }}
        isLoading={isSubFileDelete}
        btnText="Delete"
      />
    </>
  );
};

export default RfpDocuments;

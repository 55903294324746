import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import * as Styles from "./styles";
import { PATHS } from "../../../constants";
import { RootState } from "../../../store";
import { SecondaryButton } from "../../../shared";
import { sendOtp, verifyOtp } from "../../../services/auth";
import {
  resetOtpPassword,
  setOtpAtForgetPassword,
} from "../../../store/auth/authSlice";

const OTP = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [form] = useForm();

  const { userEmailAtForgetPassword, isSendOtpLoading } = useSelector(
    (state: RootState) => state.authSlice
  );

  const handleValueChange = (value: { otp: string }) => {
    const { otp } = value;

    if (otp && /^[0-9]*$/.test(otp) && otp.length === 6) {
      form.submit(); // Submit the form when OTP is valid
    }
  };

  const onFinish = (value: { otp: number }) => {
    const { otp } = value;

    dispatch(setOtpAtForgetPassword(otp));
    verifyOtp({ email: userEmailAtForgetPassword, otp }, navigate);
  };

  const handleResendOtp = () => {
    sendOtp({ email: userEmailAtForgetPassword }, navigate);
    form.resetFields();
  };

  return (
    <>
      <Styles.HeadingText>Check your email</Styles.HeadingText>
      <Styles.SubHeadingText>
        we've sent a password reset link to {userEmailAtForgetPassword ?? ""}
      </Styles.SubHeadingText>
      <Form
        name="otp"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        validateTrigger="onSubmit"
        onValuesChange={handleValueChange}
        onFinishFailed={() => form.submit()}
      >
        <Form.Item
          label="OTP"
          name="otp"
          validateTrigger="onChange"
          rules={[
            {
              validator: (_, value) => {
                if (!/^[0-9]*$/.test(value)) {
                  return Promise.reject(new Error("Please enter only numbers"));
                }
                if (value.length !== 6) {
                  return Promise.reject(new Error("OTP must be 6 digits"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Styles.InputField placeholder="123456" />
        </Form.Item>
      </Form>
      <Styles.ResendContainer>
        <Styles.ResendText>Didn't receive an email yet?</Styles.ResendText>
        <Styles.ResendButton
          type="link"
          onClick={handleResendOtp}
          loading={isSendOtpLoading}
        >
          Click to resend
        </Styles.ResendButton>
      </Styles.ResendContainer>
      <Styles.BackToLoginContainer>
        <SecondaryButton
          icon={<LeftOutlined />}
          onClick={() => {
            navigate(PATHS.login);
            dispatch(resetOtpPassword([]));
          }}
        >
          Back to login
        </SecondaryButton>
      </Styles.BackToLoginContainer>
    </>
  );
};

export default OTP;

import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateRFPSummary } from "../../../services/allRfps";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../shared";
import { RootState } from "../../../store";
import { IAllRfp } from "../../../store/allRfps/allRfp.interface";
import * as Styles from "./styles";
import { Tooltip } from "antd";
import { RFPStatus } from "../../../constants";
import { useOutsideClick } from "../../../shared/hooks/useOutsideClick";
import { MarkDownText } from "../../../shared/markDownText/MarkDownText";
import { setAllRfps } from "../../../store/allRfps/allRfpSlice";

interface ISummaryModalProps {
  rfp: IAllRfp;
  selectedIndex: number;
}
const SummaryModal = ({ rfp, selectedIndex }: ISummaryModalProps) => {
  const dispatch = useDispatch();

  const [isEditSummary, setIsEditSummary] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [summary, setSummary] = useState("");
  const [isOpenSummaryId, setIsOpenSummaryId] = useState(-1);
  const popOverRef = useRef(null);

  const { allRfps } = useSelector((state: RootState) => state.allRfps);

  useOutsideClick(
    popOverRef,
    () => handleClose(),
    isOpenSummaryId === rfp?.rfp_id && rfp?.status !== RFPStatus.PROCESSING
  );

  const SaveRFPSummary = async (rfp: IAllRfp) => {
    const response = await UpdateRFPSummary(rfp?.rfp_id, {
      rfp_summary: summary.trim(),
    });
    if (response?.status === 200) {
      const updatedRfp = {
        ...rfp,
        rfp_summary: summary.trim(),
      };
      const allRfpsValues = [...allRfps];
      allRfpsValues[selectedIndex] = updatedRfp;
      dispatch(setAllRfps(allRfpsValues));
      handleClose();
    }
    setIsSummaryLoading(false);
  };

  const handleClose = () => {
    setIsOpenSummaryId(-1);
    setIsEditSummary(false);
    setSummary("");
  };

  const handleCancel = () => {
    setIsEditSummary(false);
    setSummary(rfp.rfp_summary);
  };

  return (
    <Styles.RFPSummary onClick={(e) => e.stopPropagation()}>
      <Styles.StyledPopover
        overlayClassName="rfp-summary-popover"
        trigger="click"
        open={
          isOpenSummaryId === rfp?.rfp_id &&
          rfp?.status !== RFPStatus.PROCESSING
        }
        onOpenChange={() => {
          setIsOpenSummaryId(rfp?.rfp_id);
        }}
        content={
          <Styles.SummaryContentContainer ref={popOverRef}>
            {isEditSummary ? (
              <Styles.InputSummary
                isEdit={isEditSummary}
                placeholder="Type Summary here..."
                readOnly={!isEditSummary}
                autoFocus={true}
                value={summary ? summary : rfp.rfp_summary}
                onChange={(e: any) => {
                  setSummary(e.target.value);
                }}
              />
            ) : (
              <MarkDownText text={summary ? summary : rfp.rfp_summary} />
            )}
            <Styles.SummaryBtnContainer>
              {isEditSummary ? (
                <PrimaryButton
                  disabled={!summary.trim()}
                  loading={isSummaryLoading}
                  onClick={() => {
                    setIsSummaryLoading(true);
                    SaveRFPSummary(rfp);
                  }}
                >
                  Save
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    setSummary(rfp?.rfp_summary);
                    setIsEditSummary(true);
                  }}
                >
                  Edit
                </PrimaryButton>
              )}

              {isEditSummary ? (
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
              ) : (
                <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
              )}
            </Styles.SummaryBtnContainer>
          </Styles.SummaryContentContainer>
        }
        overlayStyle={{ width: "600px" }}
        arrow={false}
        placement="bottom"
      >
        <Tooltip
          title={rfp?.status !== RFPStatus.PROCESSING ? "View RFP summary" : ""}
        >
          <Styles.ViewRfp
            src={IMAGES.rfpSummeryIcon}
            alt="rfpSummery"
            $active={false}
            $isProcessing={rfp?.status === RFPStatus.PROCESSING}
          />
        </Tooltip>
      </Styles.StyledPopover>
    </Styles.RFPSummary>
  );
};

export default SummaryModal;

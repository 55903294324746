import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { updateFilter } from "../../../store/dashboard/dashboardSlice";
import { clientGroupOptions, projectOptions } from "../../../shared/helpers";
import * as Styles from "./styles";

const Filter = () => {
  const dispatch = useDispatch();
  const { dashboardFilter } = useSelector(
    (state: RootState) => state.dashboard
  );
  const { clientGroup, projectCategory } = dashboardFilter;

  const onChangeClientGroup = (value: string[]) => {
    dispatch(
      updateFilter({ field: "clientGroup", value: value.length ? value : null })
    );
  };

  const onChangeProject = (value: string[]) => {
    dispatch(
      updateFilter({
        field: "projectCategory",
        value: value.length ? value : null,
      })
    );
  };

  return (
    <>
      <Styles.SelectWrap>
        <Styles.StyledSelect
          mode="multiple"
          placeholder="Client Group"
          loading={false}
          value={clientGroup}
          options={clientGroupOptions}
          onChange={(value) => onChangeClientGroup(value as string[])}
          showSearch={false}
          popupClassName="ui-dropdown-default"
          maxTagCount={2}
        />
        <Styles.StyledSelect
          mode="multiple"
          placeholder="Projects"
          loading={false}
          value={projectCategory}
          options={projectOptions}
          onChange={(value) => onChangeProject(value as string[])}
          showSearch={false}
          popupClassName="ui-dropdown-default"
          maxTagCount={2}
        />
      </Styles.SelectWrap>
    </>
  );
};

export default Filter;

import styled from "styled-components";
import { Switch } from "antd";

export const PrimarySwitch = styled(Switch)`
  background: #d5d0d8;

  &.ant-switch.ant-switch-checked:hover {
    background: #b829ff;
  }

  &.ant-switch:hover {
    background: #d5d0d8;
  }
`;

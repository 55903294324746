import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  IDashboard,
  IRFPFileListCount,
  IRFPFileListCountByDate,
} from "./dashboard.interface";

const initialState: IDashboard = {
  dashboardFilter: {
    clientGroup: null,
    startDate: dayjs().add(-7, "d").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    projectCategory: null,
  },
  rfpFileListCount: {
    qualifiedRfps: 0,
    nonQualifiedRfps: 0,
    unassignedRfps: 0,
    stage0AQualifiedRfps: 0,
    stage1QualifiedRfps: 0,
    stage2AQualifiedRfps: 0,
    stage2BQualifiedRfps: 0,
    stage3AQualifiedRfps: 0,
    stage3BQualifiedRfps: 0,
  },
  isRFPFileListCountLoading: false,
  rfpFileListCountByDate: {
    stage0Days7Rfps: 0,
    stage1Days7Rfps: 0,
    stage2ADays7Rfps: 0,
    stage2BDays7Rfps: 0,
    stage3ADays7Rfps: 0,
    stage3BDays7Rfps: 0,
    stage0Days30Rfps: 0,
    stage1Days30Rfps: 0,
    stage2ADays30Rfps: 0,
    stage2BDays30Rfps: 0,
    stage3ADays30Rfps: 0,
    stage3BDays30Rfps: 0,
    stage0Days30BeforeRfps: 0,
    stage1Days30BeforeRfps: 0,
    stage2ADays30BeforeRfps: 0,
    stage2BDays30BeforeRfps: 0,
    stage3ADays30BeforeRfps: 0,
    stage3BDays30BeforeRfps: 0,
  },
  isRFPFileListCountByDateLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    updateFilter: (
      state,
      action: PayloadAction<{ field: string; value: string | string[] | null }>
    ) => {
      state.dashboardFilter = {
        ...state.dashboardFilter,
        [action.payload.field]: action.payload.value,
      };
    },
    clearFilter: (state) => {
      state.dashboardFilter = {
        clientGroup: null,
        startDate: "",
        endDate: "",
        projectCategory: null,
      };
    },
    setRFPFileListCount: (state, action: PayloadAction<IRFPFileListCount>) => {
      state.rfpFileListCount = action.payload;
    },
    setIsRFPFileListCountLoading: (state, action: PayloadAction<boolean>) => {
      state.isRFPFileListCountLoading = action.payload;
    },
    setRFPFileListCountByDate: (
      state,
      action: PayloadAction<IRFPFileListCountByDate>
    ) => {
      state.rfpFileListCountByDate = action.payload;
    },
    setIsRFPFileListCountByDateLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isRFPFileListCountByDateLoading = action.payload;
    },
  },
});

export const {
  updateFilter,
  clearFilter,
  setRFPFileListCount,
  setIsRFPFileListCountLoading,
  setRFPFileListCountByDate,
  setIsRFPFileListCountByDateLoading,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

import styled from "styled-components";
import { ActiveStatus } from "../../types";

export const SideNavContainer = styled.div`
  width: 100%;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borderColorAlpha};
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AppLogo = styled.img`
  width: 45px;
`;

export const NavIconWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

export const NavTitle = styled.div`
  font-family: "GraphikRegular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
`;

export const NavIconTitleWrap = styled.div<ActiveStatus>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.backgroundColorBeta : "transparent"};
  color: ${({ theme, $active }) =>
    $active ? theme.colors.fontColorBeta : theme.colors.fontColorDelta};
  cursor: pointer;
  gap: 5px;
  padding: 8px 0;
  transition: ${({ $active }) =>
    $active ? "background-color 1s, color 0.5s, img 1s" : "none"};

  /* &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundColorBeta};
    color: ${({ theme }) => theme.colors.fontColorBeta};
  } */

  ${NavTitle} {
    transition: ${({ $active }) => ($active ? "color 0.6s" : "none")};
  }
`;

export const AdminText = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 12.1px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const AdminTextIconWrap = styled.div<ActiveStatus>`
  img {
    box-shadow: ${({ $active }) =>
      $active ? "0px 4px 8px 0px #65009f26 inset" : "unset"};
    box-shadow: ${({ $active }) =>
      $active ? "0px 1px 8px 0px #65009f26" : "unset"};
    border: ${({ $active }) => ($active ? "0.6px solid #ad8fbf" : "unset")};
  }
`;

export const NavFooter = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #e6e4f0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
`;

export const ActiveIcon = styled.div`
  width: 16px;
  height: 16px;
  display: grid;
  place-content: center;
  z-index: 10;
  position: absolute;
  right: 0px;
  bottom: 0px;

  div {
    width: 14px;
    height: 14px;
    border-radius: 8px;
    border: 3px solid #fff;
    background: #00b884;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
  cursor: pointer;
  position: relative;
`;

import { useEffect, useRef, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import {
  IMAGES,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../../../../shared";
import ConfirmationModal from "../../../common/confirmModal/ConfirmationModal";
import {
  deleteSolutionExtractedFile,
  extractFRSDetails,
  generateResponse,
  getAllFrsPages,
  updateSolutionPages,
} from "../../../../services/frs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ExtractionStatus } from "../../../../constants";
import {
  getFileExtension,
  onDownloadPdf,
  uniqueId,
} from "../../../../shared/helpers";
import { Empty, Skeleton } from "antd";
import {
  setFrsExtractStatus,
  setGenerateFrsResponseStatus,
} from "../../../../store/frs/frsSlice";
import { stopRFPExtGen } from "../../../../services/stopRfpExtGen";
import { toast } from "react-toastify";
interface IRelevantPages {
  start_page: number;
  end_page: number;
}

const Frs = () => {
  const [to, setTo] = useState(0);
  const [from, setFrom] = useState(0);
  const [editIndex, setEditIndex] = useState(-1);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const [isAddRelevantPage, setIsAddRelevantPage] = useState(false);
  const [legalFile, setLegalFile] = useState<File | null>(null);
  const [legalFileName, setLegalFileName] = useState<string | null>(null);
  const [relevantPages, setRelevantPages] = useState<IRelevantPages[]>([]);
  const [downloadingClauses, setDownloadingClauses] = useState<boolean>(false);
  const [downloadingResponse, setDownloadingResponse] =
    useState<boolean>(false);
  const [isStopExtracting, setIsStopExtracting] = useState(false);
  const [isStopGeneration, setIsStopGeneration] = useState(false);

  const inputClauseFile = useRef<HTMLInputElement>(null);

  const { rfpId } = useParams();

  const dispatch = useDispatch();

  const currentRfpId = !!rfpId ? parseInt(rfpId) : -1;

  const {
    frsDetails,
    extractFrsClauses,
    isFrsDetailsLoading,
    generateFrsResponse,
    isDeleteExtractFrsFileLoading,
  } = useSelector((state: RootState) => state.frs);

  const SolutionPageId = !!frsDetails?.frsId ? frsDetails.frsId : -1;

  useEffect(() => {
    getAllFrsPages(currentRfpId);
  }, [currentRfpId]);

  useEffect(() => {
    if (frsDetails?.relevantPages) {
      const pages = frsDetails?.relevantPages;
      setRelevantPages(pages);
    }
  }, [frsDetails]);

  const handleClauseFileUploadButton = () => {
    if (inputClauseFile.current != null) {
      inputClauseFile.current.click();
    }
  };

  const handleCancelProcessing = () => {
    dispatch(setGenerateFrsResponseStatus(ExtractionStatus.NOT_STARTED));

    setLegalFileName(null);
    setLegalFile(null);
    if (inputClauseFile.current) {
      inputClauseFile.current.value = "";
    }
  };

  const addRelevantPages = () => {
    setRelevantPages([...relevantPages, { start_page: 0, end_page: 0 }]);
    setEditIndex(relevantPages.length);
    setIsAddRelevantPage(true);
  };

  const handleEdit = (index: number) => {
    setFrom(relevantPages[index].start_page);
    setTo(relevantPages[index].end_page);
    setEditIndex(index);
  };

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setFrom(Number(value));
    }
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setTo(Number(value));
    }
  };

  const deleteRelevantPage = (index: number) => {
    const remainingPages = relevantPages.filter((_, i: number) => index !== i);
    setRelevantPages(remainingPages);
  };

  const handleStopExtGen = async (type: "Extraction" | "Generation") => {
    try {
      if (!SolutionPageId || !Number(rfpId) || !frsDetails?.fileName) return;

      const isExtraction = type === "Extraction" ? 1 : 0;
      const isStopState =
        type === "Extraction" ? setIsStopExtracting : setIsStopGeneration;

      isStopState(true);

      const request = {
        id: Number(SolutionPageId),
        rfpId: Number(rfpId),
        rfpFileName: frsDetails.fileName,
        proposalsCategory: "SOLUTION",
        isExtraction,
        proposalSubCategory: "FRS",
      };

      const response = await stopRFPExtGen(request);

      if (response?.status === 200) {
        const { status } = response.data;
        if (status) {
          type === "Extraction"
            ? dispatch(setFrsExtractStatus(status))
            : dispatch(setGenerateFrsResponseStatus(status));
        } else {
          type === "Extraction"
            ? dispatch(setFrsExtractStatus(ExtractionStatus.STOPPING))
            : dispatch(setGenerateFrsResponseStatus(ExtractionStatus.STOPPING));
          toast.error(
            `we can't stop now. The ${type} has been completed successfully.`
          );
        }
      }

      isStopState(false);
    } catch (error) {
      type === "Extraction"
        ? setIsStopExtracting(false)
        : setIsStopGeneration(false);
      console.log(`Error while stop ${type} file`, error);
    }
  };

  const renderBadge = () => {
    if (isFrsDetailsLoading) {
      return (
        <Styles.skeletonLoadingWrap>
          {[...Array(2)].map(() => (
            <Skeleton paragraph={{ rows: 1 }} key={uniqueId()} />
          ))}
        </Styles.skeletonLoadingWrap>
      );
    }

    if (relevantPages?.length) {
      return (
        <Styles.PagesContainer>
          {relevantPages?.map((item, index) => {
            return (
              <Styles.BadgeContainer key={index}>
                <Styles.BadgeCustom
                  status="default"
                  count={
                    editIndex !== index ? (
                      <SecondaryButton
                        disabled={
                          extractFrsClauses?.extractionStatus ===
                            ExtractionStatus.PROCESSING ||
                          extractFrsClauses?.extractionStatus ===
                            ExtractionStatus.STOPPING ||
                          extractFrsClauses?.extractionStatus ===
                            ExtractionStatus.COMPLETED
                        }
                        type="text"
                        shape="circle"
                        icon={
                          <Styles.CloseOutlinedIcon
                            onClick={() => {
                              deleteRelevantPage(index);
                            }}
                          />
                        }
                      />
                    ) : (
                      0
                    )
                  }
                >
                  <Styles.PageBox $active={editIndex === index}>
                    {editIndex === index ? (
                      <>
                        <Styles.PageBoxEditContainer>
                          <Styles.PageBoxSubContainer>
                            <Styles.FromTO>From</Styles.FromTO>
                            <Styles.AntInput
                              value={from}
                              onChange={handleFromChange}
                            />
                          </Styles.PageBoxSubContainer>
                          <Styles.BorderRight></Styles.BorderRight>
                          <Styles.PageBoxSubContainer>
                            <Styles.FromTO>To</Styles.FromTO>
                            <Styles.AntInput
                              value={to}
                              onChange={handleToChange}
                            />
                          </Styles.PageBoxSubContainer>
                        </Styles.PageBoxEditContainer>
                        <Styles.ButtonLink
                          type="link"
                          disabled={from <= 0 || to <= 0}
                          onClick={handleSaveRelevantPage}
                        >
                          {isAddRelevantPage ? "Add" : "Save"}
                        </Styles.ButtonLink>
                      </>
                    ) : (
                      <Styles.PageBoxViewContainer>
                        <Styles.PageText>
                          {item?.start_page} to {item?.end_page}
                        </Styles.PageText>
                        <Styles.ButtonLink
                          type="link"
                          onClick={() => {
                            handleEdit(index);
                          }}
                          disabled={
                            extractFrsClauses?.extractionStatus ===
                              ExtractionStatus.PROCESSING ||
                            extractFrsClauses?.extractionStatus ===
                              ExtractionStatus.STOPPING ||
                            extractFrsClauses?.extractionStatus ===
                              ExtractionStatus.COMPLETED
                          }
                        >
                          Edit
                        </Styles.ButtonLink>
                      </Styles.PageBoxViewContainer>
                    )}
                  </Styles.PageBox>
                </Styles.BadgeCustom>
              </Styles.BadgeContainer>
            );
          })}
        </Styles.PagesContainer>
      );
    } else return <Styles.EmptyState image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  const handleExtractLegalDetails = () => {
    frsDetails?.fileName &&
      extractFRSDetails(
        { rfpFileName: frsDetails?.fileName },
        frsDetails?.frsId
      );
  };

  const renderExtractClauses = () => {
    if (extractFrsClauses?.extractionStatus === ExtractionStatus.COMPLETED) {
      return (
        <>
          <Styles.FileText>
            {extractFrsClauses?.frsSectionSummaryFileName}
          </Styles.FileText>
          <PrimaryButton
            loading={downloadingClauses}
            onClick={() => {
              if (extractFrsClauses?.frsSectionSummaryFilePreSignedUrl)
                onDownloadPdf(
                  extractFrsClauses?.frsSectionSummaryFilePreSignedUrl,
                  setDownloadingClauses,
                  extractFrsClauses?.frsSectionSummaryFileName
                );
            }}
          >
            Download
          </PrimaryButton>
          <Styles.DeleteButton
            loading={isDeleteExtractFrsFileLoading}
            onClick={() => {
              setIsDeleteOpenModal(true);
            }}
            shape="circle"
            icon={<DeleteOutlined />}
            danger
          />
        </>
      );
    }
    return (
      <>
        {extractFrsClauses?.extractionStatus === ExtractionStatus.PROCESSING &&
          !isStopExtracting && (
            <>
              <img src={IMAGES.loadingSpinner} alt="loading" />
              <Styles.GenerateText>Generating output...</Styles.GenerateText>
              <SecondaryButton
                type="text"
                shape="circle"
                onClick={() => {
                  handleStopExtGen("Extraction");
                }}
                icon={<img src={IMAGES.stop} alt="Stop" />}
              />
            </>
          )}
        {(extractFrsClauses?.extractionStatus === ExtractionStatus.STOPPING ||
          isStopExtracting) && (
          <>
            <img src={IMAGES.loadingSpinner} alt="loading" />
            <Styles.GenerateText>Stopping Generation...</Styles.GenerateText>
          </>
        )}
        <Styles.ExtractButton
          disabled={
            relevantPages?.length <= 0 ||
            extractFrsClauses?.extractionStatus ===
              ExtractionStatus.PROCESSING ||
            extractFrsClauses?.extractionStatus === ExtractionStatus.STOPPING
          }
          onClick={handleExtractLegalDetails}
        >
          <img src={IMAGES.submitProcess} alt="Logo" />
          Extract clauses
        </Styles.ExtractButton>
      </>
    );
  };

  const handleSaveRelevantPage = () => {
    let data = [...relevantPages];
    data[editIndex] = { end_page: to, start_page: from };
    setRelevantPages(data);
    setEditIndex(-1);
    setFrom(0);
    setTo(0);
    setIsAddRelevantPage(false);
    updateSolutionPages(SolutionPageId, data, "Frs pages updated successfully");
  };

  const handleClauseFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files && e.target.files[0];
    if (file) {
      setLegalFile(file);

      const formData = new FormData();
      formData.append("file", file);

      const uploadedFile = formData.get("file") as File;
      setLegalFileName(uploadedFile.name);

      dispatch(setGenerateFrsResponseStatus(ExtractionStatus.UPLOADED));
    }
  };

  const handleStartProcessing = () => {
    const formData = new FormData();
    legalFile && formData.append("file", legalFile);

    const uploadedFile = formData?.get("file") as File;
    const fileName = uploadedFile?.name;
    const fileType = fileName
      ? fileName?.substring(fileName.lastIndexOf(".") + 1)
      : "";

    generateResponse(
      {
        rfpId: currentRfpId,
        contentType: getFileExtension(
          extractFrsClauses?.frsSectionSummaryFileName
        ),
        preSignedUrl: extractFrsClauses?.frsSectionSummaryFilePreSignedUrl,
        frsId: frsDetails?.frsId,
      },
      generateFrsResponse?.generatedResponseStatus,
      formData,
      fileType ?? "csv"
    );
  };

  const renderGenerateFrsResponse = () => {
    switch (generateFrsResponse?.generatedResponseStatus) {
      case ExtractionStatus.NOT_STARTED:
      case ExtractionStatus.AI:
      default:
        return (
          <>
            <Styles.Description>
              {extractFrsClauses?.generatedResponseStatus !==
              ExtractionStatus.COMPLETED
                ? "You can directly upload your modified file to generate responses"
                : "The above file generated is an assistive step for your RFP Frs clause extraction. To generate a response you can either use the above generated AI file or upload your own modified file"}
            </Styles.Description>
            <Styles.SubContainer>
              <PrimaryButton
                onClick={() =>
                  dispatch(
                    setGenerateFrsResponseStatus(ExtractionStatus.UPLOADING)
                  )
                }
                disabled={
                  generateFrsResponse?.generatedResponseStatus ===
                    ExtractionStatus.AI ||
                  generateFrsResponse?.generatedResponseStatus ===
                    ExtractionStatus.PROCESSING ||
                  generateFrsResponse?.generatedResponseStatus ===
                    ExtractionStatus.STOPPING
                }
              >
                Upload modified file
              </PrimaryButton>
              <Styles.Span>Or</Styles.Span>
              <Styles.AIFilesButton
                $active={
                  generateFrsResponse?.generatedResponseStatus ===
                  ExtractionStatus.AI
                }
                disabled={
                  extractFrsClauses?.extractionStatus !==
                    ExtractionStatus.COMPLETED ||
                  generateFrsResponse?.generatedResponseStatus ===
                    ExtractionStatus.PROCESSING ||
                  generateFrsResponse?.generatedResponseStatus ===
                    ExtractionStatus.STOPPING
                }
                onClick={() =>
                  dispatch(setGenerateFrsResponseStatus(ExtractionStatus.AI))
                }
              >
                Use AI generated file
              </Styles.AIFilesButton>
              <Styles.NoteContainer>
                <Styles.NoteTitle>Note:</Styles.NoteTitle>
                <Styles.NoteDescription>
                  Please verify all the documents before processing
                </Styles.NoteDescription>
              </Styles.NoteContainer>
            </Styles.SubContainer>
          </>
        );

      case ExtractionStatus.COMPLETED:
        return (
          <>
            <Styles.Description>
              Your file processing is successfully completed and is now
              available for download.
            </Styles.Description>
            <Styles.DownloadContainer>
              <PrimaryButton
                loading={downloadingResponse}
                onClick={() => {
                  if (generateFrsResponse?.generatedResponseFilePreSignedUrl)
                    onDownloadPdf(
                      generateFrsResponse?.generatedResponseFileName,
                      setDownloadingResponse,
                      generateFrsResponse?.generatedResponseFileName
                    );
                }}
              >
                Download
              </PrimaryButton>
              <Styles.Span>
                {generateFrsResponse?.generatedResponseFileName}
              </Styles.Span>
            </Styles.DownloadContainer>
          </>
        );

      case ExtractionStatus.UPLOADED:
      case ExtractionStatus.UPLOADING:
        return (
          <>
            <Styles.Description>
              Please note before uploading, ensure it is a{" "}
              <span>single column</span> file titled “Legal Clause” or similar.
            </Styles.Description>
            <Styles.UploadFileTitle>
              Upload your modified file here
            </Styles.UploadFileTitle>
            <Styles.UploadSubContainer>
              <Styles.UploadIconTitle>
                <img src={IMAGES.browse} alt="Browse" />
                <Styles.Span>{legalFileName ?? "Browse"}</Styles.Span>
              </Styles.UploadIconTitle>

              <PrimaryButton onClick={handleClauseFileUploadButton}>
                Browse
              </PrimaryButton>
              <input
                type="file"
                ref={inputClauseFile}
                accept=".xlsx, .xls, .csv"
                onChange={handleClauseFileUpload}
                hidden
              />
            </Styles.UploadSubContainer>
          </>
        );
    }
  };

  return (
    <>
      <Styles.frsContainer>
        {/* Relevant page start */}
        <Styles.RelevantPageContainer>
          <Styles.RelevantPageTitle>
            Relevant Pages{" "}
            <Styles.AIIcon>
              <img src={IMAGES.aiTagLogo} alt="Logo" />
            </Styles.AIIcon>
          </Styles.RelevantPageTitle>
          <Styles.RelevantPageBody>
            {renderBadge()}
            <Styles.BtnContainer>
              <TertiaryButton
                icon={<PlusOutlined />}
                onClick={addRelevantPages}
                disabled={
                  extractFrsClauses?.extractionStatus ===
                    ExtractionStatus.COMPLETED ||
                  extractFrsClauses?.extractionStatus ===
                    ExtractionStatus.PROCESSING ||
                  extractFrsClauses?.extractionStatus ===
                    ExtractionStatus.STOPPING
                }
              >
                Add more pages
              </TertiaryButton>
              <Styles.ExtractfrsContainer>
                {renderExtractClauses()}
              </Styles.ExtractfrsContainer>
            </Styles.BtnContainer>
            <Styles.NoteContainer>
              <Styles.NoteTitle>Note:</Styles.NoteTitle>
              <Styles.NoteDescription>
                Please verify the above above pages before proceeding to the
                next step of extraction
              </Styles.NoteDescription>
            </Styles.NoteContainer>
          </Styles.RelevantPageBody>
        </Styles.RelevantPageContainer>
        {/* Relevant page end */}
        <Styles.GenerateResponseContainer>
          <Styles.GenerateTitle>Generate response</Styles.GenerateTitle>
          <Styles.ResponseBody>
            {renderGenerateFrsResponse()}

            <Styles.UploadFileContainer>
              {generateFrsResponse?.generatedResponseStatus ===
                ExtractionStatus.PROCESSING &&
                !isStopGeneration && (
                  <Styles.CancelStartContainer>
                    <img src={IMAGES.loadingSpinner} alt="loading" />
                    <Styles.GenerateText>
                      Generating output...
                    </Styles.GenerateText>
                    <SecondaryButton
                      type="text"
                      shape="circle"
                      onClick={() => {
                        handleStopExtGen("Generation");
                      }}
                      icon={<img src={IMAGES.stop} alt="Stop" />}
                    />
                  </Styles.CancelStartContainer>
                )}
              {(generateFrsResponse?.generatedResponseStatus ===
                ExtractionStatus.STOPPING ||
                isStopGeneration) && (
                <Styles.CancelStartContainer>
                  <img src={IMAGES.loadingSpinner} alt="loading" />
                  <Styles.GenerateText>
                    Stopping Generation...
                  </Styles.GenerateText>
                </Styles.CancelStartContainer>
              )}
              {(generateFrsResponse?.generatedResponseStatus ===
                ExtractionStatus.NOT_STARTED ||
                generateFrsResponse?.generatedResponseStatus ===
                  ExtractionStatus.AI ||
                generateFrsResponse?.generatedResponseStatus ===
                  ExtractionStatus.UPLOADED ||
                generateFrsResponse?.generatedResponseStatus ===
                  ExtractionStatus.UPLOADING) &&
                !isStopGeneration && (
                  <Styles.CancelStartContainer>
                    <SecondaryButton
                      disabled={
                        generateFrsResponse?.generatedResponseStatus ===
                        ExtractionStatus.NOT_STARTED
                      }
                      onClick={handleCancelProcessing}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={handleStartProcessing}
                      disabled={
                        generateFrsResponse?.generatedResponseStatus ===
                          ExtractionStatus.NOT_STARTED ||
                        generateFrsResponse?.generatedResponseStatus ===
                          ExtractionStatus.UPLOADING
                      }
                      // loading={
                      //   generateFrsResponse?.generatedResponseStatus ===
                      //   ExtractionStatus.PROCESSING
                      // }
                    >
                      Start Processing
                    </PrimaryButton>
                  </Styles.CancelStartContainer>
                )}
            </Styles.UploadFileContainer>
          </Styles.ResponseBody>
        </Styles.GenerateResponseContainer>
      </Styles.frsContainer>

      <ConfirmationModal
        title="Delete File"
        text={"Are you sure you want to delete this file?"}
        isOpen={isDeleteOpenModal}
        isDanger={true}
        btnText="Delete"
        handleOk={() => {
          deleteSolutionExtractedFile(SolutionPageId);
          setIsDeleteOpenModal(false);
        }}
        handleCancel={() => {
          setIsDeleteOpenModal(false);
        }}
      />
    </>
  );
};

export default Frs;

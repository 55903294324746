import styled from "styled-components";
import { Input } from "antd";
import { PrimaryButton } from "../../../shared";

export const HeadingText = styled.div`
  font-family: "GraphikMedium";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const SubHeadingText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.fontColorEta};
`;

export const InputField = styled(Input)`
  height: 40px;
`;

export const BackToLoginContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SendLinkButton = styled(PrimaryButton)`
  width: 100%;
  height: 40px;
`;

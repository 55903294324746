import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "../../../shared";
import { RootState } from "../../../store";
import * as Styles from "./styles";
import { useOutsideClick } from "../../../shared/hooks/useOutsideClick";
import { IContent } from "../../../store/contentManagement/contentManagement.interface";

interface ISummaryModal {
  isOpen: boolean;
  setModalClose(): void;
  records: IContent;
  handleSaveDes: (records: IContent) => void;
  description: string;
  setDescription: (value: string) => void;
}

const SummaryModal = ({
  records,
  handleSaveDes,
  isOpen,
  setModalClose,
  description,
  setDescription,
}: ISummaryModal) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const popOverRef = useRef(null);

  const { loadingAssetUpdating } = useSelector(
    (state: RootState) => state.content
  );

  useOutsideClick(popOverRef, () => setModalClose(), isOpen);

  useEffect(() => {
    setDescription(records.summary);
  }, [records.summary]);

  const handleClose = () => {
    setModalClose();
    setIsEditMode(false);
  };

  const handleChangeSummary = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);

    if (e.target.value !== records.summary) {
      setIsValueChanged(true);
    } else {
      setIsValueChanged(false);
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setDescription(records.summary);
  };

  return (
    <Styles.SummaryContainer onClick={(e) => e.stopPropagation()}>
      <Styles.StyledPopover
        trigger="click"
        open={isOpen}
        content={
          <Styles.SummaryContentContainer ref={popOverRef}>
            <Styles.SummaryInput
              style={{ height: "120px", width: "450px", fontSize: "12px" }}
              value={description}
              onChange={handleChangeSummary}
              isEdit={isEditMode}
              placeholder="Type summary"
              readOnly={!isEditMode}
            />
            <Styles.FooterContainer>
              {isEditMode ? (
                <PrimaryButton
                  disabled={!isValueChanged}
                  loading={loadingAssetUpdating}
                  onClick={() => {
                    handleSaveDes(records);
                  }}
                >
                  Save
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    setIsEditMode(true);
                  }}
                >
                  Edit
                </PrimaryButton>
              )}

              {isEditMode ? (
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
              ) : (
                <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
              )}
            </Styles.FooterContainer>
          </Styles.SummaryContentContainer>
        }
        arrow={false}
        placement="bottomLeft"
      ></Styles.StyledPopover>
    </Styles.SummaryContainer>
  );
};

export default SummaryModal;

import * as Styles from "./styles";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../shared";

interface IConfirmationModal {
  isOpen: boolean;
  title: string;
  text: string;
  handleOk: () => void;
  handleCancel: () => void;
  isDanger?: boolean;
  btnText?: string;
  isLoading?: boolean;
}
const ConfirmationModal = ({
  isOpen,
  title,
  text,
  handleOk,
  handleCancel,
  isDanger = false,
  btnText = "Confirm",
  isLoading = false,
}: IConfirmationModal) => {
  return (
    <Styles.ModalContainer>
      <Styles.AntModal
        title={title}
        open={isOpen}
        onCancel={handleCancel}
        centered
        closeIcon={<img src={IMAGES.CloseModalIcon} alt="Close" />}
        footer={[
          <Styles.FooterContainer>
            <PrimaryButton
              onClick={handleOk}
              danger={isDanger}
              loading={isLoading}
            >
              {btnText}
            </PrimaryButton>
            <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
          </Styles.FooterContainer>,
        ]}
      >
        <Styles.ModalBody>{text}</Styles.ModalBody>
      </Styles.AntModal>
    </Styles.ModalContainer>
  );
};

export default ConfirmationModal;

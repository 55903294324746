import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IFailedPromotersDataByDate,
  IInvestmentResearcher,
  IPromotorsData,
  IPromotorsList,
  IResearchHistory,
} from "./investmentResercher.interface";
import { store } from "../../store";
import { RFPGenerationStatus, AiBulletinTab } from "../../constants";
import { getInvestResearchSearchById } from "../../services/investmentResearcher";
import dayjs from "dayjs";

const initialState: IInvestmentResearcher = {
  researchIsLoading: false,
  isPromoterDeleteLoading: false,
  isPromoterAddLoading: false,
  isLoading: false,
  researchUpdateLoading: false,
  researchDeleteLoading: false,
  promotorsDataByDate: [],
  failedPromotersDataByDate: [],
  promptResearcherResults: [
    {
      title: "UX lift _content design.",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
      title: "UX lift _content design.",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
    },
  ],

  researchHistory: [],
  stopResearchAutoRefresh: false,
  isSubsUnSubsLoading: false,
  isGetPromoterLoading: false,
  bulletinDate: String(dayjs().format("YYYY-MM-DD")),
  bulletinTab: AiBulletinTab.PromoterResearcher,
  subscribeStatus: "",
  promotorsList: [],
  selectedResearch: null,
  stopResearchIsLoading: false,
};

export const investmentResearcherSlice = createSlice({
  name: "investmentResearcher",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setResearchIsLoading: (state, action: PayloadAction<boolean>) => {
      state.researchIsLoading = action.payload;
    },

    setStopResearchIsLoading: (state, action: PayloadAction<boolean>) => {
      state.stopResearchIsLoading = action.payload;
    },

    setResearchUpdateLoading: (state, action: PayloadAction<boolean>) => {
      state.researchUpdateLoading = action.payload;
    },

    setPromoterAddLoading: (state, action: PayloadAction<boolean>) => {
      state.isPromoterAddLoading = action.payload;
    },

    setPromoterDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.isPromoterDeleteLoading = action.payload;
    },

    setResearchDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.researchDeleteLoading = action.payload;
    },

    setPromptResearcherResults: (
      state,
      action: PayloadAction<{ title: ""; description: "" }[]>
    ) => {
      state.promptResearcherResults = action.payload;
    },

    setResearchHistory: (state, action: PayloadAction<IResearchHistory[]>) => {
      state.researchHistory = action.payload;
    },

    setPromoterListByDate: (state, action: PayloadAction<IPromotorsData[]>) => {
      state.promotorsDataByDate = action.payload;
    },

    setFailedPromotersDataByDate: (
      state,
      action: PayloadAction<IFailedPromotersDataByDate[]>
    ) => {
      state.failedPromotersDataByDate = action.payload;
    },

    setStopResearchAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.stopResearchAutoRefresh = action.payload;
    },

    setSelectedResearch: (
      state,
      action: PayloadAction<IResearchHistory | null>
    ) => {
      state.selectedResearch = action.payload;
    },

    addResearchHistory: (state, action: PayloadAction<IResearchHistory>) => {
      state.researchHistory.unshift(action.payload);
    },

    updateResearchHistory: (state, action: PayloadAction<IResearchHistory>) => {
      state.researchHistory = state.researchHistory.map((v) =>
        v?.invest_research_id === action.payload.invest_research_id
          ? action.payload
          : v
      );

      if (
        action.payload.invest_research_id ===
        state.selectedResearch?.invest_research_id
      ) {
        state.selectedResearch = action.payload;
      }
    },

    deleteResearchHistory: (
      state,
      action: PayloadAction<{ researchId: number; deleteCurrent: boolean }>
    ) => {
      state.researchHistory = state.researchHistory.filter(
        (v) => v.invest_research_id !== action.payload.researchId
      );
      if (action.payload.deleteCurrent) {
        state.selectedResearch = state.researchHistory[0];
      }
    },

    stoppingResearchHistory: (state, action: PayloadAction<number>) => {
      state.researchHistory = state.researchHistory.map((v) =>
        v?.invest_research_id === action.payload
          ? { ...v, status: RFPGenerationStatus.STOPPING }
          : v
      );

      if (action.payload === state.selectedResearch?.invest_research_id) {
        state.selectedResearch = {
          ...state.selectedResearch,
          status: RFPGenerationStatus.STOPPING,
        };
      }
    },

    autoRefreshResearchStatus: (
      _,
      action: PayloadAction<{ researchId: number }>
    ) => {
      let res: IResearchHistory;
      const interval = setInterval(async () => {
        try {
          res = await getInvestResearchSearchById(action.payload.researchId);

          store.getState().investmentResearcher.stopResearchAutoRefresh &&
            store.getState().investmentResearcher.stopResearchAutoRefresh ===
              true &&
            clearInterval(interval);

          !!res &&
            res?.status === RFPGenerationStatus.COMPLETED &&
            clearInterval(interval);

          !!res &&
            res?.status === RFPGenerationStatus.STOPPING &&
            clearInterval(interval);

          !!res &&
            res?.status === RFPGenerationStatus.STOPPED &&
            clearInterval(interval);

          !!res &&
            res.status === RFPGenerationStatus.FAILED &&
            clearInterval(interval);
        } catch {
          clearInterval(interval);
        }
      }, 20000);
    },

    setIsSubsUnSubsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSubsUnSubsLoading = action.payload;
    },

    setIsGetPromoterLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetPromoterLoading = action.payload;
    },

    setBulletinDate: (state, action: PayloadAction<string>) => {
      state.bulletinDate = action.payload;
    },

    setBulletinTab: (state, action: PayloadAction<string>) => {
      state.bulletinTab = action.payload;
    },

    setClearRequiredStateOnInvestementTab: (state) => {
      state.bulletinDate = String(dayjs().format("YYYY-MM-DD"));
      state.bulletinTab = AiBulletinTab.PromoterResearcher;
    },

    setSubscribeStatus: (state, action: PayloadAction<string>) => {
      state.subscribeStatus = action.payload;
    },

    setPromotorsList: (state, action: PayloadAction<IPromotorsList[]>) => {
      state.promotorsList = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setBulletinTab,
  setBulletinDate,
  setPromotorsList,
  setSubscribeStatus,
  addResearchHistory,
  setResearchHistory,
  setSelectedResearch,
  setResearchIsLoading,
  setPromoterListByDate,
  deleteResearchHistory,
  setPromoterAddLoading,
  updateResearchHistory,
  setIsSubsUnSubsLoading,
  stoppingResearchHistory,
  setIsGetPromoterLoading,
  setResearchDeleteLoading,
  setResearchUpdateLoading,
  setStopResearchIsLoading,
  setPromoterDeleteLoading,
  autoRefreshResearchStatus,
  setPromptResearcherResults,
  setStopResearchAutoRefresh,
  setFailedPromotersDataByDate,
  setClearRequiredStateOnInvestementTab,
} = investmentResearcherSlice.actions;

export default investmentResearcherSlice.reducer;

import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateQualificationReason } from "../../../services/allRfps";
import { PrimaryButton, SecondaryButton } from "../../../shared";
import { RootState } from "../../../store";
import { IAllRfp } from "../../../store/allRfps/allRfp.interface";
import { setAllRfps } from "../../../store/allRfps/allRfpSlice";
import * as Styles from "./styles";
import { useOutsideClick } from "../../../shared/hooks/useOutsideClick";
import { RFPStatus } from "../../../constants";

interface IQRProps {
  rfp: IAllRfp | null;
  onSuccess: (
    value: any,
    rfp: IAllRfp,
    isDisqualify: boolean,
    index: number
  ) => void;
  isOpen: boolean;
  setModalClose(): void;
  isReasonEditMode: boolean;
  selectedIndex: number;
}

const QualificationReason = ({
  rfp,
  onSuccess,
  isOpen,
  setModalClose,
  isReasonEditMode,
  selectedIndex,
}: IQRProps) => {
  const [isEditReason, setIsEditReason] = useState(isReasonEditMode);
  const [isReasonLoading, setIsReasonLoading] = useState(false);
  const [reason, setReason] = useState("");
  const popOverRef = useRef(null);
  const dispatch = useDispatch();

  const { allRfps } = useSelector((state: RootState) => state.allRfps);

  useOutsideClick(popOverRef, () => setModalClose(), isOpen);

  const SaveReason = async (rfp: IAllRfp | null) => {
    if (!rfp) return;

    const response = await UpdateQualificationReason(rfp?.rfp_id, {
      reason: reason.trim(),
    });
    if (response?.status === 200) {
      onSuccess("NON_QUALIFIED", rfp, true, -1);
      const updatedRfp = {
        ...rfp,
        qualification: "NON_QUALIFIED",
        qualification_status: RFPStatus.NOT_STARTED,
        reason: reason.trim(),
        status: RFPStatus.CANCELLED,
      };
      const allRfpsValues = [...allRfps];
      allRfpsValues[selectedIndex] = updatedRfp;
      dispatch(setAllRfps(allRfpsValues));
      handleClose();
    }
    setIsReasonLoading(false);
  };

  const handleClose = () => {
    setModalClose();
    setIsEditReason(false);
    setReason("");
  };

  const handleCancel = () => {
    setIsEditReason(false);
    setReason(rfp?.reason || "");
  };

  return (
    <Styles.QRContainer onClick={(e) => e.stopPropagation()}>
      <Styles.StyledPopover
        trigger="click"
        open={isOpen}
        content={
          <Styles.QRContentContainer ref={popOverRef}>
            <Styles.InputReason
              isEdit={isEditReason}
              placeholder="Type reason for non qualify here..."
              readOnly={!isEditReason}
              autoFocus={true}
              value={reason ? reason : rfp?.reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
            <Styles.QRBtnContainer>
              <Styles.ProfileContainer></Styles.ProfileContainer>
              <Styles.QRBtnSubContainer>
                {isEditReason ? (
                  <PrimaryButton
                    disabled={!reason?.trim()}
                    loading={isReasonLoading}
                    onClick={() => {
                      setIsReasonLoading(true);
                      SaveReason(rfp);
                    }}
                  >
                    Save
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    onClick={() => {
                      setReason(rfp?.reason ?? "");
                      setIsEditReason(true);
                    }}
                  >
                    Edit
                  </PrimaryButton>
                )}

                {isEditReason ? (
                  <SecondaryButton onClick={handleCancel}>
                    Cancel
                  </SecondaryButton>
                ) : (
                  <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
                )}
              </Styles.QRBtnSubContainer>
            </Styles.QRBtnContainer>
          </Styles.QRContentContainer>
        }
        overlayStyle={{ width: "600px" }}
        arrow={false}
        placement="left"
      ></Styles.StyledPopover>
    </Styles.QRContainer>
  );
};

export default QualificationReason;

import { toast } from "react-toastify";
import { store } from "../store";
import {
  addUploadedContent,
  autoRefreshContent,
  refreshedAssetByAssetId,
  setAllContent,
  setIsFilesUploading,
  setLoading,
  setLoadingAssetUpdating,
  setTotalContentCount,
  setIsContentExportCSVLoading,
} from "../store/contentManagement/contentManagementSlice";
import { deleteRequest, get, post, put } from "./apiClient";
import { IContent } from "../store/contentManagement/contentManagement.interface";
import { FileName, PageLimit } from "../constants";
import { onDownloadPdf } from "../shared";

interface IUpdateReleventContent {
  asset_id: number;
  relevant_section: ["string"];
}

interface IUpdateContent {
  asset_id: number;
  title: string;
  summary: string;
}
interface IAssetUpload {
  userId: number;
  content_type: string;
}

export const fetchAllContent = async (
  searchText = "",
  page = 1,
  pageLimit = PageLimit.ContentManagement
) => {
  store.dispatch(setLoading(true));
  try {
    const res = await get(
      `/core/asset/searchByTitleOrSummary?titleOrSummary=${searchText}&page=${
        page - 1
      }&limit=${pageLimit}`
    );
    store.dispatch(setAllContent(res?.content || []));
    store.dispatch(setTotalContentCount(res?.totalElements || 0));
    return res;
  } catch (error) {
    store.dispatch(setAllContent([]));
    store.dispatch(setTotalContentCount(0));
    console.log(error);
    return error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const searchByTitle = async (title: string) => {
  store.dispatch(setLoading(true));
  try {
    const res = await get(`/core/asset/searchByTitle?title=${title}`);
    store.dispatch(setAllContent(res));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const assetUpload = async (
  setUploadingPercentage: (percent: number) => void,
  formData: FormData,
  params: IAssetUpload
) => {
  store.dispatch(setIsFilesUploading(true));

  try {
    const { data } = await post(`/core/asset/apa/assetUpload`, formData, {
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadingPercentage(percentCompleted);
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: params,
    });
    toast.success("Files successfully uploaded");

    store.dispatch(autoRefreshContent({ assetId: data[0]?.asset_id }));
    store.dispatch(addUploadedContent(data[0]));
  } catch (error) {
    console.log(error);
    toast.error("Files not uploaded");
  } finally {
    store.dispatch(setIsFilesUploading(false));
  }
};

export const getAssetById = async (assetId: number): Promise<IContent> => {
  try {
    const data = await get(`core/asset/assetInfo/${assetId}`);
    store.dispatch(refreshedAssetByAssetId({ data, id: assetId }));

    return data;
  } catch (err) {
    console.log("get AssetBy Id api error", err);
    throw err;
  }
};

export const addContent = async (request: any) => {
  try {
    const res = await post(`/core/token/addContent`, request);
    return res;
  } catch (error) {
    console.log("Error while adding Content", error);
    return error;
  }
};

export const updateContent = async (request: IUpdateContent) => {
  store.dispatch(setLoading(true));
  try {
    const res = await put(`/core/asset/editAssetTitleAndSummary`, request);
    toast.success(`Content successfully updated`);
    return res;
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? `Failed to update content`);
    return error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateDescriptionContent = async (
  request: any,
  searchText: string,
  page: number,
  pageLimit: number
) => {
  store.dispatch(setLoadingAssetUpdating(true));
  try {
    const res = await put(`/core/asset/editAssetTitleAndSummary`, request);
    toast.success(`Content successfully updated`);
    fetchAllContent(searchText, page, pageLimit);
    return res;
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? `Failed to update content`);
  } finally {
    store.dispatch(setLoadingAssetUpdating(false));
  }
};

export const updateReleventContent = async (
  request: IUpdateReleventContent
) => {
  try {
    const res = await put(`/core/asset/editRelevantSection`, request);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const removeContent = async (
  assetId: number,
  searchText: string,
  page: number,
  pageLimit: number
) => {
  try {
    const res = await deleteRequest(`/core/asset/deleteAssetInfo/${assetId}`);
    toast.success(`Content successfully deleted`);
    fetchAllContent(searchText, page, pageLimit);
    return res;
  } catch (error) {
    console.log(error);
    toast.success(`Failed to delete`);
    return error;
  }
};

export const getContentExportCSV = async () => {
  store.dispatch(setIsContentExportCSVLoading(true));

  try {
    const res = await get(`/core/asset/apa/generateCsvFile`);
    onDownloadPdf(res, setIsContentExportCSVLoading, FileName.AssetSCV);
    toast.success("Asset successfully exported");
  } catch (error) {
    console.log(error);
    toast.error("Failed to export Asset. Please try again");
  } finally {
    store.dispatch(setIsContentExportCSVLoading(false));
  }
};

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import { RootState } from "../../../../store";
import AddEditCDLModal from "./AddEditCDLModal.tsx/AddEditCDLModal";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../../shared";
import { ICDLDocument } from "../../../../store/overview/overview.interface";
import {
  getCDLDocuments,
  getPreSignedURL,
} from "../../../../services/overview";
import {
  formateDate,
  onDownloadPdf,
  uniqueId,
} from "../../../../shared/helpers";

const CDLDocuments = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isAddDocument, setIsAddDocument] = useState(false);
  const [documentList, setDocumentList] = useState<ICDLDocument[]>();
  const [isDownloadingFileName, setIsDownloadingFileName] = useState("");
  const { CDLDocumentList } = useSelector((state: RootState) => state.overview);
  const [selectedDocument, setSelectedDocument] = useState<
    ICDLDocument | undefined
  >(undefined);

  const { rfpId } = useParams();

  const { isOverviewLoading } = useSelector(
    (state: RootState) => state.overview
  );

  useEffect(() => {
    if (CDLDocumentList.length > 0) {
      setDocumentList(CDLDocumentList);
    } else {
      setDocumentList([]);
    }
  }, [CDLDocumentList]);

  useEffect(() => {
    fetchCDLDocumentList();
  }, []);

  const fetchCDLDocumentList = async () => {
    try {
      if (rfpId) {
        getCDLDocuments(Number(rfpId));
      }
    } catch (error) {
      console.log("Error fetching CDLDocumentList", error);
    }
  };

  const getFileImg = (type: string): string => {
    switch (type) {
      case "PDF":
        return IMAGES.pdfIcon;
      case "PPT":
        return IMAGES.pptIcon;
      case "WORD":
        return IMAGES.wordIcon;
      default:
        return IMAGES.pdfIcon;
    }
  };

  const handleSuccess = () => {
    fetchCDLDocumentList();
    setIsAddDocument(false);
    setSelectedDocument(undefined);
  };

  const handleCancel = () => {
    setIsAddDocument(false);
    setSelectedDocument(undefined);
  };

  const handleDownloadFile = async (fileName: string) => {
    try {
      setIsDownloadingFileName(fileName);
      const resposne: any = await getPreSignedURL(fileName);
      if (resposne.status === 200) {
        onDownloadPdf(resposne.data, setIsDownloading, fileName);
      }
    } catch (error) {
      setIsDownloadingFileName("");
      console.log("Error while downloading file", error);
    }
  };

  const renderCDLDocuments = () => {
    if (isOverviewLoading) {
      return (
        <Styles.CardsContainerLoadingWrap>
          {[...Array(2)].map(() => (
            <Skeleton.Image active key={uniqueId()} />
          ))}
        </Styles.CardsContainerLoadingWrap>
      );
    } else {
      if (documentList && documentList?.length <= 0) {
        return (
          <Styles.AddBtnContainer>
            <PrimaryButton
              icon={<PlusOutlined />}
              onClick={() => {
                setIsAddDocument(true);
              }}
            >
              Upload Documents
            </PrimaryButton>
            {documentList && documentList?.length <= 0 && (
              <Styles.NoRecord>No records found.</Styles.NoRecord>
            )}
          </Styles.AddBtnContainer>
        );
      } else {
        if (!!documentList?.length) {
          return documentList?.map((document) => {
            return (
              <Styles.CDLSubContainer key={document.cdl_doc_id}>
                <Styles.OnLineContainer>
                  <Styles.CDLTitle>{document.title}</Styles.CDLTitle>
                  <Styles.UpdateDate>
                    {!!document?.modified_on &&
                      formateDate(document?.modified_on)}
                  </Styles.UpdateDate>
                  <Styles.EditBtnContainer>
                    <SecondaryButton
                      icon={<EditOutlined />}
                      type="link"
                      onClick={() => {
                        setSelectedDocument(document);
                        setIsAddDocument(true);
                      }}
                    />
                  </Styles.EditBtnContainer>
                </Styles.OnLineContainer>
                <Styles.CDLDetails>
                  <Styles.CDLSummary>{document.note}</Styles.CDLSummary>
                  <Styles.CardsContainerWrap>
                    {document.cdl_files.map((file) => (
                      <Styles.CardContainer key={file.cdl_file_id}>
                        <Styles.DownloadBtnContainer>
                          <SecondaryButton
                            loading={
                              isDownloading &&
                              isDownloadingFileName === file.file_name
                            }
                            type="link"
                            icon={
                              <Styles.DownloadIcon
                                src={IMAGES.downloadContentIcon}
                                alt="download"
                              />
                            }
                            onClick={() => {
                              handleDownloadFile(file?.file_name);
                            }}
                          />
                        </Styles.DownloadBtnContainer>

                        <img src={getFileImg("PDF")} alt="doc" />
                        <Styles.CardHeaderText>
                          {file.file_name}
                        </Styles.CardHeaderText>
                        {/* <Styles.CardSubHeaderText>
                            {file.content}
                          </Styles.CardSubHeaderText> */}
                      </Styles.CardContainer>
                    ))}
                  </Styles.CardsContainerWrap>
                </Styles.CDLDetails>
              </Styles.CDLSubContainer>
            );
          });
        } else {
          return (
            <Styles.AddBtnContainer>
              <PrimaryButton
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsAddDocument(true);
                }}
              >
                Upload Documents
              </PrimaryButton>
              {documentList && documentList?.length <= 0 && (
                <Styles.NoRecord>No records found.</Styles.NoRecord>
              )}
            </Styles.AddBtnContainer>
          );
        }
      }
    }
  };

  return (
    <Styles.CDLContainer>
      {renderCDLDocuments()}
      {isAddDocument && (
        <AddEditCDLModal
          isOpen={isAddDocument}
          document={selectedDocument}
          handleOk={handleSuccess}
          handleCancel={handleCancel}
        />
      )}
    </Styles.CDLContainer>
  );
};

export default CDLDocuments;

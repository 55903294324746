import { toast } from "react-toastify";
import { store } from "../store";
import { nbmData } from "../store/nbm/nbm.interface";
import { deleteRequest, get, post, put } from "./apiClient";
import { RFPGenerationKey, RFPGenerationStatus } from "../constants";
import { updateRfpGenerationStatus } from "../store/overview/overviewSlice";
import {
  setNbmData,
  setIsNbmDataLoading,
  autoRefreshNbmStatus,
  setIsNbmUpdateLoading,
  setStopNbmAutoRefresh,
  setIsNbmExportLoading,
} from "../store/nbm/nbmSlice";

export const getUploadedFiles = async (RFPId: number) => {
  try {
    const res = await get(`apa/nbm/getNBMFileList/${RFPId}`);
    return res;
  } catch (error: unknown) {
    throw error;
  }
};

export const uploadNBMFile = async (
  setUploadingPercentage: (percent: number) => void,
  formData: FormData,
  params: { userId: number; content_type: string; rfpId: number }
) => {
  try {
    const res = await post(`apa/nbm/`, formData, {
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadingPercentage(percentCompleted);
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: params,
    });
    return res;
  } catch (error: unknown) {
    console.log(error, "finance upload support api error");
    throw error;
  }
};

export const pullingNbmFileList = async (rfpId: number) => {
  try {
    const res = await get(`/apa/nbm/getNBMFileList/${rfpId}`);
    return res;
  } catch (error: unknown) {
    console.log(error, "finance upload support api error");
    throw error;
  }
};

export const generateAINBMFile = async (params: {
  userId: number;
  reqType: string;
  rfpId: number;
}) => {
  try {
    const res: any = await post(
      `apa/nbm/generate?userId=${params.userId}&reqType=${params.reqType}&rfpId=${params.rfpId}`
    );
    let getPullingResponse: any = null;
    if (res.data.status === "PROCESSING") {
      const interval = setInterval(async function () {
        getPullingResponse = await pullingNbmFileList(params.rfpId);

        if (getPullingResponse.data.status === "COMPLETED") {
          clearInterval(interval);
        }
      }, 1000);
      clearInterval(interval);
    } else if (res.data.status === "COMPLETED") {
      return res; //  data is in object not in array
    }
    return getPullingResponse;
  } catch (error: unknown) {
    console.log(error, "finance upload support api error");
    throw error;
  }
};

export const deleteNBMFile = async (NBMId: number) => {
  try {
    const res = await deleteRequest(`apa/nbm/${NBMId}`);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/***nbm */
export const generateNbm = async (RFPId: number) => {
  try {
    store.dispatch(setStopNbmAutoRefresh(false)),
      store.dispatch(
        updateRfpGenerationStatus({
          key: RFPGenerationKey.RfpId,
          value: RFPId.toString(),
        })
      );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.NBM,
        value: RFPGenerationStatus.PROCESSING,
      })
    );

    const { data } = await post(`apa/nbm/generate?rfpId=${RFPId}`);

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.NBM,
        value: data.generationStatus,
      })
    );
    store.dispatch(autoRefreshNbmStatus({ rfpId: RFPId }));
  } catch (error: any) {
    console.log(error, "generateNbm api error");
    toast.error(error?.errorMessage ?? "Failed to generate");

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: RFPId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.NBM,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    store.dispatch(setStopNbmAutoRefresh(true));
  }
};

export const getGeneratedNbmById = async (
  RFPId: number,
  isLoading: boolean
): Promise<nbmData> => {
  try {
    isLoading && store.dispatch(setIsNbmDataLoading(true));
    const res = await get(`apa/nbm/${RFPId}/rfp`);

    store.dispatch(setNbmData(res));
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: RFPId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.NBM,
        value: res?.generationStatus,
      })
    );

    return res;
  } catch (error: unknown) {
    console.log("getGeneratedNbmStatus api error", error);
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: RFPId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.NBM,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    throw error;
  } finally {
    store.dispatch(setIsNbmDataLoading(false));
  }
};

export const updateNbm = async (request: nbmData, rfpId: number) => {
  try {
    store.dispatch(setIsNbmUpdateLoading(true));

    await put(`apa/nbm/update`, request);

    getGeneratedNbmById(rfpId, false);

    toast.success("Nbm Updated Successfully");
  } catch (error: unknown) {
    console.log("getGeneratedNbmStatus api error", error);
    toast.error("Failed to update nbm");
  } finally {
    store.dispatch(setIsNbmUpdateLoading(false));
  }
};

export const exportNbm = async (
  nbmId: number,
  rfpId: number,
  rfpFileName: string
) => {
  try {
    store.dispatch(setIsNbmExportLoading(true));
    const res = await get(`apa/nbm/${nbmId}/export/${rfpId}`, {
      responseType: "blob",
    });

    const fileName = !!rfpFileName ? `NBM_${rfpFileName}.pptx` : "NBM.pptx";

    const blobUrl = URL.createObjectURL(res);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", fileName); // Specify the filename for the downloaded file
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (err: any) {
    console.log("exportNbm api error", err);
    toast.error(err?.message ?? "Failed to export nbm.");
  } finally {
    store.dispatch(setIsNbmExportLoading(false));
  }
};

import { Tabs } from "antd";
import styled from "styled-components";

export const FinanceContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  height: 100%;
  overflow: hidden;
`;

export const PrimaryTabs = styled(Tabs)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;

  .ant-tabs-tab-active {
    background-color: #faf0ff;
    border-bottom: 0px solid;
    font-family: GraphikMedium;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
  }

  .ant-tabs-tab-btn {
    font-size: 14px;
  }

  .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-btn {
  }

  .ant-tabs-tab.ant-tabs-tab-inactive .ant-tabs-tab-btn {
    color: var(--Black-60, #45464e);
    font-weight: 400;
    line-height: normal;
    font-family: "GraphikRegular";
  }

  .ant-tabs-ink-bar {
    background-color: #dbdbdb;
  }

  .tab-background[selected="true"] {
    background-color: orange !important;
  }

  .ant-tabs-tab {
    border-bottom: 1px solid #dbdbdb;
    width: 100%;
    height: 60px;
    margin: 0 0 0 0 !important;
  }

  .sc-fopvai.cpzbWc {
    color: #45464e;
  }

  .ant-tabs-content-holder {
    border-left: 0.1px solid #dbdbdb;
    height: 100%;
  }
`;

export const Arrow = styled.div`
  margin-right: -130px;
`;
export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
`;

export const TabContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

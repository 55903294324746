const LOCAL_HOST = ["127.0.0.1", "localhost"];
const PRODUCTION_HOST = ["acnpa.accenture.com"];
const DEV_HOST = ["dev.acnpa.accenture.com"];

const hostname = window?.location?.hostname;

let configs: {
  BASE_URL: string;
  HOST: string;
} = {
  BASE_URL: "",
  HOST: "",
};

if (
  LOCAL_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Localhost environment
  const SOURCE = "https://dev-api.acnpa.accenture.com/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
  };
} else if (
  DEV_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Development environment
  const SOURCE = "https://dev-api.acnpa.accenture.com/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
  };
} else if (
  PRODUCTION_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // production environment
  const SOURCE = "https://api.acnpa.accenture.com/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
  };
}

export default configs;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { RFPGenerationStatus } from "../../constants";
import {
  ICDLDocument,
  IOverView,
  IRPF,
  IRfpGenerationStatus,
} from "./overview.interface";

const overViewInitialValues: IOverView = {
  file_name: "",
  qualification_status: null,
  rfp_id: 0,
  rfp_name: "",
  is_rfp_name_override: false,
  client_name: null,
  is_client_name_override: false,
  stakeholders: null,
  is_stakeholders_override: false,
  rfp_summary: "",
  is_rfp_summary_override: false,
  uploaded_on: "",
  last_Updated_On: "",
  qualification: null,
  is_qualification_override: false,
  reason: null,
  is_reason_override: false,
  status: "",
  is_status_override: false,
  user: {
    status: null,
    id: 0,
    username: "",
    name: "",
    email: "",
    defaultSettingId: null,
    userPermissions: [],
    expiryDate: null,
  },
  department: {
    id: 0,
    departmentName: "",
  },
  is_department_override: false,
  pre_signed_url: "",
  bucket_name: "",
  error_detail: null,
  bid_stage: "",
};

const rfpGenerationStatusInitialValue: IRfpGenerationStatus = {
  rfpId: "-1",
  finance: RFPGenerationStatus.NOT_STARTED,
  legal: RFPGenerationStatus.NOT_STARTED,
  solution: RFPGenerationStatus.NOT_STARTED,
  policy966: RFPGenerationStatus.NOT_STARTED,
  ati: RFPGenerationStatus.NOT_STARTED,
  nbm: RFPGenerationStatus.NOT_STARTED,
};

const initialState: IRPF = {
  getOverView: overViewInitialValues,
  isOverviewLoading: false,
  CDLDocumentList: [],
  rfpGenerationStatus: rfpGenerationStatusInitialValue,
  isRfpGenerationStatusLoading: false,
  stopPolicy966NavigationCheck: true,
  currentRfpStatus: "",
};

export const rfpSlice = createSlice({
  name: "overviewSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setOverview: (state, action: PayloadAction<IOverView>) => {
      state.getOverView = action.payload;
    },

    setCurrentRfpStatus: (state, action: PayloadAction<string>) => {
      state.currentRfpStatus = action.payload;
    },

    setIsOverviewLoading: (state, action: PayloadAction<boolean>) => {
      state.isOverviewLoading = action.payload;
    },

    setCDLDocumentList: (state, action: PayloadAction<ICDLDocument[]>) => {
      state.CDLDocumentList = action.payload;
    },

    updateRfpGenerationStatus: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState.rfpGenerationStatus;
        value: string;
      }>
    ) => {
      state.rfpGenerationStatus[action.payload.key] = action.payload.value;
    },

    setIsRfpGenerationStatusLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isRfpGenerationStatusLoading = action.payload;
    },

    setStopPolicy966NavigationCheck: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.stopPolicy966NavigationCheck = action.payload;
    },
  },
});

export const {
  setOverview,
  setCDLDocumentList,
  setIsOverviewLoading,
  setCurrentRfpStatus,
  updateRfpGenerationStatus,
  setIsRfpGenerationStatusLoading,
  setStopPolicy966NavigationCheck,
} = rfpSlice.actions;

export default rfpSlice.reducer;

import { store } from "../store";
import { setAmContent, setIsAmContentLoading } from "../store/a&m/a&mSlice";
import { get } from "./apiClient";

export const getAllAMContent = async (RFPId: number) => {
  try {
    store.dispatch(setIsAmContentLoading(true));

    const { solution } = await get(`apa/profile/${RFPId}/proposals`);
    store.dispatch(setAmContent(solution?.aandM));
  } catch (err) {
    console.log("getAllAMContent api error", err);
    throw err;
  } finally {
    store.dispatch(setIsAmContentLoading(false));
  }
};

import { CloseOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import {
  IMAGES,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../../../../shared";
import * as Styles from "./styles";
import { IReleventPage } from "./ProjectPlan.interface";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { IAllRfp } from "../../../../store/allRfps/allRfp.interface";
import {
  deleteExtractionFile,
  extractProjectPlanDetails,
  generateProjectPlanDetails,
  getAllProposalsDetails,
  getProjectPlanDetails,
  updateRelevantPages,
} from "../../../../services/projectPlan";
import {
  setExtractStatus,
  setGenerateResponseStatus,
  setProjectPlanDetails,
} from "../../../../store/projectPlan/projectPlanSlice";
import { ExtractionStatus, PATHS } from "../../../../constants";
import { toast } from "react-toastify";
import { getFileExtension, onDownloadPdf } from "../../../../shared/helpers";
import { stopRFPExtGen } from "../../../../services/stopRfpExtGen";
import ConfirmationModal from "../../../common/confirmModal/ConfirmationModal";

let ExtractionInterval: ReturnType<typeof setInterval>;
let GenerationInterval: ReturnType<typeof setInterval>;

const ProjectPlan = () => {
  const { projectPlanDetails } = useSelector(
    (state: RootState) => state.projectPlan
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allRfps } = useSelector((state: RootState) => state.allRfps);
  const { rfpId } = useParams();
  const [ppId, setPpId] = useState<string | number>();
  const [relevantPages, setRelevantPages] = useState<any>([]);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [editIndex, setEditIndex] = useState(-1);
  const [isAddRelevantPage, setIsAddRelevantPage] = useState(false);
  const [selectedRFP, setSelectedRFP] = useState<IAllRfp>();
  const [isExtractionLoading, setIsExtractionLoading] = useState(false);
  const [isExtractedFileDeleting, setIsExtractedFileDeleting] = useState(false);
  const [isRemainingExtraction, setIsRemainingExtraction] = useState(false);
  const [isDownloadingExtractedFile, setIsDownloadingExtractedFile] =
    useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  // Generation states
  const inputClauseFile = useRef<HTMLInputElement>(null);
  const [isDownloadingGeneratedFile, setIsDownloadingGeneratedFile] =
    useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [isStopExtracting, setIsStopExtracting] = useState(false);
  const [isStopGeneration, setIsStopGeneration] = useState(false);
  const [isRemainingGeneration, setIsRemainingGeneration] = useState(false);

  const SolutionPageId = !!projectPlanDetails?.prId
    ? projectPlanDetails.prId
    : -1;

  useEffect(() => {
    if (rfpId) {
      fetchProjectPlanDetails();
      const findRFP = allRfps?.find((rfp) => rfp.rfp_id === Number(rfpId));
      if (findRFP) {
        setSelectedRFP(findRFP);
      }
    }
  }, [rfpId]);

  const fetchProjectPlanDetails = async () => {
    try {
      const response = await getAllProposalsDetails(rfpId);

      if (!!response?.solution?.projectPlan) {
        dispatch(setProjectPlanDetails(response?.solution?.projectPlan));
      }
    } catch (error: any) {
      if (error.errorCode === 400 && error?.errorMessage) {
        toast.error(error?.errorMessage);
      }
      navigate(`/rfp/${rfpId}/${PATHS.overview}`);
      console.log("Failed to fetch project plan details", error);
    }
  };

  useEffect(() => {
    if (projectPlanDetails?.relevantPages?.length) {
      setRelevantPages(projectPlanDetails?.relevantPages);
      if (
        projectPlanDetails?.extractionStatus === ExtractionStatus.PROCESSING ||
        projectPlanDetails?.extractionStatus === ExtractionStatus.STOPPING
      ) {
        setIsRemainingExtraction(true);
      }
      // Here need to add for generation also
    }
  }, [projectPlanDetails]);

  useEffect(() => {
    if (projectPlanDetails?.prId) {
      setPpId(projectPlanDetails?.prId);
    }
  }, [projectPlanDetails]);

  const addRelevantPages = () => {
    setRelevantPages([...relevantPages, { start_page: 0, end_page: 0 }]);
    setEditIndex(relevantPages.length);
    setIsAddRelevantPage(true);
  };

  const handleEdit = (index: number) => {
    setStartPage(relevantPages[index].start_page);
    setEndPage(relevantPages[index].end_page);
    setEditIndex(index);
  };

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setStartPage(Number(value));
    }
  };
  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setEndPage(Number(value));
    }
  };

  const handleSaveRelevantPage = () => {
    let data = [...relevantPages];
    const updatedPage = { end_page: endPage, start_page: startPage };
    data[editIndex] = updatedPage;
    setRelevantPages(data);
    setEditIndex(-1);
    setStartPage(0);
    setEndPage(0);
    setIsAddRelevantPage(false);
    updateRelevantPages(
      Number(ppId),
      data,
      "Project plan pages updated successfully"
    );
  };

  const deleteRelevantPage = (index: number) => {
    const remainingPages = relevantPages.filter(
      (_: any, i: number) => index !== i
    );
    updateRelevantPages(
      Number(ppId),
      remainingPages,
      "Project plan pages deleted successfully"
    );
    setRelevantPages(remainingPages);
  };

  const handleExtractionClause = async () => {
    try {
      setIsExtractionLoading(true);
      if (projectPlanDetails?.prId && selectedRFP?.file_name) {
        const response = await extractProjectPlanDetails(
          projectPlanDetails?.prId,
          selectedRFP?.file_name
        );
        if (response.status === 200) {
          FetchPpDetails();
          setIsRemainingExtraction(true);
        }
      }
      setIsExtractionLoading(false);
    } catch (error) {
      setIsExtractionLoading(false);
      console.log("Error while extracting clause finane", error);
    }
  };

  useEffect(() => {
    if (isRemainingExtraction) {
      ExtractionInterval = setInterval(async () => {
        const res = await getProjectPlanDetails(projectPlanDetails?.prId);
        if (
          res?.extractionStatus !== ExtractionStatus.PROCESSING &&
          res?.extractionStatus !== ExtractionStatus.STOPPING
        ) {
          dispatch(setProjectPlanDetails(res));
          setIsRemainingExtraction(false);
          clearInterval(ExtractionInterval);
        }
      }, 10000);

      return () => {
        clearInterval(ExtractionInterval);
      };
    }
  }, [isRemainingExtraction]);

  const FetchPpDetails = async () => {
    try {
      const response = await getProjectPlanDetails(projectPlanDetails?.prId);
      if (response?.prId) {
        dispatch(setProjectPlanDetails(response));
      }
    } catch (error) {
      console.log("Error while getting finance details", error);
    }
  };

  const handleDeleteExtractionFile = async () => {
    try {
      if (projectPlanDetails?.prId) {
        setIsExtractedFileDeleting(true);
        const response: any = await deleteExtractionFile(
          projectPlanDetails?.prId
        );
        if (response?.status === 200) {
          toast.success("File deleted successfully");
          FetchPpDetails();
        }
      }
      setIsExtractedFileDeleting(false);
    } catch (error) {
      setIsExtractedFileDeleting(false);
      console.log("Error while deleting extracted file", error);
    }
  };

  const isDisabledExtractionBtn = () => {
    return (
      relevantPages?.length <= 0 ||
      projectPlanDetails?.extractionStatus === ExtractionStatus.PROCESSING ||
      projectPlanDetails?.extractionStatus === ExtractionStatus.STOPPING ||
      projectPlanDetails?.extractionStatus === ExtractionStatus.COMPLETED
    );
  };

  const handleClauseFileUploadButton = () => {
    if (inputClauseFile.current != null) {
      inputClauseFile.current.click();
    }
  };

  const handleClauseFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files && e.target.files[0];
    if (file && file !== null) {
      setUploadedFile(file);
      const formData = new FormData();
      formData.append("file", file);

      const uploadedFile = formData.get("file") as File;
      setUploadedFileName(uploadedFile.name);

      dispatch(setGenerateResponseStatus(ExtractionStatus.UPLOADED));
    }
  };

  const renderGenerateResponse = () => {
    switch (projectPlanDetails?.generatedResponseFileStatus) {
      case ExtractionStatus.NOT_STARTED:
      case ExtractionStatus.AI:
      default:
        return (
          <>
            <Styles.Description>
              {projectPlanDetails?.generatedResponseFileStatus !==
              ExtractionStatus.COMPLETED
                ? "You can directly upload your modified file to generate responses"
                : "The above file generated is an assistive step for your RFP Frs clause extraction. To generate a response you can either use the above generated AI file or upload your own modified file"}
            </Styles.Description>
            <Styles.SubContainer>
              <PrimaryButton
                onClick={() =>
                  dispatch(
                    setGenerateResponseStatus(ExtractionStatus.UPLOADING)
                  )
                }
                disabled={
                  projectPlanDetails?.generatedResponseFileStatus ===
                    ExtractionStatus.AI ||
                  projectPlanDetails?.generatedResponseFileStatus ===
                    ExtractionStatus.PROCESSING ||
                  projectPlanDetails?.generatedResponseFileStatus ===
                    ExtractionStatus.STOPPING
                }
              >
                Upload modified file
              </PrimaryButton>
              <Styles.Span>Or</Styles.Span>
              <Styles.AIFilesButton
                $active={
                  projectPlanDetails?.generatedResponseFileStatus ===
                  ExtractionStatus.AI
                }
                disabled={
                  projectPlanDetails?.extractionStatus !==
                    ExtractionStatus.COMPLETED ||
                  projectPlanDetails?.generatedResponseFileStatus ===
                    ExtractionStatus.PROCESSING ||
                  projectPlanDetails?.generatedResponseFileStatus ===
                    ExtractionStatus.STOPPING ||
                  isGenerationLoading
                }
                onClick={() =>
                  dispatch(setGenerateResponseStatus(ExtractionStatus.AI))
                }
              >
                Use AI generated file
              </Styles.AIFilesButton>
              <Styles.NoteContainer>
                <Styles.NoteTitle>Note:</Styles.NoteTitle>
                <Styles.NoteDescription>
                  Please verify all the documents before processing
                </Styles.NoteDescription>
              </Styles.NoteContainer>
            </Styles.SubContainer>
          </>
        );

      case ExtractionStatus.COMPLETED:
        return (
          <>
            <Styles.Description>
              Your file processing is successfully completed and is now
              available for download.
            </Styles.Description>
            <Styles.DownloadContainer>
              <PrimaryButton
                loading={isDownloadingGeneratedFile}
                onClick={() => {
                  if (projectPlanDetails?.generatedResponseFilePreSignedUrl)
                    onDownloadPdf(
                      projectPlanDetails?.generatedResponseFileName,
                      setIsDownloadingGeneratedFile,
                      projectPlanDetails?.generatedResponseFileName
                    );
                }}
              >
                Download
              </PrimaryButton>
              <Styles.Span>
                {projectPlanDetails?.generatedResponseFileName}
              </Styles.Span>
            </Styles.DownloadContainer>
          </>
        );

      case ExtractionStatus.UPLOADED:
      case ExtractionStatus.UPLOADING:
        return (
          <>
            <Styles.Description>
              Please note before uploading, ensure it is a{" "}
              <span>single column</span> file titled “Legal Clause” or similar.
            </Styles.Description>
            <Styles.UploadFileTitle>
              Upload your modified file here
            </Styles.UploadFileTitle>
            <Styles.UploadSubContainer>
              <Styles.UploadIconTitle>
                <img src={IMAGES.browse} alt="Browse" />
                <Styles.Span>{uploadedFileName ?? "Browse"}</Styles.Span>
              </Styles.UploadIconTitle>

              <PrimaryButton onClick={handleClauseFileUploadButton}>
                Browse
              </PrimaryButton>
              <input
                type="file"
                ref={inputClauseFile}
                accept=".xlsx, .xls, .csv"
                onChange={handleClauseFileUpload}
                hidden
              />
            </Styles.UploadSubContainer>
          </>
        );
    }
  };

  const handleStopExtGen = async (type: "Extraction" | "Generation") => {
    try {
      if (!SolutionPageId || !Number(rfpId) || !selectedRFP?.file_name) return;

      const isExtraction = type === "Extraction" ? 1 : 0;
      const isStopState =
        type === "Extraction" ? setIsStopExtracting : setIsStopGeneration;

      isStopState(true);

      const request = {
        id: Number(SolutionPageId),
        rfpId: Number(rfpId),
        rfpFileName: selectedRFP.file_name,
        proposalsCategory: "SOLUTION",
        isExtraction,
        proposalSubCategory: "PROJECTPLAN",
      };

      const response = await stopRFPExtGen(request);

      if (response?.status === 200) {
        const { status } = response.data;
        if (status) {
          type === "Extraction"
            ? dispatch(setExtractStatus(status))
            : dispatch(setGenerateResponseStatus(status));
        } else {
          type === "Extraction"
            ? dispatch(setExtractStatus(ExtractionStatus.STOPPING))
            : dispatch(setGenerateResponseStatus(ExtractionStatus.STOPPING));
          toast.error(
            `we can't stop now. The ${type} has been completed successfully.`
          );
        }
      }

      isStopState(false);
    } catch (error) {
      type === "Extraction"
        ? setIsStopExtracting(false)
        : setIsStopGeneration(false);
      console.log(`Error while stop ${type} file`, error);
    }
  };

  const handleCancelProcessing = () => {
    dispatch(setGenerateResponseStatus(ExtractionStatus.NOT_STARTED));

    setUploadedFileName(null);
    setUploadedFile(null);
    if (inputClauseFile.current) {
      inputClauseFile.current.value = "";
    }
  };

  //  Generation
  const handleStartProcessing = async () => {
    try {
      if (!Number(rfpId) || !selectedRFP?.file_name) return;
      const formData = new FormData();
      uploadedFile && formData.append("file", uploadedFile);

      const file = formData?.get("file") as File;
      const fileName = file?.name;
      const fileType = fileName
        ? fileName?.substring(fileName.lastIndexOf(".") + 1)
        : "";
      setIsGenerationLoading(true);
      if (projectPlanDetails?.prId && selectedRFP?.file_name) {
        const response = await generateProjectPlanDetails(
          {
            rfpId: Number(rfpId),
            contentType: getFileExtension(
              projectPlanDetails?.projectPlanFileName
            ),
            preSignedUrl: projectPlanDetails?.projectPlanFilePreSignedUrl,
            prId: projectPlanDetails?.prId,
          },
          projectPlanDetails?.generatedResponseFileStatus,
          formData,
          fileType ?? "csv"
        );
        if (response.status === 200) {
          FetchPpDetails();
          setIsRemainingGeneration(true);
        }
      }
      setIsGenerationLoading(false);
    } catch (error) {
      setIsGenerationLoading(false);
      console.log("Error while generation clause project plan", error);
    }
  };

  useEffect(() => {
    if (isRemainingGeneration) {
      GenerationInterval = setInterval(async () => {
        const res = await getProjectPlanDetails(projectPlanDetails?.prId);
        if (
          res?.generatedResponseFileStatus !== ExtractionStatus.PROCESSING &&
          res?.generatedResponseFileStatus !== ExtractionStatus.STOPPING
        ) {
          dispatch(setProjectPlanDetails(res));
          setIsRemainingGeneration(false);
          clearInterval(GenerationInterval);
        }
      }, 10000);

      return () => {
        clearInterval(GenerationInterval);
      };
    }
  }, [isRemainingExtraction]);

  return (
    <Styles.QualificationContent>
      <Styles.Details>
        <Styles.TitlePage>
          Relevant pages for Project Plan
          <Styles.AIIcon>
            <img src={IMAGES.aiTagLogo} alt="Logo" />
          </Styles.AIIcon>
        </Styles.TitlePage>
        <Styles.PagesContainer>
          {relevantPages?.length > 0 &&
            relevantPages.map((item: IReleventPage, index: number) => {
              return (
                <Styles.BadgeContainer key={index}>
                  <Styles.BadgeCustom
                    status="default"
                    count={
                      editIndex !== index ? (
                        <SecondaryButton
                          disabled={isDisabledExtractionBtn()}
                          type="text"
                          shape="circle"
                          icon={
                            <CloseOutlined
                              style={{ color: "#fff", fontSize: "10px" }}
                              onClick={() => {
                                deleteRelevantPage(index);
                              }}
                            />
                          }
                        />
                      ) : (
                        0
                      )
                    }
                  >
                    <Styles.PageBox isedit={editIndex === index ? 1 : 0}>
                      {editIndex === index ? (
                        <>
                          <Styles.PageBoxEditContainer>
                            <Styles.PageBoxSubContainer>
                              <Styles.FromTO>From</Styles.FromTO>
                              <Styles.AntInput
                                value={startPage}
                                onChange={handleFromChange}
                              />
                            </Styles.PageBoxSubContainer>
                            <Styles.BorderRight></Styles.BorderRight>
                            <Styles.PageBoxSubContainer>
                              <Styles.FromTO>To</Styles.FromTO>
                              <Styles.AntInput
                                value={endPage}
                                onChange={handleToChange}
                              />
                            </Styles.PageBoxSubContainer>
                          </Styles.PageBoxEditContainer>
                          <Styles.ButtonLink
                            type="link"
                            disabled={
                              startPage <= 0 ||
                              endPage <= 0 ||
                              projectPlanDetails?.extractionStatus ===
                                ExtractionStatus.COMPLETED
                            }
                            onClick={handleSaveRelevantPage}
                          >
                            {isAddRelevantPage ? "Add" : "Save"}
                          </Styles.ButtonLink>
                        </>
                      ) : (
                        <Styles.PageBoxViewContainer>
                          <Styles.PageText>
                            {item?.start_page} to {item?.end_page}
                          </Styles.PageText>
                          <Styles.ButtonLink
                            disabled={isDisabledExtractionBtn()}
                            type="link"
                            onClick={() => {
                              handleEdit(index);
                            }}
                          >
                            Edit
                          </Styles.ButtonLink>
                        </Styles.PageBoxViewContainer>
                      )}
                    </Styles.PageBox>
                  </Styles.BadgeCustom>
                </Styles.BadgeContainer>
              );
            })}
        </Styles.PagesContainer>

        <Styles.ReleventPagesFooter>
          <Styles.BtnStyling>
            <TertiaryButton
              disabled={
                projectPlanDetails?.extractionStatus !==
                ExtractionStatus.NOT_STARTED
              }
              icon={<PlusOutlined />}
              onClick={addRelevantPages}
            >
              Add more pages
            </TertiaryButton>
          </Styles.BtnStyling>
          <Styles.FooterRightPart>
            {projectPlanDetails?.extractionStatus ===
              ExtractionStatus.PROCESSING &&
              !isStopExtracting && (
                <Styles.ExtractLegalContainer>
                  <img src={IMAGES.loadingSpinner} alt="loading" />
                  <Styles.GenerateText>
                    Generating output...
                  </Styles.GenerateText>
                </Styles.ExtractLegalContainer>
              )}
            {(projectPlanDetails?.extractionStatus ===
              ExtractionStatus.STOPPING ||
              isStopExtracting) && (
              <Styles.ExtractLegalContainer>
                <img src={IMAGES.loadingSpinner} alt="loading" />
                <Styles.GenerateText>
                  Stopping Generation...
                </Styles.GenerateText>
                <SecondaryButton
                  type="text"
                  shape="circle"
                  onClick={() => {
                    handleStopExtGen("Extraction");
                  }}
                  icon={<img src={IMAGES.stop} alt="Stop" />}
                />
              </Styles.ExtractLegalContainer>
            )}
            {projectPlanDetails?.extractionStatus !==
              ExtractionStatus.COMPLETED && (
              <Styles.ExtractButton
                loading={isExtractionLoading}
                disabled={isDisabledExtractionBtn()}
                onClick={handleExtractionClause}
              >
                <img src={IMAGES.submitProcess} alt="AI" />
                Extract requirements
              </Styles.ExtractButton>
            )}
            {projectPlanDetails?.extractionStatus ===
              ExtractionStatus.COMPLETED && (
              <>
                <Styles.FileText>
                  {projectPlanDetails?.projectPlanFileName}
                </Styles.FileText>
                <PrimaryButton
                  loading={isDownloadingExtractedFile}
                  onClick={() => {
                    if (projectPlanDetails?.projectPlanFilePreSignedUrl) {
                      onDownloadPdf(
                        projectPlanDetails?.projectPlanFilePreSignedUrl,
                        setIsDownloadingExtractedFile,
                        projectPlanDetails?.projectPlanFileName
                      );
                    }
                  }}
                >
                  Download
                </PrimaryButton>
                <Styles.DeleteButton
                  loading={isExtractedFileDeleting}
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  danger
                />
              </>
            )}
            {/* <img src={IMAGES.loading} alt="Logo" /> */}
          </Styles.FooterRightPart>
        </Styles.ReleventPagesFooter>
      </Styles.Details>
      <Styles.GenerateResponseContainer>
        <Styles.GenerateTitle>Generate response</Styles.GenerateTitle>
        <Styles.ResponseBody>
          {renderGenerateResponse()}

          <Styles.UploadFileContainer>
            {projectPlanDetails?.generatedResponseFileStatus ===
              ExtractionStatus.PROCESSING &&
              !isStopGeneration && (
                <Styles.CancelStartContainer>
                  <img src={IMAGES.loadingSpinner} alt="loading" />
                  <Styles.GenerateText>
                    Generating output...
                  </Styles.GenerateText>
                  <SecondaryButton
                    type="text"
                    shape="circle"
                    onClick={() => {
                      handleStopExtGen("Generation");
                    }}
                    icon={<img src={IMAGES.stop} alt="Stop" />}
                  />
                </Styles.CancelStartContainer>
              )}
            {(projectPlanDetails?.generatedResponseFileStatus ===
              ExtractionStatus.STOPPING ||
              isStopGeneration) && (
              <Styles.CancelStartContainer>
                <img src={IMAGES.loadingSpinner} alt="loading" />
                <Styles.GenerateText>
                  Stopping Generation...
                </Styles.GenerateText>
              </Styles.CancelStartContainer>
            )}
            {(projectPlanDetails?.generatedResponseFileStatus ===
              ExtractionStatus.NOT_STARTED ||
              projectPlanDetails?.generatedResponseFileStatus ===
                ExtractionStatus.AI ||
              projectPlanDetails?.generatedResponseFileStatus ===
                ExtractionStatus.UPLOADED ||
              projectPlanDetails?.generatedResponseFileStatus ===
                ExtractionStatus.UPLOADING) &&
              !isStopGeneration && (
                <Styles.CancelStartContainer>
                  <SecondaryButton
                    disabled={
                      projectPlanDetails?.generatedResponseFileStatus ===
                      ExtractionStatus.NOT_STARTED
                    }
                    onClick={handleCancelProcessing}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={handleStartProcessing}
                    disabled={
                      projectPlanDetails?.generatedResponseFileStatus ===
                        ExtractionStatus.NOT_STARTED ||
                      isGenerationLoading ||
                      projectPlanDetails?.generatedResponseFileStatus ===
                        ExtractionStatus.UPLOADING
                    }
                    loading={isGenerationLoading}
                  >
                    Start Processing
                  </PrimaryButton>
                </Styles.CancelStartContainer>
              )}
          </Styles.UploadFileContainer>
        </Styles.ResponseBody>
      </Styles.GenerateResponseContainer>

      {/* {renderInputForms()} */}
      <ConfirmationModal
        title="Delete File"
        text={"Are you sure you want to delete this file?"}
        isOpen={isOpenModal}
        isDanger={true}
        btnText="Delete"
        handleOk={() => {
          setIsOpenModal(false);
          handleDeleteExtractionFile();
        }}
        handleCancel={() => {
          setIsOpenModal(false);
        }}
      />
    </Styles.QualificationContent>
  );
};

export default ProjectPlan;

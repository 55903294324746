import styled from "styled-components";
import { Pagination } from "antd";

export const PaginationWrapper = styled.div`
  ul.ant-pagination {
    padding: 12px 20px;
    background: #fff;
    justify-content: end;
    display: flex;
  }
`;

export const CustomPagination = styled(Pagination)``;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUploadRFP } from "./rfpUpload.interface";
import { RFPUploadSteps } from "../../constants";

const initialState: IUploadRFP = {
  uploadedSubFiles: [],
  subFiles: [],
  mainFile: null,
  isFilesUploading: false,
  mainFileUploadProgress: 0,
  subFileUploadProgress: 0,
  uploadSteps: RFPUploadSteps.BROWSE,
  currentSubUploading: null,
};

export const rfpUploadSlice = createSlice({
  name: "rfpUploadSlice",
  initialState,
  reducers: {
    setUploadedSubFiles: (state, action: PayloadAction<string[]>) => {
      state.uploadedSubFiles = action.payload;
    },

    updateUploadedSubFiles: (state, action: PayloadAction<string>) => {
      state.uploadedSubFiles.push(action.payload);

      // remove file from uploading state
      state.subFiles = state.subFiles?.filter(
        (file) => file.name !== action.payload
      );
    },

    setSubFiles: (state, action: PayloadAction<File[] | undefined>) => {
      state.subFiles = action.payload;
    },

    removeSubFiles: (state, action: PayloadAction<string>) => {
      state.subFiles = state.subFiles?.filter(
        (file) => file.name !== action.payload
      );
    },

    setMailFile: (state, action: PayloadAction<File | null>) => {
      state.mainFile = action.payload;
    },

    setIsFilesUploading: (state, action: PayloadAction<boolean>) => {
      state.isFilesUploading = action.payload;
    },

    setMainFileUploadProgress: (state, action: PayloadAction<number>) => {
      state.mainFileUploadProgress = action.payload;
    },

    setSubFileUploadProgress: (state, action: PayloadAction<number>) => {
      state.subFileUploadProgress = action.payload;
    },

    setCurrentSubUploading: (state, action: PayloadAction<File | null>) => {
      state.currentSubUploading = action.payload;
    },

    setUploadSteps: (state, action: PayloadAction<RFPUploadSteps>) => {
      state.uploadSteps = action.payload;
    },

    resetUploadStates: (state) => {
      state.subFiles = [];
      state.mainFile = null;
      state.uploadedSubFiles = [];
      state.isFilesUploading = false;
      state.subFileUploadProgress = 0;
      state.currentSubUploading = null;
      state.mainFileUploadProgress = 0;
      state.uploadSteps = RFPUploadSteps.BROWSE;
    },
  },
});

export const {
  setSubFiles,
  setMailFile,
  removeSubFiles,
  setUploadSteps,
  resetUploadStates,
  setIsFilesUploading,
  setUploadedSubFiles,
  updateUploadedSubFiles,
  setCurrentSubUploading,
  setSubFileUploadProgress,
  setMainFileUploadProgress,
} = rfpUploadSlice.actions;

export default rfpUploadSlice.reducer;

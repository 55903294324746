import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DownloadOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { exportNbm } from "../../../services/nbm";
import { IMAGES, bidStageOptions } from "../../../shared";
import { updateBidStage, updateStatus } from "../../../services/overview";
import { PATHS, RFPGenerationStatus, RFPStatus } from "../../../constants";
import { setCurrentRfpStatus } from "../../../store/overview/overviewSlice";

const Header = () => {
  const location = useLocation();
  const pathnameParts = location.pathname.split("/");
  const currentPage = pathnameParts[pathnameParts.length - 1];

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { rfpId } = useParams();

  const currentRfpId: number = !!rfpId ? Number(rfpId) : -1;

  const {
    getOverView,
    currentRfpStatus,
    rfpGenerationStatus,
    isOverviewLoading,
  } = useSelector((state: RootState) => state.overview);

  const { isNbmExportLoading, nbmData } = useSelector(
    (state: RootState) => state.nbm
  );

  const [bidStage, setBidStage] = useState<string>("");

  const rfpOptions = [
    { value: "documents", label: "RFP Documents" },
    {
      value: "overview",
      label: "Overview",
    },
    {
      value: "ati",
      label: "ATI",
      disabled: rfpGenerationStatus?.ati !== RFPGenerationStatus.COMPLETED,
    },
    {
      value: "policy966",
      label: "Policy 966",
      disabled:
        rfpGenerationStatus?.policy966 !== RFPGenerationStatus.COMPLETED,
    },
    {
      value: "nbm",
      label: "NBM",
      disabled: rfpGenerationStatus?.nbm !== RFPGenerationStatus.COMPLETED,
    },
    {
      value: "finance",
      label: "Finance",
      disabled: rfpGenerationStatus?.finance !== RFPGenerationStatus.COMPLETED,
    },
    {
      value: "legal",
      label: "Legal",
      disabled: rfpGenerationStatus?.legal !== RFPGenerationStatus.COMPLETED,
    },
    {
      value: "solution",
      label: "Solution",
      disabled: rfpGenerationStatus?.solution !== RFPGenerationStatus.COMPLETED,
    },
  ];

  useEffect(() => {
    setBidStage(getOverView?.bid_stage),
      dispatch(setCurrentRfpStatus(getOverView?.status));
  }, [getOverView]);

  const handleChange = (value: any) => {
    navigate(value);
  };

  const handleBidStage = useCallback((value: any) => {
    updateBidStage(Number(rfpId), value);
    setBidStage(value);
  }, []);

  const handleExportNbm = async () => {
    if (!!currentRfpId) {
      await exportNbm(nbmData?.nbmId, currentRfpId, nbmData?.rfpFileName);
    } else {
      toast.error("Failed to export nbm.");
    }
  };

  const renderNbmExport = () => {
    return (
      <Styles.ExportAtiButton
        icon={<DownloadOutlined />}
        loading={isNbmExportLoading}
        onClick={handleExportNbm}
      >
        Export
      </Styles.ExportAtiButton>
    );
  };

  return (
    <Styles.OverviewHeader>
      <Styles.SubHeader>
        <Styles.BackStyle onClick={() => navigate(-1)}>
          <img src={IMAGES.leftArrowWithBG} alt="Icon" />
        </Styles.BackStyle>

        <Styles.AntSelect
          disabled={false}
          value={currentPage}
          options={rfpOptions}
          onChange={handleChange}
        />
        <Styles.AntSelectStatus
          $RfpStatus={currentRfpStatus}
          value={currentRfpStatus}
          loading={isOverviewLoading}
          options={[
            { value: RFPStatus.NOT_STARTED, label: "NOT STARTED" },
            { value: RFPStatus.IN_PROGRESS, label: "IN PROGRESS" },
            { value: RFPStatus.ON_HOLD, label: "ON HOLD" },
            { value: RFPStatus.COMPLETED, label: "COMPLETED" },
          ]}
          onChange={(values: any) => {
            updateStatus(Number(rfpId), { status: values });
          }}
        />
      </Styles.SubHeader>
      <Styles.SubHeader>
        <Styles.BidStage
          placeholder="Stage"
          style={{ width: 120 }}
          value={bidStage}
          options={bidStageOptions}
          onChange={handleBidStage}
        />
        <Styles.OrganogramIconWrap
          onClick={() => {
            navigate(PATHS.organogram);
          }}
        >
          <img src={IMAGES.rfpOverview} alt="Organogram" />
        </Styles.OrganogramIconWrap>
        <Styles.RfpDocumentsButton
          $active={currentPage === PATHS.rfpDocuments}
          onClick={() =>
            currentPage === PATHS.rfpDocuments
              ? navigate(-1)
              : navigate(PATHS.rfpDocuments)
          }
        >
          RFP documents <img src={IMAGES.downArrow} alt="arrow" />
        </Styles.RfpDocumentsButton>

        {location.pathname.includes(PATHS.nbm) && renderNbmExport()}
      </Styles.SubHeader>
    </Styles.OverviewHeader>
  );
};

export default Header;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IPQTQ, IPQTQDetails } from "./pqtq.interface";
import { ExtractionStatus } from "../../constants";
import { PURGE } from "redux-persist";

const pqtqInitialValues = {
  pqExtractionStatus: ExtractionStatus.NOT_STARTED,
  pqGeneratedRespStatus: ExtractionStatus.NOT_STARTED,
  pqGeneratedResponseFileName: "",
  pqGeneratedResponseFilePreSignedUrl: "",
  pqRelevantPages: [],
  pqSectionSummaryFileName: "",
  pqSectionSummaryFilePreSignedUrl: "",
  pqtqId: 2,
  status: "",
  tqExtractionStatus: ExtractionStatus.NOT_STARTED,
  tqGeneratedRespStatus: ExtractionStatus.NOT_STARTED,
  tqGeneratedResponseFileName: "",
  tqGeneratedResponseFilePreSignedUrl: "",
  tqRelevantPages: [],
  tqSectionSummaryFileName: "",
  tqSectionSummaryFilePreSignedUrl: "",
  fileName: "",
};

const initialState: IPQTQ = {
  isPQTQDetailsLoading: false,
  pqtqDetails: pqtqInitialValues,
  isDeleteExtractPQFileLoading: false,
  isDeleteExtractTQFileLoading: false,
};

export const pqtqSlice = createSlice({
  name: "pqtqSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setPQTQDetails: (state, action: PayloadAction<IPQTQDetails>) => {
      state.pqtqDetails = action.payload;
    },

    setIsPQTQDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.isPQTQDetailsLoading = action.payload;
    },

    setPQExtractionStatus: (state, action: PayloadAction<ExtractionStatus>) => {
      state.pqtqDetails = {
        ...state.pqtqDetails,
        pqExtractionStatus: action.payload,
      };
    },

    updatePQExtractionStatus: (
      state,
      action: PayloadAction<{
        status: ExtractionStatus;
        url: string;
        fileName: string;
      }>
    ) => {
      state.pqtqDetails = {
        ...state.pqtqDetails,
        pqExtractionStatus: action.payload?.status,
        pqSectionSummaryFilePreSignedUrl: action.payload?.url,
        pqSectionSummaryFileName: action.payload?.fileName,
        pqGeneratedRespStatus: ExtractionStatus.NOT_STARTED,
        pqGeneratedResponseFilePreSignedUrl: "",
      };
    },

    setTQExtractionStatus: (state, action: PayloadAction<ExtractionStatus>) => {
      state.pqtqDetails = {
        ...state.pqtqDetails,
        tqExtractionStatus: action.payload,
      };
    },

    updateTQExtractionStatus: (
      state,
      action: PayloadAction<{
        status: ExtractionStatus;
        url: string;
        fileName: string;
      }>
    ) => {
      state.pqtqDetails = {
        ...state.pqtqDetails,
        tqExtractionStatus: action.payload?.status,
        tqSectionSummaryFilePreSignedUrl: action.payload?.url,
        tqSectionSummaryFileName: action?.payload?.fileName,
        tqGeneratedRespStatus: ExtractionStatus.NOT_STARTED,
        tqGeneratedResponseFilePreSignedUrl: "",
      };
    },

    setIsDeleteExtractPQFileLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteExtractPQFileLoading = action.payload;
    },

    setIsDeleteExtractTQFileLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteExtractTQFileLoading = action.payload;
    },

    updatePQGenerationStatus: (
      state,
      action: PayloadAction<{
        status: ExtractionStatus;
        url: string;
        fileName: string;
      }>
    ) => {
      state.pqtqDetails = {
        ...state.pqtqDetails,
        pqGeneratedRespStatus: action.payload?.status,
        pqGeneratedResponseFilePreSignedUrl: action.payload?.url,
        pqGeneratedResponseFileName: action.payload?.fileName,
      };
    },

    updateTQGenerationStatus: (
      state,
      action: PayloadAction<{
        status: ExtractionStatus;
        url: string;
        fileName: string;
      }>
    ) => {
      state.pqtqDetails = {
        ...state.pqtqDetails,
        tqGeneratedRespStatus: action.payload?.status,
        tqGeneratedResponseFilePreSignedUrl: action.payload?.url,
        tqGeneratedResponseFileName: action?.payload?.fileName,
      };
    },
  },
});

export const {
  setPQTQDetails,
  setTQExtractionStatus,
  setPQExtractionStatus,
  setIsPQTQDetailsLoading,
  updateTQExtractionStatus,
  updatePQExtractionStatus,
  updatePQGenerationStatus,
  updateTQGenerationStatus,
  setIsDeleteExtractPQFileLoading,
  setIsDeleteExtractTQFileLoading,
} = pqtqSlice.actions;

export default pqtqSlice.reducer;

import * as Styles from "./style";

export const PrimaryButton = (props: any) => {
  return (
    <Styles.PrimaryButton type="primary" {...props}>
      {props.children}
    </Styles.PrimaryButton>
  );
};

export const SecondaryButton = (props: any) => {
  return (
    <Styles.SecondaryButton {...props}>{props.children}</Styles.SecondaryButton>
  );
};

export const TertiaryButton = (props: any) => {
  return (
    <Styles.TertiaryButton {...props}>{props.children}</Styles.TertiaryButton>
  );
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { store } from "../../store";
import { IAllFinance, IFinance } from "./finance.interface";
import { RFPGenerationStatus } from "../../constants";
import { getFinanceDetails } from "../../services/finance";

const initialFinanceDetails: IFinance = {
  fid: -1,
  rfpId: -1,
  status: "",
  rfpFileName: "",
  slasSummary: "",
  relevantPages: [],
  map: {
    PBG: [],
    EBD: [],
    SLAs: [],
    FEE_AT_RISK: [],
    PAYMENT_TERMS: [],
    TIMELINES_FOR_BILLING: [],
  },
  slasDefinition: "",
  generationStatus: "",
  feeAtRiskSummary: "",
  feeAtRiskDefinition: "",
  moneyDepositSummary: "",
  paymentTermsSummary: "",
  paymentTermsDefinition: "",
  moneyDepositDefinition: "",
  timelinesForBillingsSummary: "",
  financeSectionSummaryFileName: "",
  timelinesForBillingsDefinition: "",
  performanceBankGuaranteeSummary: "",
  performanceBankGuaranteeDefinition: "",
  financeSectionSummaryFilePreSignedUrl: "",
  extractionStatus: RFPGenerationStatus.NOT_STARTED,
};

const initialState: IAllFinance = {
  stopFinanceAutoRefresh: false,
  isFinanceDetailsLoading: false,
  isGenerateFinanceLoading: false,
  stopFinanceNavigationCheck: false,
  financeDetails: initialFinanceDetails,
};

export const allRfpSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setFinanceDetails: (state, action: PayloadAction<IFinance>) => {
      state.financeDetails = action.payload;
    },

    updateFinanceStatus: (state, action: PayloadAction<string>) => {
      state.financeDetails = {
        ...state.financeDetails,
        extractionStatus: action.payload,
      };
    },

    setIsFinanceDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.isFinanceDetailsLoading = action.payload;
    },

    setIsGenerateFinanceLoading: (state, action: PayloadAction<boolean>) => {
      state.isGenerateFinanceLoading = action.payload;
    },

    setStopFinanceNavigationCheck: (state, action: PayloadAction<boolean>) => {
      state.stopFinanceNavigationCheck = action.payload;
    },

    setStopFinanceAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.stopFinanceAutoRefresh = action.payload;
    },

    autoRefreshFinanceStatus: (_, action: PayloadAction<{ rfpId: number }>) => {
      let res: IFinance;
      const interval = setInterval(async () => {
        res = await getFinanceDetails(action.payload.rfpId, false);

        store.getState().finance.stopFinanceAutoRefresh &&
          store.getState().finance.stopFinanceAutoRefresh === true &&
          clearInterval(interval);

        !!res &&
          res?.extractionStatus === RFPGenerationStatus.COMPLETED &&
          clearInterval(interval);

        !!res &&
          res?.extractionStatus === RFPGenerationStatus.STOPPING &&
          clearInterval(interval);

        !!res &&
          res?.extractionStatus === RFPGenerationStatus.STOP &&
          clearInterval(interval);

        !!res &&
          res.extractionStatus === RFPGenerationStatus.FAILED &&
          clearInterval(interval);
      }, 5000);
    },
  },
});

export const {
  setFinanceDetails,
  updateFinanceStatus,
  autoRefreshFinanceStatus,
  setStopFinanceAutoRefresh,
  setIsFinanceDetailsLoading,
  setIsGenerateFinanceLoading,
  setStopFinanceNavigationCheck,
} = allRfpSlice.actions;

export default allRfpSlice.reducer;

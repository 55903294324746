import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IATIContent, IAti } from "./ati.interface";
import { RFPGenerationStatus } from "../../constants";
import { autoRefreshedGenerateAti } from "../../services/overview";
import { store } from "../../store";

const atiInitialValue: IAti = {
  atiId: 0,
  changeImperative: "",
  clientRd: "",
  companyDesc: "",
  isc: "",
  newOrExistingClient: "",
  opportunityDesc: "",
  par: "",
  pfsLtr: "",
  phpi: "",
  generationStatus: "",
  rfpFileName: "",
};

const initialState: IATIContent = {
  ati: atiInitialValue,
  isAtiLoading: false,
  isUpdatingValue: false,
  isExportLoading: false,
  stopAtiAutoRefresh: false,
};

export const atiSlice = createSlice({
  name: "atiSlice",
  initialState,
  reducers: {
    setAti: (state, action: PayloadAction<IAti>) => {
      state.ati = action.payload;
    },

    setIsAtiLoading: (state, action: PayloadAction<boolean>) => {
      state.isAtiLoading = action.payload;
    },

    setIsUpdatingValue: (state, action: PayloadAction<boolean>) => {
      state.isUpdatingValue = action.payload;
    },

    setIsExportLoading: (state, action: PayloadAction<boolean>) => {
      state.isExportLoading = action.payload;
    },

    setStopAtiAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.stopAtiAutoRefresh = action.payload;
    },

    autoRefreshAtiStatus: (
      _,
      action: PayloadAction<{ rfpId: number | string }>
    ) => {
      let res: IAti;
      const interval = setInterval(async () => {
        res = await autoRefreshedGenerateAti(action.payload.rfpId);

        store.getState().ati.stopAtiAutoRefresh &&
          store.getState().ati.stopAtiAutoRefresh === true &&
          clearInterval(interval);

        !!res &&
          res?.generationStatus === RFPGenerationStatus.COMPLETED &&
          clearInterval(interval);

        !!res &&
          res.generationStatus === RFPGenerationStatus.FAILED &&
          clearInterval(interval);
      }, 5000);
    },
  },
});

export const {
  setAti,
  setIsAtiLoading,
  setIsUpdatingValue,
  setIsExportLoading,
  autoRefreshAtiStatus,
  setStopAtiAutoRefresh,
} = atiSlice.actions;

export default atiSlice.reducer;

import { store } from "../store";
import { IRFPFilters } from "../store/allRfps/allRfp.interface";
import {
  setAllRfps,
  setIsAllRfpLoading,
  updateStatusCount,
  setTotalRfpsCount,
  setIsRfpDeleteLoading,
  setUpdateRFPsById,
  updateQualificationStatus,
  updatePageOnRfpDelete,
} from "../store/allRfps/allRfpSlice";
import {
  setRFPFileListCount,
  setIsRFPFileListCountLoading,
  setRFPFileListCountByDate,
  setIsRFPFileListCountByDateLoading,
} from "../store/dashboard/dashboardSlice";
import { deleteRequest, get, post, put } from "./apiClient";
import { IDashboardFilter } from "../store/dashboard/dashboard.interface";
import { PageLimit } from "../constants";
import { toast } from "react-toastify";

export interface IUpdateRFPDetails {
  [key: string]: string | number;
}

export const getAllRfps = async (
  filters: IRFPFilters,
  page = 1,
  pageLimit = PageLimit.Rfp
) => {
  try {
    store.dispatch(setIsAllRfpLoading(true));
    const { data } = await post(
      `apa/rfp/search?page=${page}&limit=${pageLimit}`,
      filters
    );
    store.dispatch(setAllRfps(data.rfpDtos));
    store.dispatch(setTotalRfpsCount(data?.totalRfps ?? 0));

    store.dispatch(
      updateStatusCount({
        totalLiveRfps: data.totalLiveRfps,
        unassignedRfps: data.unassignedRfps,
        inProgressRfps: data.inProgressRfps,
        upcomingDeadlines: data.upcomingDeadlines,
        completedRfps: data.completedRfps,
        notStartedRfps: data.notStartedRfps,
        onHoldRfps: data.onHoldRfps,
      })
    );
  } catch (error: any) {
    console.log(error, "getAllRfps api error");
    store.dispatch(setAllRfps([]));
    store.dispatch(setTotalRfpsCount(0));
  } finally {
    store.dispatch(setIsAllRfpLoading(false));
  }
};

export const UpdateClientGroup = async (
  id: number,
  request: IUpdateRFPDetails
) => {
  try {
    const res = await put(`apa/rfp/updateClientGroup/${id}`, request);
    toast.success("Client group updated successfully");
    return res;
  } catch (error: unknown) {
    console.log(error, "UpdateClient group api error");
    toast.error("Failed to update client group");
    throw error;
  }
};

// New code start

const updateApiCall = async (
  id: number,
  endpoint: string,
  request: IUpdateRFPDetails
) => {
  try {
    const res = await put(`apa/rfp/${endpoint}/${id}`, request);
    if (endpoint === "updateClientName") {
      toast.success("Client name updated successfully");
    } else if (endpoint === "updateOpportunityId") {
      toast.success("Opportunity Id updated successfully");
    }
    return res;
  } catch (error: unknown) {
    console.log(error, `${endpoint} api error`);
    throw error;
  }
};

export const UpdateOpportunityId = async (
  id: number,
  request: IUpdateRFPDetails
) => {
  return updateApiCall(id, "updateOpportunityId", request);
};

export const UpdateRFPName = async (id: number, request: IUpdateRFPDetails) => {
  return updateApiCall(id, "updateRFPName", request);
};

export const UpdateClientName = async (
  id: number,
  request: IUpdateRFPDetails
) => {
  return updateApiCall(id, "updateClientName", request);
};

export const UpdateStakeholders = async (
  id: number,
  request: IUpdateRFPDetails
) => {
  return updateApiCall(id, "updateStakeholders", request);
};

export const UpdateBidStage = async (
  id: number,
  request: IUpdateRFPDetails
) => {
  return updateApiCall(id, "updateBidStage", request);
};

//  New code end

export const UpdateQualification = async (
  id: number,
  isDisqualify: boolean
) => {
  try {
    const res = await put(
      `apa/rfp/${id}/qualify?isDisqualify=${isDisqualify}`,
      {}
    );
    const label = isDisqualify ? "disqualified" : "qualified";
    toast.success(`RFP successfully ${label}`);
    return res;
  } catch (error: unknown) {
    console.log(error, "updateQualification group api error");
    const label = isDisqualify ? "disqualify" : "qualify";
    toast.success(`Failed to ${label} RFP`);
    throw error;
  }
};

export const UpdateRFPSummary = async (
  id: number,
  request: IUpdateRFPDetails
) => {
  try {
    const res = await put(`apa/rfp/updateRFPSummary/${id}`, request);
    toast.success("Summary updated successfully");
    return res;
  } catch (error: unknown) {
    console.log(error, "updateRFPSummary group api error");
    toast.error("Failed to update summary");
    throw error;
  }
};

export const UpdateQualificationReason = async (
  id: number,
  request: IUpdateRFPDetails
) => {
  try {
    const res = await put(`apa/rfp/updateReason/${id}`, request);
    return res;
  } catch (error: unknown) {
    console.log(error, "updateReason group api error");
    throw error;
  }
};

export const getRFPDetailsByRFPId = async (RFPId: number) => {
  try {
    const data = await get(`apa/rfp/${RFPId}`);

    store.dispatch(updateQualificationStatus(data));
    return data;
  } catch (err) {
    console.log("get RFP from id api error", err);
    throw err;
  }
};

export const uploadSupportFile = async (request: any) => {
  try {
    const res = await post(`apa/finance/inputs-files`, request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error: unknown) {
    console.log(error, "finance upload support api error");
    throw error;
  }
};

export const deleteSupportedFile = async (id: number) => {
  try {
    const res = await deleteRequest(`finance/inputs-files/${id}`);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteExtractionFile = async (id: number) => {
  try {
    const res = await deleteRequest(`apa/finance/details-file/${id}`);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getRFPFileListCount = async (filter: IDashboardFilter) => {
  try {
    store.dispatch(setIsRFPFileListCountLoading(true));
    const { data } = await post(`apa/rfp/getRFPFileListCount`, filter);
    store.dispatch(setRFPFileListCount(data));
  } catch (error: unknown) {
    console.log(error, "getRFPFileListCount api error");
  } finally {
    store.dispatch(setIsRFPFileListCountLoading(false));
  }
};

export const getRFPFileListCountByDate = async (
  clientGroup: string[] | null,
  projectCategory: string[] | null
) => {
  try {
    store.dispatch(setIsRFPFileListCountByDateLoading(true));
    const { data } = await post(`apa/rfp/getRFPFileListCountByDate`, {
      clientGroup,
      projectCategory,
    });
    store.dispatch(setRFPFileListCountByDate(data));
  } catch (error: unknown) {
    console.log(error, "getRFPFileListCountByDate api error");
  } finally {
    store.dispatch(setIsRFPFileListCountByDateLoading(false));
  }
};

export const deleteRfp = async (
  id: number,
  currentPage: number,
  pageLimit: number,
  totalRfpsCount: number
) => {
  try {
    store.dispatch(setIsRfpDeleteLoading(true));
    const res = await deleteRequest(`apa/rfp/${id}`);

    let newPage = currentPage;
    if (totalRfpsCount % pageLimit === 1) {
      newPage = newPage - 1;
      store.dispatch(updatePageOnRfpDelete(newPage));
    }

    store.dispatch(setUpdateRFPsById(id));
    toast.success("Successfully deleted");
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to delete");
    return error;
  } finally {
    store.dispatch(setIsRfpDeleteLoading(false));
  }
};

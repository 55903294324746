import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./styles";
import { PATHS } from "../../../constants";
import { RootState } from "../../../store";
import { SecondaryButton } from "../../../shared";
import { CreateNewPassword } from "../../../services/auth";
import { resetOtpPassword } from "../../../store/auth/authSlice";

const ChangePassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    userEmailAtForgetPassword,
    otpAtForgetPassword,
    isCreatePasswordLoading,
  } = useSelector((state: RootState) => state.authSlice);

  const onFinish = (values: { password: string; confirmPassword: string }) => {
    const { confirmPassword } = values;

    CreateNewPassword(
      {
        email: userEmailAtForgetPassword,
        otp: otpAtForgetPassword,
        newPassword: confirmPassword,
      },
      navigate
    );
  };
  return (
    <>
      <Styles.HeadingText>Set a new password</Styles.HeadingText>
      <Styles.SubHeadingText>
        Your new password must be different to previously used passwords.
      </Styles.SubHeadingText>
      <Form
        name="resetPassword"
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please Enter Your Password",
            },
          ]}
        >
          <Styles.InputField placeholder="New Password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please Enter Your Confirm Password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "The Confirm Password That You Entered Does Not Match!"
                  )
                );
              },
            }),
          ]}
        >
          <Styles.InputField placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item style={{ marginTop: "15px" }}>
          <Styles.UpdatePasswordButton
            htmlType="submit"
            loading={isCreatePasswordLoading}
          >
            Update Password
          </Styles.UpdatePasswordButton>
        </Form.Item>
      </Form>
      <Styles.BackToLoginContainer>
        <SecondaryButton
          icon={<LeftOutlined />}
          onClick={() => {
            navigate(PATHS.login);
            dispatch(resetOtpPassword([]));
          }}
        >
          Back to login
        </SecondaryButton>
      </Styles.BackToLoginContainer>
    </>
  );
};

export default ChangePassword;

import { Form, Modal, Input, Select } from "antd";
import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from "../../../shared";

export const UserModalConatiner = styled(Modal)`
  .ant-modal-content {
    bottom: 80px;
  }
  .ant-modal-title {
    font-family: "GraphikMedium";
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  .ant-modal-body {
    padding-top: 10px;
  }
`;

export const UserForm = styled(Form)``;

export const UserFromItem = styled(Form.Item)`
  margin-top: 10px;
`;

export const UserInput = styled(Input)``;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
`;

export const PrimaryButtons = styled(PrimaryButton)`
  margin: 10px;
`;

export const SecondaryButtons = styled(SecondaryButton)`
  margin: 10px;
`;

export const RoleSelect = styled(Select)``;

export const RoleAlert = styled.div`
  display: flex;
  align-items: center;
  background: #ffeab5;
  border: 1px solid #daa82b;
  padding: 7px 10px;
  color: var(--font-black);
  font-family: "GraphikMedium";
  font-size: 12px;
  letter-spacing: 0.02em;

  img {
    margin-right: 5px !important;
    width: 18px;
  }
`;

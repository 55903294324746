import styled from "styled-components";
import { Button, Modal } from "antd";
import { PrimaryButton } from "../../shared";

export const ModalContainer = styled(Modal)`
  & .ant-modal {
    padding-bottom: 24px;
  }
  & .ant-modal-content {
    padding: 0 0 25px 0;

    & .ant-modal-close {
      right: 80px;
    }
  }

  & .ant-modal-header {
    margin: 0 80px;
    padding: 15px 0;
  }

  & .ant-modal-body {
    width: 70%;
    margin: 0 auto;
  }

  & .ant-modal-wrap {
    overflow: hidden !important;
  }
`;

export const UploadModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const UploadModalHeader = styled.div`
  font-family: "GraphikMedium";
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const UploadedRfpContainer = styled.div`
  border-radius: 8px;
  background: #f4f6fc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const UploadedRfpHeader = styled.div`
  font-family: "GraphikMedium";
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: #8200cc;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BrowseFilesButton = styled(PrimaryButton)`
  font-size: 12px;
`;

export const UploadedFileNameContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e3e4e5;
  border-radius: 5px;
  padding: 10px;
`;

export const BrowseFilesContainer = styled(UploadedFileNameContainer)`
  padding: 5px 5px 5px 10px;
`;

export const UploadedFileNameDeleteIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UploadedFileNamePinIconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const UploadedFileName = styled.div`
  width: 100%;
  font-family: "GraphikRegular";
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  color: #0f0f0f;
  overflow: hidden;
  height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UploadingDocContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const UploadingText = styled.div`
  font-family: "GraphikMedium";
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  color: #6d7987;
`;

export const UploadingFilesContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #e3e3e3;
  background: #ffffff;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 30px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: #a7a7a7;
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }
`;

export const FooterBorder = styled.div`
  border: 0.5px solid #e4e4e4;
  margin: 10px 0;
`;

export const PinIconTextWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  overflow: hidden;
`;

export const UploadFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 40px;
  gap: 10px;
  font-family: "GraphikMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f5f5f5;
  background: linear-gradient(107deg, #cf26eb 33.23%, #6d39f5 87.55%);

  &:hover {
    color: #f5f5f5 !important;
  }
`;

export const FooterDescription = styled.div`
  color: ${({ theme }) => theme.colors.fontColorZeta};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
`;

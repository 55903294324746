import Cookies from "js-cookie";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PATHS, Role } from "../../constants";
import { getUserRole } from "../../shared";

const ProtectedRoute = () => {
  const isLogin = Cookies.get("token_acnpa");
  const { pathname } = useLocation();

  const renderContent = () => {
    if (!isLogin) {
      return <Navigate to={PATHS.login} />;
    }

    if (getUserRole() !== Role.Admim && pathname.includes(PATHS.user)) {
      return <Navigate to={PATHS.home} />;
    }

    return <Outlet />;
  };

  return renderContent();
};

export default ProtectedRoute;

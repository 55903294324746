import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { Form, Tabs, TabsProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./styles";
import Summary from "./summary/Summary";
import { RootState } from "../../../store";
import { generateNbm } from "../../../services/nbm";
import CDLDocuments from "./CDLDocuments/CDLDocuments";
import { extractLegalDetails } from "../../../services/legal";
import { generatePolicy966 } from "../../../services/policy966";
import { setStopAtiAutoRefresh } from "../../../store/ati/atiSlice";
import { GenerateFinanceDetails } from "../../../services/finance";
import { setStopNbmAutoRefresh } from "../../../store/nbm/nbmSlice";
import { setStopLegalAutoRefresh } from "../../../store/legal/legalSlice";
import { setStopPolicy966AutoRefresh } from "../../../store/policy966/policy966";
import { setStopFinanceAutoRefresh } from "../../../store/finance/financeSlice";
import {
  IMAGES,
  PrimaryButton,
  SecondaryButton,
  formateDate,
} from "../../../shared";
import {
  PATHS,
  QualificationsStatus,
  RFPCircleColors,
  RFPGenerationStatus,
  RFPStatus,
} from "../../../constants";
import {
  generateAti,
  getAllGenerationStatusByRfpId,
  getOverviewDetails,
  updateOverviewDetails,
  updateStatus,
} from "../../../services/overview";

const initialValues = {
  rfp_name: "",
  client_name: "",
};

const Overview = () => {
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);

  const {
    getOverView,
    isOverviewLoading,
    rfpGenerationStatus,
    isRfpGenerationStatusLoading,
  } = useSelector((state: RootState) => state.overview);

  const { legalId } = useSelector((state: RootState) => state.legal);

  const [form] = Form.useForm();

  const { rfpId } = useParams();

  const dispatch = useDispatch();

  const currentRfpId: number = rfpId ? Number(rfpId) : -1;

  useEffect(() => {
    getOverviewDetails(currentRfpId);
    getAllGenerationStatusByRfpId(currentRfpId);
  }, [currentRfpId]);

  const {
    rfp_name,
    rfp_summary,
    client_name,
    uploaded_on,
    last_Updated_On,
    qualification_status,
    file_name,
  } = getOverView;

  useEffect(() => {
    if (getOverView) {
      form.setFieldsValue({
        rfp_name: rfp_name ? rfp_name?.replace(/^"(.*)"$/, "$1") : null,
        client_name,
      });
    }
  });

  useEffect(() => {
    return () => {
      // stops autoRefresh  when component unmount
      dispatch(setStopAtiAutoRefresh(true));
      dispatch(setStopNbmAutoRefresh(true));
      dispatch(setStopLegalAutoRefresh(true));
      dispatch(setStopFinanceAutoRefresh(true));
      dispatch(setStopPolicy966AutoRefresh(true));
    };
  }, [currentRfpId]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  const onFinish = async () => {
    const { client_name, rfp_name } = form.getFieldsValue();
    try {
      updateOverviewDetails({ client_name, rfp_name }, currentRfpId);
      setIsEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  const checkRfpItemStatus = (key: string): RFPGenerationStatus | string => {
    const id: string = rfpId ?? "-1";

    if (rfpGenerationStatus["rfpId"] === id) {
      return rfpGenerationStatus[key as keyof typeof rfpGenerationStatus];
    }
    return RFPGenerationStatus.NOT_STARTED;
  };

  const checkAllRfpStatus = (): boolean => {
    if (
      checkRfpItemStatus("ati") === RFPGenerationStatus.NOT_STARTED &&
      checkRfpItemStatus("finance") === RFPGenerationStatus.NOT_STARTED &&
      checkRfpItemStatus("nbm") === RFPGenerationStatus.NOT_STARTED &&
      checkRfpItemStatus("policy966") === RFPGenerationStatus.NOT_STARTED
    )
      return true;
    else return false;
  };

  const handleHandleGenerateAti = async () => {
    try {
      await generateAti(currentRfpId);

      checkAllRfpStatus() &&
        (await updateStatus(currentRfpId, { status: RFPStatus.IN_PROGRESS }));
    } catch (err) {
      console.log(err);
      toast.error("Failed to extract legal response");
    }
  };

  const handleHandleGeneratePolicy966 = async () => {
    try {
      await generatePolicy966(currentRfpId);

      checkAllRfpStatus() &&
        (await updateStatus(currentRfpId, { status: RFPStatus.IN_PROGRESS }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleHandleGenerateNbm = async () => {
    try {
      await generateNbm(currentRfpId);

      checkAllRfpStatus() &&
        (await updateStatus(currentRfpId, { status: RFPStatus.IN_PROGRESS }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleHandleGenerateFinance = async () => {
    try {
      await GenerateFinanceDetails(currentRfpId);

      checkAllRfpStatus() &&
        (await updateStatus(currentRfpId, { status: RFPStatus.IN_PROGRESS }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleHandleGenerateLegal = async () => {
    try {
      file_name &&
        extractLegalDetails({ fileName: file_name }, legalId, currentRfpId);

      checkAllRfpStatus() &&
        (await updateStatus(currentRfpId, { status: RFPStatus.IN_PROGRESS }));
    } catch (err) {
      console.log(err);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "RFP summary",
      children: (
        <Summary rfpId={rfpId ? rfpId : "-1"} rfp_summary={rfp_summary} />
      ),
    },
    {
      key: "2",
      label: "CDL Documents",
      children: <CDLDocuments />,
    },
  ];

  const renderRfpGenerationStatus = (status: string) => {
    switch (status) {
      case RFPGenerationStatus.PROCESSING:
        return (
          <Styles.ProcessingContainer>
            <img src={IMAGES.loader} alt={"processing"} />
            In processing...
          </Styles.ProcessingContainer>
        );

      case RFPGenerationStatus.COMPLETED:
      case RFPGenerationStatus.NOT_STARTED:
      default:
        return null;

      case RFPGenerationStatus.FAILED:
        return (
          <Styles.ProcessingContainer>
            <span>Failed</span>
          </Styles.ProcessingContainer>
        );
    }
  };

  const renderPrepareRFPItem = (
    label: keyof typeof RFPCircleColors,
    icon: string,
    route: string,
    generateApiCall: () => void,
    checkRfpItemStatus: RFPGenerationStatus | string
  ) => {
    return (
      <Styles.PrepareRFPItem
        $isProcessing={
          checkRfpItemStatus !== RFPGenerationStatus.COMPLETED &&
          checkRfpItemStatus !== RFPGenerationStatus.REGENERATING
        }
        onClick={() => {
          if (
            checkRfpItemStatus === RFPGenerationStatus.COMPLETED ||
            checkRfpItemStatus === RFPGenerationStatus.REGENERATING
          ) {
            navigate(`/rfp/${currentRfpId}/${route}`);
          }
        }}
      >
        <Styles.IconTitle>
          <Styles.CircleRound
            color={
              checkRfpItemStatus !== RFPGenerationStatus.COMPLETED
                ? "#f2f2f2"
                : RFPCircleColors[label]
            }
          >
            <img src={icon} alt={label} />
          </Styles.CircleRound>
          <Styles.LableContainer>
            {label}

            {renderRfpGenerationStatus(checkRfpItemStatus)}
          </Styles.LableContainer>
        </Styles.IconTitle>
        <Styles.GenerateIconWrap>
          {checkRfpItemStatus !== RFPGenerationStatus.PROCESSING &&
            // checkRfpItemStatus !== RFPGenerationStatus.STOPPING &&
            checkRfpItemStatus !== RFPGenerationStatus.REGENERATING &&
            checkRfpItemStatus !== RFPGenerationStatus.COMPLETED && (
              <SecondaryButton
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                  generateApiCall();
                }}
                disabled={
                  qualification_status !== QualificationsStatus.COMPLETED
                }
                loading={isRfpGenerationStatusLoading}
              >
                Generate
              </SecondaryButton>
            )}
          <Styles.ImgArrow src={IMAGES.rightArrow} alt="Right Arrow" />
        </Styles.GenerateIconWrap>
      </Styles.PrepareRFPItem>
    );
  };

  const renderRfpDetailsForm = () => {
    if (isOverviewLoading) {
      return <Styles.RFPDetailsSkeleton active />;
    }
    return (
      <Styles.AntForm
        form={form}
        name="rfpForm"
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Styles.AntRow gutter={10}>
          <Styles.AntCol span={24}>
            <Styles.AntFormItem
              label="RFP Name"
              name="rfp_name"
              rules={[
                {
                  required: true,
                  message: "Please input RFP name",
                },
              ]}
            >
              {isEdit ? (
                <Styles.AntColInput />
              ) : (
                <Styles.AntColValue>
                  {rfp_name?.replace(/^"(.*)"$/, "$1")}
                </Styles.AntColValue>
              )}
            </Styles.AntFormItem>
          </Styles.AntCol>
          <Styles.AntCol span={12}>
            <Styles.AntFormItem
              label="Client name"
              name="client_name"
              rules={[
                {
                  required: true,
                  message: "Please input Client name",
                },
              ]}
            >
              {isEdit ? (
                <Styles.AntColInput />
              ) : (
                <Styles.AntColValue>{client_name}</Styles.AntColValue>
              )}
            </Styles.AntFormItem>
          </Styles.AntCol>

          <Styles.AntCol span={6}>
            <Styles.AntColTitle>Uploaded on</Styles.AntColTitle>
            <Styles.AntColValue>{formateDate(uploaded_on)}</Styles.AntColValue>
          </Styles.AntCol>
          <Styles.AntCol span={6}>
            <Styles.AntColTitle>Last updated</Styles.AntColTitle>
            <Styles.AntColValue>
              {formateDate(last_Updated_On)}
            </Styles.AntColValue>
          </Styles.AntCol>
        </Styles.AntRow>
      </Styles.AntForm>
    );
  };

  return (
    <Styles.OverviewContainer>
      <Styles.OverviewBody>
        <Styles.OverviewDetails>
          <Styles.BodyHeader>
            {isEdit ? (
              <Styles.SaveDiv>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                <PrimaryButton onClick={() => form.submit()}>
                  Save
                </PrimaryButton>
              </Styles.SaveDiv>
            ) : (
              <SecondaryButton onClick={handleEdit}>Edit</SecondaryButton>
            )}
          </Styles.BodyHeader>
          <Styles.RFPDetails>
            <Styles.RFPTitle>
              RFP Details
              <Styles.AIIcon>
                <img src={IMAGES.aiTagLogo} alt="Logo" />
              </Styles.AIIcon>
            </Styles.RFPTitle>
            {renderRfpDetailsForm()}
          </Styles.RFPDetails>
          <Tabs defaultActiveKey="1" items={items} />
        </Styles.OverviewDetails>
        <Styles.PrepareRFP>
          <Styles.PrepareRFPTitle>Prepare RFP</Styles.PrepareRFPTitle>
          {renderPrepareRFPItem(
            "ATI",
            IMAGES.ATIIcon,
            PATHS.ati,
            handleHandleGenerateAti,
            checkRfpItemStatus("ati")
          )}
          {renderPrepareRFPItem(
            "Policy 966",
            IMAGES.policyApproval,
            PATHS.policy966,
            handleHandleGeneratePolicy966,
            checkRfpItemStatus("policy966")
          )}
          {renderPrepareRFPItem(
            "NBM",
            IMAGES.NBMIcon,
            PATHS.nbm,
            handleHandleGenerateNbm,
            checkRfpItemStatus("nbm")
          )}
          {renderPrepareRFPItem(
            "Finance",
            IMAGES.finance,
            PATHS.finance,
            handleHandleGenerateFinance,
            checkRfpItemStatus("finance")
          )}
          {renderPrepareRFPItem(
            "Legal",
            IMAGES.legal,
            PATHS.legal,
            handleHandleGenerateLegal,
            checkRfpItemStatus("legal")
          )}
          {renderPrepareRFPItem(
            "Solution",
            IMAGES.solution,
            PATHS.solution,
            () => console.log("Solution api"),
            checkRfpItemStatus("solution")
          )}
        </Styles.PrepareRFP>
      </Styles.OverviewBody>
    </Styles.OverviewContainer>
  );
};

export default Overview;

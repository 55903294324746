import { Skeleton } from "antd";
import * as Styles from "../styles";

const SkeletonTable = () => {
  const columns = [
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "opportunity_id",
      key: "opportunity_id",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "rfp_name",
      key: "rfp_name",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "client_name",
      key: "client_name",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "client_group",
      key: "client_group",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "project_category",
      key: "project_category",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "stakeholders",
      key: "stakeholders",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "bid_stage",
      key: "bid_stage",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "client_group",
      key: "client_group",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "uploaded_on",
      key: "uploaded_on",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "qualification",
      key: "qualification",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "status",
      key: "status",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      key: "action",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
  ];

  return (
    <Styles.RFPTableContainer>
      <Styles.RfpTable
        rowKey={"rfp_id"}
        dataSource={[{}, {}, {}, {}, {}, {}]}
        columns={columns as any}
        pagination={false}
      />
    </Styles.RFPTableContainer>
  );
};

export default SkeletonTable;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import {
  DownloadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import AtiInfoModal from "./atiInfoModal/AtiInfoModal";
import { IAti } from "../../../store/ati/ati.interface";
import { IMAGES, SecondaryButton } from "../../../shared";
import { PATHS, RFPGenerationStatus } from "../../../constants";
import { MarkDownText } from "../../../shared/markDownText/MarkDownText";
import { exportAti, getAtiById, updateATIValue } from "../../../services/ati";

interface IAgreement {
  id: number;
  key: keyof IAti;
  title: string;
  information: string;
  value: string;
}

const Agreements: IAgreement[] = [
  {
    id: 1,
    key: "newOrExistingClient",
    title: "New or Existing Client",
    information: ``,
    value: "",
  },
  {
    id: 2,
    key: "companyDesc",
    title: "Company Description",
    information: `Information to include : 
• Size, location, credit rating*, overview of products and services.
• Does this company have a parent? If so, what is the parent?
*If a client is rated investment grade by one of the major credit rating agencies (S&P or Moody’s) then credit is not an issue.  If, however, a client is NOT rated investment grade (e.g., below BBB- or Baa3), or is unrated, then Treasury should be consulted for risk assessment.`,
    value: "",
  },
  {
    id: 3,
    key: "isc",
    title: "Industry Stature of Company",
    information: `• Is the company part of the G2000 and/or a CG target client?
• Is the company viewed as a top player in its industry; what role does the company play in their industry?  
• If not already a leader, what is their potential to become a leader?`,
    value: "",
  },
  {
    id: 4,
    key: "phpi",
    title: "Potential for High Performance Improvement",
    information:
      "To what degree is this company in need of performance improvement; in what way can we provide assistance?",
    value: "",
  },
  {
    id: 5,
    key: "changeImperative",
    title: "Change Imperative",
    information:
      "What is the company’s change mandate and culture; what is their openness to new ideas and step improvements in their operations and performance?",
    value: "",
  },
  {
    id: 6,
    key: "pfsLtr",
    title: "Propensity to Fund a Substantial, Long-Term Relationship",
    information: `• What is the company’s fiscal strength and ability to fund a long-term relationship? 
• What is the company’s credit rating in the major countries in which you are targeting pursuit of work?
• What is the company’s history in engaging consulting and service providers?  How much do they spend in this area annually ?`,
    value: "",
  },
  {
    id: 7,
    key: "par",
    title: "Potential for an Accenture Relationship",
    information: `• What are the company’s perceptions about Accenture (prior history, current relationships)?
• What is the level of competitor presence and relationships at this company?
• Do we have appropriate staffing and skills to pursue and drive work at this company that should not be directed to another target client?
• Are there any outstanding legal considerations that should be evaluated further?`,
    value: "",
  },
  {
    id: 8,
    key: "clientRd",
    title: "Should Client RD be setup for this client?",
    information: `• Is client Relationship Development (RD) BD necessary?  If so, how much?`,
    value: "",
  },
  {
    id: 9,
    key: "opportunityDesc",
    title: "Opportunity Description",
    information: `• What opportunity are we looking to explore right now? 
• Do we have the skill and resource to produce a competitive solution? 
• Can we win with only a modest BD investment? (Is the competitive landscape on our side?)`,
    value: "",
  },
];

const Ati: React.FC = () => {
  const [isEdit, setIsEdit] = useState<number>(-1); // Specify the type for isEdit
  const [formValues, setFormValues] = useState<IAgreement[]>(Agreements);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const { rfpId } = useParams();

  const currentRfpId = rfpId ? parseInt(rfpId) : null;

  const { ati, isUpdatingValue, isExportLoading, isAtiLoading } = useSelector(
    (state: RootState) => state.ati
  );

  const { rfpGenerationStatus } = useSelector(
    (state: RootState) => state.overview
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      rfpGenerationStatus?.ati !== RFPGenerationStatus.COMPLETED &&
      pathname.includes(PATHS.ati)
    ) {
      navigate(`/rfp/${currentRfpId}/${PATHS.overview}`);
    }
  }, [rfpGenerationStatus, pathname]);

  useEffect(() => {
    if (currentRfpId) {
      getAtiById(currentRfpId);
    }
  }, [currentRfpId]);

  useEffect(() => {
    if (ati && ati?.atiId) {
      const updatedFormValues: IAgreement[] = formValues.map(
        (agreement: IAgreement) => ({
          ...agreement,
          value: ati[agreement.key] ? ati[agreement.key].toString() : "",
        })
      );
      setFormValues(updatedFormValues);
    }
  }, [ati]);

  const handleSaveValue = async () => {
    try {
      const request = formValues.reduce((acc: any, curr) => {
        if (curr && curr.key) {
          acc[curr.key] = curr.value;
        }
        return acc;
      }, {});

      if (ati) {
        request.atiId = ati?.atiId;
      }
      await updateATIValue(request);
      currentRfpId && getAtiById(currentRfpId);
    } finally {
      setIsEdit(-1);
    }
  };

  const handleCancel = (index: number, agreement: IAgreement) => {
    const updatedFormValues = [...formValues];
    updatedFormValues[index].value = ati[agreement?.key] as string;
    setFormValues(updatedFormValues);
    setIsEdit(-1);
  };

  const handleExportAti = async () => {
    if (!!currentRfpId) {
      await exportAti(ati?.atiId, currentRfpId, ati?.rfpFileName);
    } else {
      toast.error("Failed to export ati.");
    }
  };

  return (
    <Styles.AtiContainer>
      <Styles.GenerateExportWrap>
        <Styles.AgreementText>
          Agreement to Include (ATI)
          <Styles.StyledTooltip
            placement="bottomLeft"
            overlayClassName="agreement-include"
            title={<AtiInfoModal />}
          >
            <img src={IMAGES.infoIcon} alt="info" />
          </Styles.StyledTooltip>
        </Styles.AgreementText>
        <Styles.ExportAtiButton
          icon={<DownloadOutlined />}
          loading={isExportLoading}
          onClick={handleExportAti}
        >
          Export
        </Styles.ExportAtiButton>
      </Styles.GenerateExportWrap>

      <Styles.AgreementsContainer>
        {formValues.map((agreement: IAgreement, index: number) => (
          <React.Fragment key={agreement?.title}>
            <Styles.LeftSide>
              <Styles.LeftTextNumberAlign>
                <Styles.FinanceDetails>
                  <Styles.Heading>
                    <Styles.SerialNum>{index + 1}</Styles.SerialNum>
                    <Styles.FTitle>{agreement?.title}</Styles.FTitle>
                  </Styles.Heading>
                </Styles.FinanceDetails>
              </Styles.LeftTextNumberAlign>
            </Styles.LeftSide>
            <Styles.RightSide>
              <Styles.RightTextNumberAlign>
                {!!agreement?.information && (
                  <Styles.AgreementsPoints>
                    <MarkDownText text={agreement?.information} />
                  </Styles.AgreementsPoints>
                )}
                {isAtiLoading ? (
                  <Styles.AtiSkeleton active />
                ) : (
                  <Styles.AgreementsEditablePointsPoints>
                    <Styles.InputFooterWrap $active={isEdit === index}>
                      {isEdit === index ? (
                        <Styles.InputSummary
                          autoFocus
                          readOnly={isEdit !== index}
                          value={agreement?.value}
                          onChange={(e) => {
                            const updatedFormValues = [...formValues];
                            updatedFormValues[index].value = e.target.value;
                            setFormValues(updatedFormValues);
                            setSelectedIndex(index);
                          }}
                          autoSize={{ minRows: 1, maxRows: 100 }}
                        />
                      ) : (
                        <MarkDownText text={agreement?.value} />
                      )}
                      <Styles.InputFooterButtonWrap>
                        <Button
                          loading={isUpdatingValue}
                          disabled={!agreement?.value?.trim()}
                          type="link"
                          icon={<CheckOutlined style={{ fontSize: "13px" }} />}
                          onClick={() => handleSaveValue()}
                        />
                        <Button
                          type="link"
                          icon={<CloseOutlined style={{ fontSize: "13px" }} />}
                          onClick={() => handleCancel(index, agreement)}
                        />
                      </Styles.InputFooterButtonWrap>
                    </Styles.InputFooterWrap>

                    {isEdit !== index && (
                      <SecondaryButton
                        className="secondary-button"
                        onClick={() => {
                          if (selectedIndex !== -1) {
                            handleCancel(
                              selectedIndex,
                              formValues[selectedIndex]
                            );
                          }
                          setIsEdit(index);
                        }}
                      >
                        Edit
                      </SecondaryButton>
                    )}
                  </Styles.AgreementsEditablePointsPoints>
                )}
              </Styles.RightTextNumberAlign>
            </Styles.RightSide>
          </React.Fragment>
        ))}
      </Styles.AgreementsContainer>
    </Styles.AtiContainer>
  );
};

export default Ati;

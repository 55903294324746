import { toast } from "react-toastify";
import { store } from "../store";
import { get, put } from "./apiClient";
import { IAti } from "../store/ati/ati.interface";
import {
  setAti,
  setIsAtiLoading,
  setIsExportLoading,
  setIsUpdatingValue,
} from "../store/ati/atiSlice";

export const getAtiById = async (AtiId: number) => {
  try {
    store.dispatch(setIsAtiLoading(true));

    const res = await get(`apa/ati/${AtiId}/rfp`);
    store.dispatch(setAti(res));
  } catch (err) {
    console.log("getAllAMContent api error", err);
    throw err;
  } finally {
    store.dispatch(setIsAtiLoading(false));
  }
};

export const updateATIValue = async (request: IAti) => {
  try {
    store.dispatch(setIsUpdatingValue(true));
    await put(`apa/ati/update`, request);
    toast.success("Updated successfully.");
  } catch (error: any) {
    console.log("Error while updating ATI: ", error);
    toast.error(error?.message ?? "Failed to Update.");
  } finally {
    store.dispatch(setIsUpdatingValue(false));
  }
};

export const exportAti = async (
  AtiId: number,
  rfpId: number,
  rfpFileName: string
) => {
  try {
    store.dispatch(setIsExportLoading(true));
    const res = await get(`apa/ati/${AtiId}/export/${rfpId}`, {
      responseType: "blob",
    });

    const fileName = !!rfpFileName ? `ATI_${rfpFileName}.docx` : "ATI.docx";

    const blobUrl = URL.createObjectURL(res);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", fileName); // Specify the filename for the downloaded file
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (err: any) {
    console.log("exportAti api error", err);
    toast.error(err?.message ?? "Failed to export ati.");
  } finally {
    store.dispatch(setIsExportLoading(false));
  }
};

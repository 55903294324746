import { Button, Form, Input, Select, Skeleton } from "antd";
import styled from "styled-components";
import { ActiveStatus } from "../../../types";

export const OrgContainer = styled.div`
  height: 100%;
  padding: 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const OrgHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebecf2;
`;

export const OrgSubHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const OrgTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  font-size: 16px;
`;

export const OrgBody = styled.div`
  padding: 20px 0px;
  height: calc(100% - 35px);
  overflow: auto;
`;

export const OrgBodyTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  font-size: 16px;
`;

export const AntForm = styled(Form)`
  padding: 20px 10px 10px 0px;

  &.ant-form-vertical .ant-form-item-label,
  :where(.css-dev-only-do-not-override-t26387).ant-col-24.ant-form-item-label,
  :where(
      .css-dev-only-do-not-override-t26387
    ).ant-col-xl-24.ant-form-item-label {
    padding: 0;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const AntFormItem = styled(Form.Item)`
  width: 300px;

  .ant-input {
    padding: 8px 11px;
    border-color: #d2deff;
    background: #f4f6fc;
  }
  .ant-input-outlined:hover {
    border-color: #d2deff;
  }
  .ant-input-outlined:focus,
  :where(
      .css-dev-only-do-not-override-1v67itz
    ).ant-input-outlined:focus-within {
    box-shadow: none;
  }
`;

export const AntInputWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const AntSelectWrap = styled(Select)`
  & .ant-select-selector {
    border-color: #d2deff !important;
    background: #f4f6fc !important;
  }

  &:hover {
    & .ant-select-selector {
      border-color: #d2deff !important;
    }
  }
`;

export const AntAutoCompleteWrap = styled.div<ActiveStatus>`
  & .ant-select-single {
    width: 100%;

    &:hover {
      & .ant-select-selector {
        border-color: ${({ $active }) =>
          $active ? "#ff4d4f !important" : "#d2deff !important"};
      }
    }

    &:focus {
      & .ant-select-selector {
        border-color: ${({ $active }) =>
          $active ? "#ff4d4f !important" : "#d2deff !important"};
      }
    }

    & .ant-select-selector {
      border-color: ${({ $active }) =>
        $active ? "#ff4d4f !important" : "#d2deff !important"};
      background: #f4f6fc !important;
    }
  }
`;

export const AntInput = styled(Input)`
  border-color: #d2deff;
  background: #f4f6fc;

  &:hover {
    border-color: #d2deff;
  }

  &:focus {
    border-color: #d2deff;
  }
`;

export const AntRoleInput = styled(AntInput)`
  border-color: #8200cc;

  &:hover {
    border-color: #8200cc;
  }
`;

export const BtnContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const AddFieldButton = styled(Button)`
  width: 25px !important;
  min-width: 25px !important;
  height: 25px;
  background-color: transparent;
  border: 1px solid #a7a7a7;
  display: flex;
  align-items: center;
  justify-content: center;

  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: transparent;
  }
`;

export const AddChildFieldButton = styled(AddFieldButton)`
  img {
    filter: invert(30%) sepia(29%) saturate(12%) hue-rotate(358deg)
      brightness(93%) contrast(93%);
  }
`;

export const RemoveFieldIcon = styled.img`
  filter: invert(86%) sepia(10%) saturate(16%) hue-rotate(331deg)
    brightness(77%) contrast(88%);
  transform: rotate(45deg);
`;

export const DynamicInputContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
  flex-wrap: wrap;
  align-items: center;
`;

export const DynamicInputParentContainer = styled.div<{
  $isChildExists: boolean;
}>`
  width: ${({ $isChildExists }) => ($isChildExists ? "100%" : "fit-content")};
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  flex-direction: ${({ $isChildExists }) =>
    $isChildExists ? "column" : "unset"};
  justify-content: ${({ $isChildExists }) =>
    $isChildExists ? "center" : "fit-content"};
  align-items: ${({ $isChildExists }) =>
    $isChildExists ? "center" : "fit-content"};
`;

export const DynamicInputParentWrap = styled.div`
  width: 300px;
  display: grid;
  grid-template-columns: 1fr 25px;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const DynamicInputChildWrap = styled(DynamicInputParentWrap)``;

export const Error = styled.div<ActiveStatus>`
  color: #ff4d4f;
  opacity: ${({ $active }) => ($active ? "1" : "0")};
  max-height: ${({ $active }) => ($active ? "100px" : "0")};
  transition: opacity 0.5s ease-in-out, max-height 1s ease;
`;

export const CustomSkeleton = styled(Skeleton)`
  margin-top: 20px;
`;

export const CancelBtnContainer = styled.div`
  margin-right: 10px;
`;

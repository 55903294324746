import { toast } from "react-toastify";
import { store } from "../store";
import {
  setAllUsers,
  setUIsAllUsersLoading,
  setTotalUsersCount,
} from "../store/userManagement/usersSlice";
import { deleteRequest, get, post } from "./apiClient";
import { PageLimit } from "../constants";

interface IAddUserRequest {
  name: string;
  email: string;
  password: string;
  userPermission: [
    {
      configSettingId: number;
      roleName: string;
    }
  ];
}

interface IStatus {
  status: "disable" | "enable";
}
export const fetchAllUsers = async (
  search: string,
  page = 1,
  pageLimit = PageLimit.UserManagement
) => {
  try {
    store.dispatch(setUIsAllUsersLoading(true));
    const { data } = await post(
      `/core/token/searchByNameOrEmail?nameOrEmail=${search}&page=${
        page - 1
      }&limit=${pageLimit}`
    );
    const userRes = data?.content ?? [];

    store.dispatch(setAllUsers(userRes));
    store.dispatch(setTotalUsersCount(data?.totalElements ?? 0));
  } catch (error) {
    console.log(error);
    store.dispatch(setAllUsers([]));
  } finally {
    store.dispatch(setUIsAllUsersLoading(false));
  }
};

export const getUsersStatus = async (
  userId: number,
  status: IStatus,
  currentPage: number,
  pageLimit: number
) => {
  try {
    const res = await get(`core/token/${status}/${userId}`);
    toast.success(res ?? "Status updated successfully");
    fetchAllUsers("", currentPage, pageLimit);
  } catch (error) {
    console.log(error);
  }
};

export const addUser = async (request: IAddUserRequest) => {
  try {
    const res = await post(`/core/token/adduser`, request);
    toast.success("User added successfully");
    return res;
  } catch (error: any) {
    toast.error(error ?? "Failed to add user");
    console.log("Error while add user", error);
    return error;
  }
};

export const updateUser = async (request: any) => {
  try {
    const res = await post(`/core/token/editUser`, request);
    toast.success("User updated successfully");
    return res;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const removeUser = async (id: number) => {
  try {
    const res = await deleteRequest(`/core/token/deleteUser/${id}`);
    toast.success("User deleted successfully");
    return res;
  } catch (error: any) {
    console.log(error);
    toast.error(error ?? "Failed to delete user");
    return error;
  }
};

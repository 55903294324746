import { AMContent } from "../A&M";
import {
  Frs,
  PreQualification,
  PaymentTerms,
  ProjectPlan,
  TechQualification,
} from "./../index";
import * as Styles from "./style";

export const PrimaryTabs = () => {
  const tabsTitleArray = [
    "PQ",
    "TQ",
    "A&M",
    "FRS",
    "Project Plan",
    "Payment Terms",
  ];

  const renderContent = (TabContent: string) => {
    if (TabContent === "PQ") return <PreQualification />;
    else if (TabContent === "TQ") return <TechQualification />;
    else if (TabContent === "Project Plan") return <ProjectPlan />;
    else if (TabContent === "A&M") return <AMContent />;
    else if (TabContent === "FRS") return <Frs />;
    else if (TabContent === "Payment Terms") return <PaymentTerms />;
    else return TabContent;
  };

  return (
    <Styles.PrimaryTabs
      size="large"
      animated={false}
      tabPosition="left"
      tabBarStyle={{
        display: "flex",
        justifyContent: "left",
        width: "170px",
      }}
      items={tabsTitleArray.map((element, i) => {
        const id = String(i + 1);
        return {
          key: id,
          label: (
            <Styles.Label>
              {`${element}`}
              <Styles.Arrow> {`>`} </Styles.Arrow>
            </Styles.Label>
          ),
          children: (
            <Styles.TabContent>{renderContent(element)}</Styles.TabContent>
          ),
        };
      })}
    />
  );
};

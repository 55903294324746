import Markdown from "react-markdown";
import styled from "styled-components";

export const MarkDownText = styled(Markdown)`
  .mark-down-text {
    padding: 5px;
  }
  * {
    font-size: 13px;
    color: #333333;
  }
  p {
    white-space: pre-wrap;
  }
  ul,
  ol {
    padding-left: 24px;
    margin: 10px 0;
  }
  li {
    line-height: 20px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  p {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  strong,
  h3 {
    font-weight: normal;
    font-family: "GraphikMedium", sans-serif;
  }
`;

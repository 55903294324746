import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import AboutCompany from "./AboutCompany";
import { RootState } from "../../../store";
import EngagementMPPGCL from "./EngagementMPPGCL";
import { PATHS, RFPGenerationStatus } from "../../../constants";
import {
  autoRefreshPolicyStatus,
  setStopPolicy966AutoRefresh,
} from "../../../store/policy966/policy966";
// import {
//   IMAGES,
//   // PrimaryButton,
//   //   SecondaryButton,
//   TertiaryButton,
// } from "../../../shared";
import {
  exportPolicy966,
  // generatePolicy966,
  getGeneratedPolicy966,
  // stopGeneratePolicy966,
} from "../../../services/policy966";
// import { setStopPolicy966NavigationCheck } from "../../../store/overview/overviewSlice";

const tabsTitleArray = [
  "About the Company",
  "Our Engagement with MPPGCL  and Vision",
];

const renderContent = (TabContent: string) => {
  switch (TabContent) {
    case "About the Company":
    default:
      return <AboutCompany />;

    case "Our Engagement with MPPGCL  and Vision":
      return <EngagementMPPGCL />;
  }
};

const Policy966 = () => {
  const { rfpId } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const currentRfpId: number = rfpId ? Number(rfpId) : -1;

  const { isExportPolicyLoading, policy966Data } = useSelector(
    (state: RootState) => state.policy966
  );

  const { rfpGenerationStatus, stopPolicy966NavigationCheck } = useSelector(
    (state: RootState) => state.overview
  );

  // const { selectedRFP } = useSelector((state: RootState) => state.allRfps);

  useEffect(() => {
    if (
      rfpGenerationStatus?.policy966 !== RFPGenerationStatus.COMPLETED &&
      rfpGenerationStatus?.policy966 !== RFPGenerationStatus.STOPPING &&
      stopPolicy966NavigationCheck &&
      pathname.includes(PATHS.policy966)
    ) {
      navigate(`/rfp/${currentRfpId}/${PATHS.overview}`);
    }
  }, [rfpGenerationStatus, pathname]);

  useEffect(() => {
    const getPolicyById = async () => {
      const res = await getGeneratedPolicy966(currentRfpId, true);
      !!res?.generationStatus &&
        res?.generationStatus === RFPGenerationStatus.PROCESSING &&
        dispatch(setStopPolicy966AutoRefresh(false)),
        dispatch(autoRefreshPolicyStatus({ rfpId: currentRfpId }));
    };
    getPolicyById();
  }, [currentRfpId]);

  // const handleGeneratePolicy = async () => {
  //   if (
  //     rfpGenerationStatus?.policy966 === RFPGenerationStatus.COMPLETED ||
  //     rfpGenerationStatus?.policy966 === RFPGenerationStatus.STOPPING
  //   ) {
  //     dispatch(setStopPolicy966NavigationCheck(false));
  //   }
  //   await generatePolicy966(currentRfpId);
  // };

  // const handleStopPolicy966Generation = async () => {
  //   dispatch(setStopPolicy966NavigationCheck(false));
  //   await stopGeneratePolicy966(
  //     policy966Data?.policyId,
  //     currentRfpId,
  //     selectedRFP?.file_name ?? ""
  //   );
  // };

  const handlePolicyExport = async () => {
    await exportPolicy966(
      policy966Data?.policyId,
      currentRfpId,
      policy966Data?.rfpFileName
    );
  };

  return (
    <Styles.PAContainer>
      <Styles.ExportButton
        icon={<DownloadOutlined />}
        onClick={handlePolicyExport}
        loading={isExportPolicyLoading}
        disabled={
          rfpGenerationStatus?.policy966 !== RFPGenerationStatus.COMPLETED
        }
      >
        Export
      </Styles.ExportButton>

      {/* <Styles.GenerateCard className="generate-card">
        <Styles.GenerateHeader className="generate-header">
          Generate Policy 966 <img src={IMAGES.aiTagLogo} alt="Logo" />
        </Styles.GenerateHeader>
        <Styles.GenerateButtonWrap>
          <PrimaryButton
            className="regenerate-btn"
            icon={<img src={IMAGES.aiTagLogo} />}
            onClick={handleGeneratePolicy}
            disabled={
              rfpGenerationStatus?.policy966 ===
                RFPGenerationStatus.PROCESSING ||
              rfpGenerationStatus?.policy966 === RFPGenerationStatus.STOPPING_FE
            }
          >
            {rfpGenerationStatus?.policy966 === RFPGenerationStatus.COMPLETED ||
            rfpGenerationStatus?.policy966 === RFPGenerationStatus.STOPPING ||
            rfpGenerationStatus?.policy966 === RFPGenerationStatus.STOPPING_FE
              ? "Regenerate"
              : "Generate"}
          </PrimaryButton>
          {(rfpGenerationStatus?.policy966 === RFPGenerationStatus.PROCESSING ||
            rfpGenerationStatus?.policy966 ===
              RFPGenerationStatus.STOPPING_FE) && (
            <Styles.GeneratingTextWrap>
              <img src={IMAGES.loadingSpinner} alt="loading" />
              <Styles.GeneratingText>
                {rfpGenerationStatus?.policy966 ===
                RFPGenerationStatus.STOPPING_FE
                  ? "Stopping generation..."
                  : "Generating output..."}
              </Styles.GeneratingText>
              <SecondaryButton
                type="text"
                shape="circle"
                icon={<img src={IMAGES.stop} alt="Stop" />}
                onClick={handleStopPolicy966Generation}
                disabled={
                  rfpGenerationStatus?.policy966 ===
                  RFPGenerationStatus.STOPPING_FE
                }
              />
            </Styles.GeneratingTextWrap>
          )}
        </Styles.GenerateButtonWrap>
        <TertiaryButton
          icon={<DownloadOutlined />}
          onClick={handlePolicyExport}
          loading={isExportPolicyLoading}
          disabled={
            rfpGenerationStatus?.policy966 !== RFPGenerationStatus.COMPLETED
          }
        >
          Export
        </TertiaryButton>
      </Styles.GenerateCard> */}
      <Styles.QualificationContent>
        <Styles.PrimaryTabs
          items={tabsTitleArray.map((element, i) => {
            const id = String(i + 1);
            return {
              key: id,
              label: <Styles.Label>{`${element}`}</Styles.Label>,
              children: (
                <Styles.TabContent>{renderContent(element)}</Styles.TabContent>
              ),
            };
          })}
        />
      </Styles.QualificationContent>
    </Styles.PAContainer>
  );
};

export default Policy966;

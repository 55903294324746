import { Checkbox } from "antd";
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  IUpdateRFPDetails,
  UpdateBidStage,
  UpdateClientName,
  UpdateOpportunityId,
  UpdateRFPName,
  UpdateStakeholders,
} from "../../../services/allRfps";
import { IMAGES } from "../../../shared";
import { RootState } from "../../../store";
import { IAllRfp } from "../../../store/allRfps/allRfp.interface";
import {
  setIsManuallyEditedShown,
  updateRFPDetails,
  setRfpCurrentPage,
  setRfpPageLimit,
} from "../../../store/allRfps/allRfpSlice";
import RFPTableColumns from "../RFPTableColumns/RFPTableColumns";
import { components } from "../editableRowCell/EditableRowCell";
import * as Styles from "./styles";
import { PageLimit, RFPStatus } from "../../../constants";
import { CustomPagination } from "../../../components";
import SkeletonTable from "./skeletonTable/SkeletonTable";

interface ApiFunctions {
  [key: string]: (
    id: number,
    request: IUpdateRFPDetails
  ) => Promise<AxiosResponse<any, any>>;
}
const RFPTable = () => {
  const dispatch = useDispatch();
  const {
    allRfps,
    isAllRfpLoading,
    isManuallyEditedShown,
    currentPage,
    pageLimit,
    totalRfpsCount,
  } = useSelector((state: RootState) => state.allRfps);
  const currentRfpPage = currentPage ?? 1;
  const rfpPageLimit = pageLimit ?? PageLimit.Rfp;

  const apiFunctions: ApiFunctions = {
    rfp_name: UpdateRFPName,
    client_name: UpdateClientName,
    stakeholders: UpdateStakeholders,
    bid_stage: UpdateBidStage,
    opportunity_id: UpdateOpportunityId,
  };

  const handleSave = async (row: IAllRfp | any) => {
    const dataIndex = row?.dataIndex;

    if (dataIndex && apiFunctions[dataIndex]) {
      try {
        const response = await updateFunction(row?.rfp_id, {
          [dataIndex]: row[dataIndex],
        });

        if (response?.status === 200) {
          dispatch(updateRFPDetails(response?.data));
          // getAllRfps(rfpFilters);
        }
      } catch (error) {
        console.log("Error updating column", error);
      }
    }
  };

  const updateFunction = async (
    rfpId: number,
    updateObj: IUpdateRFPDetails
  ) => {
    const dataIndex = Object.keys(updateObj)[0];
    const updateApiFunction = apiFunctions[dataIndex];

    if (updateApiFunction) {
      return await updateApiFunction(rfpId, updateObj);
    } else {
      return null;
    }
  };

  const columns = RFPTableColumns()?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable:
          record?.status !== RFPStatus.PROCESSING ? col.editable : false,
        dataIndex: col.dataIndex,
        title: col.label,
        handleSave,
      }),
    };
  });

  const renderFooter = () => {
    return (
      <Styles.FooterContainer>
        <Checkbox
          checked={isManuallyEditedShown}
          onChange={() => {
            dispatch(setIsManuallyEditedShown(!isManuallyEditedShown));
          }}
        >
          Manually edited
        </Checkbox>
        <Styles.ManualEditTooltip title="Fields in pink indicate edited content. Review your changes and confirm for customization.">
          <img src={IMAGES.infoIcon} alt="info" />
        </Styles.ManualEditTooltip>
      </Styles.FooterContainer>
    );
  };

  const handlePagination = (page: number, size: number) => {
    dispatch(setRfpCurrentPage(page));
    dispatch(setRfpPageLimit(size));
  };

  return (
    <Styles.RFPTableContainer>
      {isAllRfpLoading ? (
        <SkeletonTable />
      ) : (
        <Styles.RfpTable
          rowKey={"rfp_id"}
          components={components}
          dataSource={allRfps && allRfps.length ? allRfps : []}
          columns={columns as any}
          footer={allRfps.length ? renderFooter : undefined}
          pagination={false}
        />
      )}

      {allRfps && allRfps.length && !isAllRfpLoading ? (
        <CustomPagination
          currentPage={currentRfpPage}
          total={totalRfpsCount ?? 0}
          handlePagination={handlePagination}
          pageSize={rfpPageLimit}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      ) : (
        <></>
      )}
    </Styles.RFPTableContainer>
  );
};

export default RFPTable;

import * as Styles from "./styles";

const AtiInfoModal = () => {
  return (
    <Styles.AtiInfoContainer>
      <Styles.Content>
        An Agreement to Include (ATI) discussion is required for a new client or
        existing inactive client to be approved and added to the client
        portfolio. All clients with a client class of “Requires ATI” need such
        approval. Use the form below to help Client Group (CG)/Market Unit (MU)
        leadership determine if Accenture should invest in a relationship with
        this client.
      </Styles.Content>
      <Styles.Content>
        To support the ATI discussion in NBM when including a new client in the
        portfolio, review the one-page Responsible Business Guidance on Client
        Due Diligence. This guidance will assist in assessing if enough is known
        about the client to enter a relationship while minimizing the risk of
        causing harm to Accenture’s reputation and/or stakeholders.
      </Styles.Content>
      <Styles.Content>
        After completing and submitting the information below, use the
        Responsible Business Opportunity Assessment Framework to consider the
        client opportunity through a Responsible Business lens. This Framework
        should be used to prepare for the ATI discussion and subsequent
        opportunity approval discussions and decisions in NBM. The Framework is
        a light-touch/low effort sales aid to identify, raise, and discuss
        concerns or opportunities as they relate to Accenture, our clients,
        wider stakeholders/society, and the environment.
      </Styles.Content>

      <Styles.Title2>Actions Needed by the Client Team:</Styles.Title2>
      <Styles.ContentUl style={{ listStyle: "decimal" }}>
        <Styles.Contentli>
          New/Existing Client: Complete and submit the ATI Discussion Template
          below to the appropriate CG Sales Ops for discussion at a New Business
          Meeting (NBM). If the client is approved:
          <Styles.ContentUl>
            <Styles.Contentli>
              A new client (MC/FC/CR) can be created in MMS and in SAP
              Financials.
            </Styles.Contentli>
            <Styles.Contentli>
              An existing inactive client can be re-opened in MMS and the client
              classification updated from Requires ATI to Opportunistic.
            </Styles.Contentli>
          </Styles.ContentUl>
        </Styles.Contentli>

        <Styles.Contentli>
          Third Party: If a 3rd party is involved (i.e., Accenture is
          sub-contracting to another company; a 3rd party will be paying
          Accenture), use the 3rd Party FC/CR set up. Enter information for the
          3rd party below, NOT the end client.
        </Styles.Contentli>

        <Styles.Contentli>
          Once leadership has approved the inclusion of the new client, the CPM
          team will update the MMS client hierarchy, if necessary.
        </Styles.Contentli>

        <Styles.Contentli>
          The client team should work with Finance to request financial customer
          creation or updates in SAP, as necessary. A table is included in this
          template that facilitates the data needed by finance for new
          customers.
        </Styles.Contentli>
      </Styles.ContentUl>
    </Styles.AtiInfoContainer>
  );
};

export default AtiInfoModal;

import styled from "styled-components";
import { PrimaryButton } from "../../../shared";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";

export const Header = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: 100%;
`;

export const SubHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
`;

export const PageTitle = styled.div`
  font-family: GraphikMedium;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const UploadButton = styled(PrimaryButton)`
  height: 32px;
  font-size: 13px;

  img {
    width: 13px;
  }
`;

export const SearchOut = styled(SearchOutlined)`
  color: #a100ff;
  font-size: 16px;
  background-color: #fff !important;
  span.ant-input-group-addon {
    background-color: #fff !important;
  }
`;

export const InputSearch = styled(Input)`
  width: 300px;

  .ant-input-prefix {
    img {
      width: 15px;
    }
  }
`;

export const Spaced = styled(Space)``;

export const ExportUploadButtonWrap = styled.div`
  display: flex;
  gap: 15px;
`;

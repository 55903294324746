import { Skeleton } from "antd";
import * as Styles from "../../../pages/userManagement/styles";

const SkeletonTable = () => {
  const columns = [
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "name",
      key: "name",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "email",
      key: "email",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "role",
      key: "role",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "Status",
      key: "Status",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      key: "action",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
  ];

  return (
    <Styles.UserContainer>
      <Styles.UserTable
        rowKey={"id"}
        dataSource={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
        columns={columns as any}
        scroll={{ y: "calc(100vh - 312px)" }}
        pagination={false}
      />
    </Styles.UserContainer>
  );
};

export default SkeletonTable;

import styled from "styled-components";
import { IMAGES } from "./../../shared/images";
import { Link } from "react-router-dom";

interface IRfpCardIconWrap {
  $bgcolor: string;
}

export const HomeContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 50px);
  display: grid;
  grid-template-rows: 200px 1fr;
`;

export const HeaderComponentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${IMAGES.homeBackground});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const WelcomeText = styled.div`
  color: ${({ theme }) => theme.colors.fontColorBeta};
  font-family: "GraphikRegular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DashboardHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorBeta};
  font-family: "GraphikBold";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
`;

export const RfpComponentsContainer = styled.div`
  width: 97%;
  height: calc(100vh - 270px);
  padding: 0 10px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

export const RfpCardWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
`;

export const RfpCardContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 10px;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #e5eaf8;
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
`;

export const RfpCardIconWrap = styled.div<IRfpCardIconWrap>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ $bgcolor }) => $bgcolor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RfpCardTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const RfpCardHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorGamma};
  font-family: "GraphikMedium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RfpCardSubHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorRho};
  height: 32px;
  font-family: "GraphikRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RfpCardUrl = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 5px;

  span {
    color: ${({ theme }) => theme.colors.fontColorDelta};
    font-family: "GraphikMedium";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.28px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  img {
    width: 15px;
  }
`;

export const RfpStatusContainer = styled.div`
  width: 100%;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px ${({ theme }) => theme.colors.borderColoRho};
  padding: 15px 0 15px 15px;
  background-image: url(${IMAGES.trackRfpBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 20% 1fr;
  align-items: center;
  gap: 50px;
`;

export const RfpStatusHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorGamma};
  font-family: "GraphikMedium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const RfpStatusCountTextContainer = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
`;

export const RfpStatusCountBorder = styled.div`
  width: 0.5px;
  height: 100%;
  background: #8200cc2b;
`;

export const RfpStatusCountTextWrap = styled.div``;

export const RfpStatusCountBorderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RfpStatusSubHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorGamma};
  font-family: "GraphikRegular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
`;

export const RfpStatusPendingTextWrap = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

export const RfpStatusCount = styled.div`
  color: ${({ theme }) => theme.colors.fontColorGamma};
  font-family: "GraphikMedium";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const RfpStatusPendingText = styled.div`
  color: ${({ theme }) => theme.colors.fontColorXi};
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
`;

export const QuickStartContainer = styled.div`
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px ${({ theme }) => theme.colors.borderColoRho};
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const QuickStartHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorDelta};
  font-family: "GraphikMedium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const QuickStartCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
`;

export const QuickStartCardWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const QuickStartCardImgWrap = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  display: grid;
  place-items: center;
`;

export const QuickStartCardImg = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 5px;
`;

export const QuickStartCardPlayImg = styled.img`
  position: absolute;
  z-index: 10;
  width: 40px;
`;

export const QuickStartCardTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const QuickStartCardHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorGamma};
  font-family: "GraphikMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const QuickStartCardSubHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorDelta};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
`;

export const QuickStartGuideCardWrap = styled.div`
  border-radius: 2px;
  border: 0.5px solid #e1c3f3;
  background: #f7f2fa;
  box-shadow: 0px 4px 4px 0px ${({ theme }) => theme.colors.borderColoRho};
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const QuickStartGuideCardHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorGamma};
  font-family: "GraphikMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const QuickStartGuideCardSubHeader = styled.div`
  color: ${({ theme }) => theme.colors.fontColorRho};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const QuickStartGuideReadMore = styled.div`
  color: ${({ theme }) => theme.colors.fontColorDelta};
  font-family: "GraphikMedium";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  text-decoration: underline;
`;

export const LinkAdded = styled(Link)`
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const ViewText = styled.div``;

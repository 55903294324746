import styled, { css } from "styled-components";
import { Button, Col, Dropdown, Form, Input, Row, Tooltip } from "antd";
import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import { IViewRFP } from "../../../../types";

export const OverviewDetails = styled.div`
  width: 100%;
  height: calc(100vh - 143px);
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 12px;
  overflow-y: auto;
`;

export const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SaveDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Details = styled.div`
  margin-top: 20px;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  position: relative;
  margin-bottom: 16px;
`;

export const AntRow = styled(Row)`
  padding: 10px 10px 10px 20px;
`;

export const AntCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const FinanceSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 0 10px 35px;
  margin-bottom: 16px;
  height: 210px;
  position: relative;
`;

export const FinanceInputRequired = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 0px 0px 0px;
  margin-bottom: 16px;
  position: relative;
`;

export const FinanceInputBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 10px 10px 35px;
  margin-bottom: 10px;
  height: 200px;
`;

export const SummaryText = styled.div`
  margin-top: 12px;
  background-color: #f4f6fc;
  padding: 15px 18px 5px 17px;
`;

export const Text = styled.div`
  margin-top: 12px;
  height: 160px;
  overflow: auto;
  font-size: 12px;

  div {
    height: 100%;
  }
`;

export const FinanceBox = styled.div`
  overflow: auto;
  height: 100%;
  background-color: #f4f6fc;
  position: relative;
  width: 99%;
`;

export const FinanceBoxContent = styled.div`
  margin: 12px 0px 0px 12px;
  font-size: 12px;
`;

export const TitleIconWrap = styled.div`
  position: relative;
  bottom: -12px;
  margin-left: 10px;
  left: 20px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Title = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  font-size: 14px;
`;

export const TitlePage = styled.div`
  top: -11px;
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 10px;
  left: 20px;
  position: relative;
  padding: 0 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
`;

export const InputFormItem = styled(Form.Item);

export const InputSummary = styled(Input.TextArea)`
  margin-top: 10px;

  &.ant-input {
    resize: none;
    height: fit-content;
    min-height: 165px;

    ${(props) =>
      props.readOnly &&
      css`
        border: none;
        &:hover {
          border: none;
        }
      `}
  }
`;

export const InputPages = styled(Input)`
  &.ant-input {
    resize: none;
    border: none;
    height: 14px !important;
    width: 53px;
    border: 1px solid ${({ theme }) => theme.colors.backgroundColoZeta};
    background: ${({ theme }) => theme.colors.backgroundColoZeta};
    font-family: GraphikBold;
    font-weight: 600;
    margin-top: 4px;
  }
`;

export const EditFinance = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const CancelImage = styled.div`
  position: absolute;
  top: -5px;
  right: -6px;
  cursor: pointer;
`;

export const NoFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.fontColorEta};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  flex-direction: column;
`;
export const FileTextOutlinedIcon = styled(FileTextOutlined)`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
`;
export const VerticalLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 37%;
  border-left: 1px solid ${({ theme }) => theme.colors.borderColorTheta}; /* Adjust the color and style as needed */
`;

export const HalfVerticalLine = styled.div`
  margin-right: 7px;
  margin-top: 4px;
`;

export const PageCol = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 8%;
  color: ${({ theme }) => theme.colors.fontColorEta};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ColTitle = styled.div`
  margin: -1px 29px 0px 3px;
`;

export const FinanceSummaryCliped = styled.div`
  display: flex;
  gap: 8px;
  margin: 100px 0 0 0;
`;

export const FinanceInputCliped = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0px 0px 0px 55px;
  height: 38px;
  border: 1px solid #d2deff;
  background: #ffffff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 7px;
  box-shadow: 0px 4px 4px 0px #dbdee7;
  color: var(--Black, #333);
  font-family: GraphikMedium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin: 10px;
  padding: 10px;
  gap: 10px;
`;

export const ReleventPagesFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GraphikMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  margin: 0px 25px 20px 3px;
  justify-content: space-between;
`;

export const FooterRightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
`;

export const ButtonLink = styled(Button)`
  &.ant-btn {
    padding: 0;
    font-size: 12px;
  }
  &.ant-btn > span {
    text-decoration: underline;
  }
  &.ant-btn-link {
    color: #194dea;
  }
`;

export const AntInput = styled(Input)`
  &.ant-input {
    padding: 0px;
    width: 45px;
    font-family: "GraphikMedium", sans-serif;
  }
  &.ant-input-outlined {
    background: transparent;
    border: none;
  }
  &.ant-input-outlined:focus,
  :where(
      .css-dev-only-do-not-override-1v67itz
    ).ant-input-outlined:focus-within {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  &.ant-input-outlined:hover {
    background: transparent;
  }
`;

export const FileText = styled.div`
  font-size: 12px;
`;

export const BtnStyling = styled.div`
  margin: 14px 0px 0px 29px;
`;

export const DeleteIcons = styled.span``;

export const DeleteButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoteContainer = styled.div`
  border-radius: 2px;
  border: 0.5px solid #f1d6ff;
  background: #f8ebff;
  box-shadow: 0px 4px 4px 0px #fbf3ff;
  font-size: 11px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const NoteTitle = styled.span`
  font-family: "GraphikMedium", sans-serif;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  padding-right: 5px;
`;

export const NoteDescription = styled.div``;

export const ExtractButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  background: linear-gradient(107deg, #cf26eb 33.23%, #6d39f5 87.55%);
  flex-shrink: 0;

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  }

  img {
    height: 16px;
  }
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 70% 1fr;
  /* border: 0.1px solid red; */
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: start;
`;

export const LeftSide = styled.div``;

export const FinanceTitle = styled.div`
  display: grid;
  grid-template-columns: 70% 1fr;
`;

export const TitleText = styled.div`
  font-size: 15px;
  font-weight: 400;
  padding: 7px;
  color: #5c5b5b;
`;

export const FinanceHeader = styled.div`
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 20px 10px 35px;
  height: 100%;
  min-height: 240px;
  /* margin-bottom: 16px; */
`;

export const EmptyFiles = styled(FinanceHeader)`
  display: flex;
  align-items: center;
  justify-content: center;

  & .ant-empty-image {
    height: 50px;
  }
`;

export const FinanceDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SerialNum = styled.div`
  width: 33px;
  font-size: 15px;
  font-family: GraphikMedium;
  font-weight: 500;
  height: 33px;
  border-radius: 50px;
  border: 0.1px solid #f4edf9;
  background-color: #f4edf9;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  font-size: 16px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const Action = styled.div`
  height: 100%;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditOutlinedIcon = styled(EditOutlined)`
  font-size: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
`;

// export const RigthSideFooter = styled.div`
//   display: flex;
//   justify-content: end;
//   align-items: center;
//   margin-top: 8px;
// `;

export const TertiaryButton = styled(Button)`
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  font-family: "GraphikMedium";
  font-size: 14px;
  font-weight: 500;
`;

export const DropDown = styled(Dropdown)`
  margin-right: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const DropText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Anchor = styled.a`
  font-size: 14px;
  font-weight: 400;
`;

export const ExtractLegalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GenerateText = styled.span`
  font-size: 12px;
`;

export const AIIcon = styled.span`
  margin-left: 5px;
`;

export const FileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 205px;
  overflow-x: auto;
`;

export const FileNameWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  gap: 15px;
  border-bottom: 1px solid #bac5d8;
  padding-bottom: 5px;
`;

export const FileName = styled.div`
  font-size: 13px;
  font-weight: 400;
  height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileIconsWrap = styled.div`
  display: flex;
  gap: 20px;

  img {
    width: 20px;
    cursor: pointer;
  }

  button {
    padding: 0;
    height: 0;

    img {
      width: 18px;
      cursor: pointer;
      filter: invert(65%) sepia(6%) saturate(17%) hue-rotate(345deg)
        brightness(105%) contrast(79%);
      &:hover {
        filter: invert(11%) sepia(93%) saturate(6771%) hue-rotate(279deg)
          brightness(102%) contrast(116%);
      }
    }
  }
`;

export const ViewRfp = styled.img<IViewRFP>`
  width: 20px;
  height: 20px;

  filter: invert(65%) sepia(6%) saturate(17%) hue-rotate(345deg)
    brightness(105%) contrast(79%);
  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "pointer")};
  &:hover {
    filter: invert(11%) sepia(93%) saturate(6771%) hue-rotate(279deg)
      brightness(102%) contrast(116%);
  }
`;

export const StyledTooltip = styled(Tooltip)``;

import { store } from "../store";
import { deleteRequest, get, post, put } from "./apiClient";
import {
  setIsDeleteExtractPQFileLoading,
  setIsDeleteExtractTQFileLoading,
  setIsPQTQDetailsLoading,
  setPQExtractionStatus,
  setPQTQDetails,
  setTQExtractionStatus,
  updatePQExtractionStatus,
  updatePQGenerationStatus,
  updateTQExtractionStatus,
  updateTQGenerationStatus,
} from "../store/pqtq/pqtqSlice";
import { toast } from "react-toastify";
import { IPQTQRelevantPages } from "../store/pqtq/pqtq.interface";
import { ExtractionStatus } from "../constants";
import { IStopRFPExtGen } from "./stopRfpExtGen";

export const getAllPQTQPages = async (rfpId: number) => {
  try {
    store.dispatch(setIsPQTQDetailsLoading(true));
    const res = await get(`apa/profile/${rfpId}/proposals`);

    const data = {
      fileName: res?.rfp?.file_name,
      ...res?.solution?.pqtq,
    };

    store.dispatch(setPQTQDetails(data));
  } catch (error: any) {
    console.log(error, "getAllPQTQPages api error");
  } finally {
    store.dispatch(setIsPQTQDetailsLoading(false));
  }
};

export const updatePQPages = async (
  id: number,
  request: IPQTQRelevantPages[],
  message: string
) => {
  try {
    await put(`/apa/pqtq/${id}/pq-pages`, request);
    toast.success(message);
  } catch (error: any) {
    console.log(error, "updatePQPages api error");
    toast.error(error?.message ?? "Failed to update PQ pages");
  }
};

export const updateTQPages = async (
  id: number,
  request: IPQTQRelevantPages[],
  message: string
) => {
  try {
    await put(`/apa/pqtq/${id}/tq-pages`, request);
    toast.success(message);
  } catch (error: any) {
    console.log(error, "updateTQPages api error");
    toast.error(error?.message ?? "Failed to update TQ pages");
  }
};

export const getPQTQPageDetailById = async (pqtqId: number) => {
  try {
    const res = await get(`/apa/pqtq/${pqtqId}`);
    return res;
  } catch (error: any) {
    console.log("getPQTQPageDetailById", error);
  }
};

export const extractPQDetails = async (request: {
  id: number;
  rfpFileName: string;
}) => {
  try {
    store.dispatch(setPQExtractionStatus(ExtractionStatus.PROCESSING));

    await put(
      `/apa/pqtq/${request?.id}/extract-pq-details`,
      {},
      { params: request }
    );

    let interval = setInterval(async () => {
      try {
        const pqByIdRes = await getPQTQPageDetailById(request?.id);
        store.dispatch(
          updatePQExtractionStatus({
            status: pqByIdRes?.pqExtractionStatus,
            url: pqByIdRes?.pqSectionSummaryFilePreSignedUrl,
            fileName: pqByIdRes?.pqSectionSummaryFileName,
          })
        );

        !!pqByIdRes &&
          pqByIdRes.pqExtractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      } catch {
        clearInterval(interval);
        store.dispatch(setPQExtractionStatus(ExtractionStatus.FAILED));
      }
    }, 10000);
  } catch (error: any) {
    console.log("extractPQDetails", error);
    toast.error(error?.error ?? "Failed to extract PQ details");
    store.dispatch(setPQExtractionStatus(ExtractionStatus.NOT_STARTED));
  }
};

export const stopPQExtraction = async (request: IStopRFPExtGen) => {
  try {
    store.dispatch(setPQExtractionStatus(ExtractionStatus.STOPPING));

    const { data } = await put(`/apa/profile/stop`, request);

    if (data?.errorMessage === "Extraction is completed") {
      toast.error(
        `we can't stop now. The extraction has been completed successfully.`
      );
    }

    let interval = setInterval(async () => {
      try {
        const pqByIdRes = await getPQTQPageDetailById(request?.id);
        store.dispatch(
          updatePQExtractionStatus({
            status: pqByIdRes?.pqExtractionStatus,
            url: pqByIdRes?.pqSectionSummaryFilePreSignedUrl,
            fileName: pqByIdRes?.pqSectionSummaryFileName,
          })
        );

        if (!!pqByIdRes) {
          if (
            pqByIdRes.pqExtractionStatus !== ExtractionStatus.NOT_STARTED ||
            pqByIdRes.pqExtractionStatus !== ExtractionStatus.COMPLETED
          ) {
            clearInterval(interval);
          }
        }
      } catch {
        clearInterval(interval);
        store.dispatch(setPQExtractionStatus(ExtractionStatus.FAILED));
      }
    }, 10000);
  } catch (error: any) {
    console.log("stopPQExtraction", error);
    toast.error(error?.error ?? "Failed to stop PQ extraction ");
    store.dispatch(setPQExtractionStatus(ExtractionStatus.NOT_STARTED));
  }
};

export const extractTQDetails = async (request: {
  id: number;
  rfpFileName: string;
}) => {
  try {
    store.dispatch(setTQExtractionStatus(ExtractionStatus.PROCESSING));

    await put(
      `/apa/pqtq/${request?.id}/extract-tq-details`,
      {},
      { params: request }
    );

    let interval = setInterval(async () => {
      try {
        const tqByIdRes = await getPQTQPageDetailById(request?.id);

        store.dispatch(
          updateTQExtractionStatus({
            status: tqByIdRes?.tqExtractionStatus,
            url: tqByIdRes?.tqSectionSummaryFilePreSignedUrl,
            fileName: tqByIdRes?.tqSectionSummaryFileName,
          })
        );

        !!tqByIdRes &&
          tqByIdRes.tqExtractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      } catch {
        clearInterval(interval);
        store.dispatch(setTQExtractionStatus(ExtractionStatus.NOT_STARTED));
      }
    }, 10000);
  } catch (error: any) {
    console.log("extractTQDetails", error);
    toast.error(error?.error ?? "Failed to extract TQ details");
    store.dispatch(setTQExtractionStatus(ExtractionStatus.NOT_STARTED));
  }
};

export const stopTQExtraction = async (request: IStopRFPExtGen) => {
  try {
    store.dispatch(setTQExtractionStatus(ExtractionStatus.STOPPING));

    const { data } = await put(`/apa/profile/stop`, request);

    if (data?.errorMessage === "Extraction is completed") {
      toast.error(
        `we can't stop now. The extraction has been completed successfully.`
      );
    }

    let interval = setInterval(async () => {
      try {
        const tqByIdRes = await getPQTQPageDetailById(request?.id);

        store.dispatch(
          updateTQExtractionStatus({
            status: tqByIdRes?.tqExtractionStatus,
            url: tqByIdRes?.tqSectionSummaryFilePreSignedUrl,
            fileName: tqByIdRes?.tqSectionSummaryFileName,
          })
        );

        if (!!tqByIdRes) {
          if (
            tqByIdRes.tqExtractionStatus === ExtractionStatus.NOT_STARTED ||
            tqByIdRes.tqExtractionStatus === ExtractionStatus.COMPLETED
          )
            clearInterval(interval);
        }
      } catch {
        clearInterval(interval);
        store.dispatch(setTQExtractionStatus(ExtractionStatus.FAILED));
      }
    }, 10000);
  } catch (error: any) {
    console.log("stopTQExtraction", error);
    toast.error(error?.error ?? "Failed to stop TQ extraction ");
    store.dispatch(setTQExtractionStatus(ExtractionStatus.NOT_STARTED));
  }
};

export const deletePQExtractedFile = async (pqtqId: number) => {
  try {
    store.dispatch(setIsDeleteExtractPQFileLoading(true));

    const res = await deleteRequest(`/apa/pqtq/pq-details-file/${pqtqId}`);

    let interval = setInterval(async () => {
      try {
        const pqByIdRes = await getPQTQPageDetailById(pqtqId);
        store.dispatch(
          updatePQExtractionStatus({
            status: pqByIdRes?.pqExtractionStatus,
            url: pqByIdRes?.pqSectionSummaryFilePreSignedUrl,
            fileName: pqByIdRes?.pqSectionSummaryFileName,
          })
        );

        !!pqByIdRes &&
          pqByIdRes.pqExtractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);

        toast.success("File deleted successfully");
      } catch {
        clearInterval(interval);
        store.dispatch(setPQExtractionStatus(ExtractionStatus.NOT_STARTED));
      } finally {
        store.dispatch(setIsDeleteExtractPQFileLoading(false));
      }
    }, 10000);

    return res;
  } catch (error: any) {
    console.log("deletePQExtractedFile", error);
    toast.error(error?.error ?? "Failed to delete file");
    store.dispatch(setIsDeleteExtractPQFileLoading(false));
    store.dispatch(setPQExtractionStatus(ExtractionStatus.NOT_STARTED));
  }
};

export const deleteTQExtractedFile = async (pqtqId: number) => {
  try {
    store.dispatch(setIsDeleteExtractTQFileLoading(true));

    const res = await deleteRequest(`/apa/pqtq/tq-details-file/${pqtqId}`);

    let interval = setInterval(async () => {
      try {
        const tqByIdRes = await getPQTQPageDetailById(pqtqId);

        store.dispatch(
          updateTQExtractionStatus({
            status: tqByIdRes?.tqExtractionStatus,
            url: tqByIdRes?.tqSectionSummaryFilePreSignedUrl,
            fileName: tqByIdRes?.tqSectionSummaryFileName,
          })
        );

        !!tqByIdRes &&
          tqByIdRes.tqExtractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      } catch {
        clearInterval(interval);
        store.dispatch(setTQExtractionStatus(ExtractionStatus.FAILED));
      } finally {
        store.dispatch(setIsDeleteExtractTQFileLoading(false));
      }
    }, 10000);

    return res;
  } catch (error: any) {
    console.log("deleteTQExtractedFile", error);
    toast.error(error?.error ?? "Failed to delete file");
    store.dispatch(setIsDeleteExtractTQFileLoading(false));
  }
};

export const generatePQResponse = async (
  pqRequest: {
    rfpId: number;
    pqtqId: number;
    contentType: string;
    preSignedUrl: string;
  },
  pqProcessingStatus: string,
  formData: FormData,
  uploadedContentType: string
) => {
  try {
    store.dispatch(
      updatePQGenerationStatus({
        status: ExtractionStatus.PROCESSING,
        url: "",
        fileName: "",
      })
    );
    pqProcessingStatus === ExtractionStatus.AI
      ? await post(
          `/apa/pqtq/generate-pq-response`,
          {},
          {
            params: {
              rfpId: pqRequest?.rfpId,
              pqtqId: pqRequest?.pqtqId,
              contentType: pqRequest?.contentType,
              preSignedUrl: pqRequest?.preSignedUrl,
            },
          }
        )
      : await post(`/apa/pqtq/generate-pq-response`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            rfpId: pqRequest?.rfpId,
            pqtqId: pqRequest?.pqtqId,
            contentType: uploadedContentType,
          },
        });

    let interval = setInterval(async () => {
      try {
        const pqByIdRes = await getPQTQPageDetailById(pqRequest?.pqtqId);

        store.dispatch(
          updatePQGenerationStatus({
            status: pqByIdRes?.pqGeneratedRespStatus,
            url: pqByIdRes?.pqGeneratedResponseFilePreSignedUrl,
            fileName: pqByIdRes?.pqGeneratedResponseFileName,
          })
        );

        !!pqByIdRes &&
          pqByIdRes.pqGeneratedRespStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      } catch {
        clearInterval(interval);
        store.dispatch(
          updatePQGenerationStatus({
            status: ExtractionStatus.FAILED,
            url: "",
            fileName: "",
          })
        );
      }
    }, 10000);
  } catch (error: any) {
    console.log("generatePQResponse", error);
    toast.error(error?.error ?? "Failed to generate PQ response");
    store.dispatch(
      updatePQGenerationStatus({
        status: ExtractionStatus.NOT_STARTED,
        url: "",
        fileName: "",
      })
    );
  }
};

export const stopPQGeneration = async (request: IStopRFPExtGen) => {
  try {
    store.dispatch(
      updatePQGenerationStatus({
        status: ExtractionStatus.STOPPING,
        url: "",
        fileName: "",
      })
    );

    const { data } = await put(`/apa/profile/stop`, request);

    if (data?.errorMessage === "Extraction is completed") {
      toast.error(
        `we can't stop now. The generation has been completed successfully.`
      );
    }

    let interval = setInterval(async () => {
      try {
        const pqByIdRes = await getPQTQPageDetailById(request?.id);

        store.dispatch(
          updatePQGenerationStatus({
            status: pqByIdRes?.pqGeneratedRespStatus,
            url: pqByIdRes?.pqGeneratedResponseFilePreSignedUrl,
            fileName: pqByIdRes?.pqGeneratedResponseFileName,
          })
        );

        if (!!pqByIdRes) {
          if (
            pqByIdRes.pqGeneratedRespStatus === ExtractionStatus.COMPLETED ||
            pqByIdRes.pqGeneratedRespStatus === ExtractionStatus.NOT_STARTED
          ) {
            clearInterval(interval);
          }
        }
      } catch {
        clearInterval(interval);
        store.dispatch(
          updatePQGenerationStatus({
            status: ExtractionStatus.NOT_STARTED,
            url: "",
            fileName: "",
          })
        );
      }
    }, 10000);
  } catch (error: any) {
    console.log("stopPQGeneration", error);
    toast.error(error?.error ?? "Failed to stop PQ generation");
    store.dispatch(
      updatePQGenerationStatus({
        status: ExtractionStatus.NOT_STARTED,
        url: "",
        fileName: "",
      })
    );
  }
};

export const generateTQResponse = async (
  tqRequest: {
    rfpId: number;
    pqtqId: number;
    contentType: string;
    preSignedUrl: string;
  },
  tqProcessingStatus: string,
  formData: FormData,
  uploadedContentType: string
) => {
  try {
    store.dispatch(
      updateTQGenerationStatus({
        status: ExtractionStatus.PROCESSING,
        url: "",
        fileName: "",
      })
    );
    tqProcessingStatus === ExtractionStatus.AI
      ? await post(
          `/apa/pqtq/generate-tq-response`,
          {},
          {
            params: {
              rfpId: tqRequest?.rfpId,
              pqtqId: tqRequest?.pqtqId,
              contentType: tqRequest?.contentType,
              preSignedUrl: tqRequest?.preSignedUrl,
            },
          }
        )
      : await post(`/apa/pqtq/generate-tq-response`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            rfpId: tqRequest?.rfpId,
            pqtqId: tqRequest?.pqtqId,
            contentType: uploadedContentType,
          },
        });

    let interval = setInterval(async () => {
      try {
        const tqByIdRes = await getPQTQPageDetailById(tqRequest?.pqtqId);

        store.dispatch(
          updateTQGenerationStatus({
            status: tqByIdRes?.tqGeneratedRespStatus,
            url: tqByIdRes?.tqGeneratedResponseFilePreSignedUrl,
            fileName: tqByIdRes?.tqGeneratedResponseFileName,
          })
        );

        !!tqByIdRes &&
          tqByIdRes.tqGeneratedRespStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      } catch {
        clearInterval(interval);
        store.dispatch(
          updateTQGenerationStatus({
            status: ExtractionStatus.FAILED,
            url: "",
            fileName: "",
          })
        );
      }
    }, 10000);
  } catch (error: any) {
    console.log("generateTQResponse", error);
    toast.error(error?.error ?? "Failed to generate TQ response");
    store.dispatch(
      updateTQGenerationStatus({
        status: ExtractionStatus.NOT_STARTED,
        url: "",
        fileName: "",
      })
    );
  }
};

export const stopTQGeneration = async (request: IStopRFPExtGen) => {
  try {
    store.dispatch(
      updateTQGenerationStatus({
        status: ExtractionStatus.STOPPING,
        url: "",
        fileName: "",
      })
    );
    const { data } = await put(`/apa/profile/stop`, request);

    if (data?.errorMessage === "Extraction is completed") {
      toast.error(
        `we can't stop now. The generation has been completed successfully.`
      );
    }

    let interval = setInterval(async () => {
      try {
        const tqByIdRes = await getPQTQPageDetailById(request?.id);

        store.dispatch(
          updateTQGenerationStatus({
            status: tqByIdRes?.tqGeneratedRespStatus,
            url: tqByIdRes?.tqGeneratedResponseFilePreSignedUrl,
            fileName: tqByIdRes?.tqGeneratedResponseFileName,
          })
        );

        if (!!tqByIdRes) {
          if (
            tqByIdRes.tqGeneratedRespStatus === ExtractionStatus.COMPLETED ||
            tqByIdRes.tqGeneratedRespStatus === ExtractionStatus.NOT_STARTED
          ) {
            clearInterval(interval);
          }
        }
      } catch {
        clearInterval(interval);
        store.dispatch(
          updateTQGenerationStatus({
            status: ExtractionStatus.NOT_STARTED,
            url: "",
            fileName: "",
          })
        );
      }
    }, 10000);
  } catch (error: any) {
    console.log("generateTQResponse", error);
    toast.error(error?.error ?? "Failed to stop TQ generation");
    store.dispatch(
      updateTQGenerationStatus({
        status: ExtractionStatus.NOT_STARTED,
        url: "",
        fileName: "",
      })
    );
  }
};

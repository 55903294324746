import { Dropdown, Switch, Table } from "antd";
import styled from "styled-components";

export const UserContainer = styled.div`
  /* margin: 10px; */
  /* height: 100%;
  overflow: hidden; */
`;

export const UserTable = styled(Table)`
  &.ant-table-wrapper {
    height: 100%;
    min-height: calc(100vh - 260px);
    background: #fff;
    .ant-table-thead > tr {
      & > th {
        background: none;
        line-height: 18px;
        font-family: "GraphikMedium";
        font-size: 13px;
        color: ${({ theme }) => theme.colors.fontColorZeta};
        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody > tr {
      &:hover > td {
        background: #f7ecff;
      }
    }
    .ant-table-tbody > tr {
      & > td {
        padding-top: 12px;
        padding-bottom: 12px;
        background: #fbfcff;
        font-size: 13px;
      }
    }
    .ant-table-pagination.ant-pagination {
      padding: 16px 0;
      background: #fff;
      margin: 0px;
    }
  }
`;

export const SelectAction = styled(Dropdown)`
  &.ant-dropdown-trigger {
    width: 5px;
    display: block;
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const AdminRole = styled.div`
  font-family: GraphikMedium;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #a100ff;
`;

export const Role = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

export const UserSwitch = styled(Switch)``;

export const ColumnStyle = styled.span`
  font-size: 13px;
`;

export const IMAGES = {
  gcLogo: "/assets/images/greychain-logo.svg",

  accentureLogo: "/assets/images/accenture-logo.svg",

  aiTagLogo: "/assets/images/ai-tag-logo.svg",

  loginBackground: "/assets/images/login-bg.svg",

  homeBackground: "/assets/images/home-background.svg",

  homeIcon: "/assets/images/home-icon.svg",

  activeHomeIcon: "/assets/images/active-home-icon.svg",

  dashboardIcon: "/assets/images/dashboard-icon.svg",

  activeDashboardIcon: "/assets/images/active-dashboard-icon.svg",

  rfpsIcon: "/assets/images/rfps-icon.svg",

  activeRfpsIcon: "/assets/images/active-rfps-icon.svg",

  aiBulletinIcon: "/assets/images/ai-bulletin-Icon.png",

  activeAiBulletinIcon: "/assets/images/active-ai-bulletin-icon.png",

  leftArrowWithBG: "/assets/images/left-arrow-with-bg.svg",

  searchIcon: "/assets/images/search-icon.svg",

  selectorIcon: "/assets/images/selector-icon.svg",

  dateRangeIcon: "/assets/images/date-range-icon.svg",

  regionIcon: "/assets/images/region-icon.svg",

  UploadIcon: "/assets/images/upload-icon.svg",

  rightArrow: "/assets/images/right-arrow.svg",

  trackRfpBg: "/assets/images/track-rfp-bg.svg",

  eye: "/assets/images/eye.svg",

  download: "/assets/images/download.svg",

  finance: "/assets/images/finance.svg",

  legal: "/assets/images/legal.svg",

  solution: "/assets/images/solution.svg",

  policyApproval: "/assets/images/policy-approval.svg",

  ATIIcon: "/assets/images/ATIIcon.svg",

  NBMIcon: "/assets/images/NBMIcon.svg",

  dummyQuickStart: "/assets/images/dummy-quick-start.svg",

  playIcon: "/assets/images/play-icon.svg",

  allRfpIcon: "/assets/images/all-rfp-icon.svg",

  matrixReview: "/assets/images/review-matrix-icon.svg",

  uploadIcon: "/assets/images/upload.svg",

  deleteIcon: "/assets/images/delete.svg",

  submitProcess: "/assets/images/AIProcess.svg",

  browse: "/assets/images/browse.svg",

  loader: "/assets/images/loader.svg",

  profileIcon: "/assets/images/profile-icon.svg",

  cancel: "/assets/images/cancel.svg",

  reachOutIcon: "/assets/images/reach-out-icon.svg",

  uploadRfpIcon: "/assets/images/upload-rfp-icon.svg",

  checkIcon: "/assets/images/check-icon.svg",

  processingIcon: "/assets/images/processing-icon.svg",

  stackHolderIcon: "/assets/images/stackHolder-icon.svg",

  infoIcon: "/assets/images/info-icon.svg",

  closeIcon: "/assets/images/close-icon.svg",

  editIcon: "/assets/images/edit-icon.svg",

  pinIcon: "/assets/images/pin-icon.svg",

  CloseModalIcon: "/assets/images/close-modal-icon.svg",

  rfpOverview: "/assets/images/rfp-overview.svg",

  rfpRightArrow: "/assets/images/rfp-right-arrow.svg",

  rfpSummeryIcon: "/assets/images/rfp-summery-icon.svg",

  loading: "/assets/images/Loading.svg",

  overviewCable: "/assets/images/cabel.svg",

  usersIcons: `/assets/images/usersIcon.svg`,

  addFieldIcon: "/assets/images/add-field-icon.svg",

  resetPassword: "/assets/images/reset-password.svg",

  pptIcon: "/assets/images/ppt-icon.svg",

  pdfIcon: "/assets/images/pdf-icon.svg",

  wordIcon: "/assets/images/word-icon.svg",

  downloadContentIcon: "/assets/images/download-content-icon.svg",

  dot: "/assets/images/dot.svg",

  stop: "/assets/images/stop.svg",

  downArrow: "/assets/images/downArrow.svg",

  contentSetting: "/assets/images/settingContent.svg",

  activeContentSetting: "/assets/images/activeContent.svg",

  clientGroup: "/assets/images/client-group-icon.svg",

  bidStage: "/assets/images/bid-stage-icon.svg",

  loadingSpinner: "/assets/images/loading-spinner.svg",

  upArrowWithBg: "/assets/images/upArrowWithBg.svg",

  actionMenu: "/assets/images/actionMenu.svg",

  openBook: "/assets/images/open-book.svg",

  viewLink: "/assets/images/view-link.png",

  roundPlus: "/assets/images/round-plus.svg",

  previousArrow: "/assets/images/previous-arrow.png",

  historyIcon: "/assets/images/history-icon.svg",

  financeInputIcon: "/assets/images/finance-input-icon.svg",

  alertIcon: "/assets/images/alert-icon.png",
};

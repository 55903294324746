import { IPropstats } from "./../store/overview/overview.interface";
import { toast } from "react-toastify";
import { store } from "../store";
import { IAti } from "../store/ati/ati.interface";
import { put, get, post, deleteRequest } from "./apiClient";
import {
  autoRefreshAtiStatus,
  setStopAtiAutoRefresh,
} from "../store/ati/atiSlice";
import { setSelectedRFP } from "../store/allRfps/allRfpSlice";
import { RFPGenerationKey, RFPGenerationStatus } from "../constants";
import {
  autoRefreshPolicyStatus,
  setStopPolicy966AutoRefresh,
} from "../store/policy966/policy966";
import {
  setCDLDocumentList,
  setCurrentRfpStatus,
  setIsOverviewLoading,
  setIsRfpGenerationStatusLoading,
  setOverview,
  updateRfpGenerationStatus,
} from "../store/overview/overviewSlice";
import {
  autoRefreshNbmStatus,
  setStopNbmAutoRefresh,
} from "../store/nbm/nbmSlice";
import {
  autoRefreshFinanceStatus,
  setStopFinanceAutoRefresh,
} from "../store/finance/financeSlice";
import {
  autoRefreshLegal,
  setLegalId,
  setStopLegalAutoRefresh,
} from "../store/legal/legalSlice";

export const getOverviewDetails = async (rfpId: number | string) => {
  try {
    store.dispatch(setIsOverviewLoading(true));
    const res = await get(`apa/rfp/${rfpId}`);
    store.dispatch(setOverview(res));
    store.dispatch(setSelectedRFP(res));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setIsOverviewLoading(false));
  }
};

export const getAllGenerationStatusByRfpId = async (rfpId: number | string) => {
  try {
    store.dispatch(setStopNbmAutoRefresh(false));
    store.dispatch(setStopAtiAutoRefresh(false));
    store.dispatch(setStopLegalAutoRefresh(false));
    store.dispatch(setStopFinanceAutoRefresh(false));
    store.dispatch(setStopPolicy966AutoRefresh(false));
    store.dispatch(setIsRfpGenerationStatusLoading(true));

    const res: IPropstats = await get(`apa/profile/${rfpId}/propstats`);

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.ATI,
        value: res?.ati?.generationStatus,
      })
    );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: res?.p966?.generationStatus,
      })
    );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.NBM,
        value: res?.nbm?.generationStatus,
      })
    );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: res?.finance?.generationStatus,
      })
    );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Legal,
        value: res?.legal?.extractionStatus,
      })
    );

    store.dispatch(setLegalId(res?.legal?.legalId));

    res?.ati?.generationStatus === RFPGenerationStatus.PROCESSING &&
      store.dispatch(autoRefreshAtiStatus({ rfpId: rfpId }));

    res?.p966?.generationStatus === RFPGenerationStatus.PROCESSING &&
      store.dispatch(autoRefreshPolicyStatus({ rfpId: Number(rfpId) ?? -1 }));

    res?.nbm?.generationStatus === RFPGenerationStatus.PROCESSING &&
      store.dispatch(autoRefreshNbmStatus({ rfpId: Number(rfpId) ?? -1 }));

    res?.finance?.generationStatus === RFPGenerationStatus.PROCESSING &&
      store.dispatch(autoRefreshFinanceStatus({ rfpId: Number(rfpId) ?? -1 }));

    res?.legal?.extractionStatus === RFPGenerationStatus.PROCESSING &&
      store.dispatch(
        autoRefreshLegal({
          lId: res?.legal?.legalId,
          rfpId: Number(rfpId) ?? -1,
        })
      );
  } catch (error) {
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.ATI,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.NBM,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Legal,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
  } finally {
    store.dispatch(setIsRfpGenerationStatusLoading(false));
  }
};

export const updateOverviewDetails = async (values: any, rfpId: any) => {
  try {
    const res = await put(`/apa/rfp/updateRFPDetails/${rfpId}`, values);
    toast.success("RFP Details updated successfully");
    getOverviewDetails(rfpId);
    return res;
  } catch (error) {
    console.log(error);
    toast.success("Failed to update RFP Details");
    return error;
  }
};

export const updateRPFSummmary = async (values: any, rfpId: any) => {
  try {
    const res = await put(`/apa/rfp/updateRFPSummary/${rfpId}`, values);
    getOverviewDetails(rfpId);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateStatus = async (
  rfpId: number,
  values: { status: string }
) => {
  try {
    const res = await put(`/apa/rfp/updateStatus/${rfpId}`, values);
    store.dispatch(setCurrentRfpStatus(values?.status));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateBidStage = async (rfpId: number, values: string) => {
  try {
    const res = await put(`/apa/rfp/updateBidStage/${rfpId}`, {
      bid_stage: values,
    });
    toast.success("Bid status updated successfully");
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateProjectCategory = async (rfpId: number, values: string) => {
  try {
    const res = await put(`/apa/rfp/updateProjectCategory/${rfpId}`, {
      project_category: values,
    });
    toast.success("Project Category updated successfully");
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// CDL - Documents

export const getCDLDocuments = async (rfpId: number) => {
  try {
    const res = await get(`/apa/rfp/cdl-document/${rfpId}`);
    store.dispatch(setCDLDocumentList(res));
  } catch (error) {
    return error;
  }
};

export const uplaodCDLDocument = async (request: FormData) => {
  try {
    const res = await post(`/apa/rfp/cdl-document`, request, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const updateCDLDocument = async (
  request: FormData,
  CDLDocId: number
) => {
  try {
    const res = await put(`/apa/rfp/cdl-document/${CDLDocId}`, request, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteCDLDocFile = async (CDLFileIds: number[]) => {
  try {
    await deleteRequest(
      `/apa/rfp/cdl-files?cdl_file_ids=${CDLFileIds.toString()}`
    );
  } catch (error) {
    return error;
  }
};

export const deleteCDLDocument = async (CDLDocId: number) => {
  try {
    const res = await deleteRequest(`/apa/rfp/cdl-document/${CDLDocId}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getPreSignedURL = async (fileName: string) => {
  try {
    const res = await post(
      `/core/pre-signed-url/`,
      {},
      { params: { file_name: fileName } }
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const generateAti = async (rfpId: number | string) => {
  try {
    store.dispatch(setStopAtiAutoRefresh(false));
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.ATI,
        value: RFPGenerationStatus.PROCESSING,
      })
    );

    const { data } = await post(`apa/ati/generate/${rfpId}`);

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.ATI,
        value: data.generationStatus,
      })
    );
    store.dispatch(autoRefreshAtiStatus({ rfpId: rfpId }));
  } catch (error: any) {
    console.log(error, "generateAti api error");
    toast.error(error?.error ?? "Failed to generate");
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.ATI,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
  }
};

export const autoRefreshedGenerateAti = async (
  rfpId: number | string
): Promise<IAti> => {
  try {
    const res = await get(`apa/ati/${rfpId}/rfp`);

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.ATI,
        value: res?.generationStatus,
      })
    );

    return res;
  } catch (err) {
    console.log("autoRefreshedGenerateAti api error", err);
    throw err;
  }
};

import { Button, Dropdown, Input, Modal, Skeleton } from "antd";
import styled from "styled-components";

interface IButton {
  disabled?: boolean;
}

export const aIBulletinTab = styled.div`
  padding-top: 15px;
  .ShowingResultsWrap {
    margin-top: 0px;
  }
  .scrollpage {
    height: calc(100vh - 183px);
  }
`;

export const FilterButton = styled(Button)<IButton>`
  font-family: "GraphikRegularfont";
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  border-color: ${({ theme }) => theme.colors.fontColorOmega};
  color: ${({ theme }) => theme.colors.fontColorOmega};
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 2px;

  &:not(:last-child) {
    margin-right: 20px;
  }
  &.line-button {
    border: 0px;
    padding: 0px;
    background: none;
    line-height: normal;
    box-shadow: none;
    font-size: 13px;
    text-align: left;
    & > div {
      display: none;
    }
    img {
      margin-right: 5px;
      filter: ${(props) =>
        props.disabled
          ? "invert(39%) sepia(3%) saturate(0%) hue-rotate(355deg) brightness(93%) contrast(97%)"
          : ""};
    }
  }
`;

export const PromotersCoveredWrap = styled.div`
  background: #fff;
  height: calc(100vh - 183px);
  margin-bottom: 15px;
  border: 1px solid #e6e4f09e;
`;

export const PromotersCoveredTitle = styled.h3`
  font-family: "GraphikMediumfont";
  font-size: 16px;
  font-weight: normal;
  line-height: 17.6px;
  letter-spacing: -0.02em;
  text-align: left;
  padding: 22px 20px 12px 20px;
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const PromotersCoveredList = styled.div`
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - 100px);
  display: block;
`;

export const PromotersCoveredItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 24px;
  line-height: 24px;
  border-bottom: 0.5px solid #e3e3e3;
  padding: 8px 20px;
  &:first-child {
    border-top: 0.5px solid #e3e3e3;
  }
  .ant-dropdown-trigger {
    position: absolute;
    right: 0px;
    top: 0px;
  }
`;

export const ItemTitle = styled.h4`
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectAction = styled(Dropdown)`
  &.ant-dropdown-trigger {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    cursor: pointer;
  }
`;

export const ActionMode = styled.div`
  box-shadow: 1px 0px 8px 0px #00000026;
  background-color: #fff;
  height: 47px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 6px 20px;
`;

export const AddPromoter = styled(Button)`
  height: 36px;
  width: 100%;
  border-color: ${({ theme }) => theme.colors.fontColorOmega};
  color: ${({ theme }) => theme.colors.fontColorOmega};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "GraphikRegularfont";
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  text-align: left;

  img {
    display: block;
    margin-right: 5px;
  }
`;

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
`;

export const InputField = styled(Input)`
  padding-right: 60px;
  text-overflow: ellipsis;
  font-family: "GraphikRegularfont";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const InputFieldAction = styled.div`
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
`;

export const InputButton = styled(Button)`
  border: 0px;
  padding: 0px;
  background: none;
  line-height: normal;
  box-shadow: none;
  height: auto;
  &.check-outlined {
    color: ${({ theme }) => theme.colors.fontColorOmega};
    margin-right: 8px;
  }
`;

export const SubscribeModal = styled(Modal)`
  &.unsubscribed-modal {
    max-width: 435px;
    // overlap css
    width: 100% !important;

    .ant-modal-header {
      margin-bottom: 8px;
    }

    .ant-modal-content {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #dee2e6;
      box-shadow: 0px 0px 2px 0px #0000001f;
    }

    .ant-modal-close {
      background: #e5e5e5;
      border-radius: 30px;
      width: 20px;
      height: 20px;

      .ant-modal-close-x {
        color: #4f4f4f;
        .anticon svg {
          display: inline-block;
          width: 12px;
        }
      }

      .ant-modal-close-icon {
        width: auto;
        height: auto;
      }
    }

    .ant-modal-title {
      color: ${({ theme }) => theme.colors.fontColorDelta};
      font-family: "GraphikSemiboldfont";
      font-weight: normal;
      line-height: 22px;
      font-size: 18px;
    }

    .ant-modal-body {
      font-family: "GraphikRegularfont";
      color: ${({ theme }) => theme.colors.fontColorDelta};
      font-size: 13px;
      line-height: 22px;
    }

    .ant-modal-footer {
      margin: 0px;
      margin-top: 24px;

      button.ant-btn {
        width: calc(50% - 12px);
        padding: 10px;
        line-height: 14px;
        height: auto;
        border-radius: 6px;
        font-family: "GraphikSemiboldfont";
        font-size: 13px;
        font-style: normal;
        font-weight: normal;
        margin: 0px;
        margin-right: 12px;
      }

      .ant-btn-dangerous {
        color: ${({ theme }) => theme.colors.fontColorBeta};
        border-color: ${({ theme }) => theme.colors.backgroundColorBeta};
        background: ${({ theme }) => theme.colors.backgroundColorBeta};

        &:hover {
          color: ${({ theme }) => theme.colors.fontColorBeta};
          border-color: ${({ theme }) => theme.colors.backgroundColorBeta};
          background: ${({ theme }) => theme.colors.backgroundColorBeta};
        }
      }

      .ant-btn-default:last-child {
        margin-left: 12px;
        margin-right: 0px;
        // border-color: var(--black-oak);
        // color: var(--black-oak);
      }
    }
    /* subscribed */
    &.subscribed-modal {
      .ant-modal-header,
      .ant-modal-body {
        padding-left: 30px;
        position: relative;
      }
      .ant-modal-header {
        margin-top: 14px;
      }
      .ant-modal-header::before {
        content: "";
        background-image: url(/assets/images/check-circle.png);
        position: absolute;
        width: 20px;
        height: 20px;
        display: block;
        left: 0px;
      }
      button.ant-btn {
        width: 63px;
        height: 27px;
        padding: 0px;
      }
    }
  }
`;

export const UnSubsBtn = styled(FilterButton)`
  border-color: ${({ theme }) => theme.colors.fontColorRho};
  color: ${({ theme }) => theme.colors.fontColorRho};

  &:hover {
    border-color: ${({ theme }) => theme.colors.fontColorRho};
    color: ${({ theme }) => theme.colors.fontColorRho};
  }
`;

export const BulletinSkeleton = styled(Skeleton)`
  padding: 10px;
`;

import { toast } from "react-toastify";
import { get, put } from "./apiClient";
import { store } from "../store";
import {
  autoRefreshFinanceStatus,
  setFinanceDetails,
  setIsFinanceDetailsLoading,
  setIsGenerateFinanceLoading,
  setStopFinanceAutoRefresh,
  setStopFinanceNavigationCheck,
  updateFinanceStatus,
} from "../store/finance/financeSlice";
import { updateRfpGenerationStatus } from "../store/overview/overviewSlice";
import { RFPGenerationKey, RFPGenerationStatus } from "../constants";
import { IFinance } from "../store/finance/finance.interface";

export interface IUpdateRFPDetails {
  [key: string]: string | number;
}

export const getFinanceDetails = async (rfpId: number, isLoading: boolean) => {
  try {
    isLoading && store.dispatch(setIsFinanceDetailsLoading(true));
    const res: IFinance = await get(`/apa/finance/${rfpId}`);

    res?.extractionStatus === RFPGenerationStatus.REGENERATING &&
      isLoading &&
      store.dispatch(autoRefreshFinanceStatus({ rfpId: rfpId }));

    store.dispatch(setFinanceDetails(res));

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: res?.extractionStatus,
      })
    );

    return res;
  } catch (error: any) {
    console.log("Get Finance Details", error);
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    throw error;
  } finally {
    store.dispatch(setIsFinanceDetailsLoading(false));
    store.dispatch(setStopFinanceNavigationCheck(true));
  }
};

export const GenerateFinanceDetails = async (rfpId: number) => {
  try {
    store.dispatch(setStopFinanceAutoRefresh(false));
    store.dispatch(setIsGenerateFinanceLoading(true));

    store.dispatch(updateFinanceStatus(RFPGenerationStatus.PROCESSING));

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: RFPGenerationStatus.PROCESSING,
      })
    );

    await put(
      `/apa/finance/extract-finance-details`,
      {},
      { params: { rfpId: rfpId } }
    );

    store.dispatch(autoRefreshFinanceStatus({ rfpId: rfpId }));
  } catch (error: any) {
    console.log("extractFinanceDetails", error);
    toast.error(error?.errorMessage ?? "Failed to extract");

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    store.dispatch(updateFinanceStatus(RFPGenerationStatus.NOT_STARTED));
    throw error;
  } finally {
    store.dispatch(setIsGenerateFinanceLoading(false));
  }
};

export const ReGenerateFinanceDetails = async (rfpId: number) => {
  try {
    store.dispatch(setStopFinanceAutoRefresh(false));
    store.dispatch(setIsGenerateFinanceLoading(true));

    store.dispatch(updateFinanceStatus(RFPGenerationStatus.REGENERATING));

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: RFPGenerationStatus.PROCESSING,
      })
    );

    await put(
      `/apa/finance/regenerate-finance-details`,
      {},
      { params: { rfpId: rfpId } }
    );

    store.dispatch(autoRefreshFinanceStatus({ rfpId: rfpId }));
  } catch (error: any) {
    console.log("ReGenerateFinanceDetails", error);
    toast.error(error?.errorMessage ?? "Failed to regenerate");

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Finance,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    store.dispatch(updateFinanceStatus(RFPGenerationStatus.COMPLETED));
    throw error;
  } finally {
    store.dispatch(setIsGenerateFinanceLoading(false));
  }
};

export const getAllProposalsDetails = async (rfpId: any) => {
  try {
    const res = await get(`apa/profile/${rfpId}/proposals`);
    return res;
  } catch (error: unknown) {
    throw error;
  }
};

export const UpdateFinanceInputs = async (
  fId: number,
  inputCategory: string,
  request: { summary: string }
) => {
  try {
    const res = await put(
      `/apa/finance/${fId}/inputs/${inputCategory}`,
      request
    );
    toast.success("Updated successfully.");
    return res;
  } catch (error: any) {
    console.log(error, "UpdateClient group api error");
    toast.error(error?.errorMessage ?? "Failed to update ");
    throw error;
  }
};

export const updateRelevantPages = async (
  id: string | number,
  request: any,
  message: string
) => {
  try {
    const res = await put(`/apa/finance/${id}/pages`, request);
    if (res.status === 200) {
      toast.success(message);
    }
  } catch (error: unknown) {
    console.log(error, `updateRelevantPagesAPI , finance/${id}/pages`);
    throw error;
  }
};

import styled from "styled-components";
import { ActiveStatus } from "../../types";

export const TabContainerItem = styled.div<ActiveStatus>`
  padding: 12px 0px 10px 0px;
  cursor: pointer;
  font-family: ${({ $active }) => ($active ? "GraphikBold" : "GraphikRegular")};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.fontColorDelta : theme.colors.fontColorDelta};
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${({ $active }) =>
    $active ? "#a100ff" : "transparent"};
  transition: 300ms all;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;

  ${TabContainerItem}:not(:first-child) {
    margin-left: 28px;

    @media (max-width: 768px) {
      margin-left: 20px;
    }
  }
`;

export const TabLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebecf2;

  @media (max-width: 1000px) {
    display: none;
  }
`;

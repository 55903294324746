import { Modal } from "antd";
import * as Styles from "./styles";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../../../shared";
import { useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  deleteCDLDocFile,
  deleteCDLDocument,
  updateCDLDocument,
  uplaodCDLDocument,
} from "../../../../../services/overview";
import { toast } from "react-toastify";
import {
  ICDLDocument,
  ICDLFiles,
} from "../../../../../store/overview/overview.interface";
import ConfirmationModal from "../../../../common/confirmModal/ConfirmationModal";

interface ICDLModalProps {
  isOpen: boolean;
  document: ICDLDocument | undefined;
  handleOk: () => void;
  handleCancel: () => void;
}
const AddEditCDLModal = ({
  isOpen,
  document,
  handleOk,
  handleCancel,
}: ICDLModalProps) => {
  const { rfpId } = useParams();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [CDLFiles, setCDLFiles] = useState<ICDLFiles[]>([]);
  const [isUploading, setIsUplaoding] = useState(false);
  const [isDeletingDoc, setIsDeletingDoc] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [deleteIds, setDeleteIds] = useState<number[]>([]);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(-1);

  useEffect(() => {
    if (document && document?.cdl_doc_id) {
      setTitle(document?.title);
      setNote(document?.note);
      setCDLFiles(document?.cdl_files);
    }
  }, [document]);

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleChangeNotes: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    setNote(e.target.value);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = e.target.files;
    if (fileList) {
      const fileArray: File[] = Array.from(fileList);
      setFiles(fileArray);
    }
  };

  const handleUplaod = async () => {
    if (rfpId) {
      try {
        setIsUplaoding(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("note", note);
        for (let i = 0; i < files.length; i++) {
          formData.append(`file${i + 1}`, files[i]);
        }
        formData.append("rfp_id", rfpId);
        let response: any;
        if (!!document?.cdl_doc_id) {
          response = await updateCDLDocument(formData, document?.cdl_doc_id);
          if (response?.status === 200) {
            if (deleteIds?.length) {
              await deleteCDLDocFile(deleteIds);
              handleOk();
            } else {
              handleOk();
            }
            toast.success(response?.data);
          }
        } else {
          response = await uplaodCDLDocument(formData);
          if (response?.status === 200) {
            toast.success(response?.data);
            handleOk();
          }
        }
        setIsUplaoding(false);
      } catch (error) {
        setIsUplaoding(false);
        console.log("Error while uploading documents", error);
      }
    }
  };

  const handleDeleteFromDB = (CDLFileId: number) => {
    if (CDLFileId) {
      setDeleteIds([...deleteIds, CDLFileId]);
      setCDLFiles(CDLFiles?.filter((file) => file.cdl_file_id !== CDLFileId));
      setSelectedFileId(-1);
    }
  };

  const handleDeleteFromState = (fileIndex: number) => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }

    setFiles(files?.filter((_, index: number) => index !== fileIndex));
  };

  const handleDeleteDocument = async () => {
    try {
      if (document?.cdl_doc_id) {
        setIsDeletingDoc(true);
        const response: any = await deleteCDLDocument(document?.cdl_doc_id);
        if (response.status === 200) {
          toast.success("CDL document deleted successfully");
          handleOk();
        }
        setIsDeletingDoc(false);
      }
    } catch (error) {
      setIsDeletingDoc(false);
      console.log("Error while deleting document", error);
    }
  };

  const resetStates = () => {
    setTitle("");
    setNote("");
    setFiles([]);
    setCDLFiles([]);
    setIsDeleteFile(false);
    setSelectedFileId(-1);
  };

  const handleCloseModal = () => {
    handleCancel();
  };

  return (
    <Styles.CDLModalContainer>
      <Modal
        title={!document?.cdl_doc_id ? "Share Update" : "Edit Update"}
        open={isOpen}
        onCancel={handleCloseModal}
        centered
        afterClose={resetStates}
        footer={[
          <Styles.FooterContainer>
            <PrimaryButton
              loading={isUploading}
              disabled={
                files.length <= 0 ||
                ((document && document?.cdl_files?.length <= 0) ?? false) ||
                title.trim() === "" ||
                note.trim() === ""
              }
              onClick={handleUplaod}
            >
              {!document?.cdl_doc_id ? "Upload" : "Update"}
            </PrimaryButton>
            {document?.cdl_doc_id ? (
              <SecondaryButton
                loading={isDeletingDoc}
                disabled={!document?.cdl_doc_id}
                danger
                onClick={handleDeleteDocument}
              >
                Delete
              </SecondaryButton>
            ) : null}
          </Styles.FooterContainer>,
        ]}
      >
        <Styles.CDLModalBody>
          <Styles.LableAndText>
            <Styles.Label>Title</Styles.Label>
            <Styles.StyledInput
              placeholder="Enter title name"
              value={title}
              onChange={handleChangeTitle}
            />
          </Styles.LableAndText>
          <Styles.LableAndText>
            <Styles.Label>Note</Styles.Label>
            <Styles.StyledInput.TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder="Write here..."
              value={note}
              onChange={handleChangeNotes}
            />
          </Styles.LableAndText>
          <Styles.LableAndText>
            <Styles.Label>Upload documents</Styles.Label>
            <Styles.UploadSubContainer>
              <Styles.UploadIconTitle>
                <img src={IMAGES.browse} alt="Browse" />
                <Styles.Span>Browse</Styles.Span>
              </Styles.UploadIconTitle>

              <PrimaryButton
                onClick={() => {
                  if (inputFileRef.current != null) {
                    inputFileRef.current.click();
                  }
                }}
              >
                Browse
              </PrimaryButton>
              <input
                multiple
                type="file"
                ref={inputFileRef}
                accept=".pdf"
                onChange={handleFileUpload}
                hidden
              />
            </Styles.UploadSubContainer>
            {/* <Styles.UplodingTitle>
              Uploading...
              <Styles.UploadingContent>
                <Styles.UploadFile>your-file-here.PDF</Styles.UploadFile>
                <SecondaryButton
                  icon={<CloseOutlined />}
                  type="text"
                  shape="circle"
                />
              </Styles.UploadingContent>
              <Styles.UploadProgress showInfo={false} percent={60} />
            </Styles.UplodingTitle> */}
            <Styles.UploadDocContainer>
              {!!CDLFiles.length ||
                (!!files?.length && (
                  <Styles.UplodedText>Uploaded</Styles.UplodedText>
                ))}
              {!!document?.cdl_doc_id &&
                !!CDLFiles.length &&
                CDLFiles?.map((file) => {
                  return (
                    <Styles.UploadSubContainer>
                      <Styles.UploadIconTitle>
                        <img src={IMAGES.browse} alt="Browse" />
                        <Styles.Span>{file?.file_name}</Styles.Span>
                      </Styles.UploadIconTitle>
                      <SecondaryButton
                        icon={<DeleteOutlined />}
                        type="text"
                        shape="circle"
                        danger
                        onClick={() => {
                          setSelectedFileId(file?.cdl_file_id);
                          setIsDeleteFile(true);
                        }}
                      />
                    </Styles.UploadSubContainer>
                  );
                })}
              {!!files.length &&
                files?.map((file, index: number) => {
                  return (
                    <Styles.UploadSubContainer>
                      <Styles.UploadIconTitle>
                        <img src={IMAGES.browse} alt="Browse" />
                        <Styles.Span>{file?.name}</Styles.Span>
                      </Styles.UploadIconTitle>
                      <SecondaryButton
                        icon={<DeleteOutlined />}
                        type="text"
                        shape="circle"
                        danger
                        onClick={() => {
                          handleDeleteFromState(index);
                        }}
                      />
                    </Styles.UploadSubContainer>
                  );
                })}
            </Styles.UploadDocContainer>
          </Styles.LableAndText>
        </Styles.CDLModalBody>
      </Modal>
      <ConfirmationModal
        title="Delete File"
        text={"Are you sure you want to delete this file?"}
        isOpen={isDeleteFile}
        isDanger={true}
        btnText="Delete"
        handleOk={() => {
          setIsDeleteFile(false);
          handleDeleteFromDB(selectedFileId);
        }}
        handleCancel={() => {
          setIsDeleteFile(false);
        }}
      />
    </Styles.CDLModalContainer>
  );
};

export default AddEditCDLModal;

import { useState } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import Tabs from "../../../shared/tabs";
import { AdminTabs } from "../../../constants";
import { IMAGES, TertiaryButton, debounce, uniqueId } from "../../../shared";
import { ContentHeader, UploadModal } from "../../../components";
import { ContentManagement, UserManagement } from "../../../pages";
import { fetchAllUsers } from "../../../services/userManagement";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import {
  setUserCurrentPage,
  setSearchText,
  setAdminTab,
} from "../../../store/userManagement/usersSlice";

const UserManagementTabs = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const { searchText, pageLimit, adminTab } = useSelector(
    (state: RootState) => state.users
  );

  const saveToServer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    if (trimmedSearchText.length >= 3) {
      fetchAllUsers(trimmedSearchText, 1, pageLimit);
    }
    if (e.target.value.length === 0) {
      fetchAllUsers("", 1, pageLimit);
    }
  };

  const debouncedSave = debounce(saveToServer, 900);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSave(e);
    dispatch(setSearchText(e.target.value));
    dispatch(setUserCurrentPage(1));
  };

  const renderTabHeaderContent = () => {
    switch (adminTab) {
      case AdminTabs.User:
      default:
        return (
          <Styles.TabContentContainer>
            <Styles.SearchAddNewUerButtonWrap>
              <Styles.UserInputSearch
                placeholder="Search by name or email..."
                prefix={<img src={IMAGES.searchIcon} alt="search" />}
                value={searchText}
                onChange={handleChangeSearch}
                allowClear
              />
              <TertiaryButton
                icon={<UserAddOutlined style={{ fontSize: "18px" }} />}
                onClick={() => setIsModalOpen(true)}
              >
                Add New User
              </TertiaryButton>
            </Styles.SearchAddNewUerButtonWrap>
          </Styles.TabContentContainer>
        );

      case AdminTabs.Content:
        return <ContentHeader setIsUploadModalOpen={setIsUploadModalOpen} />;
    }
  };

  const renderContent = () => {
    switch (adminTab) {
      case AdminTabs.User:
      default:
        return (
          <UserManagement
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        );

      case AdminTabs.Content:
        return <ContentManagement />;
    }
  };

  return (
    <>
      <Styles.UserManagementTabsContainer>
        <Styles.TabsContainer>
          <Tabs
            tabs={[
              {
                tab: (
                  <Styles.TabsHeaderText>User Management</Styles.TabsHeaderText>
                ),
                tabId: uniqueId(),
                handleTabClick: () => {
                  dispatch(setAdminTab(AdminTabs.User));
                },
                isActive: adminTab === AdminTabs.User,
              },
              {
                tab: (
                  <Styles.TabsHeaderText>
                    Content Management
                  </Styles.TabsHeaderText>
                ),
                tabId: uniqueId(),
                handleTabClick: () => dispatch(setAdminTab(AdminTabs.Content)),
                isActive: adminTab === AdminTabs.Content,
              },
            ]}
          />
          {renderTabHeaderContent()}
        </Styles.TabsContainer>
        {renderContent()}
      </Styles.UserManagementTabsContainer>

      <UploadModal
        isModalOpen={isUploadModalOpen}
        setUploadModal={setIsUploadModalOpen}
      />
    </>
  );
};

export default UserManagementTabs;

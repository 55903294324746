import { NavigateFunction } from "react-router";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
import { ILogin } from "../types";
import { store } from "../store";
import { post, get } from "./apiClient";
import { PATHS } from "../constants";
import configs from "../configs/api.configs";
import { removeCookie } from "../shared/helpers";
import { IUser } from "../store/userManagement/user.interface";
import {
  setAllUsers,
  setIsCreatePasswordLoading,
  setIsSendOtpLoading,
  setIsVerifyOtpLoading,
  setLoginLoading,
  setRFPStatusCount,
  setUserId,
  setRestPasswordLoading,
} from "../store/auth/authSlice";

export const login = async (request: ILogin, navigate: NavigateFunction) => {
  store.dispatch(setLoginLoading(true));
  try {
    const { data } = await post("/core/token/authenticate", request);
    const user = {
      id: data.id,
      username: data.username,
      name: data.name,
      email: data.email,
      userRole:
        data.userPermissions && data.userPermissions.length
          ? data.userPermissions[0].role
          : null,
    };
    Cookies.set("user_acnpa", JSON.stringify(user), {
      domain: configs.HOST,
      expires: 8,
    });

    Cookies.set("token_acnpa", data?.jwttoken, {
      domain: configs.HOST,
      expires: 8,
    });

    store.dispatch(setUserId(data?.id));

    navigate(PATHS.home);

    toast.success("Login successfully");
  } catch (error: any) {
    console.log("login Error", error?.error);
    toast.error(error?.error || "Failed to login");
  } finally {
    store.dispatch(setLoginLoading(false));
  }
};

export const getRfpStatusCount = async () => {
  try {
    const res = await get(`/apa/dashboard/`);
    store.dispatch(setRFPStatusCount(res));
  } catch (error: any) {
    console.log(error, "getRfpStatusCount api error");
  }
};

export const getAllUsers = async () => {
  try {
    const res = await get(`core/token/fetchAll`);
    const filterdUser = res?.filter((user: IUser) => user?.status === "A");
    if (filterdUser?.length > 0) {
      store.dispatch(setAllUsers(filterdUser));
    } else {
      store.dispatch(setAllUsers([]));
    }
  } catch (error: any) {
    console.log(error, "getAllUsers api error");
  }
};

export const sendOtp = async (
  request: { email: string },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsSendOtpLoading(true));

    await axios.post(`${configs.BASE_URL}/core/token/send-otp`, request);

    navigate(PATHS.otp);
    toast.success("OTP sent successfully");
  } catch (error: any) {
    console.log(error, "sendOtp api error");
    toast.error(error?.message ?? "Failed to send OTP");
  } finally {
    store.dispatch(setIsSendOtpLoading(false));
  }
};

export const verifyOtp = async (
  request: { email: string; otp: number },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsVerifyOtpLoading(true));

    await axios.post(`${configs.BASE_URL}/core/token/verify-otp`, request);

    navigate(PATHS.changePassword);
    toast.success("OTP verified successfully");
  } catch (error: any) {
    console.log(error, "verifyOtp api error");
    toast.error(error ?? "Failed to verify OTP", error);
  } finally {
    store.dispatch(setIsVerifyOtpLoading(false));
  }
};

export const CreateNewPassword = async (
  request: { email: string; otp: number; newPassword: string },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsCreatePasswordLoading(true));
    await axios.post(
      `${configs.BASE_URL}/core/token/createNewPassword`,
      request
    );
    navigate(PATHS.login);
    toast.success("Password updated successfully");
  } catch (error: any) {
    console.log(error, "CreateNewPassword api error");
    toast.error(error ?? "Failed to reset password", error);
  } finally {
    store.dispatch(setIsCreatePasswordLoading(false));
  }
};

export const resetPassword = async (
  request: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    userId: number;
  },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setRestPasswordLoading(true));
    await post(`core/token/resetPasswordOnboarding`, request);
    persistStore(store).purge();
    removeCookie();
    toast.success("Password reset successfully");
    navigate(PATHS.login);
  } catch (error: any) {
    console.log(error, "reset api error");
    toast.error(error ?? "Failed to reset password", error);
  } finally {
    store.dispatch(setRestPasswordLoading(false));
  }
};

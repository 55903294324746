import { PrimaryTabs } from "./Tabs";
import * as Styles from "./style";

function Solution() {
  return (
    <Styles.SolutionContainer>
      <PrimaryTabs />
    </Styles.SolutionContainer>
  );
}

export default Solution;

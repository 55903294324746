import { useEffect, useState } from "react";
import { Empty, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import { IMAGES } from "../../../../shared";
import { RootState } from "../../../../store";
import { handleOpenPdfDoc, onDownloadPdf } from "../../../../shared/helpers";
import { getAllAMContent } from "../../../../services/a&m";

const AMContent = () => {
  const { rfpId } = useParams();

  const [downloadId, setDownloadId] = useState(-1);
  const [_, setIsDownloading] = useState(false);

  const currentRfpId: number = !!rfpId ? parseInt(rfpId) : -1;

  const { amContent, isAmContentLoading } = useSelector(
    (state: RootState) => state.amContent
  );

  useEffect(() => {
    getAllAMContent(currentRfpId);
  }, [currentRfpId]);

  const getRandomImg = (fileName: string): string => {
    const lastIndex = fileName.lastIndexOf(".");
    const fileType = fileName.substring(lastIndex + 1);

    switch (fileType) {
      case "pdf":
        return IMAGES.pdfIcon;

      case "ppt":
        return IMAGES.pptIcon;

      case "docx":
      case "doc":
        return IMAGES.wordIcon;

      default:
        return IMAGES.pdfIcon;
    }
  };

  const handleDownloadDoc = async (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    url: string,
    fileName: string,
    id: number
  ) => {
    e.stopPropagation();
    setDownloadId(id);
    await onDownloadPdf(url, setIsDownloading, fileName);
    setDownloadId(-1);
  };

  const renderCards = () => {
    if (isAmContentLoading) {
      return (
        <Styles.CardsContainerWrap>
          {[...Array(5)].map((_, i) => (
            <Styles.CardContainer key={i}>
              <Skeleton.Image active={true} />
              <Styles.ButtonSkeleton active size={"small"} />
            </Styles.CardContainer>
          ))}
        </Styles.CardsContainerWrap>
      );
    }

    if (amContent?.length) {
      return (
        <Styles.CardsContainerWrap>
          {amContent.map((v) => (
            <Styles.CardContainer
              key={v?.proposalFileId}
              onClick={() => handleOpenPdfDoc(v?.originalFileName, v?.fileUrl)}
            >
              {downloadId === v?.proposalFileId ? (
                <Styles.Loader
                  indicator={<LoadingOutlined style={{ fontSize: 12 }} spin />}
                />
              ) : (
                <Styles.DownloadIcon
                  src={IMAGES.downloadContentIcon}
                  alt="download"
                  onClick={(e) =>
                    handleDownloadDoc(
                      e,
                      v?.fileUrl,
                      v?.originalFileName,
                      v?.proposalFileId
                    )
                  }
                />
              )}
              <img src={getRandomImg(v?.originalFileName)} alt="doc" />
              <Styles.CardHeaderText>
                {v?.proposalFileName}
              </Styles.CardHeaderText>
              <Styles.CardSubHeaderText>{v?.category}</Styles.CardSubHeaderText>
            </Styles.CardContainer>
          ))}
        </Styles.CardsContainerWrap>
      );
    } else
      return (
        <Styles.EmptyStateWrap>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Styles.EmptyStateWrap>
      );
  };

  return (
    <Styles.AMContentContainer>
      <Styles.Details>
        <Styles.TitlePage>
          Recommended A&M content
          <Styles.AIIcon>
            <img src={IMAGES.aiTagLogo} alt="Logo" />
          </Styles.AIIcon>
        </Styles.TitlePage>
        {renderCards()}
      </Styles.Details>
    </Styles.AMContentContainer>
  );
};

export default AMContent;

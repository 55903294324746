import styled from "styled-components";
import { Button, Input } from "antd";

export const HeadingText = styled.div`
  font-family: "GraphikMedium";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const SubHeadingText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.fontColorEta};
`;

export const InputField = styled(Input)`
  height: 40px;

  &.ant-input-outlined {
    border: none;
    border-bottom: 1px solid #d9d9d9;
  }
  &.ant-input-outlined:focus,
  :where(.css-dev-only-do-not-override-pl41c).ant-input-outlined:focus-within {
    box-shadow: none;
  }
  &.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus,
  :where(
      .css-dev-only-do-not-override-pl41c
    ).ant-input-outlined.ant-input-status-error:not(
      .ant-input-disabled
    ):focus-within {
    box-shadow: none;
  }
  &.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    border-style: none;
    border-bottom: 1px solid #ff4d4f;
  }
`;

export const ResendContainer = styled.div`
  margin-top: 15px;
`;
export const ResendText = styled.span`
  color: ${({ theme }) => theme.colors.fontColorEta};
`;

export const ResendButton = styled(Button)`
  &.ant-btn-link {
    color: ${({ theme }) => theme.colors.fontColorOmega};
  }
  &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.fontColorOmega};
  }
`;

export const BackToLoginContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from "styled-components";
import { Select } from "antd";

export const PrimarySelect = styled(Select)`
  && .ant-select-selector {
    border: 0.6px solid rgba(109, 96, 164, 0.4);
    background: #403379;
  }

  && .ant-select-arrow {
    color: #ffffff;
  }

  && .ant-select-selection-item {
    color: #fff;
    font-family: "GraphikRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }
`;

export const PrefixIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IContent, IContentSliceProps } from "./contentManagement.interface";
import { getAssetById } from "../../services/contentManagement";
import { AssetUploadStatus, PageLimit } from "../../constants";

const initialState: IContentSliceProps = {
  getAllContent: [],
  assetsFile: null,
  isFilesUploading: false,
  loading: false,
  loadingAssetUpdating: false,
  currentPage: 1,
  pageLimit: PageLimit.ContentManagement,
  totalContentsCount: 0,
  searchText: "",
  isExportCSVLoading: false,
};

export const contentManagementSlice = createSlice({
  name: "contentManagementSlice",
  initialState,
  reducers: {
    setAllContent: (state, action: PayloadAction<IContent[]>) => {
      state.getAllContent = action.payload;
    },

    setAssetsFile: (state, action: PayloadAction<File | null>) => {
      state.assetsFile = action.payload;
    },

    setIsFilesUploading: (state, action: PayloadAction<boolean>) => {
      state.isFilesUploading = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setLoadingAssetUpdating: (state, action: PayloadAction<boolean>) => {
      state.loadingAssetUpdating = action.payload;
    },

    addUploadedContent: (state, action: PayloadAction<IContent>) => {
      state.getAllContent.push(action.payload);
    },

    autoRefreshContent: (_, action: PayloadAction<{ assetId: number }>) => {
      let res: IContent;
      const interval = setInterval(async () => {
        res = await getAssetById(action.payload.assetId);

        !!res &&
          res.assetStatus === AssetUploadStatus.LOADED_INTO_LLM &&
          clearInterval(interval);

        !!res &&
          res.assetStatus === AssetUploadStatus.COMPLETED &&
          clearInterval(interval);

        !!res &&
          res.assetStatus === AssetUploadStatus.FAILED &&
          clearInterval(interval);
      }, 5000);
    },

    refreshedAssetByAssetId: (
      state,
      action: PayloadAction<{ data: IContent; id: number }>
    ) => {
      state.getAllContent = state.getAllContent.map((v) =>
        v.asset_id === action.payload.id ? action.payload.data : v
      );
    },

    setContentCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    setContentPageLimit: (state, action: PayloadAction<number>) => {
      state.pageLimit = action.payload;
    },

    setResetContentPaginationAndSearch: (state) => {
      state.currentPage = 1;
      state.pageLimit = PageLimit.ContentManagement;
      state.searchText = "";
    },

    setTotalContentCount: (state, action: PayloadAction<number>) => {
      state.totalContentsCount = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },

    setIsContentExportCSVLoading: (state, action: PayloadAction<boolean>) => {
      state.isExportCSVLoading = action.payload;
    },
  },
});

export const {
  setLoading,
  setAssetsFile,
  setAllContent,
  addUploadedContent,
  autoRefreshContent,
  setIsFilesUploading,
  setLoadingAssetUpdating,
  refreshedAssetByAssetId,
  setContentCurrentPage,
  setContentPageLimit,
  setResetContentPaginationAndSearch,
  setTotalContentCount,
  setSearchText,
  setIsContentExportCSVLoading,
} = contentManagementSlice.actions;

export default contentManagementSlice.reducer;

import { Input, Popover } from "antd";
import styled, { css } from "styled-components";

interface ISummaryInput {
  isEdit?: boolean;
}

export const SummaryContainer = styled.div`
  font-size: 14px;
  font-weight: 400;

  span {
    font-family: "GraphikMedium";
    font-size: 14px;
    font-weight: 500;
  }
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-content {
    max-height: 250px;
    overflow-y: auto;
  }
`;

export const SummaryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 250px;
  width: 100%;
  overflow: hidden;
`;
export const SummaryInput = styled(Input.TextArea)<ISummaryInput>`
  &.ant-input {
    resize: none;
    height: 205px;
    ${(props) =>
      props.readOnly &&
      css`
        border: none;
        &:hover {
          border: none;
        }
      `};
    background-color: ${(props) => (props.isEdit ? "rgb(244, 246, 252)" : "")};
    border: none;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: right;
  padding: 5px;
  gap: 5px;
`;

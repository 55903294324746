import { Table } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Styles from "./styles";
import { IMAGES } from "../../../shared";
import { RootState } from "../../../store";
import { ConfirmationModal } from "../../../components";
import { IAllRfp } from "../../../store/allRfps/allRfp.interface";
import SummaryModal from "../../../components/rfps/summary/SummaryModal";
import {
  PATHS,
  QualificationsStatus,
  RFPQualifications,
  RFPStatus,
} from "../../../constants";
import { setCurrentRfpIdForPdf } from "../../../store/pdfViewer/pdfViewerSlice";
import QualificationReason from "../../../components/rfps/qualification/QualificationReason";
import {
  updateBidStage,
  updateProjectCategory,
} from "../../../services/overview";
import {
  UpdateClientGroup,
  UpdateQualification,
  UpdateQualificationReason,
  deleteRfp,
} from "../../../services/allRfps";
import {
  addPendingFile,
  setSelectedRFP,
  setAllRfps,
  autoRefreshQualification,
  updateQualificationStatus,
} from "../../../store/allRfps/allRfpSlice";
import {
  bidStageOptions,
  clientGroupOptions,
  formateDate,
  handleOpenPdfDoc,
  projectOptions,
} from "../../../shared/helpers";

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const RFPTableColumns: () => (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
  label: string;
})[] = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isConfirmQualificationId, setIsConfirmQualificationId] = useState(-1);
  const [selectedColumnRfp, setSelectedColumnRfp] = useState<IAllRfp | null>(
    null
  );
  const [isQualificationModalOpen, setIsQualificationModalOpen] =
    useState<number>(-1);
  const [isReasonEditMode, setIsReasonEditMode] = useState<boolean>(false);
  const [isOpenDeleteRfpModal, setIsOpenDeleteRfpModal] = useState<number>(-1);

  const { currentRfpIdForPdf } = useSelector(
    (state: RootState) => state.pdfViewer
  );
  const {
    isManuallyEditedShown,
    isRfpDeleteLoading,
    allRfps,
    currentPage,
    pageLimit,
    totalRfpsCount,
  } = useSelector((state: RootState) => state.allRfps);

  const handleChangeClientGroup = async (
    value: any,
    rfp: IAllRfp,
    index: number
  ) => {
    const response = await UpdateClientGroup(rfp?.rfp_id, {
      client_group: value,
    });
    if (response?.status === 200) {
      const updatedRfp = {
        ...rfp,
        client_group: value,
      };
      const allRfpsValues = [...allRfps];
      allRfpsValues[index] = updatedRfp;
      dispatch(setAllRfps(allRfpsValues));
    }
  };

  const handleCloseQualificationModal = () => {
    setIsQualificationModalOpen(-1);
    setSelectedColumnRfp(null);
    setIsReasonEditMode(false);
  };

  const handleDisqualifyIcon = (rfp: IAllRfp) => {
    setSelectedColumnRfp(rfp);
    setIsQualificationModalOpen(rfp?.rfp_id);
    setIsReasonEditMode(false);
  };

  const handleChangeQualification = async (value: any, rfp: IAllRfp) => {
    if (value === RFPQualifications.NON_QUALIFIED) {
      setSelectedColumnRfp(rfp);
      setIsQualificationModalOpen(rfp?.rfp_id);
      setIsReasonEditMode(true);
    } else if (value === RFPQualifications.DELETE) {
      setIsOpenDeleteRfpModal(rfp?.rfp_id);
    } else {
      setIsConfirmQualificationId(rfp.rfp_id);
    }
  };

  const updateQualificationAPI = async (
    _: any,
    rfp: IAllRfp,
    isDisqualify: boolean
  ) => {
    try {
      dispatch(
        updateQualificationStatus({
          ...rfp,
          qualification_status: QualificationsStatus.PROCESSING,
        })
      );

      await UpdateQualification(rfp?.rfp_id, isDisqualify);

      dispatch(addPendingFile(rfp));
      setIsConfirmQualificationId(-1);

      dispatch(
        updateQualificationStatus({
          ...rfp,
          qualification: "QUALIFIED",
          qualification_status: RFPStatus.PROCESSING,
          reason: "",
          status: RFPStatus.NOT_STARTED,
        })
      );

      dispatch(autoRefreshQualification({ rfpId: rfp?.rfp_id }));
    } catch (e) {
      dispatch(updateQualificationStatus(rfp));
    }
  };

  const handleBidStage = async (value: any, rfp: IAllRfp, index: number) => {
    try {
      const response: any = await updateBidStage(Number(rfp?.rfp_id), value);
      if (response?.status === 200) {
        const updatedRfp = {
          ...rfp,
          bid_stage: value,
        };
        const allRfpsValues = [...allRfps];
        allRfpsValues[index] = updatedRfp;
        dispatch(setAllRfps(allRfpsValues));
      }
    } catch (error) {
      console.log("Error while Update bid stage", error);
    }
  };

  const handleProjectCategory = async (
    value: any,
    rfp: IAllRfp,
    index: number
  ) => {
    try {
      const response: any = await updateProjectCategory(
        Number(rfp?.rfp_id),
        value
      );
      if (response?.status === 200) {
        const updatedRfp = {
          ...rfp,
          project_category: value,
        };
        const allRfpsValues = [...allRfps];
        allRfpsValues[index] = updatedRfp;
        dispatch(setAllRfps(allRfpsValues));
      }
    } catch (error) {
      console.log("Error while Update project category", error);
    }
  };

  const handleQualificationConfirmation = async (rfp: IAllRfp) => {
    const clientName = rfp?.client_name || "";
    const rfpName = (rfp?.rfp_name ?? rfp?.original_file_name) || "";

    const renderMessage = (): string => {
      if (!clientName && !rfpName)
        return "Client name and RFP name cannot be empty";
      else if (!clientName) return "Client name cannot be empty";
      else if (!rfpName) return "Rfp name cannot be empty";
      else return "Client name and RFP name cannot be empty";
    };

    if (!clientName || !rfpName) {
      toast.error(renderMessage());
    } else {
      const response = await UpdateQualificationReason(rfp?.rfp_id, {
        reason: "",
      });
      if (response?.status === 200) {
        updateQualificationAPI(RFPQualifications.QUALIFIED, rfp, false);
      }
    }
    setIsConfirmQualificationId(-1);
  };

  const renderTableStatus = (status: string) => {
    switch (status) {
      case RFPStatus.LIVE:
        return <Styles.LiveStatusContainer>LIVE</Styles.LiveStatusContainer>;

      case RFPStatus.NOT_STARTED:
        return (
          <Styles.NotStartedStatusContainer>
            NOT STARTED
          </Styles.NotStartedStatusContainer>
        );

      case RFPStatus.PROCESSING:
        return (
          <Styles.ProcessingStatusContainer>
            <img src={IMAGES.loadingSpinner} alt="processing" />
            In processing...
          </Styles.ProcessingStatusContainer>
        );

      case RFPStatus.COMPLETED:
        return (
          <Styles.CompletedStatusContainer>
            COMPLETED
          </Styles.CompletedStatusContainer>
        );
      case RFPStatus.IN_PROGRESS:
        return (
          <Styles.LiveStatusContainer>IN PROGRESS</Styles.LiveStatusContainer>
        );

      case RFPStatus.FAILED:
        return (
          <Styles.FailedStatusContainer>FAILED</Styles.FailedStatusContainer>
        );
      case RFPStatus.ON_HOLD:
        return (
          <Styles.NotStartedStatusContainer>
            ON HOLD
          </Styles.NotStartedStatusContainer>
        );
      case RFPStatus.CANCELLED:
        return (
          <Styles.NotStartedStatusContainer>
            CANCELLED
          </Styles.NotStartedStatusContainer>
        );
      default:
        return <Styles.LiveStatusContainer>-</Styles.LiveStatusContainer>;
    }
  };

  const handleRfpDelete = async (rfp: IAllRfp) => {
    await deleteRfp(rfp?.rfp_id, currentPage, pageLimit, totalRfpsCount);
    setIsOpenDeleteRfpModal(-1);
  };

  const handleOverviewNavigate = (rfpId: number) => {
    navigate(`${PATHS.rfp}/${rfpId}/${PATHS.overview}`);
  };

  return [
    {
      title: <Styles.RpfTableHeader>Opt ID</Styles.RpfTableHeader>,
      render: (_, record: Object) => {
        const rfp: IAllRfp = record as IAllRfp;
        return {
          children: (
            <Styles.RfpTableOpportunityId>
              {rfp?.opportunity_id ?? (
                <Styles.SpanDisable>To be allocated</Styles.SpanDisable>
              )}
            </Styles.RfpTableOpportunityId>
          ),
        };
      },
      width: "50px",
      dataIndex: "opportunity_id",
      label: "Opt ID",
      editable: true,
    },
    {
      title: <Styles.RpfTableHeader>RFP name</Styles.RpfTableHeader>,
      render: (_, record: Object, index: number) => {
        const rfp: IAllRfp = record as IAllRfp;
        return {
          children: (
            <Styles.RfpTableNameWrap>
              <Styles.RfpTableName
                enableCursor={
                  rfp?.qualification === RFPQualifications.QUALIFIED ||
                  rfp?.qualification_status === QualificationsStatus.COMPLETED
                }
                onClick={() => {
                  if (
                    rfp?.status !== RFPStatus.PROCESSING &&
                    rfp?.qualification_status !== RFPStatus.PROCESSING &&
                    rfp?.qualification === RFPQualifications.QUALIFIED
                  ) {
                    dispatch(setSelectedRFP(rfp));
                    handleOverviewNavigate(rfp.rfp_id);
                  }
                }}
              >
                {rfp?.rfp_name?.replace(/^"(.*)"$/, "$1") ?? (
                  <Styles.SpanDisable>
                    {rfp?.original_file_name}
                  </Styles.SpanDisable>
                )}
              </Styles.RfpTableName>
              <Styles.RfpTableIconWrap>
                <Styles.StyledTooltip
                  title={
                    rfp?.status !== RFPStatus.PROCESSING ||
                    rfp?.qualification_status !== RFPStatus.PROCESSING
                      ? "Quick RFP view"
                      : ""
                  }
                >
                  <Styles.ViewRfp
                    src={IMAGES.allRfpIcon}
                    alt="rfpName"
                    $isProcessing={
                      rfp?.status === RFPStatus.PROCESSING ||
                      rfp?.qualification_status === RFPStatus.PROCESSING
                    }
                    $active={currentRfpIdForPdf === rfp?.rfp_id}
                    onClick={(e) => {
                      if (
                        rfp?.status !== RFPStatus.PROCESSING ||
                        rfp?.qualification_status !==
                          QualificationsStatus.PROCESSING
                      ) {
                        e.stopPropagation();
                        dispatch(setSelectedRFP(rfp));
                        handleOpenPdfDoc(
                          rfp?.original_file_name,
                          rfp?.pre_signed_url
                        );
                        dispatch(setCurrentRfpIdForPdf(rfp?.rfp_id));
                      }
                    }}
                  />
                </Styles.StyledTooltip>
                <Styles.StyledTooltip title="View RFP summary">
                  <SummaryModal rfp={rfp} selectedIndex={index} />
                </Styles.StyledTooltip>
              </Styles.RfpTableIconWrap>
            </Styles.RfpTableNameWrap>
          ),
        };
      },
      width: "auto",
      dataIndex: "rfp_name",
      label: "RFP name",
    },
    {
      title: <Styles.RpfTableHeader>Client name</Styles.RpfTableHeader>,
      render: (_, record: Object) => {
        const rfp: IAllRfp = record as IAllRfp;
        return {
          children: (
            <Styles.RfpTableClientName>
              {rfp?.client_name ?? <Styles.SpanDisable>-</Styles.SpanDisable>}
            </Styles.RfpTableClientName>
          ),
          props: {
            style: {
              backgroundColor:
                rfp?.is_client_name_override && isManuallyEditedShown
                  ? "#FAF0FF"
                  : "",
            },
          },
        };
      },
      width: "10%",
      dataIndex: "client_name",
      editable: true,
      label: "Client name",
    },
    {
      title: <Styles.RpfTableHeader>Client group</Styles.RpfTableHeader>,
      render: (_, record: Object, index: number) => {
        const rfp: IAllRfp = record as IAllRfp;
        return {
          children: (
            <Styles.RfpTableDepartmentSelect
              disabled={
                rfp?.status === RFPStatus.PROCESSING ||
                rfp?.qualification_status === RFPStatus.PROCESSING
              }
              background={
                rfp?.is_client_group_override && isManuallyEditedShown
                  ? "#FAF0FF"
                  : "#eff4ff"
              }
              variant="borderless"
              placeholder="Select"
              value={rfp?.client_group}
              loading={false}
              options={clientGroupOptions}
              onClick={(e) => e.stopPropagation()}
              onChange={(value) => {
                handleChangeClientGroup(value, rfp, index);
              }}
              style={{ width: "100px" }}
              popupClassName="ui-dropdown-default"
            ></Styles.RfpTableDepartmentSelect>
          ),
          props: {
            style: {
              backgroundColor:
                rfp?.is_client_group_override && isManuallyEditedShown
                  ? "#FAF0FF"
                  : " ",
            },
          },
        };
      },
      width: "100px",
      dataIndex: "client_group",
      label: "Client group",
    },
    {
      title: <Styles.RpfTableHeader>Project Category</Styles.RpfTableHeader>,
      render: (_, record: Object, index: number) => {
        const rfp: IAllRfp = record as IAllRfp;
        return (
          <Styles.PCContainer>
            <Styles.ProjectCategory
              disabled={
                rfp?.status === RFPStatus.PROCESSING ||
                rfp?.qualification_status === RFPStatus.PROCESSING
              }
              placeholder="Project Category"
              variant="borderless"
              value={rfp?.project_category}
              options={projectOptions}
              style={{ width: "100px" }}
              onChange={(value) => {
                handleProjectCategory(value, rfp, index);
              }}
              popupClassName="ui-dropdown-default"
            />
          </Styles.PCContainer>
        );
      },
      width: "130px",
      dataIndex: "project_category",
      label: "Project Category",
    },
    {
      title: <Styles.RpfTableHeader>Stakeholders</Styles.RpfTableHeader>,
      render: (_, record: Object) => {
        const rfp: IAllRfp = record as IAllRfp;
        return (
          <Styles.RfpTableStackHolder>
            {rfp?.stakeholders ?? <Styles.SpanDisable>Nil</Styles.SpanDisable>}
            <Styles.RfpTableStackHolderIcon
              $isProcessing={
                rfp?.status === RFPStatus.PROCESSING ||
                rfp?.qualification_status === RFPStatus.PROCESSING
              }
              onClick={(e) => {
                if (
                  rfp?.status !== RFPStatus.PROCESSING &&
                  rfp?.qualification !== RFPQualifications.NON_QUALIFIED
                ) {
                  e.stopPropagation();
                  navigate(`/rfp/${rfp?.rfp_id}/organogram`);
                }
              }}
            >
              <img src={IMAGES.stackHolderIcon} alt="steckHolder" />
            </Styles.RfpTableStackHolderIcon>
          </Styles.RfpTableStackHolder>
        );
      },
      width: "130px",
      dataIndex: "stakeholders",
      label: "Stakeholders",
    },
    {
      title: <Styles.RpfTableHeader>Bid stage</Styles.RpfTableHeader>,
      render: (_, record: Object, index: number) => {
        const rfp: IAllRfp = record as IAllRfp;
        return (
          <Styles.BidStageContainer>
            {/* {rfp?.bid_stage ?? <Styles.SpanDisable>-</Styles.SpanDisable>} */}
            <Styles.BidStage
              disabled={
                rfp?.status === RFPStatus.PROCESSING ||
                rfp?.qualification_status === RFPStatus.PROCESSING
              }
              placeholder="Stage"
              value={rfp?.bid_stage}
              options={bidStageOptions}
              style={{ width: "100px" }}
              onChange={(value) => {
                handleBidStage(value, rfp, index);
              }}
            />
          </Styles.BidStageContainer>
        );
      },
      width: "90px",
      dataIndex: "bid_stage",
      label: "Bid stage",
    },
    {
      title: <Styles.RpfTableHeader>Uploaded on</Styles.RpfTableHeader>,
      render: (_, record: Object) => {
        const rfp: IAllRfp = record as IAllRfp;
        return (
          <Styles.RfpTableUploadedOn>
            {formateDate(rfp?.uploaded_on)}
          </Styles.RfpTableUploadedOn>
        );
      },
      width: "100px",
      dataIndex: "uploaded_on",
      label: "Uploaded on",
    },
    {
      title: <Styles.RpfTableHeader>Qualification</Styles.RpfTableHeader>,
      render: (_, record: Object, index: number) => {
        const rfp: IAllRfp = record as IAllRfp;

        return (
          <Styles.RfpTableQualifiedWrap>
            <Styles.RfpTableDepartmentSelect
              disabled={
                rfp?.status === RFPStatus.PROCESSING ||
                rfp?.qualification_status === RFPStatus.PROCESSING
              }
              background={
                rfp?.qualification === "QUALIFIED"
                  ? "#00B8844D"
                  : rfp?.qualification === "NON_QUALIFIED"
                  ? "#CB347812"
                  : "transparent"
              }
              color={
                rfp?.qualification === "QUALIFIED"
                  ? "#027E5B"
                  : rfp?.qualification === "NON_QUALIFIED"
                  ? "#CB3478"
                  : "#333"
              }
              variant="borderless"
              placeholder="Not updated"
              value={rfp?.qualification}
              loading={false}
              onClick={(e) => e.stopPropagation()}
              onChange={(value) => {
                handleChangeQualification(value, rfp);
              }}
              style={{ width: "120px" }}
              options={[
                { value: RFPQualifications.QUALIFIED, label: "Qualified" },
                {
                  value: RFPQualifications.NON_QUALIFIED,
                  label: "Disqualify",
                },
                {
                  value: RFPQualifications.DELETE,
                  label: "Delete",
                },
              ]}
              popupClassName="ui-dropdown-default"
            />

            {rfp?.qualification === RFPQualifications.NON_QUALIFIED && (
              <Styles.StyledImgContainer
                onClick={() => handleDisqualifyIcon(rfp)}
              >
                <Styles.StyledImg
                  $isProcessing={rfp?.status === RFPStatus.PROCESSING}
                  src={IMAGES.infoIcon}
                  alt="info"
                />
              </Styles.StyledImgContainer>
            )}

            {rfp.rfp_id === isQualificationModalOpen && (
              <QualificationReason
                rfp={selectedColumnRfp}
                onSuccess={updateQualificationAPI}
                isOpen={
                  rfp.rfp_id === isQualificationModalOpen &&
                  rfp?.status !== RFPStatus.PROCESSING
                }
                setModalClose={handleCloseQualificationModal}
                isReasonEditMode={isReasonEditMode}
                selectedIndex={index}
              />
            )}

            <ConfirmationModal
              title="Qualification"
              text={
                "Are you sure, this is qualified? If yes, please confirm as AI processing will get initiated."
              }
              isOpen={isConfirmQualificationId === rfp.rfp_id}
              handleOk={() => handleQualificationConfirmation(rfp)}
              handleCancel={() => {
                setIsConfirmQualificationId(-1);
              }}
            />

            <ConfirmationModal
              title="Delete"
              text="Are you sure you want to delete this RFP?"
              isOpen={isOpenDeleteRfpModal === rfp.rfp_id}
              handleOk={() => handleRfpDelete(rfp)}
              handleCancel={() => setIsOpenDeleteRfpModal(-1)}
              isLoading={isRfpDeleteLoading}
              btnText="Delete"
            />
          </Styles.RfpTableQualifiedWrap>
        );
      },
      width: "100px",
      dataIndex: "qualification",
      label: "Qualification",
    },
    {
      title: <Styles.RpfTableHeader>Status</Styles.RpfTableHeader>,
      render: (_, record: Object) => {
        const rfp: IAllRfp = record as IAllRfp;
        return (
          <Styles.TableStatusIconWrap>
            {renderTableStatus(
              rfp?.qualification_status === RFPStatus.PROCESSING
                ? rfp?.qualification_status
                : rfp?.status
            )}
            {rfp.qualification === RFPQualifications.QUALIFIED && (
              <Styles.TableStatusImgStyle
                src={IMAGES.rfpRightArrow}
                alt="arrow"
                onClick={() => {
                  if (
                    rfp?.status !== RFPStatus.PROCESSING &&
                    rfp?.qualification !== RFPQualifications.NON_QUALIFIED
                  ) {
                    dispatch(setSelectedRFP(rfp));
                    handleOverviewNavigate(rfp.rfp_id);
                  }
                }}
              />
            )}
          </Styles.TableStatusIconWrap>
        );
      },
      width: "125px",
      dataIndex: "status",
      label: "Status",
    },
  ];
};

export default RFPTableColumns;

export enum PATHS {
  home = "/",
  ati = "ati",
  nbm = "nbm",
  otp = "/otp",
  rfp = "/rfp",
  rfps = "/rfps",
  notFound = "*",
  legal = "legal",
  user = "/admin/users",
  login = "/login",
  finance = "finance",
  solution = "solution",
  overview = "overview",
  policy966 = "policy966",
  dashboard = "/dashboard",
  organogram = "organogram",
  rfpDocuments = "documents",
  content = "/content-management",
  resetPassword = "/reset-password",
  forgotPassword = "/forgot-password",
  changePassword = "/change-password",
  policyRequirements = "policy-requirements",
  aiBulletin = "/ai-bulletin",
}

export enum RFPStatus {
  LIVE = "LIVE",
  PROCESSING = "PROCESSING",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
  ON_HOLD = "ON_HOLD",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

export enum RFPFilters {
  LIVE = "LIVE",
  COMPLETED = "COMPLETED",
  ON_HOLD = "ON_HOLD",
  NOT_STARTED = "NOT_STARTED",
  ALL = "ALL",
}

export enum RFPQualifications {
  QUALIFIED = "QUALIFIED",
  NON_QUALIFIED = "NON_QUALIFIED",
  DELETE = "DELETE",
}

export enum QualificationsStatus {
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum RFPUploadSteps {
  BROWSE,
  UPLOADING,
  UPLOADED,
}

// Extraction Status
export enum ExtractionStatus {
  AI_BUSY = "AI_BUSY",
  NOT_STARTED = "NOT_STARTED",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  STOPPING = "STOPPING",
  STOP = "STOP",
  AI = "AI",
  UPLOADED = "UPLOADED",
  UPLOADING = "UPLOADING",
  REGENERATING = "REGENERATING",
}

export enum RFPCircleColors {
  "Finance" = "#a100ff12",
  "Legal" = "#CB347812",
  "Solution" = "#004DFF12",
  "Policy 966" = "#027E5B12",
  "ATI" = "#C22DD212",
  "NBM" = "#7A2BCC12",
}

export enum Role {
  Admim = "ROLE_ADMIN",
  User = "ROLE_USER",
}

export enum OrganogramFieldLevels {
  none = "NONE",
  parent = "PARENT",
  Child = "CHILD",
}

// export enum LegalProcessingStatus {
//   AI = "AI",
//   IDLE = "IDLE",
//   UPLOADED = "UPLOADED",
//   UPLOADING = "UPLOADING",
// }

// export enum ProcessingStatus {
//   AI = "AI",
//   IDLE = "IDLE",
//   UPLOADED = "UPLOADED",
//   UPLOADING = "UPLOADING",
// }

export enum SolutionProcessingStatus {
  AI = "AI",
  IDLE = "IDLE",
  UPLOADED = "UPLOADED",
  UPLOADING = "UPLOADING",
}

export enum ProposalCategory {
  FINANCE = "FINANCE",
  LEGAL = "LEGAL",
  SOLUTION = "SOLUTION",
  POLICY_REQUIREMENTS = "POLICY_REQUIREMENTS",
}

export const GroupChartLabel = [
  "Stage 0A",
  "Stage 1",
  "Stage 2A",
  "Stage 2B",
  "Stage 3A",
  "Stage 3B",
];

export enum AdminTabs {
  User = "USER",
  Content = "CONTENT",
}

export enum AssetUploadStatus {
  FAILED = "FAILED",
  RECEIVED = "RECEIVED",
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  SENT_TO_LLM = "SENT_TO_LLM",
  FAILED_IN_LLM = "FAILED_IN_LLM",
  DELETING_START = "DELETING_START",
  UPLOADED_TO_DO = "UPLOADED_TO_DO",
  LOADED_INTO_LLM = "LOADED_INTO_LLM",
  CONVERTED_TO_PDF = "CONVERTED_TO_PDF",
}

export enum RFPGenerationStatus {
  FAILED = "FAILED",
  AI_BUSY = "AI_BUSY",
  STOPPED = "STOPPED",
  STOPPING = "STOPPING",
  STOP = "STOP",
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  NOT_STARTED = "NOT_STARTED",
  STOPPING_FE = "STOPPING_FE",
  REGENERATING = "REGENERATING",
}

export enum RFPGenerationKey {
  ATI = "ati",
  NBM = "nbm",
  Legal = "legal",
  RfpId = "rfpId",
  Finance = "finance",
  Solution = "solution",
  Policy966 = "policy966",
}

export enum PageLimit {
  Rfp = 10,
  UserManagement = 50,
  ContentManagement = 20,
}

export enum BreadcrumbText {
  documents = "RFP documents",
  overview = "Overview",
  finance = "Finance",
  legal = "Legal",
  ati = "ATI",
  organogram = "Organogram",
  solution = "Solution",
  policy966 = "Policy 966",
  nbm = "NBM",
}

export enum Policy966Engagement {
  KEYQUESTIONS = "KEYQUESTIONS",
  NEWOPPORTUNITY = "NEWOPPORTUNITY",
}

export enum FileName {
  AssetSCV = "Assets.csv",
}

export enum AiBulletinTab {
  PromoterResearcher = "Promoter Researcher",
  AIBulletin = "AI bulletin",
}

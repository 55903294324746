import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAllProjectPlan, IProjectPlan } from "./projectPlan.interface";
import { ExtractionStatus } from "../../constants";

const initialState: IAllProjectPlan = {
  projectPlanDetails: {
    extractionStatus: ExtractionStatus.NOT_STARTED,
    prId: -1,
    relevantPages: [],
    status: "",
    projectPlanFileName: "",
    projectPlanFilePreSignedUrl: "",
    generatedResponseFileName: "",
    generatedResponseFileStatus: ExtractionStatus.NOT_STARTED,
    generatedResponseFilePreSignedUrl: "",
  },
};

export const projectPlanSlice = createSlice({
  name: "projectPlan",
  initialState,
  reducers: {
    setProjectPlanDetails: (state, action: PayloadAction<IProjectPlan>) => {
      state.projectPlanDetails = action.payload;
    },
    setExtractStatus: (state, action: PayloadAction<ExtractionStatus>) => {
      state.projectPlanDetails = {
        ...state.projectPlanDetails,
        extractionStatus: action.payload,
      };
    },
    setGenerateResponseStatus: (
      state,
      action: PayloadAction<ExtractionStatus>
    ) => {
      state.projectPlanDetails = {
        ...state.projectPlanDetails,
        generatedResponseFileStatus: action.payload,
      };
    },
  },
});

export const {
  setProjectPlanDetails,
  setExtractStatus,
  setGenerateResponseStatus,
} = projectPlanSlice.actions;

export default projectPlanSlice.reducer;

import axios, { AxiosRequestConfig, AxiosError } from "axios";
import configs from "../configs/api.configs";
import persistStore from "redux-persist/es/persistStore";
import { store } from "../store";
import Cookies from "js-cookie";
import { getCookie, removeCookie } from "../shared/helpers";

// Axios Instance
export const axiosClient = axios.create({
  baseURL: configs.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request configuration (Interceptor)
axiosClient.interceptors.request.use((config) => {
  const token = getCookie("token_acnpa");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export const refreshToken = async (): Promise<string> => {
  try {
    const { data } = await axios.get(
      `${configs.BASE_URL}/core/token/refreshtoken`,
      {
        headers: {
          Authorization: `Bearer ${getCookie("token_acnpa")}`,
          isRefreshToken: true,
        },
      }
    );

    return data.jwttoken;
  } catch (err) {
    console.log("get refreshToken error", err);
    throw err;
  } finally {
  }
};

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err: AxiosError) => {
    const originalRequest: any = err.config;
    const status = err.response?.status;

    if (status === 401 && !originalRequest?._retry) {
      if (originalRequest) {
        originalRequest._retry = true;

        try {
          // Call the refresh token function to get a new access token
          const newAccessToken = await refreshToken();
          // Update the Authorization header with the new token
          Cookies.set("token_acnpa", newAccessToken, {
            domain: configs.HOST,
            expires: 8,
          });
          if (newAccessToken) {
            return axiosClient(originalRequest);
          }
        } catch (refreshError: any) {
          console.error("Error refreshing token:", refreshError);
          persistStore(store).purge();
          removeCookie();
          // window.location.reload();
        }
      }
    }
    if (err?.code === "ERR_NETWORK" || err?.code === "ERR_CANCELED") {
      return Promise.reject(err);
    }
    return Promise.reject(err?.response?.data);
  }
);

// Methods GET, POST,PUT, DELETE
export const get = async (path: string, config?: AxiosRequestConfig) => {
  return await axiosClient
    .get(`${path}`, config)
    .then((response) => response.data);
};

export const post = async (
  path: string,
  payload?: any,
  config?: AxiosRequestConfig
) => {
  return await axiosClient
    .post(`${path}`, payload, config)
    .then((response) => response);
};

export const put = async (
  path: string,
  payload: any,
  config?: AxiosRequestConfig
) => {
  return await axiosClient
    .put(`${path}`, payload, config)
    .then((response) => response);
};

export const deleteRequest = async (
  path: string,
  config?: AxiosRequestConfig
) => {
  return await axiosClient
    .delete(`${path}`, config)
    .then((response) => response);
};

import { useSelector, useDispatch } from "react-redux";
import * as Styles from "./styles";
import { IMAGES } from "../../shared";
import { RootState } from "../../store";
import { AiBulletinTab } from "../../constants";
import { AIBulletin } from "../../components/investmentResearcher/aIBulletin";
import { setBulletinTab } from "../../store/investmentResercher/investmentResercherSlice";
import { PromoterResearcher } from "../../components/investmentResearcher/promoterResearcher";

const tabsTitleArray = [
  AiBulletinTab.PromoterResearcher,
  AiBulletinTab.AIBulletin,
];

const renderContent = (TabContent: string) => {
  switch (TabContent) {
    case AiBulletinTab.PromoterResearcher:
    default:
      return <PromoterResearcher />;

    case AiBulletinTab.AIBulletin:
      return <AIBulletin />;
  }
};

const InvestmentResearcher = () => {
  const dispatch = useDispatch();
  const { bulletinTab } = useSelector(
    (state: RootState) => state.investmentResearcher
  );

  const handleTabClick = (key: string) => {
    dispatch(
      setBulletinTab(
        key === "2"
          ? AiBulletinTab.AIBulletin
          : AiBulletinTab.PromoterResearcher
      )
    );
  };

  return (
    <>
      <Styles.RFPSContainer>
        <Styles.GenerateCard className="generate-card">
          <Styles.GenerateHeader className="generate-header">
            Whispers in the eCorridor <img src={IMAGES.aiTagLogo} alt="Logo" />
          </Styles.GenerateHeader>
        </Styles.GenerateCard>

        <Styles.QualificationContent>
          <Styles.PrimaryTabs
            defaultActiveKey={
              bulletinTab === AiBulletinTab.AIBulletin ? "2" : "1"
            }
            onTabClick={handleTabClick}
            items={tabsTitleArray.map((element, i) => {
              const id = String(i + 1);
              return {
                key: id,
                label: <Styles.Label>{`${element}`}</Styles.Label>,
                children: (
                  <Styles.TabContent>
                    {renderContent(element)}
                  </Styles.TabContent>
                ),
              };
            })}
          />
        </Styles.QualificationContent>
      </Styles.RFPSContainer>
    </>
  );
};

export default InvestmentResearcher;

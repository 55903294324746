import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppLayout, RFPLayout, AuthLayout } from "./layouts";
import { PATHS } from "./constants";
import { PersistLogin, ProtectedRoute } from "./routes";
import {
  Ati,
  Legal,
  Nbm,
  Organogram,
  Overview,
  Policy966,
  RfpDocuments,
  Solution,
  UserManagementTabs,
} from "./components";
import {
  Home,
  Login,
  RFPS,
  ForgotPassword,
  OTP,
  ChangePassword,
  ResetPassword,
  Dashboard,
  ContentManagement,
  InvestmentResearcher,
} from "./pages";
import { Finance } from "./components/rfp/finance/finance";

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<PersistLogin />}>
          <Route element={<AuthLayout />}>
            <Route index path={PATHS.login} element={<Login />} />
            <Route
              index
              path={PATHS.forgotPassword}
              element={<ForgotPassword />}
            />
            <Route index path={PATHS.otp} element={<OTP />} />
            <Route
              index
              path={PATHS.changePassword}
              element={<ChangePassword />}
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<AppLayout />}>
              <Route path={PATHS.resetPassword} element={<ResetPassword />} />
              <Route index path={PATHS.home} element={<Home />} />
              <Route index path={PATHS.dashboard} element={<Dashboard />} />
              <Route index path={PATHS.rfps} element={<RFPS />} />
              <Route
                index
                path={PATHS.aiBulletin}
                element={<InvestmentResearcher />}
              />
              <Route index path={PATHS.user} element={<UserManagementTabs />} />
              <Route
                index
                path={PATHS.content}
                element={<ContentManagement />}
              />
              <Route path={`${PATHS.rfp}/:rfpId`} element={<RFPLayout />}>
                <Route path={PATHS.overview} element={<Overview />} />
                <Route path={PATHS.legal} element={<Legal />} />
                <Route path={PATHS.finance} element={<Finance />} />
                <Route path={PATHS.organogram} element={<Organogram />} />
                <Route path={PATHS.solution} element={<Solution />} />
                <Route path={PATHS.rfpDocuments} element={<RfpDocuments />} />
                <Route path={PATHS.ati} element={<Ati />} />
                <Route path={PATHS.nbm} element={<Nbm />} />
                <Route path={PATHS.policy966} element={<Policy966 />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeButton={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />

      <RouterProvider router={router} />
    </>
  );
};

export default App;

import * as Styles from "./styles";
import { IMAGES } from "../../shared";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Progress } from "antd";

interface UploadModalProps {
  isModalOpen: boolean;
  setUploadModal: (value: boolean) => void;
  afterClose: () => void;
}

const UploadSubDocModal = (props: UploadModalProps) => {
  const { isModalOpen, setUploadModal, afterClose } = props;

  const {
    subFiles,
    uploadedSubFiles,
    currentSubUploading,
    subFileUploadProgress,
  } = useSelector((state: RootState) => state.rfpUpload);

  const renderModal = () => {
    return (
      <Styles.UploadingDocContainer>
        <Styles.UploadingText>Uploading...</Styles.UploadingText>
        {subFiles?.map((v, i) => (
          <div key={i}>
            <Styles.UploadingFilesContainer>
              <Styles.PinIconTextWrap>
                <img src={IMAGES.pinIcon} alt="pin" />
                <Styles.UploadedFileName>{v?.name}</Styles.UploadedFileName>
              </Styles.PinIconTextWrap>
            </Styles.UploadingFilesContainer>
            {currentSubUploading?.name === v?.name && (
              <Progress
                percent={subFileUploadProgress}
                strokeColor={"#A100FF"}
              />
            )}
          </div>
        ))}

        {uploadedSubFiles.length ? (
          <>
            <Styles.UploadingText>Uploaded...</Styles.UploadingText>
            {uploadedSubFiles?.map((v, i) => (
              <div key={i}>
                <Styles.UploadingFilesContainer>
                  <Styles.PinIconTextWrap>
                    <img src={IMAGES.pinIcon} alt="pin" />
                    <Styles.UploadedFileName>{v}</Styles.UploadedFileName>
                  </Styles.PinIconTextWrap>
                </Styles.UploadingFilesContainer>
              </div>
            ))}
          </>
        ) : null}
      </Styles.UploadingDocContainer>
    );
  };

  return (
    <Styles.ModalContainer
      title={
        <Styles.UploadModalHeader>
          Uploading related documents
        </Styles.UploadModalHeader>
      }
      open={isModalOpen}
      onCancel={() => setUploadModal(false)}
      footer={null}
      confirmLoading={false}
      afterClose={afterClose}
      closeIcon={<img src={IMAGES.CloseModalIcon} alt="Close" />}
      style={{
        padding: 0,
        top: "auto",
      }}
    >
      {renderModal()}
    </Styles.ModalContainer>
  );
};

export default UploadSubDocModal;

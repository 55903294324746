import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getRFPDetailsByRFPId } from "../../services/allRfps";
import {
  removeUploadedRFP,
  updateRFPDetails,
} from "../../store/allRfps/allRfpSlice";
import { IAllRfp } from "../../store/allRfps/allRfp.interface";
import { RFPStatus, QualificationsStatus } from "../../constants";

const useRFPStatusCheck = () => {
  const dispatch = useDispatch();

  const [remainingRFP, setRemainingRFP] = useState<IAllRfp[]>([]);

  const { pendingUploadingRFP } = useSelector(
    (state: RootState) => state.allRfps
  );

  let RFPInterval: any;

  useEffect(() => {
    if (pendingUploadingRFP?.length > 0) {
      clearInterval(RFPInterval);
      setRemainingRFP(
        pendingUploadingRFP?.filter(
          (file) =>
            file?.status === RFPStatus.PROCESSING ||
            file?.qualification_status === QualificationsStatus.PROCESSING
        )
      );
    } else {
      setRemainingRFP([]);
      clearInterval(RFPInterval);
    }
  }, [pendingUploadingRFP]);

  useEffect(() => {
    if (remainingRFP?.length > 0) {
      RFPInterval = setInterval(async () => {
        for (const rfp of remainingRFP) {
          let res;
          try {
            res = await getRFPDetailsByRFPId(rfp.rfp_id);
            const isProcessing = res?.status === RFPStatus.PROCESSING;
            if (
              !isProcessing &&
              res?.qualification_status !== QualificationsStatus.PROCESSING
            ) {
              dispatch(updateRFPDetails(res));
              dispatch(removeUploadedRFP(res?.rfp_id));
            }
          } catch (error) {
            dispatch(removeUploadedRFP(res?.rfp_id));
            console.log("Error while pulling details", error);
          }
        }
      }, 10000);

      return () => {
        clearInterval(RFPInterval);
      };
    }
  }, [remainingRFP]);
};

export default useRFPStatusCheck;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IAllPolicyRequirements,
  IPolicyRequirements,
} from "./policyRequirements.interface";
import { PURGE } from "redux-persist";

const initialState: IAllPolicyRequirements = {
  policyRequirements: {
    relevantPages: [],
    newOrExistingClient: "",
    newOrExistingClientInput: "",
    companyDescription: "",
    companyDescriptionInput: "",
    industryStatureOfCompany: "",
    industryStatureOfCompanyInput: "",
    potentialForHighPerformanceImprovement: "",
    potentialForHighPerformanceImprovementInput: "",
    changeImperative: "",
    changeImperativeInput: "",
    propensityToFundASubstantialLongTermRelationShip: "",
    propensityToFundASubstantialLongTermRelationShipInput: "",
    shouldClientRDSetupForThisClient: "",
    shouldClientRDSetupForThisClientInput: "",
    opportunityDescription: "",
    opportunityDescriptionInput: "",
    policySectionSummaryFileName: "",
    policySectionSummaryFilePreSignedUrl: "",
    extractionStatus: "NOT_STARTED",
    pid: -1,
  },
};

export const policyRequirementsSlice = createSlice({
  name: "policyRequirements",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setPolicyRequirementDetails: (
      state,
      action: PayloadAction<IPolicyRequirements>
    ) => {
      state.policyRequirements = action.payload;
    },
  },
});

export const { setPolicyRequirementDetails } = policyRequirementsSlice.actions;

export default policyRequirementsSlice.reducer;

import * as Styles from "./style";

interface IMarkDownTextProps {
  text: string;
}

export const MarkDownText = ({ text }: IMarkDownTextProps) => {
  return (
    <Styles.MarkDownText className="mark-down-text">{text}</Styles.MarkDownText>
  );
};

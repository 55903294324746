import styled from "styled-components";
import { Input } from "antd";

export const UserManagementTabsContainer = styled.div`
  margin: 10px;
  height: 100%;
  overflow: hidden;
`;

export const TabsContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: 100%;
  .header-card {
    margin-top: 15px;
    padding: 0px;
    margin-bottom: 0px;
  }
`;

export const TabsHeaderWrap = styled.div`
  display: flex;
  gap: 15px;
`;

export const BorderLine = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid #ebecf2;
  border-bottom: 3px solid #0d25ff;
`;

export const TabsHeaderText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #333333;
  cursor: pointer;
`;

export const TabContentContainer = styled.div`
  margin-top: 15px;
`;

export const SearchAddNewUerButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UserInputSearch = styled(Input)`
  width: 300px;

  .ant-input-prefix {
    img {
      width: 15px;
    }
  }
`;

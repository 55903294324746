import { toast } from "react-toastify";
import { store } from "../store";
import {
  getOrganogramLoading,
  saveOrganogramLoading,
  setOrganogram,
  setOrganogramTitle,
} from "../store/organogram/organogramSlice";
import { get, post, put } from "./apiClient";
import { getRFPDetailsByRFPId } from "./allRfps";

export const getOrganogram = async (rfpId: number) => {
  try {
    store.dispatch(getOrganogramLoading(true));
    const res = await get(`core/organogram/organogram/${rfpId}`);

    const rfpById = await getRFPDetailsByRFPId(rfpId);

    rfpById?.rfp_name && store.dispatch(setOrganogramTitle(rfpById?.rfp_name));
    store.dispatch(
      setOrganogram({
        rfpId: res?.rfpId,
        organogram: JSON.parse(res?.organogramJson)?.organogram,
      })
    );
  } catch (error: any) {
    console.log(error, "getOrganogram api error");
    store.dispatch(setOrganogram({ rfpId: -1, organogram: [] }));
  } finally {
    store.dispatch(getOrganogramLoading(false));
  }
};

export const saveOrganogram = async (request: {
  rfpId: number;
  organogramJson: string;
}) => {
  try {
    store.dispatch(saveOrganogramLoading(true));
    await post(`core/organogram/save-organogram`, request);
    getOrganogram(request?.rfpId);
    toast.success("Organogram saved successfully");
  } catch (error: any) {
    console.log(error, "getOrganogram api error");
    toast.error(error?.errorMessage ?? "Failed to update Organogram");
  } finally {
    store.dispatch(saveOrganogramLoading(false));
  }
};

export const updateOrganogram = async (request: {
  rfpId: number;
  organogramJson: string;
}) => {
  try {
    store.dispatch(saveOrganogramLoading(true));
    await put(`core/organogram/update-organogram/${request?.rfpId}`, request);
    toast.success("Organogram updated successfully");
  } catch (error: any) {
    console.log(error, "updateOrganogram api error");
    toast.error(error?.errorMessage ?? "Failed to update Organogram");
  } finally {
    store.dispatch(saveOrganogramLoading(false));
  }
};

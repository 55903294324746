import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  Frs,
  IExtractFrsClauses,
  IGenerateFrsResponse,
  ISolution,
} from "./frs.interface";
import { ExtractionStatus } from "../../constants";
import { PURGE } from "redux-persist";

const initialState: ISolution = {
  frsDetails: {
    status: "",
    docId: -1,
    relevantPages: [],
    rfpId: -1,
    frsId: -1,
    fileName: "",
  },
  frsExtractStatus: ExtractionStatus?.NOT_STARTED,
  isFrsDetailsLoading: false,
  extractFrsClauses: {
    status: null,
    generatedResponseFileName: "",
    generatedResponseFilePreSignedUrl: "",
    frsId: -1,
    frsSectionSummaryFileName: "",
    frsSectionSummaryFilePreSignedUrl: "",
    extractionStatus: ExtractionStatus.NOT_STARTED,
    relevantPages: [{ start_page: 0, end_page: 0 }],
  },
  generateFrsResponse: {
    status: null,
    generatedResponseFilePreSignedUrl: "",
    generatedResponseFileName: "",
    generatedResponseStatus: ExtractionStatus.NOT_STARTED,
  },
  isDeleteExtractFrsFileLoading: false,
};

export const solutionSlice = createSlice({
  name: "solutionSlice",
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  initialState,
  reducers: {
    setFrsDetails: (state, action: PayloadAction<Frs>) => {
      state.frsDetails = action.payload;
    },

    setIsFrsDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.isFrsDetailsLoading = action.payload;
    },

    setExtractFrsClauses: (
      state,
      action: PayloadAction<IExtractFrsClauses>
    ) => {
      state.extractFrsClauses = action.payload;
    },

    setFrsExtractStatus: (state, action: PayloadAction<ExtractionStatus>) => {
      state.extractFrsClauses = {
        status: null,
        generatedResponseFileName: "",
        generatedResponseFilePreSignedUrl: "",
        frsId: -1,
        frsSectionSummaryFileName: "",
        frsSectionSummaryFilePreSignedUrl: "",
        extractionStatus: action.payload,
        relevantPages: [{ start_page: 0, end_page: 0 }],
      };
    },

    setIsDeleteExtractFrsFileLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteExtractFrsFileLoading = action.payload;
    },

    setGenerateFrsResponse: (
      state,
      action: PayloadAction<IGenerateFrsResponse>
    ) => {
      state.generateFrsResponse = action.payload;
    },

    setGenerateFrsResponseStatus: (
      state,
      action: PayloadAction<ExtractionStatus>
    ) => {
      state.generateFrsResponse = {
        ...state.generateFrsResponse,
        generatedResponseStatus: action.payload,
      };
    },
  },
});

export const {
  setFrsDetails,
  setIsFrsDetailsLoading,
  setExtractFrsClauses,
  setFrsExtractStatus,
  setGenerateFrsResponse,
  setIsDeleteExtractFrsFileLoading,
  setGenerateFrsResponseStatus,
} = solutionSlice.actions;

export default solutionSlice.reducer;

import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import * as Styles from "../styles";
import { NbmLoader } from "../nbmLoader";
import { RootState } from "../../../../store";
import { SecondaryButton } from "../../../../shared";
import { updateNbm } from "../../../../services/nbm";
import { MarkDownText } from "../../../../shared/markDownText/MarkDownText";
import {
  nbmData,
  INbmTabData,
  IPaymentTermValue,
  IPaymentTerm,
  INbmTabs,
} from "../../../../store/nbm/nbm.interface";

const editTabKey = {
  paymentTerm: "",
  descriptions: "",
  paymentMilestone: "",
};

const NbmPaymentTerms = () => {
  const [isEdit, setIsEdit] = useState<{
    id: string;
    editKey: keyof typeof editTabKey | null;
  }>({ id: "-1", editKey: null });
  const [paymentData, setPaymentData] = useState<IPaymentTerm[]>([]);
  const [currentEditValue, setCurrentEditValue] = useState<string>("");

  const { rfpId } = useParams();

  const currentRfpId: number = rfpId ? Number(rfpId) : -1;

  const { nbmData, isNbmDataLoading, isNbmUpdateLoading } = useSelector(
    (state: RootState) => state.nbm
  );

  useEffect(() => {
    setPaymentData(nbmData?.nbmData?.payments_terms);
  }, [nbmData?.nbmData]);

  const checkNbmString = (value: INbmTabData[] | string): string => {
    if (typeof value === "string") {
      return value;
    }
    return "";
  };

  const handleValueChange = (
    currentValue: string,
    currentId: string,
    editKey: keyof typeof editTabKey
  ) => {
    setCurrentEditValue(currentValue);
    const updatedPaymentTermData = paymentData.map((v) => ({
      ...v,
      value: v?.value?.map((doc) =>
        doc.id === currentId ? { ...doc, [editKey]: currentValue } : doc
      ),
    }));

    setPaymentData(updatedPaymentTermData);
  };

  const handleEditForm = (
    id: string,
    editKey: keyof typeof editTabKey,
    value: string
  ) => {
    setCurrentEditValue(value);
    setPaymentData(nbmData?.nbmData?.payments_terms);
    setIsEdit({ id, editKey });
  };

  const handleSubmitForm = async () => {
    const updatedNbmData: INbmTabs = {
      ...nbmData?.nbmData,
      payments_terms: paymentData,
    };

    const request: nbmData = {
      ...nbmData,
      nbmData: updatedNbmData,
    };

    await updateNbm(request, currentRfpId);

    setIsEdit({ id: "-1", editKey: null });

    setCurrentEditValue("");
  };

  const handleCloseForm = () => {
    setIsEdit({ id: "-1", editKey: null });
    setPaymentData(nbmData?.nbmData?.payments_terms);
    setCurrentEditValue("");
  };

  const renderEditablePoints = (
    title: string,
    value: string,
    id: string,
    editKey: keyof typeof editTabKey
  ) => {
    return (
      <Styles.NBMItemsEditablePointsHeaderWrap>
        {title !== "" && (
          <Styles.NBMItemsEditablePointsHeader>
            {title}
          </Styles.NBMItemsEditablePointsHeader>
        )}
        <Styles.NBMItemsEditablePointsPoints>
          <Styles.InputFooterWrap
            $active={isEdit?.id === id && isEdit?.editKey === editKey}
          >
            {isEdit?.id === id && isEdit?.editKey === editKey ? (
              <Styles.InputSummary
                readOnly={isEdit?.id !== id && isEdit?.editKey !== editKey}
                value={checkNbmString(currentEditValue)}
                onChange={(e) => {
                  handleValueChange(e?.target?.value, id, editKey);
                }}
                autoSize={{ minRows: 1, maxRows: 100 }}
              />
            ) : (
              <MarkDownText text={checkNbmString(value)} />
            )}
            <Styles.InputFooterButtonWrap>
              <Button
                loading={isNbmUpdateLoading}
                disabled={isNbmUpdateLoading}
                type="link"
                icon={<CheckOutlined style={{ fontSize: "13px" }} />}
                onClick={handleSubmitForm}
              />
              <Button
                type="link"
                disabled={isNbmUpdateLoading}
                icon={<CloseOutlined style={{ fontSize: "13px" }} />}
                onClick={handleCloseForm}
              />
            </Styles.InputFooterButtonWrap>
          </Styles.InputFooterWrap>

          {isEdit?.id !== id && isEdit?.editKey !== editKey && (
            <SecondaryButton
              className="secondary-button"
              onClick={() => handleEditForm(id, editKey, value)}
              disabled={isNbmUpdateLoading}
            >
              Edit
            </SecondaryButton>
          )}
        </Styles.NBMItemsEditablePointsPoints>
      </Styles.NBMItemsEditablePointsHeaderWrap>
    );
  };

  const renderTable = (data: IPaymentTermValue[]) => {
    if (isNbmDataLoading) return <NbmLoader />;
    else
      return (
        <>
          {data?.map((v: IPaymentTermValue, index: number) => {
            return (
              <React.Fragment key={index}>
                <Styles.LeftSide>
                  <Styles.LeftTextNumberAlign>
                    <Styles.FinanceDetails>
                      <Styles.Heading>
                        <Styles.SerialNum>{v?.sr_no}</Styles.SerialNum>
                        <Styles.FTitle>{v?.title}</Styles.FTitle>
                      </Styles.Heading>
                    </Styles.FinanceDetails>
                  </Styles.LeftTextNumberAlign>
                </Styles.LeftSide>
                <Styles.RightSide>
                  <Styles.RightTextNumberAlign>
                    {renderEditablePoints(
                      "Activity",
                      v?.descriptions,
                      v?.id,
                      "descriptions"
                    )}
                    {renderEditablePoints(
                      "Payment Terms",
                      v?.paymentTerm,
                      v?.id,
                      "paymentTerm"
                    )}
                    {renderEditablePoints(
                      "Payment Milestones",
                      v?.paymentMilestone,
                      v?.id,
                      "paymentMilestone"
                    )}
                  </Styles.RightTextNumberAlign>
                </Styles.RightSide>
              </React.Fragment>
            );
          })}
        </>
      );
  };

  return (
    <Styles.NbmTableItemContainer>
      {paymentData?.map((v, index: number) => (
        <div key={index}>
          <Styles.NbmFormHeader>{v?.title ?? ""}</Styles.NbmFormHeader>
          <Styles.NBMItemsContainer>
            {renderTable(v?.value)}
          </Styles.NBMItemsContainer>
        </div>
      ))}
    </Styles.NbmTableItemContainer>
  );
};

export default NbmPaymentTerms;

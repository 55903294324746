import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchRFP from "../../components/home/searchRFP/SearchRFP";
import RFPTable from "../../components/rfps/RFPTable/RFPTable";
import { PageLimit, RFPFilters, RFPQualifications } from "../../constants";
import { getAllRfps } from "../../services/allRfps";
import { IMAGES } from "../../shared";
import { RootState } from "../../store";
import {
  clearFilter,
  updateFilter,
  updateRfpFilterStatus,
  setResetRfpPagination,
} from "../../store/allRfps/allRfpSlice";
import * as Styles from "./styles";
import { Checkbox } from "antd";
import useRFPStatusCheck from "../../shared/hooks/useRFPStatusCheck";

const RFPS = () => {
  useRFPStatusCheck();
  const dispatch = useDispatch();

  const { rfpFilters, statusCounts, currentPage, pageLimit } = useSelector(
    (state: RootState) => state.allRfps
  );
  const currentRfpPage = currentPage ?? 1;
  const rfpPageLimit = pageLimit ?? PageLimit.Rfp;

  useEffect(() => {
    getAllRfps(rfpFilters, currentRfpPage, rfpPageLimit);
  }, [rfpFilters, currentRfpPage, rfpPageLimit]);

  const checkFilterApplied = (filterName: string): boolean => {
    if (rfpFilters?.status?.includes(filterName)) return true;
    return false;
  };

  const handleRfpFilters = (status: string) => {
    dispatch(updateRfpFilterStatus(status));
    dispatch(setResetRfpPagination());
  };

  const handleRfpFiltersChange = () => {
    if (rfpFilters.qualification === RFPQualifications.QUALIFIED) {
      dispatch(updateFilter({ field: "qualification", value: "" }));
    } else {
      dispatch(
        updateFilter({
          field: "qualification",
          value: RFPQualifications.QUALIFIED,
        })
      );
    }
    dispatch(setResetRfpPagination());
  };

  const handleClearFilter = () => {
    dispatch(clearFilter());
    dispatch(setResetRfpPagination());
  };

  const isDisabledClearFilter = Object.values(rfpFilters).every((value) => {
    if (typeof value === "string") {
      return !value.trim();
    } else if (Array.isArray(value)) {
      return value.length === 0;
    }
    return !value;
  });

  const renderFilters = () => {
    return (
      <Styles.RFPLabelWrap>
        {/* <Styles.RFPLabelContainer
          onClick={() => handleRfpFilters(RFPFilters.LIVE)}
        >
          <Styles.RFPLabelText>
            Live
            {checkFilterApplied(RFPFilters.LIVE) &&
              !!statusCounts.totalLiveRfps && (
                <span>({statusCounts.totalLiveRfps})</span>
              )}
          </Styles.RFPLabelText>
          {checkFilterApplied(RFPFilters.LIVE) && (
            <img src={IMAGES.checkIcon} alt="checkIcon" />
          )}
        </Styles.RFPLabelContainer> */}

        <Styles.RFPLabelContainer
          onClick={() => handleRfpFilters(RFPFilters.COMPLETED)}
        >
          <Styles.RFPLabelText>
            Completed
            {checkFilterApplied(RFPFilters.COMPLETED) &&
              !!statusCounts.completedRfps && (
                <span>({statusCounts.completedRfps})</span>
              )}
          </Styles.RFPLabelText>
          {checkFilterApplied(RFPFilters.COMPLETED) && (
            <img src={IMAGES.checkIcon} alt="checkIcon" />
          )}
        </Styles.RFPLabelContainer>

        <Styles.RFPLabelContainer
          onClick={() => handleRfpFilters(RFPFilters.ON_HOLD)}
        >
          <Styles.RFPLabelText>
            On hold
            {checkFilterApplied(RFPFilters.ON_HOLD) &&
              !!statusCounts.onHoldRfps && (
                <span>({statusCounts.onHoldRfps})</span>
              )}
          </Styles.RFPLabelText>
          {checkFilterApplied(RFPFilters.ON_HOLD) && (
            <img src={IMAGES.checkIcon} alt="checkIcon" />
          )}
        </Styles.RFPLabelContainer>

        <Styles.RFPLabelContainer
          onClick={() => handleRfpFilters(RFPFilters.NOT_STARTED)}
        >
          <Styles.RFPLabelText>
            Not started
            {checkFilterApplied(RFPFilters.NOT_STARTED) &&
              !!statusCounts.notStartedRfps && (
                <span>({statusCounts.notStartedRfps})</span>
              )}
          </Styles.RFPLabelText>
          {checkFilterApplied(RFPFilters.NOT_STARTED) && (
            <img src={IMAGES.checkIcon} alt="checkIcon" />
          )}
        </Styles.RFPLabelContainer>
      </Styles.RFPLabelWrap>
    );
  };

  return (
    <Styles.RFPSContainer>
      <Styles.RFPHeader>
        <SearchRFP />
      </Styles.RFPHeader>
      <Styles.RFPTableContainer>
        <Styles.RFPHeaderBorderWrap>
          <Styles.RFPTableHeader>
            {renderFilters()}
            <Styles.RFPTableSubContainer>
              <Checkbox
                checked={
                  rfpFilters?.qualification === RFPQualifications.QUALIFIED
                }
                onChange={handleRfpFiltersChange}
              >
                Show Only Qualified
              </Checkbox>
              <Styles.LinkButton
                type="link"
                disabled={isDisabledClearFilter}
                onClick={handleClearFilter}
              >
                Clear Filter
              </Styles.LinkButton>
            </Styles.RFPTableSubContainer>
            {/* <Styles.EditColumnButton>Edit columns</Styles.EditColumnButton> */}
          </Styles.RFPTableHeader>
          {/* <Styles.RPFHeaderBorder /> */}
        </Styles.RFPHeaderBorderWrap>
        <RFPTable />
      </Styles.RFPTableContainer>
    </Styles.RFPSContainer>
  );
};

export default RFPS;

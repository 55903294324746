import { ActiveStatus } from "./../../../types";
import { Modal, Select } from "antd";
import styled from "styled-components";
import { SecondaryButton, TertiaryButton } from "../../../shared";

export const OverviewHeader = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: 100%;
`;

export const SubHeader = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const HeaderIcon = styled.div`
  height: 25px;
  width: 25px;
  background: ${({ theme }) => theme.colors.backgroundColorBeta};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AntSelect = styled(Select)`
  width: 135px;
  .ant-select &.ant-select-single {
    font-family: "GraphikMedium", sans-serif;
    height: 20px;
    border-radius: 15px;
  }

  && .ant-select-arrow {
    color: ${({ theme }) => theme.colors.fontColorAlpha};
  }

  && .ant-select-selector {
    box-shadow: none;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.fontColorDelta};
  }

  &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 5px;
  }

  &.ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    box-shadow: none;
  }
`;

export const getSelectStyles = (
  selectedValue: string
): { backgroundColor?: string; color?: string } => {
  switch (selectedValue) {
    case "NOT_STARTED":
    case "PAUSED":
      return {
        backgroundColor: "#f2f2f2",
        color: "#5c5b5b",
      };
    case "IN_PROGRESS":
      return {
        backgroundColor: "#00b8844d",
        color: "#027e5b",
      };
    case "COMPLETED":
      return {
        backgroundColor: "#ffd6004d",
        color: "#8b7608",
      };
    default:
      return { backgroundColor: "#f2f2f2", color: "#5c5b5b" };
  }
};

interface AntSelectStatusProps {
  $RfpStatus: string;
}

export const AntSelectStatus = styled(Select)<AntSelectStatusProps>`
  &.ant-select-single {
    width: 130px;
    height: 27px;
    border-radius: 15px;
  }

  && .ant-select-arrow {
    color: ${({ $RfpStatus }) => getSelectStyles($RfpStatus).color};
  }

  &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 15px;
    ${({ $RfpStatus }) => getSelectStyles($RfpStatus)};
  }

  && .ant-select-selector {
    font-size: 13px;
  }

  &.ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    box-shadow: none;
  }
`;

export const OrganogramIconWrap = styled.div`
  min-width: 35px;
  height: 35px;
  border: 0.5px solid ${({ theme }) => theme.colors.borderColorEta};
  box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.colors.borderColorEta};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 24px;
  }
`;

export const overviewCableIconWrap = styled.div`
  min-width: 37px;
  height: 37px;
  border: 0.5px solid ${({ theme }) => theme.colors.borderColorEta};
  box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.colors.borderColorEta};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
  }
`;

export const NbmModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    background: #f4f6fc;
  }
  button.ant-modal-close {
    display: none;
  }
  &.ant-modal {
    width: 300px;
    position: absolute;
    left: 60%;
    top: 15%;
  }
`;
export const BidStage = styled(Select)`
  && .ant-select-selector {
    border: 0.6px solid rgba(109, 96, 164, 0.4);
  }

  && .ant-select-selection-item {
    font-family: "GraphikRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #5c5b5b;
  }
`;

export const BackStyle = styled.div`
  margin-top: 10px;
  cursor: pointer;
`;

export const RfpDocumentsButton = styled(SecondaryButton)<ActiveStatus>`
  gap: 8px;
  background: #f9eeffc9;
  border: 0.5px solid #a05eff;

  img {
    transform: ${({ $active }) => ($active ? "rotate(180deg)" : "rotate(0)")};
    transition: transform 0.5s ease;
  }
`;

export const ExportAtiButton = styled(TertiaryButton)`
  font-size: 11px;
  width: fit-content;
  padding: 4px 10px;
  height: 30px;
`;

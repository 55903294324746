import { Skeleton, Table } from "antd";

const SkeletonTable = () => {
  const columns = [
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "fileName",
      key: "fileName",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "UploadedBy",
      key: "UploadedBy",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "UploadedOn",
      key: "UploadedOn",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      key: "action",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
  ];

  return (
    <Table
      rowKey={"rfp-doc-id"}
      dataSource={[{}, {}, {}]}
      columns={columns as any}
      pagination={false}
    />
  );
};

export default SkeletonTable;

import { useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Progress } from "antd";
import { useForm } from "antd/es/form/Form";
import * as Styles from "./styles";
import { IMAGES } from "../../shared";
import { RootState } from "../../store";
import { getAllRfps } from "../../services/allRfps";
import { PageLimit, RFPUploadSteps } from "../../constants";
import { UploadMainRfp, UploadSubRfp } from "../../services/uploadRfp";
import {
  setMailFile,
  setSubFiles,
  setUploadSteps,
  removeSubFiles,
  resetUploadStates,
  setIsFilesUploading,
  setCurrentSubUploading,
} from "../../store/rfpUpload/rfpUploadSlice";

interface UploadModalProps {
  isModalOpen: boolean;
  setUploadModal: (value: boolean) => void;
}

const UploadDocModal = (props: UploadModalProps) => {
  const { isModalOpen, setUploadModal } = props;

  const [form] = useForm();

  const dispatch = useDispatch();

  const inputMainFileRef = useRef<HTMLInputElement>(null);
  const inputSubFileRef = useRef<HTMLInputElement>(null);

  const {
    subFiles,
    mainFile,
    uploadSteps,
    isFilesUploading,
    uploadedSubFiles,
    subFileUploadProgress,
    mainFileUploadProgress,
    currentSubUploading,
  } = useSelector((state: RootState) => state.rfpUpload);
  const { userId } = useSelector((state: RootState) => state.authSlice);
  const { rfpFilters, currentPage, pageLimit } = useSelector(
    (state: RootState) => state.allRfps
  );
  const currentRfpPage = currentPage ?? 1;
  const rfpPageLimit = pageLimit ?? PageLimit.Rfp;

  //Main file upload button click
  const handleMainFileUploadButton = () => {
    if (inputMainFileRef.current != null) {
      inputMainFileRef.current.click();
    }
  };

  //Main file upload
  const handleMainFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: File | null = e.target.files && e.target.files[0];
    if (file) {
      dispatch(setMailFile(file));
      dispatch(setUploadSteps(RFPUploadSteps.UPLOADING));
    }
  };

  // remove uploaded main file
  const handleRemoveMainFile = () => {
    if (inputMainFileRef.current) {
      inputMainFileRef.current.value = "";
    }
    dispatch(setMailFile(null));
    dispatch(setUploadSteps(RFPUploadSteps.BROWSE));
  };

  //sub file upload button click
  const handleSubFileUploadButton = () => {
    if (inputSubFileRef.current != null) {
      inputSubFileRef.current.click();
    }
  };

  // remove selected sub file
  const handleRemoveSubFile = (fileName: string) => {
    if (inputSubFileRef.current) {
      inputSubFileRef.current.value = "";
    }
    dispatch(removeSubFiles(fileName));
  };

  //sub file upload
  const handleSubFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files && e.target.files;
    const filesArray: File[] = files ? Array.from(files) : [];
    dispatch(setSubFiles(filesArray));
  };

  //check is subFile upload ui is visible
  const checkIsSubFilesVisible = (): boolean => {
    if (isFilesUploading && !subFiles?.length) {
      return false;
    }
    return true;
  };

  const handleModalSubmit = async () => {
    var mainFileRFPId: number = -1;
    try {
      dispatch(setIsFilesUploading(true));

      if (mainFile) {
        const formData = new FormData();
        formData.append("file", mainFile);

        mainFileRFPId = await UploadMainRfp(formData, userId);
      }

      if (subFiles) {
        for (let i = 0; i < subFiles.length; i++) {
          try {
            const formData = new FormData();
            formData.append("file", subFiles[i]);
            dispatch(setCurrentSubUploading(subFiles[i]));

            await UploadSubRfp(
              formData,
              mainFileRFPId,
              subFiles[i]?.name,
              userId ?? -1
            );
          } catch (error) {
            console.log("Error:", error);
          } finally {
            dispatch(setCurrentSubUploading(null));
          }
        }
      }
      setUploadModal(false);
      getAllRfps(rfpFilters, currentRfpPage, rfpPageLimit);
      toast.success("RFP uploaded successfully");
    } catch (e: any) {
      console.log("failed to upload main file", e);
    } finally {
      dispatch(resetUploadStates());
    }
  };

  const renderRFPFileUploadSteps = () => {
    switch (uploadSteps) {
      case RFPUploadSteps.BROWSE:
        return (
          <Styles.BrowseFilesContainer>
            <Styles.UploadedFileNameDeleteIconWrap>
              <Styles.UploadedFileNamePinIconWrap>
                <img src={IMAGES.pinIcon} alt="pin" />
                <Styles.UploadedFileName>Browse</Styles.UploadedFileName>
              </Styles.UploadedFileNamePinIconWrap>
              <Styles.BrowseFilesButton onClick={handleMainFileUploadButton}>
                Browse
              </Styles.BrowseFilesButton>
              <input
                type="file"
                ref={inputMainFileRef}
                accept=".pdf"
                onChange={handleMainFileUpload}
                hidden
              />
            </Styles.UploadedFileNameDeleteIconWrap>
          </Styles.BrowseFilesContainer>
        );

      case RFPUploadSteps.UPLOADING:
        return (
          <div>
            <Styles.UploadingFilesContainer>
              <Styles.PinIconTextWrap>
                <img src={IMAGES.pinIcon} alt="pin" />
                <Styles.UploadedFileName>
                  {mainFile?.name}
                </Styles.UploadedFileName>
              </Styles.PinIconTextWrap>

              <img
                src={IMAGES.deleteIcon}
                alt="delete"
                onClick={handleRemoveMainFile}
              />
            </Styles.UploadingFilesContainer>
            {mainFileUploadProgress > 0 && (
              <Progress
                percent={mainFileUploadProgress}
                strokeColor={"#A100FF"}
              />
            )}
          </div>
        );

      case RFPUploadSteps.UPLOADED:
        return (
          <Styles.UploadedFileNameContainer>
            <Styles.UploadedFileNameDeleteIconWrap>
              <Styles.UploadedFileNamePinIconWrap>
                <img src={IMAGES.pinIcon} alt="pin" />
                <Styles.UploadedFileName>
                  document-name.PDF
                </Styles.UploadedFileName>
              </Styles.UploadedFileNamePinIconWrap>
              <img src={IMAGES.deleteIcon} alt="delete" />
            </Styles.UploadedFileNameDeleteIconWrap>
          </Styles.UploadedFileNameContainer>
        );

      default:
        return (
          <Styles.UploadedFileNameContainer>
            <Styles.UploadedFileNameDeleteIconWrap>
              <Styles.UploadedFileNamePinIconWrap>
                <img src={IMAGES.pinIcon} alt="pin" />
                <Styles.UploadedFileName>
                  document-name.PDF
                </Styles.UploadedFileName>
              </Styles.UploadedFileNamePinIconWrap>
              <img src={IMAGES.deleteIcon} alt="delete" />
            </Styles.UploadedFileNameDeleteIconWrap>
          </Styles.UploadedFileNameContainer>
        );
    }
  };

  const renderUploadMainFiles = () => {
    return (
      <Styles.UploadedRfpContainer>
        <Styles.UploadedRfpHeader>
          Upload RFP file
          {renderRFPFileUploadSteps()}
        </Styles.UploadedRfpHeader>
      </Styles.UploadedRfpContainer>
    );
  };

  const renderUploadSubFiles = () => {
    return (
      <Styles.UploadedRfpContainer>
        <Styles.UploadedRfpHeader>
          Upload your RFP related documents
        </Styles.UploadedRfpHeader>

        {!isFilesUploading && (
          <Styles.BrowseFilesContainer>
            <Styles.UploadedFileNameDeleteIconWrap>
              <Styles.UploadedFileNamePinIconWrap>
                <img src={IMAGES.pinIcon} alt="pin" />
                <Styles.UploadedFileName>Browse</Styles.UploadedFileName>
              </Styles.UploadedFileNamePinIconWrap>
              <Styles.BrowseFilesButton onClick={handleSubFileUploadButton}>
                Browse
              </Styles.BrowseFilesButton>
              <input
                type="file"
                ref={inputSubFileRef}
                accept=".pdf"
                onChange={handleSubFileUpload}
                hidden
                multiple
              />
            </Styles.UploadedFileNameDeleteIconWrap>
          </Styles.BrowseFilesContainer>
        )}

        {subFiles?.length ? (
          <Styles.UploadingDocContainer>
            <Styles.UploadingText>Uploading...</Styles.UploadingText>
            {subFiles?.map((v, i) => (
              <div key={i}>
                <Styles.UploadingFilesContainer>
                  <Styles.PinIconTextWrap>
                    <img src={IMAGES.pinIcon} alt="pin" />
                    <Styles.UploadedFileName>{v?.name}</Styles.UploadedFileName>
                  </Styles.PinIconTextWrap>
                  {!isFilesUploading && (
                    <img
                      src={IMAGES.deleteIcon}
                      alt="delete"
                      onClick={() => handleRemoveSubFile(v?.name)}
                    />
                  )}
                </Styles.UploadingFilesContainer>
                {currentSubUploading?.name === v?.name && (
                  <Progress
                    percent={subFileUploadProgress}
                    strokeColor={"#A100FF"}
                  />
                )}
              </div>
            ))}
          </Styles.UploadingDocContainer>
        ) : null}

        {uploadedSubFiles.length ? (
          <Styles.UploadingDocContainer>
            <Styles.UploadingText>Uploaded</Styles.UploadingText>
            {uploadedSubFiles.map((v, i) => (
              <Styles.UploadedFileNameContainer key={i}>
                <Styles.UploadedFileNameDeleteIconWrap>
                  <Styles.UploadedFileNamePinIconWrap>
                    <img src={IMAGES.pinIcon} alt="pin" />
                    <Styles.UploadedFileName>{v}</Styles.UploadedFileName>
                  </Styles.UploadedFileNamePinIconWrap>
                  <img src={IMAGES.deleteIcon} alt="delete" />
                </Styles.UploadedFileNameDeleteIconWrap>
              </Styles.UploadedFileNameContainer>
            ))}
          </Styles.UploadingDocContainer>
        ) : null}
      </Styles.UploadedRfpContainer>
    );
  };

  const renderUploadFooter = () => {
    return (
      <Styles.UploadFooter>
        <Styles.SubmitButton
          onClick={handleModalSubmit}
          loading={isFilesUploading}
        >
          <img src={IMAGES.submitProcess} alt="delete icons" />
          Process using AI
        </Styles.SubmitButton>
        <Styles.FooterDescription>
          File will be submitted for AI Processing
        </Styles.FooterDescription>
      </Styles.UploadFooter>
    );
  };

  const renderModal = () => {
    return (
      <Styles.UploadModalContainer>
        {renderUploadMainFiles()}
        {checkIsSubFilesVisible() && renderUploadSubFiles()}
        <Styles.FooterBorder />
        {renderUploadFooter()}
      </Styles.UploadModalContainer>
    );
  };

  return (
    <Styles.ModalContainer
      title={<Styles.UploadModalHeader>Upload RFP</Styles.UploadModalHeader>}
      open={isModalOpen}
      onOk={() => form.submit()}
      onCancel={() => setUploadModal(false)}
      footer={null}
      confirmLoading={false}
      afterClose={() => dispatch(resetUploadStates())}
      closeIcon={<img src={IMAGES.CloseModalIcon} alt="Close" />}
      style={{
        padding: 0,
        top: "auto",
      }}
    >
      {renderModal()}
    </Styles.ModalContainer>
  );
};

export default UploadDocModal;

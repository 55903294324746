import { useEffect } from "react";
import { Bar, Funnel } from "@ant-design/plots";
import { Bar as ReactChartBar } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import * as Styles from "./style";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { DateRangePicker } from "../../shared";
import { GroupChartLabel } from "../../constants";
import { updateFilter } from "../../store/dashboard/dashboardSlice";
import {
  getRFPFileListCount,
  getRFPFileListCountByDate,
} from "../../services/allRfps";
import {
  getBarChartColor,
  getFunnelChartColor,
  rangePresets,
} from "../../shared/helpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReactChartBarOptions: ChartOptions<"bar"> = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        padding: 20,
        color: "#333333",
      },
    },
  },

  scales: {
    x: {
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#333333",
        font: {
          size: 13,
          weight: 400,
        },
      },
      title: {
        display: true,
        text: "Stages",
        color: "#333333",
      },
    },
    y: {
      display: true,
      grid: {
        color: "#E7E7E7",
      },
      ticks: {
        color: "#333333",
        font: {
          size: 13,
          weight: 400,
        },
      },
      title: {
        display: true,
        text: "No. of RFPs",
        color: "#333333",
      },
    },
  },

  layout: {
    padding: 20,
  },
};

const Dashboard = () => {
  const dispatch = useDispatch();

  const { dashboardFilter, rfpFileListCount, rfpFileListCountByDate } =
    useSelector((state: RootState) => state.dashboard);
  const { startDate, endDate, clientGroup, projectCategory } = dashboardFilter;

  const barChartData = [
    { year: "Unassigned", value: rfpFileListCount.unassignedRfps },
    { year: "Qualified", value: rfpFileListCount.qualifiedRfps },
    { year: "Disqualified", value: rfpFileListCount.nonQualifiedRfps },
  ];

  const funnelChartData = [
    { stage: "0A", number: rfpFileListCount.stage0AQualifiedRfps },
    { stage: "1", number: rfpFileListCount.stage1QualifiedRfps },
    { stage: "2A", number: rfpFileListCount.stage2AQualifiedRfps },
    { stage: "2B", number: rfpFileListCount.stage2BQualifiedRfps },
    { stage: "3A", number: rfpFileListCount.stage3AQualifiedRfps },
    { stage: "3B", number: rfpFileListCount.stage3BQualifiedRfps },
  ];

  const sortedFunnelChartData = funnelChartData.sort(
    (a, b) => b.number - a.number
  );

  const groupChartData: ChartData<"bar"> = {
    labels: GroupChartLabel,
    datasets: [
      {
        label: "< 7 days old",
        data: [
          rfpFileListCountByDate.stage0Days7Rfps,
          rfpFileListCountByDate.stage1Days7Rfps,
          rfpFileListCountByDate.stage2ADays7Rfps,
          rfpFileListCountByDate.stage2BDays7Rfps,
          rfpFileListCountByDate.stage3ADays7Rfps,
          rfpFileListCountByDate.stage3BDays7Rfps,
        ],
        backgroundColor: "#8E4DFF",
      },
      {
        label: "<30 days old",
        data: [
          rfpFileListCountByDate.stage0Days30BeforeRfps,
          rfpFileListCountByDate.stage1Days30BeforeRfps,
          rfpFileListCountByDate.stage2ADays30BeforeRfps,
          rfpFileListCountByDate.stage2BDays30BeforeRfps,
          rfpFileListCountByDate.stage3ADays30BeforeRfps,
          rfpFileListCountByDate.stage3BDays30BeforeRfps,
        ],
        backgroundColor: "#C22DD2",
      },
      {
        label: ">30 days old",
        data: [
          rfpFileListCountByDate.stage0Days30Rfps,
          rfpFileListCountByDate.stage1Days30Rfps,
          rfpFileListCountByDate.stage2ADays30Rfps,
          rfpFileListCountByDate.stage2BDays30Rfps,
          rfpFileListCountByDate.stage3ADays30Rfps,
          rfpFileListCountByDate.stage3BDays30Rfps,
        ],
        backgroundColor: "#740F92",
      },
    ],
  };

  const barConfig = {
    data: barChartData,
    xField: "value",
    yField: "year",
    colorField: "year",
    color: (args: any) => getBarChartColor(args.year),
    xAxis: {
      title: {
        text: "No. of RFPs",
      },
    },
  };

  const funnelConfig = {
    data: sortedFunnelChartData,
    xField: "stage",
    yField: "number",
    color: (args: any) => getFunnelChartColor(args.stage),
    label: {
      content: (d: any) => `${d.number}`,
    },
  };

  const onDateRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      const formattedStartDate = dates[0]
        ? dayjs(dates[0]).format("YYYY-MM-DD")
        : "";
      const formattedEndDate = dates[1]
        ? dayjs(dates[1]).format("YYYY-MM-DD")
        : "";
      dispatch(updateFilter({ field: "startDate", value: formattedStartDate }));
      dispatch(updateFilter({ field: "endDate", value: formattedEndDate }));
    }
  };

  useEffect(() => {
    getRFPFileListCount(dashboardFilter);
  }, [dashboardFilter]);

  useEffect(() => {
    getRFPFileListCountByDate(clientGroup, projectCategory);
  }, [clientGroup, projectCategory]);

  return (
    <Styles.DashboardContainer>
      <Styles.ChartContainer>
        <Styles.HeaderContainer>
          <Styles.TitleContainer>
            <Styles.TitleText>Review metrics</Styles.TitleText>
          </Styles.TitleContainer>

          <Styles.FilterContainer>
            <Styles.FIlterLabel>Select date range:</Styles.FIlterLabel>
            <DateRangePicker
              presets={rangePresets}
              placeholder={["Start date", "End date"]}
              format={"YYYY-MM-DD"}
              value={
                startDate && endDate
                  ? [
                      dayjs(startDate, "YYYY-MM-DD"),
                      dayjs(endDate, "YYYY-MM-DD"),
                    ]
                  : null
              }
              allowClear={false}
              onChange={onDateRangeChange}
              background="#ffffff"
              color="#5c5b5b"
            />
          </Styles.FilterContainer>
        </Styles.HeaderContainer>

        <Styles.ChartCard>
          <Styles.GraphWrapper>
            <Styles.GraphTitle>RFPs status</Styles.GraphTitle>
            <Styles.GraphStyle>
              <Bar {...barConfig} />
            </Styles.GraphStyle>
          </Styles.GraphWrapper>
          <Styles.GraphWrapper>
            <Styles.GraphTitle>
              No. of qualified RFPs in each stage
            </Styles.GraphTitle>
            <Styles.GraphStyle>
              <Funnel {...funnelConfig} />
            </Styles.GraphStyle>
          </Styles.GraphWrapper>
        </Styles.ChartCard>

        <Styles.GroupChartCard>
          <Styles.GraphWrapper>
            <Styles.GraphTitle>Ageing since qualified</Styles.GraphTitle>
            <Styles.GroupChartStyle>
              <ReactChartBar
                options={ReactChartBarOptions}
                data={groupChartData}
              />
            </Styles.GroupChartStyle>
          </Styles.GraphWrapper>
        </Styles.GroupChartCard>
      </Styles.ChartContainer>
    </Styles.DashboardContainer>
  );
};

export default Dashboard;

import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, TabsProps } from "antd";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { NbmPaymentTerms } from "./nbmPaymentTerms";
import { NbmPreQualification } from "./nbmPreQualification";
import { getGeneratedNbmById } from "../../../services/nbm";
import { PATHS, RFPGenerationStatus } from "../../../constants";
import { NbmTechnicalQualification } from "./nbmTechnicalQualification";
import { ClientProfileBoardScopeWinningThemes } from "./ClientProfileBoardScopeWinningThemes";

const NBM = () => {
  const NbmItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Client profile",
      children: (
        <ClientProfileBoardScopeWinningThemes tabKey="client_profile" />
      ),
    },
    {
      key: "2",
      label: "Broad Scope of Work",
      children: (
        <ClientProfileBoardScopeWinningThemes tabKey="broad_scope_of_work" />
      ),
    },
    {
      key: "3",
      label: "Pre - Qualification Criteria",
      children: <NbmPreQualification tabKey="pre_qualification" />,
    },

    {
      key: "4",
      label: "Qualification Requirement",
      children: <NbmPreQualification tabKey="qualification" />,
    },
    {
      key: "5",
      label: "Technical Evaluation Criteria",
      children: <NbmTechnicalQualification />,
    },
    {
      key: "6",
      label: "Payment Terms",
      children: <NbmPaymentTerms />,
    },
    {
      key: "7",
      label: "Winning Themes for Accenture",
      children: (
        <ClientProfileBoardScopeWinningThemes tabKey="winning_themes_for_accenture" />
      ),
    },
  ];

  const navigate = useNavigate();

  const { rfpId } = useParams();

  const { pathname } = useLocation();

  const currentRfpId: number = rfpId ? Number(rfpId) : -1;

  const { rfpGenerationStatus } = useSelector(
    (state: RootState) => state.overview
  );

  useEffect(() => {
    if (
      rfpGenerationStatus?.nbm !== RFPGenerationStatus.COMPLETED &&
      pathname.includes(PATHS.nbm)
    ) {
      navigate(`/rfp/${currentRfpId}/${PATHS.overview}`);
    }
  }, [rfpGenerationStatus, pathname]);

  useEffect(() => {
    if (currentRfpId) {
      getGeneratedNbmById(currentRfpId, true);
    }
  }, [currentRfpId]);

  return (
    <Styles.AtiContainer>
      <Tabs tabBarStyle={{ overflow: "auto" }} items={NbmItems} />
    </Styles.AtiContainer>
  );
};

export default NBM;

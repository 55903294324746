import { store } from "../store";
import { deleteRequest, get, post } from "./apiClient";
import { RFPUploadSteps } from "../constants";
import {
  setMainFileUploadProgress,
  setSubFileUploadProgress,
  setUploadSteps,
  updateUploadedSubFiles,
} from "../store/rfpUpload/rfpUploadSlice";
import {
  autoRefreshRfpDoc,
  refreshedRfpDocByRfpId,
  removeDeletedSubFiles,
  setIsSubFileDelete,
  updateRfpDocuments,
} from "../store/allRfps/allRfpSlice";
import { IRfpDocuments } from "../store/allRfps/allRfp.interface";
import { toast } from "react-toastify";

export const UploadMainRfp = async (
  formData: FormData,
  userId: number
): Promise<any> => {
  try {
    const { data } = await post(
      `apa/rfp/?content_type=pdf&userId=${userId}`,
      formData,
      {
        onUploadProgress: function (progressEvent) {
          if (progressEvent?.loaded && progressEvent?.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            store.dispatch(setMainFileUploadProgress(percentCompleted));
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    store.dispatch(setUploadSteps(RFPUploadSteps.UPLOADED));
    return data[0]?.rfp_id;
  } catch (error: any) {
    console.log("UploadMainRfp api Error", error?.error);
  } finally {
    store.dispatch(setMainFileUploadProgress(0));
  }
};

export const UploadSubRfp = async (
  formData: FormData,
  rfpId: number,
  currentFileName: string,
  userId: number
) => {
  try {
    let { data } = await post(
      `apa/subFile/?content_type=pdf&rfpId=${rfpId}&userId=${userId}`,
      formData,
      {
        onUploadProgress: function (progressEvent) {
          if (progressEvent?.loaded && progressEvent?.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            store.dispatch(setSubFileUploadProgress(percentCompleted));
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    store.dispatch(updateRfpDocuments(data[0]));
    store.dispatch(updateUploadedSubFiles(currentFileName));
    store.dispatch(autoRefreshRfpDoc({ subFileId: data[0]?.sub_file_id }));
  } catch (error: any) {
    console.log("UploadSubRfp api Error", error?.error);
  } finally {
    store.dispatch(setSubFileUploadProgress(0));
  }
};

export const autoRefreshedRfpDocById = async (
  subFileId: number
): Promise<IRfpDocuments> => {
  try {
    const data = await get(`apa/subFile/${subFileId}`);
    store.dispatch(refreshedRfpDocByRfpId({ data, subFileId: subFileId }));

    return data;
  } catch (err) {
    console.log("autoRefreshedRfpDocById api error", err);
    throw err;
  }
};

export const deleteRfpSubFiles = async (subFileId: number) => {
  store.dispatch(setIsSubFileDelete(true));

  try {
    await deleteRequest(`apa/subFile/deleteSubFileInfo/${subFileId}`);
    store.dispatch(removeDeletedSubFiles(subFileId));
    toast.success("file deleted successfully");
  } catch (error) {
    console.log(error);
    toast.error("Failed to delete Asset");
    return error;
  } finally {
    store.dispatch(setIsSubFileDelete(false));
  }
};

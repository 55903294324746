import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";
import "./index.css";
import App from "./App.tsx";
import { lightTheme } from "./theme/theme.ts";
import { persistor, store } from "./store/index.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={lightTheme}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "GraphikRegular, sans-serif",
              colorPrimary: "#a100ff",
              borderRadius: 0,
            },
          }}
        >
          <App />
        </ConfigProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

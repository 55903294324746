import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IExtractLegalClauses,
  IGenerateLegalResponse,
  ILegal,
  ILegalDetails,
} from "./legal.interface";
import { ExtractionStatus } from "../../constants";
import { PURGE } from "redux-persist";
import { getLegalPageDetailById } from "../../services/legal";
import { store } from "..";

const initialState: ILegal = {
  legalDetails: {
    status: "",
    lid: -1,
    relevantPages: [],
    docId: -1,
    fileName: "",
    rfpId: -1,
  },
  isLegalDetailsLoading: false,
  extractLegalClauses: {
    status: null,
    legalSectionSummaryFilePreSignedUrl: "",
    extractionStatus: ExtractionStatus.NOT_STARTED,
    legalSectionSummaryFileName: "",
  },
  generateLegalResponse: {
    status: null,
    generatedResponseFilePreSignedUrl: "",
    generatedResponseFileName: "",
    generatedResponseFileStatus: ExtractionStatus.NOT_STARTED,
  },
  isDeleteExtractLegalFileLoading: false,
  stopLegalAutoRefresh: false,
  legalId: -1,
};

export const legalSlice = createSlice({
  name: "legalSlice",
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  initialState,
  reducers: {
    setLegalDetails: (state, action: PayloadAction<ILegalDetails>) => {
      state.legalDetails = action.payload;
    },

    setLegalId: (state, action: PayloadAction<number>) => {
      state.legalId = action.payload;
    },

    setIsLegalDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLegalDetailsLoading = action.payload;
    },

    setExtractLegalClauses: (
      state,
      action: PayloadAction<IExtractLegalClauses>
    ) => {
      state.extractLegalClauses = action.payload;
    },

    setExtractionStatus: (state, action: PayloadAction<ExtractionStatus>) => {
      state.extractLegalClauses = {
        status: null,
        legalSectionSummaryFilePreSignedUrl: "",
        extractionStatus: action.payload,
        legalSectionSummaryFileName: "",
      };
    },

    setGenerateLegalResponse: (
      state,
      action: PayloadAction<IGenerateLegalResponse>
    ) => {
      state.generateLegalResponse = action.payload;
    },

    setGenerateLegalResponseStatus: (
      state,
      action: PayloadAction<ExtractionStatus>
    ) => {
      state.generateLegalResponse = {
        status: null,
        generatedResponseFilePreSignedUrl: "",
        generatedResponseFileStatus: action.payload,
        generatedResponseFileName: "",
      };
    },

    setIsDeleteExtractLegalFileLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteExtractLegalFileLoading = action.payload;
    },

    setStopLegalAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.stopLegalAutoRefresh = action.payload;
    },

    autoRefreshLegal: (
      _,
      action: PayloadAction<{ lId: number; rfpId: number }>
    ) => {
      let res: IExtractLegalClauses;
      const interval = setInterval(async () => {
        res = await getLegalPageDetailById(
          action?.payload?.lId,
          action?.payload?.rfpId
        );

        store.getState().legal.stopLegalAutoRefresh &&
          store.getState().legal.stopLegalAutoRefresh === true &&
          clearInterval(interval);

        !!res &&
          res.extractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      }, 5000);
    },

    autoRefreshLegalGeneration: (
      _,
      action: PayloadAction<{ lId: number; rfpId: number }>
    ) => {
      let res: IGenerateLegalResponse;
      const interval = setInterval(async () => {
        res = await getLegalPageDetailById(
          action?.payload?.lId,
          action?.payload?.rfpId
        );

        store.getState().legal.stopLegalAutoRefresh &&
          store.getState().legal.stopLegalAutoRefresh === true &&
          clearInterval(interval);

        if (
          !!res &&
          res.generatedResponseFileStatus !== ExtractionStatus.PROCESSING &&
          res.generatedResponseFileStatus !== ExtractionStatus.STOPPING
        ) {
          clearInterval(interval);
        }
      }, 5000);
    },
  },
});

export const {
  setLegalId,
  setLegalDetails,
  autoRefreshLegal,
  setExtractionStatus,
  setExtractLegalClauses,
  setStopLegalAutoRefresh,
  setGenerateLegalResponse,
  setIsLegalDetailsLoading,
  autoRefreshLegalGeneration,
  setGenerateLegalResponseStatus,
  setIsDeleteExtractLegalFileLoading,
} = legalSlice.actions;

export default legalSlice.reducer;

import { Button, Form, Input, Skeleton, Tabs } from "antd";
import styled, { css } from "styled-components";
import { PrimaryButton, TertiaryButton } from "../../../shared";
import { ActiveStatus } from "../../../types";

export const PAContainer = styled.div`
  overflow: auto;
  font-size: 14px;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const ExportButton = styled(TertiaryButton)`
  margin: 30px 10px 0 auto;
`;

export const QualificationContent = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: 100%;
`;

export const GenerateCard = styled.div`
  margin: 30px auto;
  width: 96%;
  position: relative;
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  border: 1px solid #af68d947;
  padding: 30px 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .regenerate-btn {
    background: linear-gradient(118.09deg, #cf26eb 32.6%, #6d39f5 92.1%);
    border: 0px;
    padding-left: 30px;
    padding-right: 30px;
    img {
      filter: brightness(0) invert(1);
    }
    &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
      background: linear-gradient(118.09deg, #cf26eb 32.6%, #6d39f5 92.1%);
    }
  }
`;
export const GenerateHeader = styled.h2`
  position: absolute;
  top: -12px;
  left: 15px;
  background: #fff;
  padding: 0 5px;
  font-family: GraphikMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.8px;
  text-align: left;
  color: #45464e;
`;

export const GenerateButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const GeneratingTextWrap = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;

export const PrimaryTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0 15px;
    margin-bottom: 0px;
  }
  .ant-tabs-content {
    padding: 10px;
  }
  .ant-tabs-tab {
    padding: 10px 12px !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 20px;
  }
`;
export const TabContent = styled.div``;
export const Label = styled.div``;
// AboutCompany Style

export const PolicyNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`;

export const PolicyNote = styled.div`
  border: 1px solid #af68d9a3;
  background: #fff;
  padding: 15px 20px;
  position: relative;
  min-height: 85px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const AppLogo = styled.img`
  height: 21px;
  float: right;
  margin-top: 5px;
`;

export const PolicyNoteHeader = styled.div`
  margin-bottom: 20px;
`;

export const PolicyNoteHeaderTitle = styled.h2`
  font-family: GraphikMedium;
  font-size: 22px;
  font-weight: normal;
  line-height: 32px;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  margin-bottom: 8px;
`;

export const PolicyNoteHeaderSubTitle = styled.h4`
  font-family: GraphikMedium;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const PolicyNoteContent = styled.div`
  width: 100%;
`;

export const PolicyNoteContentTitle = styled.h3`
  font-family: GraphikMedium;
  font-size: 18px;
  font-weight: normal;
  line-height: 38px;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontColorOmega};
  border-bottom: 1px solid ${({ theme }) => theme.colors.fontColorOmega};
  margin-bottom: 15px;
`;

export const QuestionsKey = styled.div``;

export const QuestionsKeyTitle = styled.div``;

export const QuestionsList = styled.div`
  #OpportunityForm {
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-form-item-label {
      width: 200px;
      text-align: left;
      white-space: normal;
      margin-right: 10px;
      /* background: #f5f5f5; */
      /* padding: 0 10px; */
      align-items: center;
      display: flex;
      label {
        font-size: 13px;
        line-height: 16px;
        height: auto;
      }
    }
    .ant-form-item-control {
      width: calc(50% - 200px);
      flex: none;
    }
  }
`;

export const QuestionsItem = styled.div`
  margin-bottom: 15px;
`;

export const Question = styled.div`
  font-family: GraphikMedium;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
  color: #333333;
  display: flex;
  align-items: center;
`;

export const Answered = styled.div`
  font-family: "GraphikRegular";
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #333333;
  ul {
    padding-left: 15px;
  }
`;

export const EditButton = styled(Button)`
  height: 28px;
  font-size: 12px;
  margin-left: auto;
  display: flex;
`;

export const SaveDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 5px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const FooterCopyright = styled.div`
  font-family: "GraphikRegular";
  font-size: 13px;
  font-weight: 400;
  line-height: 14.3px;
  letter-spacing: -0.02em;
  text-align: left;
  border-right: 0.5px solid ${({ theme }) => theme.colors.fontColorRho};
  padding-right: 10px;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.colors.fontColorRho};
`;
export const FooterRight = styled.div`
  font-family: "GraphikRegular";
  font-size: 11px;
  font-weight: 400;
  line-height: 12.1px;
  letter-spacing: -0.02em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.colors.fontColorRho};
`;

export const InputSummary = styled(Input.TextArea)`
  // height: 300px !important;
  border: "1px solid #b829ff";
  /* &.ant-input {
    resize: none;
    border: none;
    outline: none;
    box-shadow: none;

    ${(props) =>
    props.readOnly &&
    css`
      border: none;
      outline: none;
      &:hover {
        border: none;
        outline: none;
      }
    `}
  } */
`;

export const InputSummarySkeleton = styled(Skeleton)`
  height: 300px !important;
`;

export const AntdFormItem = styled(Form.Item)`
  margin-bottom: 15px;
`;

export const NewOpportunityFormItem = styled(Form.Item)<ActiveStatus>`
  margin-bottom: ${({ $active }) => ($active ? "10px" : "0px")};
`;

export const GeneratingText = styled.div`
  font-size: 11px;
`;

export const EngagementMPPGCLContainer = styled.div`
  margin-top: 15px;
`;

export const EditPolicyButton = styled(PrimaryButton)`
  margin-top: 15px;
`;

export const SkeletonLoading = styled(Skeleton)`
  padding: 10px 0;
`;

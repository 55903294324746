import { toast } from "react-toastify";
import { deleteRequest, get, put } from "./apiClient";

export const getAllProposalsDetails = async (rfpId: string | undefined) => {
  try {
    const res = await get(`apa/profile/${rfpId}/proposals`);
    return res;
  } catch (error: unknown) {
    throw error;
  }
};

export const updateRelevantPages = async (
  id: string | number,
  request: any,
  message: string
) => {
  try {
    const res = await put(`/apa/paymentTerms/${id}/pages`, request);
    if (res.status === 200) {
      toast.success(message);
    }
  } catch (error: unknown) {
    console.log("Error while updating pages", error);
    throw error;
  }
};

export const extractpaymentTermsDetails = async (
  id: number | string,
  fileName: string
) => {
  try {
    const res = await put(
      `/apa/paymentTerms/${id}/extract-payment-terms-details/test`, // TODO: remove test once we have implement with actual data
      {},
      { params: { rfpFileName: fileName } }
    );
    return res;
  } catch (error: unknown) {
    console.log("extract payment term Details", error);
    throw error;
  }
};

export const getPaymentTermsDetails = async (id: number) => {
  try {
    const res = await get(`/apa/paymentTerms/${id}`);
    return res;
  } catch (error: unknown) {
    console.log("Get paymentTerms Details", error);
    throw error;
  }
};

export const deleteExtractionFile = async (id: number) => {
  try {
    const res = await deleteRequest(`paymentTerms/details-file/${id}`);
    return res;
  } catch (error) {
    console.log("Error while deleting extraction file", error);
    return error;
  }
};

import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button, Col, Empty, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import * as aiBStyles from "./styles";
import { Role } from "../../../constants";
import { RootState } from "../../../store";
import * as Styles from "../promoterResearcher/styles";
import ConfirmationModal from "../../common/confirmModal/ConfirmationModal";
import { IMAGES, getFormattedDateByType, getUserRole } from "../../../shared";
import { setBulletinDate } from "../../../store/investmentResercher/investmentResercherSlice";
import {
  addPromoter,
  deletePromoter,
  getPromotersListByDate,
  subsUnsubsBulletin,
  getsubscriptionStatus,
  getPromotersLists,
} from "../../../services/investmentResearcher";
import {
  IPromotorsData,
  ILink,
  IPromotorsList,
  IFailedPromotersDataByDate,
} from "../../../store/investmentResercher/investmentResercher.interface";

const AIBulletin = () => {
  const [isUnsubsModalOpen, setIsUnsubsModalOpen] = useState(false);
  const [isDeleteModal, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItem] = useState<number | null>(null);
  const [isAddEnable, setAddEnable] = useState(false);
  const [newPromoter, setNewPromoter] = useState("");
  const [isSubsWarnModalOpen, setIsSubsWarnModalOpen] = useState(false);

  const {
    bulletinDate,
    promotorsList,
    subscribeStatus,
    promotorsDataByDate,
    isSubsUnSubsLoading,
    isPromoterAddLoading,
    isGetPromoterLoading,
    isPromoterDeleteLoading,
    failedPromotersDataByDate,
  } = useSelector((state: RootState) => state.investmentResearcher);
  const { userId } = useSelector((state: RootState) => state.authSlice);

  const dispatch = useDispatch();
  const currDate = String(dayjs().format("YYYY-MM-DD"));
  const prevDate = String(dayjs().add(-1, "d").format("YYYY-MM-DD"));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6.5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getPromotersListByDate(bulletinDate);
    getPromotersLists(true);
  }, []);

  const handleSubsUnSubsBulletin = async (isSubscribed: string) => {
    const res = await subsUnsubsBulletin(isSubscribed, userId);

    if (res?.data?.isSubscribed === "TRUE") {
      setIsSubsWarnModalOpen(true);
    }
    setIsUnsubsModalOpen(false);
  };

  const handleDeletePromoter = async (): Promise<void> => {
    await deletePromoter(deleteItemId);
    setDeleteItem(null);
    setDeleteModalOpen(false);
  };

  const handleAddPromoter = async (): Promise<void> => {
    if (newPromoter.trim()) {
      await addPromoter({ promoter_name: newPromoter });
      setAddEnable(false);
      setNewPromoter("");
    } else {
      toast.error("Please fill promoter field.");
    }
  };

  const handlePrevDate = async () => {
    dispatch(setBulletinDate(prevDate));
    await getPromotersListByDate(prevDate);
  };

  const handleNextDate = async () => {
    dispatch(setBulletinDate(currDate));
    await getPromotersListByDate(currDate);
  };

  useEffect(() => {
    getsubscriptionStatus(userId);
  }, []);

  const renderPromotersCovered = () => {
    return (
      <aiBStyles.PromotersCoveredWrap>
        <aiBStyles.PromotersCoveredTitle>
          Promoters Covered
        </aiBStyles.PromotersCoveredTitle>
        <aiBStyles.PromotersCoveredList>
          {isGetPromoterLoading ? (
            <aiBStyles.BulletinSkeleton active />
          ) : promotorsList && promotorsList.length ? (
            promotorsList.map((promotor: IPromotorsList, index: number) => (
              <aiBStyles.PromotersCoveredItem key={`promotor-${index}`}>
                {promotor.promoter_name}
                {getUserRole() === Role.Admim && (
                  <aiBStyles.SelectAction
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: `${index + 1}`,
                          label: "Delete",
                          onClick: () => [
                            setDeleteItem(promotor.promoter_id),
                            setDeleteModalOpen(true),
                          ],
                        },
                      ],
                    }}
                    placement="bottomRight"
                    arrow={false}
                  >
                    <img src={IMAGES.actionMenu} alt="Dots Icon" />
                  </aiBStyles.SelectAction>
                )}
              </aiBStyles.PromotersCoveredItem>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </aiBStyles.PromotersCoveredList>

        {getUserRole() === Role.Admim && (
          <aiBStyles.ActionMode>
            {!isAddEnable && !isGetPromoterLoading && (
              <aiBStyles.AddPromoter onClick={() => setAddEnable(true)}>
                <img src={IMAGES.roundPlus} alt="Dots Icon" /> Add Promoter
              </aiBStyles.AddPromoter>
            )}

            {/* Input mode Promoter */}
            {isAddEnable && (
              <aiBStyles.InputWrap>
                <aiBStyles.InputField
                  placeholder="Type in name"
                  value={newPromoter}
                  onChange={(e) => setNewPromoter(e.target.value)}
                />
                <aiBStyles.InputFieldAction>
                  <aiBStyles.InputButton
                    loading={isPromoterAddLoading}
                    onClick={handleAddPromoter}
                    className="check-outlined"
                  >
                    <CheckOutlined />
                  </aiBStyles.InputButton>
                  <aiBStyles.InputButton
                    onClick={() => [setAddEnable(false), setNewPromoter("")]}
                    className="close-outlined"
                  >
                    <CloseOutlined />
                  </aiBStyles.InputButton>
                </aiBStyles.InputFieldAction>
              </aiBStyles.InputWrap>
            )}
          </aiBStyles.ActionMode>
        )}
      </aiBStyles.PromotersCoveredWrap>
    );
  };

  const renderPromoterData = () => {
    if (isGetPromoterLoading) {
      return (
        <Styles.ShowingResultsContent>
          <Styles.ResultsCard>
            <aiBStyles.BulletinSkeleton active />
          </Styles.ResultsCard>
        </Styles.ShowingResultsContent>
      );
    } else if (promotorsDataByDate && promotorsDataByDate.length) {
      return (
        <Styles.ShowingResultsContent>
          {promotorsDataByDate.map(
            (promotor: IPromotorsData, index: number) => (
              <Styles.ResultsCard key={`promotor-${index}`}>
                <Styles.ResultsTitle>
                  <img className="open-book" src={IMAGES.openBook} />{" "}
                  {promotor?.title}
                </Styles.ResultsTitle>
                <Styles.ResultsDescription>
                  <p>{promotor?.summary}</p>
                </Styles.ResultsDescription>
                <Styles.SliderCarousel {...settings}>
                  {promotor?.links?.map((link: ILink) => (
                    <Styles.ResultsSliderCard key={link?.linkId}>
                      <Link to={link?.link} target="_blank">
                        <Styles.ResultsSliderTitle>
                          {link?.title}
                        </Styles.ResultsSliderTitle>
                        <Styles.ResultsSliderDescription>
                          {link?.link}
                        </Styles.ResultsSliderDescription>
                        <img className="viewLink" src={IMAGES.viewLink} />
                      </Link>
                    </Styles.ResultsSliderCard>
                  ))}
                </Styles.SliderCarousel>
              </Styles.ResultsCard>
            )
          )}
          {/* Failed promoters */}
          {failedPromotersDataByDate?.length &&
            failedPromotersDataByDate?.map(
              (promotor: IFailedPromotersDataByDate, index: number) => (
                <Styles.ResultsCard key={`promotor-${index}`}>
                  <Styles.ResultsTitle>
                    <img className="open-book" src={IMAGES.openBook} />{" "}
                    {promotor?.promoterName}
                  </Styles.ResultsTitle>
                  <Styles.ErrorDescription>
                    <p>{promotor?.errorDetail ?? "Failed to fetch details"}</p>
                  </Styles.ErrorDescription>
                </Styles.ResultsCard>
              )
            )}
        </Styles.ShowingResultsContent>
      );
    } else {
      return (
        <Styles.ShowingResultsContent>
          <Styles.EmptyStateWrap>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Styles.EmptyStateWrap>
        </Styles.ShowingResultsContent>
      );
    }
  };

  return (
    <>
      <aiBStyles.aIBulletinTab className="aIBulletinTab">
        <Row gutter={15}>
          <Col span={19}>
            <Styles.scrollPage className="scrollpage">
              <Styles.ShowingResultsWrap className="ShowingResultsWrap">
                <Styles.ShowingResultsHeader>
                  <Styles.ShowingResultsTitle>
                    {bulletinDate
                      ? getFormattedDateByType(bulletinDate, "type1")
                      : ""}
                  </Styles.ShowingResultsTitle>
                  <Styles.ShowingResultsFilter>
                    {subscribeStatus === "TRUE" ? (
                      <aiBStyles.UnSubsBtn
                        onClick={() => setIsUnsubsModalOpen(true)}
                        loading={isSubsUnSubsLoading}
                      >
                        Unsubscribe
                      </aiBStyles.UnSubsBtn>
                    ) : (
                      <aiBStyles.FilterButton
                        onClick={() => handleSubsUnSubsBulletin("TRUE")}
                        loading={isSubsUnSubsLoading}
                      >
                        Subscribe
                      </aiBStyles.FilterButton>
                    )}

                    <aiBStyles.FilterButton
                      className="line-button"
                      onClick={handlePrevDate}
                      disabled={bulletinDate === prevDate}
                    >
                      <img
                        className="previous-arrow"
                        src={IMAGES.previousArrow}
                      />
                      Previous Date
                    </aiBStyles.FilterButton>
                    <aiBStyles.FilterButton
                      className="line-button"
                      onClick={handleNextDate}
                      disabled={
                        bulletinDate === "" || bulletinDate === currDate
                      }
                    >
                      <img className="next-arrow" src={IMAGES.previousArrow} />
                      Next Date
                    </aiBStyles.FilterButton>
                  </Styles.ShowingResultsFilter>
                </Styles.ShowingResultsHeader>

                {renderPromoterData()}
              </Styles.ShowingResultsWrap>
            </Styles.scrollPage>
          </Col>

          <Col span={5}>{renderPromotersCovered()}</Col>
        </Row>
      </aiBStyles.aIBulletinTab>

      {/* unsubscribe Modal  */}
      <aiBStyles.SubscribeModal
        centered
        open={isUnsubsModalOpen}
        onOk={() => handleSubsUnSubsBulletin("FALSE")}
        onCancel={() => setIsUnsubsModalOpen(false)}
        className="unsubscribed-modal"
        rootClassName="subscribe-modal"
        title={`Are you sure you want to unsubscribe`}
        footer={
          <>
            <Button
              className="btn-type1"
              onClick={() => setIsUnsubsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className="ant-btn-dangerous"
              loading={isSubsUnSubsLoading}
              onClick={() => handleSubsUnSubsBulletin("FALSE")}
            >
              Yes
            </Button>
          </>
        }
      >
        <div>You will stop receiving daily email summary.</div>
      </aiBStyles.SubscribeModal>

      {/* Subscribe Modal  */}
      <aiBStyles.SubscribeModal
        centered
        open={isSubsWarnModalOpen}
        onOk={() => setIsSubsWarnModalOpen(false)}
        onCancel={() => setIsSubsWarnModalOpen(false)}
        className="unsubscribed-modal subscribed-modal"
        rootClassName="subscribe-modal"
        title={"You have subscribed to the bulletin."}
        closeIcon={false}
        footer={
          <>
            <Button
              className="ant-btn-dangerous"
              onClick={() => setIsSubsWarnModalOpen(false)}
            >
              OK
            </Button>
          </>
        }
      >
        <div>You will start receiving daily email with the summary.</div>
      </aiBStyles.SubscribeModal>

      <ConfirmationModal
        title="Delete"
        text="Are you sure you want to delete this?"
        isOpen={isDeleteModal}
        handleOk={() => handleDeletePromoter()}
        handleCancel={() => setDeleteModalOpen(false)}
        isLoading={isPromoterDeleteLoading}
        btnText="Delete"
      />
    </>
  );
};

export default AIBulletin;

import styled from "styled-components";
import { Select } from "antd";

export const SelectWrap = styled.div`
  display: flex;
  gap: 15px;
  margin-right: 8px;
`;

export const StyledSelect = styled(Select)`
  && .ant-select-selector {
    border: 1px solid rgba(109, 96, 164, 0.4);
    height: 33px;
    width: 250px;
    padding-inline-end: 20px;
  }

  && .ant-select-selection-item {
    font-family: "GraphikRegular";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #5c5b5b;
  }

  .ant-select-selection-placeholder {
    color: #5c5b5b;
    font-family: "GraphikMedium";
  }
  .ant-select-selection-item-content {
    max-width: 52px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-select .ant-select-arrow {
    right: 5px;
  }
`;

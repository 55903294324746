import { Badge, Button, Input } from "antd";
import styled from "styled-components";

export const PaymentTermsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 145px);
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 12px;
  overflow-y: auto;
`;

export const Details = styled.div`
  margin-top: 20px;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  position: relative;
  margin-bottom: 16px;
`;

export const TitlePage = styled.div`
  top: -12px;
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 10px;
  left: 20px;
  position: relative;
  padding: 0 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
`;

export const PagesContainer = styled.div`
  margin: 5px 0px 0px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 10px;
`;

export const BadgeContainer = styled.div`
  margin: 2px 2px 2px 2px;
`;

export const BadgeCustom = styled(Badge)`
  background: #f4f6fc;
  .anticon {
    background: #9f9f9f;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
  }
`;

export const PageBox = styled.div<any>`
  border: 1px solid
    ${({ theme, isedit }) =>
      isedit ? theme.colors.fontColorEpsilon : "#d2deff"};
  display: flex;
  align-items: center;
  padding: 0px 8px;
  gap: 20px;
`;

export const PageBoxViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0px;
  width: 150px;
  height: 52px;
`;

export const PageBoxEditContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 102px;
`;

export const PageBoxSubContainer = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
`;

export const FromTO = styled.span`
  color: #5c5b5b;
  font-size: 10px;
`;

export const AntInput = styled(Input)`
  &.ant-input {
    padding: 0px;
    width: 45px;
    font-family: "GraphikMedium", sans-serif;
  }
  &.ant-input-outlined {
    background: transparent;
    border: none;
  }
  &.ant-input-outlined:focus,
  :where(
      .css-dev-only-do-not-override-1v67itz
    ).ant-input-outlined:focus-within {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  &.ant-input-outlined:hover {
    background: transparent;
  }
`;

export const BorderRight = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const ButtonLink = styled(Button)`
  &.ant-btn {
    padding: 0;
    font-size: 12px;
  }
  &.ant-btn > span {
    text-decoration: underline;
  }
  &.ant-btn-link {
    color: #194dea;
  }
`;

export const PageText = styled.span`
  font-family: "GraphikMedium", sans-serif;
  font-size: 12px;
`;

export const ReleventPagesFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GraphikMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  margin: 0px 25px 20px 3px;
  justify-content: space-between;
`;

export const BtnStyling = styled.div`
  margin: 14px 0px 0px 29px;
`;

export const FooterRightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
`;

export const ExtractLegalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GenerateText = styled.span`
  font-size: 12px;
`;

export const ExtractButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  background: linear-gradient(107deg, #cf26eb 33.23%, #6d39f5 87.55%);
  flex-shrink: 0;

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  }

  img {
    height: 16px;
  }
`;

export const FileText = styled.div`
  font-size: 12px;
`;

export const DeleteButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AIIcon = styled.span`
  margin-left: 5px;
`;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUser, IUserSliceProps } from "./user.interface";
import { PageLimit, AdminTabs } from "../../constants";

const initialState: IUserSliceProps = {
  users: [],
  isAllUsersLoading: false,
  currentPage: 1,
  pageLimit: PageLimit.UserManagement,
  totalUsersCount: 0,
  searchText: "",
  adminTab: AdminTabs.User,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setAllUsers: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },

    setUIsAllUsersLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllUsersLoading = action.payload;
    },

    setUserCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    setUserPageLimit: (state, action: PayloadAction<number>) => {
      state.pageLimit = action.payload;
    },

    setResetUserPaginationAndSearch: (state) => {
      state.currentPage = 1;
      state.pageLimit = PageLimit.UserManagement;
      state.searchText = "";
    },

    setTotalUsersCount: (state, action: PayloadAction<number>) => {
      state.totalUsersCount = action.payload;
    },

    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },

    setAdminTab: (state, action: PayloadAction<string>) => {
      state.adminTab = action.payload;
    },
  },
});

export const {
  setAllUsers,
  setUIsAllUsersLoading,
  setUserCurrentPage,
  setUserPageLimit,
  setResetUserPaginationAndSearch,
  setTotalUsersCount,
  setSearchText,
  setAdminTab,
} = userSlice.actions;

export default userSlice.reducer;

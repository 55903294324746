import { toast } from "react-toastify";
import { store } from "../store";
import {
  IExtractLegalClauses,
  IGenerateLegalResponse,
  ILegalDetails,
  ILegalRelevantPages,
} from "../store/legal/legal.interface";
import {
  setLegalDetails,
  setExtractionStatus,
  setExtractLegalClauses,
  setIsLegalDetailsLoading,
  setGenerateLegalResponseStatus,
  setGenerateLegalResponse,
  setIsDeleteExtractLegalFileLoading,
  autoRefreshLegal,
  autoRefreshLegalGeneration,
  setStopLegalAutoRefresh,
} from "../store/legal/legalSlice";
import { deleteRequest, get, post, put } from "./apiClient";
import {
  ExtractionStatus,
  RFPGenerationKey,
  RFPGenerationStatus,
} from "../constants";
import { updateRfpGenerationStatus } from "../store/overview/overviewSlice";

export const getAllLegalPages = async (rfpId: number) => {
  try {
    store.dispatch(setIsLegalDetailsLoading(true));
    store.dispatch(setStopLegalAutoRefresh(false));
    const res = await get(`apa/profile/${rfpId}/proposals`);

    const legalRes: ILegalDetails = {
      docId: res?.legal?.docId,
      relevantPages: res?.legal?.relevantPages,
      fileName: res?.rfp?.file_name,
      lid: res?.legal?.lid,
      rfpId: res?.rfpId,
      status: res?.legal?.status,
    };

    store.dispatch(setLegalDetails(legalRes));

    const extractRes: IExtractLegalClauses = {
      status: null,
      legalSectionSummaryFilePreSignedUrl:
        res?.legal?.legalSectionSummaryFilePreSignedUrl,
      extractionStatus: res?.legal?.extractionStatus,
      legalSectionSummaryFileName: res?.legal?.legalSectionSummaryFileName,
    };

    extractRes?.extractionStatus === ExtractionStatus.PROCESSING &&
      store.dispatch(
        autoRefreshLegal({ lId: res?.legal?.lid, rfpId: res?.rfpId })
      );

    store.dispatch(setExtractLegalClauses(extractRes));

    const generatedRes: IGenerateLegalResponse = {
      status: res?.legal?.status,
      generatedResponseFileName: res?.legal?.generatedResponseFileName,
      generatedResponseFilePreSignedUrl:
        res?.legal?.generatedResponseFilePreSignedUrl,
      generatedResponseFileStatus: res?.legal?.generatedResponseFileStatus,
    };

    if (!!res?.legal?.generatedResponseFileName) {
      store.dispatch(setGenerateLegalResponse(generatedRes));
    } else {
      const initialState = {
        status: null,
        generatedResponseFilePreSignedUrl: "",
        generatedResponseFileName: "",
        generatedResponseFileStatus: ExtractionStatus.NOT_STARTED,
      };
      store.dispatch(setGenerateLegalResponse(initialState));
    }

    res?.legal?.generatedResponseFileStatus === ExtractionStatus.PROCESSING &&
      store.dispatch(
        autoRefreshLegalGeneration({ lId: res?.legal?.lid, rfpId: res?.rfpId })
      );
  } catch (error: any) {
    console.log(error, "getAllLegalPages api error");
  } finally {
    store.dispatch(setIsLegalDetailsLoading(false));
  }
};

export const updateLegalPages = async (
  id: number,
  request: ILegalRelevantPages[],
  message: string
) => {
  try {
    await put(`/apa/legal/${id}/pages`, request);
    toast.success(message);
  } catch (error: any) {
    console.log(error, "updateLegalPages api error");
    toast.error(error?.message ?? "Failed to update legal pages");
  }
};

export const getLegalPageDetailById = async (lId: number, rfpId: number) => {
  try {
    const res = await get(`/apa/legal/${lId}`);

    const extractRes: IExtractLegalClauses = {
      status: res?.status,
      legalSectionSummaryFilePreSignedUrl:
        res?.legalSectionSummaryFilePreSignedUrl,
      extractionStatus: res?.extractionStatus,
      legalSectionSummaryFileName: res?.legalSectionSummaryFileName,
    };

    store.dispatch(setExtractLegalClauses(extractRes));

    const generatedRes: IGenerateLegalResponse = {
      status: res?.status,
      generatedResponseFileName: res?.generatedResponseFileName,
      generatedResponseFilePreSignedUrl: res?.generatedResponseFilePreSignedUrl,
      generatedResponseFileStatus: res?.generatedResponseFileStatus,
    };

    store.dispatch(setGenerateLegalResponse(generatedRes));

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Legal,
        value: res?.extractionStatus,
      })
    );

    return res;
  } catch (error: any) {
    console.log("getLegalPageDetailById", error);

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Legal,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
  }
};

export const extractLegalDetails = async (
  request: { fileName: string },
  lId: number,
  rfpId: number
) => {
  try {
    store.dispatch(setExtractionStatus(ExtractionStatus.PROCESSING));

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Legal,
        value: RFPGenerationStatus.PROCESSING,
      })
    );

    await put(`/apa/legal/extract-legal-details`, request);

    store.dispatch(autoRefreshLegal({ lId, rfpId }));
  } catch (err: any) {
    console.log("extractLegalDetails", err);
    store.dispatch(setExtractionStatus(ExtractionStatus.NOT_STARTED));

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Legal,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );

    toast.error(
      (err.message || err.error) ?? "Failed to extract legal response"
    );
  }
};

export const generateResponse = async (
  aiRequest: {
    rfpId: number;
    contentType: string;
    preSignedUrl: string;
    lId: number;
  },
  legalProcessingStatus: ExtractionStatus,
  formData: FormData,
  uploadedContentType: string
) => {
  try {
    store.dispatch(setGenerateLegalResponseStatus(ExtractionStatus.PROCESSING));
    legalProcessingStatus === ExtractionStatus.AI
      ? await post(
          `/apa/legal/generate-response`,
          {},
          {
            params: {
              rfpId: aiRequest?.rfpId,
              contentType: aiRequest?.contentType,
              preSignedUrl: aiRequest?.preSignedUrl,
            },
          }
        )
      : await post(`/apa/legal/generate-response`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            rfpId: aiRequest?.rfpId,
            contentType: uploadedContentType,
          },
        });

    // let interval = setInterval(async () => {
    //   try {
    //     const generatedRes = await getLegalPageDetailById(
    //       aiRequest?.lId,
    //       aiRequest?.rfpId
    //     );
    //     store.dispatch(setGenerateLegalResponse(generatedRes));

    //     if (
    //       !!generatedRes &&
    //       generatedRes.generatedResponseFileStatus !==
    //         ExtractionStatus.PROCESSING &&
    //       generatedRes.generatedResponseFileStatus !== ExtractionStatus.STOPPING
    //     ) {
    //       clearInterval(interval);
    //     }
    //   } catch {
    //     clearInterval(interval);
    //     store.dispatch(setGenerateLegalResponseStatus(ExtractionStatus.FAILED));
    //   }
    // }, 10000);

    store.dispatch(
      autoRefreshLegalGeneration({
        lId: aiRequest?.lId,
        rfpId: aiRequest?.rfpId,
      })
    );
  } catch (err: any) {
    console.log("generateResponse", err);
    toast.error(
      (err.message || err.error) ?? "Failed to generate legal response"
    );
    store.dispatch(
      setGenerateLegalResponseStatus(ExtractionStatus.NOT_STARTED)
    );
  }
};

export const deleteLegalExtractedFile = async (lId: number) => {
  try {
    store.dispatch(setIsDeleteExtractLegalFileLoading(true));

    await deleteRequest(`/apa/legal/details-file/${lId}`);

    const extractRes: IExtractLegalClauses = {
      status: null,
      legalSectionSummaryFilePreSignedUrl: "",
      extractionStatus: ExtractionStatus.NOT_STARTED,
      legalSectionSummaryFileName: "",
    };

    store.dispatch(setExtractLegalClauses(extractRes));

    const generatedRes: IGenerateLegalResponse = {
      status: null,
      generatedResponseFileName: "",
      generatedResponseFilePreSignedUrl: "",
      generatedResponseFileStatus: ExtractionStatus.NOT_STARTED,
    };

    store.dispatch(setGenerateLegalResponse(generatedRes));

    toast.success("File deleted successfully");
  } catch (error: any) {
    console.log("deleteLegalExtractedFile", error);
    toast.error(
      (error.message || error.error) ?? "Failed to delete legal file"
    );
  } finally {
    store.dispatch(setIsDeleteExtractLegalFileLoading(false));
  }
};

import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Form, Input, Row } from "antd";
import styled, { css } from "styled-components";

export const PAContainer = styled.div`
  overflow: auto;
  font-size: 14px;
  height: 100%;
  padding: 10px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const QualificationContent = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: 100%;
  padding: 12px;
  overflow-y: auto;
`;

export const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SaveDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Details = styled.div`
  margin-top: 20px;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  position: relative;
  margin-bottom: 16px;
`;

export const AntFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0px;
  }
`;

export const AntRow = styled(Row)`
  padding: 10px 10px 10px 20px;
`;

export const AntCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const FinanceSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 0 10px 35px;
  margin-bottom: 16px;
  height: 210px;
  position: relative;
`;

export const PolicyInputRequired = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 0px 0px 0px;
  margin-bottom: 16px;
  position: relative;
`;

export const FinanceInputBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 10px 10px 35px;
  margin-bottom: 10px;
  height: 200px;
`;

export const Text = styled.div`
  margin-top: 12px;
`;

export const FinanceBox = styled.div`
  overflow: auto;
  height: 100%;
  background-color: #f4f6fc;
  position: relative;
  width: 99%;
`;

export const FinanceBoxContent = styled.div`
  margin: 12px 0px 0px 12px;
`;

export const Title = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 10px;
  position: relative;
  bottom: -12px;
  font-size: 14px;
  left: 20px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  z-index: 1;
`;

export const TitlePage = styled.div`
  top: -12px;
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 10px;
  left: 20px;
  position: relative;
  padding: 0 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
`;

export const InputFormItem = styled(Form.Item);

export const InputSummary = styled(Input.TextArea)`
  margin-top: 10px;
  &.ant-input {
    resize: none;
    height: 165px;
    ${(props) =>
      props.readOnly &&
      css`
        border: none;
        &:hover {
          border: none;
        }
      `}
  }
`;

export const InputPages = styled(Input)`
  &.ant-input {
    resize: none;
    border: none;
    height: 14px !important;
    width: 53px;
    border: 1px solid ${({ theme }) => theme.colors.backgroundColoZeta};
    background: ${({ theme }) => theme.colors.backgroundColoZeta};
    font-family: GraphikBold;
    font-weight: 600;
    margin-top: 4px;
  }
`;

export const EditFinance = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const CancelImage = styled.div`
  position: absolute;
  top: -5px;
  right: -6px;
  cursor: pointer;
`;

export const NoFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.fontColorEta};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 159%;
  flex-direction: column;
`;
export const FileTextOutlinedIcon = styled(FileTextOutlined)`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
`;
export const VerticalLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 37%;
  border-left: 1px solid ${({ theme }) => theme.colors.borderColorTheta}; /* Adjust the color and style as needed */
`;

export const HalfVerticalLine = styled.div`
  margin-right: 7px;
  margin-top: 4px;
`;

export const PageCol = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 8%;
  color: ${({ theme }) => theme.colors.fontColorEta};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ColTitle = styled.div`
  margin: -1px 29px 0px 3px;
`;

export const FinanceSummaryCliped = styled.div`
  display: flex;
  gap: 8px;
  margin: 100px 0 0 0;
`;

export const FinanceInputCliped = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 199px;
  margin: 0px 0px 0px 55px;
  height: 38px;
  border: 1px solid #d2deff;
  background: #ffffff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 7px;
  box-shadow: 0px 4px 4px 0px #dbdee7;
  color: var(--Black, #333);
  font-family: GraphikMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 10px;
`;

export const ReleventPagesFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GraphikMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  margin: 0px 25px 20px 3px;
  justify-content: space-between;
`;

export const FooterRightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BadgeContainer = styled.div`
  margin: 2px 2px 2px 2px;
`;

export const BadgeCustom = styled(Badge)`
  background: #f4f6fc;
  .anticon {
    background: #9f9f9f;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
  }
`;
export const PageText = styled.span`
  font-family: "GraphikMedium", sans-serif;
  font-size: 12px;
`;

export const ButtonLink = styled(Button)`
  &.ant-btn {
    padding: 0;
    font-size: 12px;
  }
  &.ant-btn > span {
    text-decoration: underline;
  }
  &.ant-btn-link {
    color: #194dea;
  }
`;

export const PageBoxSubContainer = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  // gap: 4px;
`;

export const BorderRight = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const FromTO = styled.span`
  color: #5c5b5b;
`;

export const AntInput = styled(Input)`
  &.ant-input {
    padding: 0px;
    width: 45px;
    font-family: "GraphikMedium", sans-serif;
  }
  &.ant-input-outlined {
    background: transparent;
    border: none;
  }
  &.ant-input-outlined:focus,
  :where(
      .css-dev-only-do-not-override-1v67itz
    ).ant-input-outlined:focus-within {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  &.ant-input-outlined:hover {
    background: transparent;
  }
`;

export const PageBox = styled.div<{ $isEdit: number }>`
  border: 1px solid
    ${({ theme, $isEdit }) =>
      $isEdit ? theme.colors.fontColorEpsilon : "#d2deff"};
  display: flex;
  align-items: center;
  padding: 0px 8px;
  gap: 20px;
`;

export const PageBoxViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0px;
  width: 150px;
  height: 52px;
`;

export const PageBoxEditContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 102px;
`;

export const PagesContainer = styled.div`
  margin: 5px 0px 0px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 10px;
`;

export const FileText = styled.div``;

export const BtnStyling = styled.div`
  margin: 14px 0px 0px 29px;
`;

export const DeleteIcons = styled.span``;

export const DeleteButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoteContainer = styled.div`
  border-radius: 2px;
  border: 0.5px solid #f1d6ff;
  background: #f8ebff;
  box-shadow: 0px 4px 4px 0px #fbf3ff;
  font-size: 10px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const NoteTitle = styled.span`
  font-family: "GraphikMedium", sans-serif;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  padding-right: 5px;
`;

export const NoteDescription = styled.div``;

export const ExtractButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  background: linear-gradient(107deg, #cf26eb 33.23%, #6d39f5 87.55%);
  flex-shrink: 0;
  &:hover {
    color: ${({ theme }) => theme.colors.backgroundColorAlpha}!important;
  }

  img {
    height: 16px;
  }
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr;
  /* border: 0.1px solid red; */
`;

export const RigthSide = styled.div`
  display: flex;
  justify-content: start;
`;

export const LeftSide = styled.div``;

// export const FinanceTitle = styled.div`
//   display: flex;
//   justify-content: space-around;
// `;

// export const TitleText = styled.div`
//   font-size: 12px;
//   font-weight: 400;
//   margin-right: 130px;
//   padding: 7px;
// `;

export const QualificationHeader = styled.div`
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 20px 10px 35px;
  height: 100%;
  min-height: 240px;
  margin-bottom: 16px;
`;

export const QualificationDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SerialNum = styled.div`
  width: 33px;
  font-size: 12px;
  font-family: GraphikMedium;
  font-weight: 500;
  height: 33px;
  border-radius: 50px;
  border: 0.1px solid #f4edf9;
  background-color: #f4edf9;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  font-size: 14px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const Action = styled.div`
  height: 100%;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditOutlinedIcon = styled(EditOutlined)`
  font-size: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const RigthSideFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 8px;
`;

// export const TertiaryButton = styled(Button)`
//   color: ${({ theme }) => theme.colors.fontColorEpsilon};
//   font-family: "GraphikMedium";
//   font-size: 12px;
//   font-weight: 500;
// `;

// export const DropDown = styled(Dropdown)`
//   margin-right: 15px;
//   cursor: pointer;
//   color: ${({ theme }) => theme.colors.fontColorEpsilon};
// `;

export const DropText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-left: 10px;
  }
`;

// export const Anchor = styled.a`
//   font-size: 12px;
//   font-weight: 400;
// `;

export const ExtractLegalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GenerateText = styled.span`
  font-size: 12px;
`;

// new
export const PolicyHeader = styled.div`
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 20px 10px 35px;
  height: 100%;
  min-height: 240px;
  margin-bottom: 16px;
`;

export const PolicyDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AIIcon = styled.span`
  margin-left: 5px;
`;

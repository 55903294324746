import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Checkbox,
  Dropdown,
  Input,
  Popover,
  Select,
  Switch,
  Table,
} from "antd";
import styled from "styled-components";
import { PrimaryButton } from "../../shared";
import { AssetUploadStatus } from "../../constants";

interface ISelectAction {
  $status?: string;
}

export const ContentContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const ContentTable = styled(Table)`
  &.ant-table-wrapper {
    .ant-table {
      height: 100%;
      min-height: calc(100vh - 260px);
    }
    .ant-table-thead > tr {
      & > th {
        background: none;
        line-height: 18px;
        font-family: "GraphikMedium";
        font-size: 13px;
        color: ${({ theme }) => theme.colors.fontColorZeta};
        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody > tr {
      &:hover > td {
        background: #f7ecff;
      }
    }
    .ant-table-tbody > tr {
      & > td {
        padding-top: 12px;
        padding-bottom: 12px;
        background: #fbfcff;
        font-size: 13px;
      }
    }
    .ant-table-pagination.ant-pagination {
      padding: 12px 20px;
      background: #fff;
      margin: 0px;
    }
  }
`;

export const SelectAction = styled(Dropdown)<ISelectAction>`
  &.ant-dropdown-trigger {
    width: 5px;
    display: block;
    margin-left: 5px;
  }

  cursor: ${({ $status }) =>
    $status === AssetUploadStatus.COMPLETED ? "pointer" : "auto"};
`;

export const AdminRole = styled.div`
  font-family: GraphikMedium;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #a100ff;
`;

export const Role = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

export const UserSwitch = styled(Switch)``;

export const DeleteButtton = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.fontColorXi};
`;

export const EditButtton = styled(EditOutlined)`
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const Title = styled.div`
  color: var(--Black, #333);
  font-family: GraphikMedium;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  text-decoration: underline;
  text-transform: capitalize;
`;

export const Popovers = styled(Popover)`
  &.ant-popover .ant-popover-title {
    color: black !important;
    font-family: GraphikMedium !important;
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 15.4px;
    text-align: left;
    background-color: "#EFF4FF" !important;
  }
  &&label.ant-checkbox-wrapper.ant-checkbox-group-item.css-dev-only-do-not-override-pl41c {
    padding: 6px;
  }
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  display: grid;
  gap: 10px;
`;

export const PopTitle = styled.div`
  font-family: GraphikMedium;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const HorizontalLine = styled.div`
  border: 0.1px solid #bac5d8;
  width: 80%;
  height: 0.5px;
  margin: 15px 0; /* Optional: Add some margin around the line */
`;

export const RelevantText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
  }
`;

export const Image = styled.img``;

export const ActionSave = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const CheckOut = styled(CheckOutlined)`
  color: #a100ff;
  font-size: 16px;
`;
export const CloseOut = styled(CloseOutlined)`
  font-size: 16px;
`;

export const More = styled.span`
  font-family: GraphikMedium;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
`;

export const NotUpdated = styled.span`
  font-family: GraphikRegular;
  font-size: 13px;
  font-weight: 400;
  color: #a7a7a7;
`;

export const PopOverBox = styled(Popover)`
  ant-popover-content {
    width: 702.33px !important;
    height: 281.9px !important;
    font-size: 13px !important;
  }

  ant-popover-inner {
    width: 100% !important;
    height: 100% !important;
    font-size: 13px !important;
  }
`;

export const ViewSummary = styled.span`
  cursor: pointer;
`;

export const HorizontalsLine = styled.div`
  border: 0.1px solid #bac5d8;
  height: 0.5px;
  margin: 15px 0;
`;

export const FooterSummary = styled.div`
  display: flex;
  gap: 10px;
  align-items: end;
  justify-content: end;
  font-size: 13px;
`;

export const PrimaryBtn = styled(PrimaryButton)`
  color: #a100ff;
`;

export const AndInput = styled(Input)`
  .textarea {
    width: 100%;
    max-width: 500px;
  }
`;

export const EditOutline = styled(EditOutlined)`
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const ReleventSelect = styled(Select)`
  font-size: 13px;
  &.ant-select-multiple {
    .ant-select-selector {
      border-color: #eff4ff;
      background: #eff4ff;
    }
  }
  span.anticon.anticon-close {
    display: none;
  }
`;

export const File = styled.div`
  text-transform: uppercase;
  font-size: 13px;
`;

export const RelevantSection = styled.div`
  text-transform: capitalize;
`;

export const label = styled.label`
  font-family: "GraphikMedium";
  font-size: 14px;
  font-weight: normal;
  line-height: 15.4px;
  text-align: left;
  border-bottom: 1px solid rgb(186, 197, 216, 0.3);
  padding-bottom: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
`;

export const Horizontallabel = styled.div`
  border: 0.1px solid #bac5d8;
  width: 100%;
  height: 0.5px;
  margin: 1px 0;
`;

export const ColumnStyle = styled.span`
  font-size: 13px;
`;

export const RfpStatusWrap = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 5px;
  font-size: 13px;
`;

export const RfpFailedStatusWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  color: #cb3478;
`;

export const ContentSummaryWrapper = styled.div``;

export const InProgressStatus = styled.div`
  position: relative;
  padding-left: 25px;
  line-height: 12px;
  min-height: 20px;
  display: flex;
  align-items: center;
  font-family: var(--font-family-regular);
  color: var(--font-black);
  font-size: 13px;
  margin: 0 -10px;

  img {
    position: absolute;
    left: -3px;
    top: -3px;
    width: 25px;
  }
`;

import styled from "styled-components";

export const CDLContainer = styled.div``;
export const CDLSubContainer = styled.div``;

export const OnLineContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  bottom: -13px;
`;

export const CDLTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 20px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  font-size: 14px;
`;

export const UpdateDate = styled.div`
  color: #45464e;
  margin-left: 80px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  font-size: 12px;
`;

export const EditBtnContainer = styled.div`
  height: 26px;
  position: absolute;
  right: 20px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};

  .ant-btn-link {
    color: ${({ theme }) => theme.colors.fontColorEpsilon};
  }
  .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.fontColorEpsilon};
  }
`;

export const NoRecord = styled.div``;

export const AddBtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;
export const CDLDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px 10px 10px 20px;
  margin-bottom: 10px;
  height: 100%;
`;

export const CDLSummary = styled.div`
  padding: 5px;
  font-size: 12px;
  max-height: 250px;
  background-color: #f4f6fc;
`;

// files
export const CardsContainerWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
  overflow: auto;
`;

export const CardsContainerLoadingWrap = styled(CardsContainerWrap)`
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
`;

export const CardContainer = styled.div`
  border: 1px solid #e6e6e6;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const CardHeaderText = styled.div`
  width: 100%;
  font-size: 12px;
  height: 15px;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

export const CardSubHeaderText = styled.div`
  font-size: 10px;
  height: 11px;
  letter-spacing: 0.015em;
  color: #909090;
`;

export const DownloadIcon = styled.img`
  display: flex;
  align-self: flex-end;
`;

export const DownloadBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

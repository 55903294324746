import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IAllRfp,
  IAllRfps,
  IRfpDocuments,
  IStatusCounts,
  ISubFileDataType,
} from "./allRfp.interface";
import {
  AssetUploadStatus,
  QualificationsStatus,
  RFPStatus,
  PageLimit,
} from "../../constants";
import { PURGE } from "redux-persist";
import { autoRefreshedRfpDocById } from "../../services/uploadRfp";
import { getRFPDetailsByRFPId } from "../../services/allRfps";

const initialRfpDocuments: IRfpDocuments = {
  rfp_id: -1,
  rfp_name: "",
  file_name: "",
  original_file_name: "",
  client_name: "",
  uploaded_on: "",
  last_updated_on: "",
  status: "",
  user: {
    status: "",
    id: -1,
    username: "",
    name: "",
    email: "",
  },
  pre_signed_url: "",
  sub_file: [],
};

const initialState: IAllRfps = {
  allRfps: [],
  pendingUploadingRFP: [],
  selectedRFP: { rfp_id: -1 },
  isAllRfpLoading: false,
  rfpFilters: {
    rfpName: "",
    clientGroup: "",
    bidStage: "",
    startDate: "",
    endDate: "",
    status: [],
    qualification: "",
  },
  statusCounts: {
    totalLiveRfps: 0,
    unassignedRfps: 0,
    inProgressRfps: 0,
    upcomingDeadlines: 0,
    completedRfps: 0,
    notStartedRfps: 0,
    onHoldRfps: 0,
  },
  isManuallyEditedShown: false,
  rfpDocuments: initialRfpDocuments,
  isSubFileDelete: false,
  currentPage: 1,
  pageLimit: PageLimit.Rfp,
  totalRfpsCount: 0,
  isRfpDeleteLoading: false,
};

export const allRfpSlice = createSlice({
  name: "allRfpSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAllRfps: (state, action: PayloadAction<IAllRfp[]>) => {
      state.allRfps = action.payload;
      state.pendingUploadingRFP = action.payload?.filter(
        (file) =>
          file.status === RFPStatus.PROCESSING ||
          file?.qualification_status === QualificationsStatus.PROCESSING
      );
    },

    updateQualificationStatus: (state, action: PayloadAction<IAllRfp>) => {
      console.log("updateQualificationStatus");
      state.allRfps = state.allRfps.map((v) =>
        v?.rfp_id === action?.payload?.rfp_id ? action?.payload : v
      );
    },

    updateStatusCount: (state, action: PayloadAction<IStatusCounts>) => {
      state.statusCounts = action.payload;
    },

    setSelectedRFP: (state, action: PayloadAction<IAllRfp>) => {
      state.selectedRFP = action.payload;
    },

    setIsAllRfpLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllRfpLoading = action.payload;
    },

    updateRfpFilterStatus: (state, action: PayloadAction<string>) => {
      if (state.rfpFilters.status.includes(action.payload)) {
        state.rfpFilters.status = state.rfpFilters.status.filter(
          (v) => v !== action.payload
        );
      } else {
        state.rfpFilters.status.push(action.payload);
      }
    },

    updateFilter: (
      state,
      action: PayloadAction<{ field: string; value: string | string[] }>
    ) => {
      state.rfpFilters = {
        ...state.rfpFilters,
        [action.payload.field]: action.payload.value,
      };
    },

    clearFilter: (state) => {
      state.rfpFilters = {
        rfpName: "",
        clientGroup: "",
        bidStage: "",
        startDate: "",
        endDate: "",
        status: [],
        qualification: "",
      };
    },

    setIsManuallyEditedShown: (state, action: PayloadAction<boolean>) => {
      state.isManuallyEditedShown = action.payload;
    },

    updateRFPDetails: (state, action: PayloadAction<IAllRfp>) => {
      state.allRfps = state.allRfps?.map((rfp) =>
        rfp.rfp_id === action.payload.rfp_id ? action.payload : rfp
      );
    },

    // Remove Uploaded files from Pending Files
    removeUploadedRFP: (state, action: PayloadAction<number>) => {
      state.pendingUploadingRFP = state.pendingUploadingRFP?.filter(
        (rfp) => rfp.rfp_id !== action.payload
      );
    },

    // Add file in Pending Files
    addPendingFile: (state, action: PayloadAction<IAllRfp>) => {
      state.pendingUploadingRFP = [
        ...state.pendingUploadingRFP,
        action.payload,
      ];
    },

    getRfpDocuments: (state, action: PayloadAction<IRfpDocuments>) => {
      state.rfpDocuments = action.payload;
    },

    updateRfpDocuments: (state, action: PayloadAction<ISubFileDataType>) => {
      state.rfpDocuments = {
        ...state.rfpDocuments,
        sub_file: [...state?.rfpDocuments?.sub_file, action.payload],
      };
    },

    autoRefreshRfpDoc: (_, action: PayloadAction<{ subFileId: number }>) => {
      let res: IRfpDocuments;
      let interval = setInterval(async () => {
        res = await autoRefreshedRfpDocById(action.payload.subFileId);

        !!res &&
          res.status === AssetUploadStatus.LOADED_INTO_LLM &&
          clearInterval(interval);

        !!res &&
          res.status === AssetUploadStatus.COMPLETED &&
          clearInterval(interval);

        !!res &&
          res.status === AssetUploadStatus.FAILED &&
          clearInterval(interval);
      }, 5000);
    },

    refreshedRfpDocByRfpId: (
      state,
      action: PayloadAction<{ data: IRfpDocuments; subFileId: number }>
    ) => {
      state.rfpDocuments = {
        ...state.rfpDocuments,
        sub_file: state.rfpDocuments.sub_file.map((v) =>
          v.sub_file_id === action.payload.subFileId
            ? { ...v, ...action.payload.data }
            : v
        ),
      };
    },

    setIsSubFileDelete: (state, action: PayloadAction<boolean>) => {
      state.isSubFileDelete = action.payload;
    },

    removeDeletedSubFiles: (state, action: PayloadAction<number>) => {
      state.rfpDocuments = {
        ...state.rfpDocuments,
        sub_file: state.rfpDocuments.sub_file.filter(
          (v) => v.sub_file_id !== action.payload
        ),
      };
    },

    setRfpCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    setRfpPageLimit: (state, action: PayloadAction<number>) => {
      state.pageLimit = action.payload;
    },

    setResetRfpPagination: (state) => {
      state.currentPage = 1;
      state.pageLimit = PageLimit.Rfp;
    },

    setTotalRfpsCount: (state, action: PayloadAction<number>) => {
      state.totalRfpsCount = action.payload;
    },

    setIsRfpDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.isRfpDeleteLoading = action.payload;
    },

    setUpdateRFPsById: (state, action: PayloadAction<number>) => {
      state.allRfps = state.allRfps?.filter(
        (rfp) => rfp.rfp_id !== action.payload
      );
    },

    autoRefreshQualification: (_, action: PayloadAction<{ rfpId: number }>) => {
      let res: IAllRfp;
      let interval = setInterval(async () => {
        res = await getRFPDetailsByRFPId(action?.payload?.rfpId);

        !!res &&
          res.qualification_status === QualificationsStatus.COMPLETED &&
          clearInterval(interval);

        !!res &&
          res.qualification_status === QualificationsStatus.FAILED &&
          clearInterval(interval);
      }, 5000);
    },

    updatePageOnRfpDelete: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const {
  clearFilter,
  setAllRfps,
  updateFilter,
  addPendingFile,
  setSelectedRFP,
  getRfpDocuments,
  setRfpPageLimit,
  updateRFPDetails,
  updateStatusCount,
  removeUploadedRFP,
  autoRefreshRfpDoc,
  setUpdateRFPsById,
  setTotalRfpsCount,
  setRfpCurrentPage,
  updateRfpDocuments,
  setIsSubFileDelete,
  setIsAllRfpLoading,
  updateRfpFilterStatus,
  removeDeletedSubFiles,
  refreshedRfpDocByRfpId,
  updatePageOnRfpDelete,
  setResetRfpPagination,
  setIsRfpDeleteLoading,
  setIsManuallyEditedShown,
  autoRefreshQualification,
  updateQualificationStatus,
} = allRfpSlice.actions;

export default allRfpSlice.reducer;

import { Select, Tooltip } from "antd";
import styled from "styled-components";
import { IViewRFP, RFPSelectBackground } from "../../../types";

interface IRfpTableName {
  enableCursor?: boolean | null;
}

export const RpfTableHeader = styled.div`
  font-family: "GraphikMedium";
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.fontColorRho};
`;
export const RfpTableNameWrap = styled.div`
  display: grid;
  grid-template-columns: 70% 42px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 13px;
`;
export const RfpTableName = styled.div<IRfpTableName>`
  font-family: "GraphikMedium";
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  cursor: ${(props) => (props.enableCursor ? "pointer" : "default")};

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.fontColorRho};
    text-decoration: none;
  }
`;
export const SpanDisable = styled.span`
  color: ${({ theme }) => theme.colors.fontColorEta} !important;
  text-decoration: none !important;
`;

export const RfpTableIconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledTooltip = styled(Tooltip)``;

export const ViewRfp = styled.img<IViewRFP>`
  width: 20px;
  height: 20px;

  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "pointer")};

  filter: ${({ $active }) =>
    $active
      ? "invert(11%) sepia(93%) saturate(6771%) hue-rotate(279deg) brightness(102%) contrast(116%)"
      : "invert(65%) sepia(6%) saturate(17%) hue-rotate(345deg) brightness(105%) contrast(79%)"};
`;

export const RfpTableOpportunityId = styled.div`
  font-family: "GraphikMedium";
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  /* padding: 8px 10px; */
`;

export const RfpTableClientName = styled.div`
  font-family: "GraphikMedium";
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  /* padding: 8px 10px; */
`;

export const RfpTableStackHolder = styled.div`
  display: grid;
  grid-template-columns: 70% 37px;
  align-items: center;
  gap: 10px;

  span {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.fontColorRho};
    text-decoration: none;
  }
`;

export const RfpTableStackHolderIcon = styled.div<{ $isProcessing: boolean }>`
  width: 32px;
  height: 32px;
  border: 0.5px solid ${({ theme }) => theme.colors.borderColorEta};
  box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.colors.borderColorEta};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "pointer")};
`;

export const BidStageContainer = styled.div`
  font-size: 13px;
  font-weight: 400;
  /* padding: 8px 10px; */

  span {
    font-family: "GraphikMedium";
    font-size: 13px;
    font-weight: 500;
  }
`;

export const RfpTableUploadedOn = styled(RfpTableClientName)`
  font-family: "GraphikRegular";
  font-size: 13px;
`;

export const RfpTableQualifiedWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 15px;
    display: block;
  }
`;

export const RfpTableDepartmentSelect = styled(Select)<RFPSelectBackground>`
  && .ant-select-selector {
    background: ${({ background }) => background};
    color: ${(props) => (props?.color ? props.color : "#333")};
    font-size: 13px;
  }
`;

export const TableStatusIconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableStatusImgStyle = styled.img`
  cursor: pointer;
  height: 12px;
`;

// All Status
export const LiveStatusContainer = styled.div`
  /* width: fit-content; */
  height: 20px;
  padding: 5px 10px 4px 10px;
  background: #00b8844d;
  border-radius: 33px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-family: "GraphikMedium";
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  color: #027e5b;
`;

export const NotStartedStatusContainer = styled(LiveStatusContainer)`
  background: #f2f2f2;
  color: #5c5b5b;
`;

export const CompletedStatusContainer = styled(LiveStatusContainer)`
  background: #ffd6004d;
  color: #8b7608;
`;

export const FailedStatusContainer = styled(LiveStatusContainer)`
  background: rgba(203, 52, 120, 0.07);
  color: #cb3478;
`;

export const ProcessingStatusContainer = styled.div`
  font-size: 13px;
  font-weight: 400;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const BidStage = styled(Select)`
  && .ant-select-selector {
    border: none;
    background-color: #fafafa;
  }

  &.ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 0.6px solid;
    box-shadow: none;
  }

  && .ant-select-selection-item {
    font-family: "GraphikRegular";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    color: #5c5b5b;
  }
`;

export const ProjectCategory = styled(Select)`
  && .ant-select-selector {
    background: #eff4ff;
    color: #333;
    font-size: 13px;
  }
`;

export const PCContainer = styled.div`
  font-size: 13px;
  font-weight: 400;
  /* padding: 8px 10px; */

  span {
    font-family: "GraphikRegular";
    font-size: 13px;
    font-weight: 400;
  }
`;

export const StyledImgContainer = styled.div``;

export const StyledImg = styled.img<{ $isProcessing: boolean }>`
  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "pointer")};
`;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IOrganograms, organogramJson } from "./organogram.interface";

const initialState: IOrganograms = {
  organogram: { rfpId: -1, organogram: [] },
  saveOrganogramLoading: false,
  getOrganogramLoading: false,
  organogramTitle: "",
};

export const organogramSlice = createSlice({
  name: "organogramSlice",
  initialState,
  reducers: {
    setOrganogram: (
      state,
      action: PayloadAction<{
        rfpId: number;
        organogram: organogramJson[];
      }>
    ) => {
      state.organogram = action.payload;
    },

    saveOrganogramLoading: (state, action: PayloadAction<boolean>) => {
      state.saveOrganogramLoading = action.payload;
    },

    setOrganogramTitle: (state, action: PayloadAction<string>) => {
      state.organogramTitle = action.payload;
    },

    getOrganogramLoading: (state, action: PayloadAction<boolean>) => {
      state.getOrganogramLoading = action.payload;
    },
  },
});

export const {
  setOrganogram,
  setOrganogramTitle,
  getOrganogramLoading,
  saveOrganogramLoading,
} = organogramSlice.actions;

export default organogramSlice.reducer;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAllPaymentTerms, IPaymentTerms } from "./paymentTerms.interface";

const initialState: IAllPaymentTerms = {
  paymentTermsDetails: {
    extractionStatus: "NOT_STARTED",
    ppId: -1,
    relevantPages: [],
    status: "",
    paymentTermsFileName: "", // TODO : remove this when we have proper file name
    paymentTermsFilePreSignedUrl: "",
  },
};

export const paymentTemrsSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setPaymentTermsDetails: (state, action: PayloadAction<IPaymentTerms>) => {
      state.paymentTermsDetails = action.payload;
    },
  },
});

export const { setPaymentTermsDetails } = paymentTemrsSlice.actions;

export default paymentTemrsSlice.reducer;

import { toast } from "react-toastify";
import { updateRPFSummmary } from "../../../../services/overview";
import * as Styles from "../styles";
import { useEffect, useState } from "react";
import { Form } from "antd";
import { PrimaryButton, SecondaryButton } from "../../../../shared";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { MarkDownText } from "../../../../shared/markDownText/MarkDownText";

interface ISummaryProps {
  rfpId: string;
  rfp_summary: string;
}
const Summary = ({ rfpId, rfp_summary }: ISummaryProps) => {
  const [formSummary] = Form.useForm();
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditSummary, setIsEditSummary] = useState(false);

  const { isOverviewLoading } = useSelector(
    (state: RootState) => state.overview
  );

  useEffect(() => {
    if (rfp_summary) {
      formSummary.setFieldsValue({ rfp_summary });
      setSummary(rfp_summary);
    }
  }, [rfp_summary]);

  const onUpdateSummary = async () => {
    try {
      setIsLoading(true);
      const res: any = await updateRPFSummmary({ rfp_summary: summary }, rfpId);
      if (res.status === 200) {
        toast.success("Summary uploaded successfully");
        setIsEditSummary(false);
        setSummary("");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const renderSummery = () => {
    if (isOverviewLoading) {
      return (
        <Styles.RFPSummary>
          <Styles.RFPDetailsSkeleton active paragraph={{ rows: 8 }} />
        </Styles.RFPSummary>
      );
    }
    return (
      <Styles.RFPSummary>
        {!isEditSummary ? (
          <MarkDownText text={rfp_summary} />
        ) : (
          // <Styles.RFPSummaryText>{rfp_summary}</Styles.RFPSummaryText>
          <Styles.AntForm
            layout="vertical"
            form={formSummary}
            requiredMark={false}
            initialValues={{ rfp_summary: "" }}
            onFinish={onUpdateSummary}
          >
            <Styles.AntFormItem
              name="rfp_summary"
              rules={[
                {
                  required: true,
                  message: "Please input RFP summary",
                },
              ]}
            >
              <Styles.InputSummary
                value={summary}
                onChange={(e) => {
                  setSummary(e.target.value);
                }}
                autoSize={{ minRows: 3, maxRows: 100 }}
              />
            </Styles.AntFormItem>
          </Styles.AntForm>
        )}
      </Styles.RFPSummary>
    );
  };

  return (
    <>
      <Styles.EditSummary>
        {!isEditSummary ? (
          <SecondaryButton
            onClick={() => {
              setIsEditSummary(true);
              setSummary(rfp_summary);
            }}
          >
            Edit
          </SecondaryButton>
        ) : (
          <>
            <SecondaryButton
              onClick={() => {
                setIsEditSummary(false);
              }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              loading={isLoading}
              htmlType="submit"
              onClick={() => {
                formSummary.submit();
              }}
            >
              Save
            </PrimaryButton>
          </>
        )}
      </Styles.EditSummary>
      <Styles.RFPSummaryMain>
        <Styles.RFPSummaryTitle>RFP Summary</Styles.RFPSummaryTitle>
        {renderSummery()}
      </Styles.RFPSummaryMain>
    </>
  );
};

export default Summary;

import { Input, Progress } from "antd";
import styled from "styled-components";

export const CDLModalContainer = styled.div``;
export const CDLModalBody = styled.div``;

export const LableAndText = styled.div`
  background-color: #f4f6fc;
  padding: 15px;
  margin-top: 15px;
  border-radius: 6px;
`;

export const Label = styled.div``;
export const StyledInput = styled(Input)``;

export const UploadSubContainer = styled.div`
  border-radius: 5px;
  border: 1px solid #e3e4e5;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
`;

export const UploadIconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  overflow: hidden;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Span = styled.span``;

export const UplodingTitle = styled.div`
  font-family: GraphikMedium;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #6d7987;
  margin-top: 15px;
`;

export const UplodedText = styled.div`
  margin-top: 15px;
  font-family: GraphikMedium;
  font-size: 12px;
  color: #6d7987;
`;

export const UploadingContent = styled.div`
  margin-top: 5px;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #e3e4e5;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
`;

export const UploadFile = styled.div`
  color: #0f0f0f;
`;

export const UploadProgress = styled(Progress)`
  &.ant-progress .ant-progress-bg {
    height: 4px !important;
    overflow: hidden;
  }

  .ant-progress-line {
    margin-bottom: 0px;
  }
`;

export const UploadDocContainer = styled.div`
  max-height: 150px;
  overflow: auto;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

import styled from "styled-components";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

interface IDatePickerColor {
  background?: string;
  color?: string;
  icon?: string;
}

export const SelectDatePicker = styled(RangePicker)<IDatePickerColor>`
  &&.ant-picker-outlined {
    border: 0.6px solid
      ${(props) => (props.color ? props.color : "rgba(109, 96, 164, 0.4)")};

    background: ${(props) => (props.background ? props.background : "#403379")};
  }

  && .anticon.anticon-calendar {
    color: ${(props) => (props.color ? props.color : "#fff")};
  }
  && .anticon-swap-right {
    color: ${(props) => (props.color ? props.color : "#fff")};
  }

  &&input[placeholder],
  [placeholder],
  *[placeholder] {
    color: ${(props) => (props.color ? props.color : "#fff")} !important;
    margin-left: ${(props) => (props.icon ? "25px" : "28px")};
  }
  input::placeholder {
    color: ${(props) => (props.color ? props.color : "#fff")} !important;
    font-style: italic;
  }
`;

export const DatePickerWrapper = styled.div`
  position: relative;
`;

export const PrefixIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
`;

import * as Styles from "./style";

type CustomProps = any;

export const DateRangePicker = (props: CustomProps) => {
  return (
    <Styles.SelectDatePicker {...props}>
      {props.children}
    </Styles.SelectDatePicker>
  );
};

export const SecondaryDateRangePicker = (props: CustomProps) => {
  const { icon } = props;
  return (
    <Styles.DatePickerWrapper>
      {icon && <Styles.PrefixIconWrapper>{icon}</Styles.PrefixIconWrapper>}
      <Styles.SelectDatePicker {...props}>
        {props.children}
      </Styles.SelectDatePicker>
    </Styles.DatePickerWrapper>
  );
};

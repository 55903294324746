import React from "react";
import * as Styles from "../styles";

const NbmLoader = () => {
  return (
    <>
      {[...Array(5)].map((_, index: number) => (
        <React.Fragment key={index}>
          <Styles.LeftSide>
            <Styles.LeftTextNumberAlignLoading>
              <Styles.AtiSkeleton active />
            </Styles.LeftTextNumberAlignLoading>
          </Styles.LeftSide>
          <Styles.RightSide>
            <Styles.RightTextNumberAlign>
              <Styles.AtiSkeleton active />
            </Styles.RightTextNumberAlign>
          </Styles.RightSide>
        </React.Fragment>
      ))}
    </>
  );
};

export default NbmLoader;

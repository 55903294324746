import { Skeleton } from "antd";
import * as Styles from "../../../pages/contentManagement/styles";

const SkeletonTable = () => {
  const columns = [
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "title",
      key: "title",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "file_type",
      key: "file_type",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "summary",
      key: "summary",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "relevant_section",
      key: "relevant_section",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "uploadedOn",
      key: "uploadedOn",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "uploadDate",
      key: "uploadDate",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "assetStatus",
      key: "assetStatus",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      key: "action",
      width: "auto",
      render: () => (
        <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />
      ),
    },
  ];

  return (
    <Styles.ContentContainer>
      <Styles.ContentTable
        rowKey={"id"}
        dataSource={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
        columns={columns as any}
        scroll={{ y: "calc(100vh - 312px)" }}
        pagination={false}
      />
    </Styles.ContentContainer>
  );
};

export default SkeletonTable;

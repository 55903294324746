import { Tabs } from "antd";
import styled from "styled-components";

export const RFPSContainer = styled.div`
  margin: 15px;
`;
export const QualificationContent = styled.div`
  height: 100%;
  /* background: ${({ theme }) => theme.colors.backgroundColorAlpha}; */
  width: 100%;
`;
export const GenerateCard = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 15px 15px 8px 26px;
`;
export const GenerateHeader = styled.h2`
  font-family: GraphikMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.8px;
  text-align: left;
  color: #45464e;
`;
export const PrimaryTabs = styled(Tabs)`
  .ant-tabs-nav::before {
    border-color: #ebecf2;
  }
  .ant-tabs-nav {
    background: ${({ theme }) => theme.colors.backgroundColorAlpha};
    padding: 0 15px;
    margin-bottom: 0px;
  }

  .ant-tabs-tab {
    padding: 10px 12px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 20px;
  }
  &.ant-tabs .ant-tabs-tab {
    .ant-tabs-tab-btn {
      font-family: "GraphikRegularfont";
    }
    .ant-tabs-tab-active {
      font-family: "GraphikMediumfont";
    }
  }
`;
export const TabContent = styled.div``;
export const Label = styled.div``;
// AboutCompany Style

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IPolicy966, IPolicy966Data } from "./policy966.interface";
import { getGeneratedPolicy966 } from "../../services/policy966";
import { RFPGenerationStatus } from "../../constants";
import { store } from "../../store";

const policy966DataInitialValues: IPolicy966Data = {
  companyOverview: "",
  companyProfile: "",
  compositionOfBoardOfDirectors: "",
  generationStatus: "",
  geographicalCoverageOfOperations: "",
  governanceAndComplianceTeam: "",
  ongoingEngagementsWithCompany: "",
  ownershipDetails: "",
  policyId: -1,
  isCompanyStrategic: "",
  newOpportunityImportance: "",
  newOpportunityDetails: {
    rfpReleaseDate: "",
    scopeAndTimeLines: "",
    expectedDealSize: "",
    bidProcessCompetitive: "",
    qad: "",
    leadershipTeam: "",
    refNoAndSubmissionDate: "",
    notRejected: "",
  },
  rfpFileName: "",
};

const initialState: IPolicy966 = {
  isExportPolicyLoading: false,
  policy966Data: policy966DataInitialValues,
  isEditPolicy966Loading: false,
  isPolicy966DataLoading: false,
  stopPolicy966AutoRefresh: false,
};

export const policy966Slice = createSlice({
  name: "policy966Slice",
  initialState,

  reducers: {
    setIsExportPolicyLoading: (state, action: PayloadAction<boolean>) => {
      state.isExportPolicyLoading = action.payload;
    },

    setPolicy966Data: (state, action: PayloadAction<IPolicy966Data>) => {
      state.policy966Data = action.payload;
    },

    setIsPolicy966DataLoading: (state, action: PayloadAction<boolean>) => {
      state.isPolicy966DataLoading = action.payload;
    },

    setStopPolicy966AutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.stopPolicy966AutoRefresh = action.payload;
    },

    autoRefreshPolicyStatus: (_, action: PayloadAction<{ rfpId: number }>) => {
      let res: IPolicy966Data;
      const interval = setInterval(async () => {
        res = await getGeneratedPolicy966(action.payload.rfpId, false);

        store.getState().policy966.stopPolicy966AutoRefresh &&
          store.getState().policy966.stopPolicy966AutoRefresh === true &&
          clearInterval(interval);

        !!res &&
          res?.generationStatus === RFPGenerationStatus.COMPLETED &&
          clearInterval(interval);

        !!res &&
          res?.generationStatus === RFPGenerationStatus.STOPPING &&
          clearInterval(interval);

        !!res &&
          res.generationStatus === RFPGenerationStatus.FAILED &&
          clearInterval(interval);
      }, 5000);
    },

    setIsEditPolicy966Loading: (state, action: PayloadAction<boolean>) => {
      state.isEditPolicy966Loading = action.payload;
    },
  },
});

export const {
  setPolicy966Data,
  autoRefreshPolicyStatus,
  setIsExportPolicyLoading,
  setIsEditPolicy966Loading,
  setIsPolicy966DataLoading,
  setStopPolicy966AutoRefresh,
} = policy966Slice.actions;

export default policy966Slice.reducer;

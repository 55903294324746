import styled from "styled-components";

export const AppContainer = styled.div`
  width: 100%;
  height: 100vh;
  /* max-width: 1540px; */
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 70px 1fr;
  background: ${({ theme }) => theme.colors.backgroundColoGamma};
  overflow: hidden;

  @media only screen and (min-width: 1400px) {
    & {
      border-left: 1px solid #e6e4f0;
    }
  }
`;

export const HeaderOutletWrap = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  min-height: 100vh;
`;

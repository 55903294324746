import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import * as Styles from "./style";
import { ILogin } from "../../../types";
import { RootState } from "../../../store";
import { login } from "../../../services/auth";
import { PATHS } from "../../../constants";

const Login = () => {
  const navigate = useNavigate();

  const { loginLoading } = useSelector((state: RootState) => state.authSlice);

  const onFinish = async (values: ILogin) => {
    await login(values, navigate);
  };

  return (
    <>
      <Styles.WelcomeText>Welcome! 👋</Styles.WelcomeText>
      <Styles.SignInText>Sign in to your account</Styles.SignInText>
      <Form
        name="login"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        size="large"
      >
        <div>
          <Styles.EmailText>Your email</Styles.EmailText>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please Enter Your Email",
              },
            ]}
          >
            <Styles.InputField placeholder="" />
          </Form.Item>
        </div>
        <div>
          <Styles.EmailText>Password</Styles.EmailText>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please Enter Your Password" }]}
          >
            <Styles.PasswordField placeholder="" />
          </Form.Item>
        </div>

        <Styles.LoginButton htmlType="submit" loading={loginLoading}>
          <Styles.LoginText>Login</Styles.LoginText>
        </Styles.LoginButton>
      </Form>
      <Styles.ForgotPassContainet>
        <Styles.ForgotPassLink
          type="link"
          onClick={() => navigate(PATHS.forgotPassword)}
        >
          Forgot Password?
        </Styles.ForgotPassLink>
      </Styles.ForgotPassContainet>
    </>
  );
};

export default Login;

import styled from "styled-components";
import { Input } from "antd";
import { PrimaryButton } from "../../../shared";

export const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: fit-content;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 6.3px 8.41px 0px #0000000d;
  padding: 25px;

  form {
    width: 100%;
  }
`;

export const HeadingText = styled.div`
  font-family: "GraphikMedium";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.fontColorDelta};
`;

export const SubHeadingText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.fontColorEta};
`;

export const InputField = styled(Input.Password)`
  height: 40px;
`;

export const BackToLoginContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UpdatePasswordButton = styled(PrimaryButton)`
  width: 100%;
  height: 40px;
`;

import styled from "styled-components";
import { Image } from "antd";
import { IMAGES } from "../../shared";

export const AuthContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  min-height: 100vh;
  background-color: #f4f6fc;
`;

export const BackgroundImageContainer = styled.div`
  background-image: url(${IMAGES.loginBackground});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const AccentureLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 20%;
  width: 40%;
  align-items: center;
  justify-content: center;
`;

export const AccentureLogo = styled(Image)`
  padding-left: 40px;
  align-self: center;
`;

export const AccentureText = styled.div`
  color: ${({ theme }) => theme.colors.fontColorBeta};
  font-family: "GraphikBold";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
`;

export const BackgroundImageTextOverlay = styled.div`
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 60%;
  height: 80%;
  color: ${({ theme }) => theme.colors.fontColorBeta};
  font-family: "GraphikBold";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const AuthBox = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const AuthHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  width: 60%;
`;

export const AuthHeadingText = styled.div`
  color: ${({ theme }) => theme.colors.fontColorDelta};
  font-family: "GraphikBold";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
`;

export const AuthSubHeadingText = styled.div`
  color: ${({ theme }) => theme.colors.backgroundColorBeta};
  font-family: "GraphikBold";
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  display: flex;
  flex-direction: row;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  width: 60%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.fontColorBeta};
  margin-top: 30px;
  border-radius: 4.203px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 6.304px 8.405px 0px rgba(0, 0, 0, 0.05);

  & form {
    margin-top: 15px;
    width: 100%;
    min-width: 286px;
    display: flex;
    flex-direction: column;

    & .ant-form-item {
      margin-bottom: 8px;
    }
  }
`;

export const aiTagImage = styled(Image)`
  width: 80px;
  height: 80px;
  margin-left: 15px;
`;

export const WelcomeText = styled.div`
  color: #757575;
  font-family: "GraphikRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

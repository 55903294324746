import styled from "styled-components";

export const EditableCellValueWrap = styled.div`
  &:hover {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
  }
`;

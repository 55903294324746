import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import * as Styles from "../styles";
import { NbmLoader } from "../nbmLoader";
import { RootState } from "../../../../store";
import { SecondaryButton } from "../../../../shared";
import { updateNbm } from "../../../../services/nbm";
import { MarkDownText } from "../../../../shared/markDownText/MarkDownText";
import {
  nbmData,
  INbmTabs,
  INbmTabData,
  IClientProfileBoardScopeWinningThemes,
} from "../../../../store/nbm/nbm.interface";

const clientProfileBoardScopeWinningThemesInitial = {
  client_profile: [],
  broad_scope_of_work: [],
  winning_themes_for_accenture: [],
};

const ClientProfileBoardScopeWinningThemes = (props: {
  tabKey: keyof typeof clientProfileBoardScopeWinningThemesInitial;
}) => {
  const { tabKey } = props;

  const [isEdit, setIsEdit] = useState<string>("-1");
  const [clientProfileData, setClientProfileData] = useState<
    IClientProfileBoardScopeWinningThemes[]
  >([]);

  const { rfpId } = useParams();

  const currentRfpId: number = rfpId ? Number(rfpId) : -1;

  const { nbmData, isNbmDataLoading, isNbmUpdateLoading } = useSelector(
    (state: RootState) => state.nbm
  );

  useEffect(() => {
    setClientProfileData(nbmData?.nbmData?.[tabKey]);
  }, [nbmData]);

  const checkNbmString = (value: INbmTabData[] | string): string => {
    if (typeof value === "string") {
      return value;
    }
    return "";
  };

  const handleValueChange = (value: string, id: string) => {
    const updatedData = clientProfileData?.map((v) =>
      v?.id === id ? { ...v, value } : v
    );

    setClientProfileData(updatedData);
  };

  const handleEditForm = (id: string) => {
    setClientProfileData(nbmData?.nbmData?.[tabKey]);
    setIsEdit(id);
  };

  const handleSubmitForm = async () => {
    const updatedNbmData: INbmTabs = {
      ...nbmData?.nbmData,
      [tabKey]: clientProfileData,
    };

    const request: nbmData = {
      ...nbmData,
      nbmData: updatedNbmData,
    };

    await updateNbm(request, currentRfpId);

    setIsEdit("-1");
  };

  const handleCloseForm = () => {
    setIsEdit("-1");
    setClientProfileData(nbmData?.nbmData?.[tabKey]);
  };

  const renderEditablePoints = (value: string, id: string) => {
    return (
      <Styles.NBMItemsEditablePointsHeaderWrap>
        <Styles.NBMItemsEditablePointsPoints>
          <Styles.InputFooterWrap $active={isEdit === id}>
            {isEdit === id ? (
              <Styles.InputSummary
                readOnly={isEdit !== id}
                value={checkNbmString(value)}
                onChange={(e) => {
                  handleValueChange(e?.target?.value, id);
                }}
                autoSize={{ minRows: 1, maxRows: 100 }}
              />
            ) : (
              <MarkDownText text={checkNbmString(value)} />
            )}
            <Styles.InputFooterButtonWrap>
              <Button
                loading={isNbmUpdateLoading}
                disabled={isNbmUpdateLoading}
                type="link"
                icon={<CheckOutlined style={{ fontSize: "13px" }} />}
                onClick={handleSubmitForm}
              />
              <Button
                type="link"
                disabled={isNbmUpdateLoading}
                icon={<CloseOutlined style={{ fontSize: "13px" }} />}
                onClick={handleCloseForm}
              />
            </Styles.InputFooterButtonWrap>
          </Styles.InputFooterWrap>

          {isEdit !== id && (
            <SecondaryButton
              className="secondary-button"
              onClick={() => handleEditForm(id)}
              disabled={isNbmUpdateLoading}
            >
              Edit
            </SecondaryButton>
          )}
        </Styles.NBMItemsEditablePointsPoints>
      </Styles.NBMItemsEditablePointsHeaderWrap>
    );
  };

  const renderTable = () => {
    if (isNbmDataLoading) return <NbmLoader />;
    else
      return (
        <>
          {clientProfileData?.map(
            (v: IClientProfileBoardScopeWinningThemes, index: number) => (
              <React.Fragment key={v?.title}>
                <Styles.LeftSide>
                  <Styles.LeftTextNumberAlign>
                    <Styles.FinanceDetails>
                      <Styles.Heading>
                        <Styles.SerialNum>{index + 1}</Styles.SerialNum>
                        <Styles.FTitle>{v?.title}</Styles.FTitle>
                      </Styles.Heading>
                    </Styles.FinanceDetails>
                  </Styles.LeftTextNumberAlign>
                </Styles.LeftSide>
                <Styles.RightSide>
                  <Styles.RightTextNumberAlign>
                    {renderEditablePoints(v?.value, v?.id)}
                  </Styles.RightTextNumberAlign>
                </Styles.RightSide>
              </React.Fragment>
            )
          )}
        </>
      );
  };

  return <Styles.NBMItemsContainer>{renderTable()}</Styles.NBMItemsContainer>;
};

export default ClientProfileBoardScopeWinningThemes;

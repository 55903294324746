import { Button, Input, Popover } from "antd";
import styled, { css } from "styled-components";

interface IInputReason {
  isEdit?: boolean;
}

// QR - Qualification Reason
export const QRContainer = styled.div`
  font-size: 14px;
  font-weight: 400;

  span {
    font-family: "GraphikMedium";
    font-size: 14px;
    font-weight: 500;
  }
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-content {
    max-height: 250px;
    overflow-y: auto;
  }
`;

export const QRContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 250px;
  width: 100%;
  overflow: hidden;
`;
export const InputReason = styled(Input.TextArea)<IInputReason>`
  &.ant-input {
    resize: none;
    height: 205px;
    ${(props) =>
      props.readOnly &&
      css`
        border: none;
        &:hover {
          border: none;
        }
      `};
    background-color: ${(props) => (props.isEdit ? "rgb(244, 246, 252)" : "")};
    border: none;
  }
`;

export const QRBtnContainer = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  padding: 5px;
  gap: 5px;
  align-items: center;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TextSpan = styled.div`
  font-family: "GraphikMedium";
  font-size: 14px;
  font-weight: 500;
`;

export const QRBtnSubContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const EditButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseText = styled.div`
  color: #a100ff;
  font-family: "GraphikMedium";
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
`;

export const StyledImg = styled.img<{ $isProcessing: boolean }>`
  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "pointer")};
`;

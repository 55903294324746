import { Modal } from "antd";
import styled from "styled-components";

export const ModalContainer = styled.div``;
export const AntModal = styled(Modal)`
  &.ant-modal .ant-modal-title {
    font-family: "GraphikMedium";
  }
`;
export const ModalBody = styled.div``;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

import { Form, Input, Select } from "antd";
import styled from "styled-components";

export const SearchRFPContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RfpForm = styled(Form)`
  width: 85%;
  height: 40px;
  margin-top: 15px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.borderColorBeta};

  img {
    width: 20px;
    height: 20px;
  }
`;

export const RfpSearch = styled(Input)`
  height: 40px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.borderColorBeta};

  .ant-input::placeholder {
    color: #5c5c5c;
    padding: 5px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const SelectToggleWrap = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

export const SelectWrap = styled.div`
  display: flex;
  gap: 15px;
`;

export const TextToggleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SearchRfpText = styled.div`
  color: #fff;
  font-family: "GraphikRegular";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
`;

export const StyledSelect = styled(Select)`
  &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #403379;
    border-color: #403379;
    width: 120px;
  }

  &.ant-select-single .ant-select-selector {
    color: ${({ theme }) => theme.colors.fontColorBeta};
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.fontColorBeta};
  }

  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.fontColorBeta};
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin: 0;
`;

export const PrefixIcon = styled.span`
  position: relative;
  top: 5.5px;
`;

import { EditOutlined } from "@ant-design/icons";
import { Input, Skeleton, Tooltip } from "antd";
import styled, { css } from "styled-components";
import { ActiveStatus } from "../../../types";
import { TertiaryButton } from "../../../shared";

export const AtiContainer = styled.div`
  overflow: auto;
  font-size: 14px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 15px;
`;

export const GenerateExportWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AgreementText = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  // margin-top: 20px;

  img {
    width: 16px;
  }
`;

export const AgreementsContainer = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 30% 1fr;
`;

export const LeftSide = styled.div``;

export const RightSide = styled.div`
  display: flex;
  justify-content: start;
`;

export const LeftTextNumberAlign = styled.div`
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px;
  height: 100%;
  // margin-bottom: 16px;
`;

export const RightTextNumberAlign = styled(LeftTextNumberAlign)`
  padding: 0px;
`;

export const FinanceDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SerialNum = styled.div`
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-family: GraphikMedium;
  font-weight: 500;
  border-radius: 50px;
  border: 0.1px solid #f4edf9;
  background-color: #f4edf9;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  font-size: 14px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const AgreementsPoints = styled.div`
  padding: 10px 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.67px;
  color: #5c5b5b;
  background: #f4f6fc;
  white-space: pre-wrap;
`;

export const EditOutlinedIcon = styled(EditOutlined)`
  font-size: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const AtiSkeleton = styled(Skeleton)`
  padding: 10px;
  height: 100px;
`;

export const AgreementsEditablePointsPoints = styled.div`
  display: flex;
  gap: 5px;
  padding: 5px;
`;

export const InputSummary = styled(Input.TextArea)`
  &.ant-input {
    height: 100px;
    resize: none;
    border: none;
    box-shadow: none;
    font-size: 13px;
    padding: 5px;
    ${(props) =>
      props.readOnly &&
      css`
        border: none;
        &:hover {
          border: none;
        }
      `}
  }
`;

export const InputFooterButtonWrap = styled.div`
  justify-content: end;

  button {
    padding: 0px;
    width: 27px;
    height: 27px;
    svg {
      color: #a100ff;
    }
  }
`;

export const InputFooterWrap = styled.div<ActiveStatus>`
  // height: 135px;
  width: 100%;
  border: ${({ $active }) => ($active ? "1px solid #b829ff" : "none")};

  ${InputFooterButtonWrap} {
    display: ${({ $active }) => ($active ? "flex" : "none")};
  }
`;

export const ExportAtiButton = styled(TertiaryButton)`
  font-size: 11px;
  width: fit-content;
  padding: 4px 10px;
  height: 30px;
`;

export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAmContent, amFiles } from "./a&m.interface";

const initialState: IAmContent = {
  amContent: [],
  isAmContentLoading: false,
};

export const amContentSlice = createSlice({
  name: "amContentSlice",
  initialState,
  reducers: {
    setAmContent: (state, action: PayloadAction<amFiles[]>) => {
      state.amContent = action.payload;
    },

    setIsAmContentLoading: (state, action: PayloadAction<boolean>) => {
      state.isAmContentLoading = action.payload;
    },
  },
});

export const { setAmContent, setIsAmContentLoading } = amContentSlice.actions;

export default amContentSlice.reducer;

import * as Styles from "./styles";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { Checkbox, Form, Input, InputNumber, TableProps } from "antd";
import { IMAGES } from "../../shared";
import { AssetUploadStatus } from "../../constants";
import { formateDate, uniqueId } from "../../shared/helpers";
import { ConfirmationModal, CustomPagination } from "../../components";
import { IContent } from "../../store/contentManagement/contentManagement.interface";
import SummaryModal from "../../components/contentManagement/summaryModal/SummaryModal";
import SkeletonTable from "../../components/contentManagement/skeletonTable/SkeletonTable";
import {
  setContentCurrentPage,
  setContentPageLimit,
} from "../../store/contentManagement/contentManagementSlice";
import {
  fetchAllContent,
  removeContent,
  updateContent,
  updateDescriptionContent,
  updateReleventContent,
} from "../../services/contentManagement";

const options = [
  {
    label: "Finance",
    key: "finance",
    value: "Finance",
  },
  {
    label: "Legal",
    key: "legal",
    value: "Legal",
  },
  {
    label: "Solution",
    key: "solution",
    value: "Solution",
    disabled: true,
  },
  {
    label: "PQ/TQ",
    value: "PQ/TQ",
    key: "PQ/TQ",
  },
  {
    label: "A&M",
    value: "A&M",
    key: "A&M",
  },
  {
    label: "FRS",
    key: "FRS",
    value: "FRS",
  },
  {
    label: "Project Plan",
    key: "projectPlan",
    value: "Project Plan",
  },
  {
    label: "Payment Terms",
    key: "paymentTerms",
    value: "Payment Terms",
  },
  {
    label: "Policy requirement",
    key: "policyRequirement",
    value: "Policy Requirement",
  },
];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: IContent;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ContentManagement = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    getAllContent,
    loading,
    searchText,
    currentPage,
    pageLimit,
    totalContentsCount,
  } = useSelector((state: RootState) => state.content);

  const [key, setKey] = useState<any>("");
  const [data, setData] = useState(getAllContent);
  const [selectedId, setSelectedId] = useState(-1);
  const [description, setDescription] = useState("");
  const [editingKey, setEditingKey] = useState<any>("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const handleCheckboxChange = async (e: any, value: any, record: any) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record === item.asset_id);
    let item: any = newData[index];
    let getCheckedValue = [];
    const rel = item.relevant_section !== null ? item.relevant_section : [];

    if (e.target.checked) {
      getCheckedValue = [...rel, value];
    } else {
      getCheckedValue = item.relevant_section.filter(
        (val: any) => val !== value
      );
    }
    try {
      item = { ...item, relevant_section: getCheckedValue };
      const payload = {
        asset_id: record,
        relevant_section: item.relevant_section,
      };
      const res: any = await updateReleventContent(payload);
      if (res.status) {
        newData[index] = item;
        setData(newData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function truncateString(str: string, maxLength: number) {
    if (typeof str === "string" && str.length > maxLength) {
      return <>{str.slice(0, maxLength)}</>;
    }
    return str;
  }

  useEffect(() => {
    fetchAllContent(searchText, currentPage, pageLimit);
  }, []);

  useEffect(() => {
    setData(getAllContent);
  }, [getAllContent]);

  const edit = (record: any) => {
    setSelectedId(record.asset_id);
    form.setFieldsValue({ title: "", summary: "", ...record });
    setEditingKey(record.asset_id);
  };

  const save = async (record: IContent) => {
    try {
      const row = (await form.validateFields()) as IContent;
      const newData = [...data];
      const index = newData.findIndex(
        (item) => record.asset_id === item.asset_id
      );
      const payload = {
        asset_id: selectedId,
        summary: row.summary,
        title: row.title,
      };
      if (record.summary === row.summary && record.title === row.title) {
        setData(newData);
        setEditingKey("");
      } else {
        const res: any = await updateContent(payload);
        if (res.status === 200) {
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            setData(newData);
            setEditingKey("");
          }
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey("");
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleSaveDes = async (records: IContent) => {
    try {
      const row = (await form.validateFields()) as IContent;
      const newData = [...data];
      const payload = {
        asset_id: records.asset_id,
        summary: description,
        title: records.title,
      };
      const res: any = await updateDescriptionContent(
        payload,
        searchText,
        currentPage,
        pageLimit
      );
      if (res.status === 200) {
        const index = newData.findIndex(
          (item) => records.asset_id === item.asset_id
        );
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setData(newData);
          setEditingKey("");
          setKey(null);
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        setKey(null);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const renderAssetStatus = (status: string) => {
    switch (status) {
      case AssetUploadStatus.PROCESSING:
      default:
        return (
          <Styles.RfpStatusWrap>
            <Styles.InProgressStatus>
              <img
                src={IMAGES.loadingSpinner}
                alt="loading"
                className="spinnerSvg"
              />
              In processing
            </Styles.InProgressStatus>
          </Styles.RfpStatusWrap>
        );

      case AssetUploadStatus.LOADED_INTO_LLM:
      case AssetUploadStatus.COMPLETED:
        return <Styles.RfpStatusWrap>Completed</Styles.RfpStatusWrap>;

      case AssetUploadStatus.FAILED:
      case AssetUploadStatus.FAILED_IN_LLM:
        return <Styles.RfpFailedStatusWrap>Failed</Styles.RfpFailedStatusWrap>;
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      editable: true,
      width: 184,
      render: (row: string) => {
        return <>{row ? <Styles.Title>{row}</Styles.Title> : "-"}</>;
      },
    },
    {
      title: "File Type",
      dataIndex: "file_type",
      key: "fileType",
      width: 90,
      render: (file_type: string) => {
        return <Styles.File>{file_type}</Styles.File>;
      },
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      editable: true,
      width: 340,
      render: (summary: string, records: IContent) => {
        return (
          <Styles.ColumnStyle>
            <Styles.ContentSummaryWrapper
              onClick={() => setKey(records.asset_id)}
            >
              <Styles.ViewSummary>
                {truncateString(summary, 45)}
              </Styles.ViewSummary>
              {summary?.length > 45 && <Styles.More>... more</Styles.More>}
            </Styles.ContentSummaryWrapper>

            {records.asset_id === key && (
              <SummaryModal
                isOpen={records.asset_id === key}
                setModalClose={() => setKey(null)}
                records={records}
                handleSaveDes={handleSaveDes}
                description={description}
                setDescription={setDescription}
              />
            )}
          </Styles.ColumnStyle>
        );
      },
    },
    {
      title: "Relevant Section",
      dataIndex: "relevant_section",
      key: "relevant_section",
      width: 240,
      render: (_: any, record: IContent) => {
        return (
          <Styles.ReleventSelect
            disabled={record?.assetStatus !== AssetUploadStatus.COMPLETED}
            maxTagCount="responsive"
            mode="multiple"
            popupClassName="multiple-dropdown"
            value={
              record.relevant_section !== null ? record.relevant_section : []
            }
            style={{ width: "100%" }}
            dropdownRender={() => {
              return (
                <div className="main-div">
                  {options.map((option) => {
                    if (option.value === "Solution") {
                      return (
                        <Styles.label className="label-title" key={uniqueId()}>
                          {option.value}
                          {/* <Styles.Horizontallabel></Styles.Horizontallabel> */}
                        </Styles.label>
                      );
                    }
                    return (
                      <div className="checkbox-label" key={option.value}>
                        <Checkbox
                          onChange={(e: any) =>
                            handleCheckboxChange(
                              e,
                              option.value,
                              record.asset_id
                            )
                          }
                          defaultChecked={
                            record.relevant_section !== null &&
                            record.relevant_section?.some(
                              (v) => v === option.value
                            )
                          }
                        >
                          {option.label}
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          ></Styles.ReleventSelect>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "uploadedOn",
      key: "uploadedOn",
      width: 120,
      render: (data: string) => {
        return <Styles.ColumnStyle>{formateDate(data)} </Styles.ColumnStyle>;
      },
    },
    {
      title: "Uploaded by",
      dataIndex: "uploadDate",
      key: "uploadDate",
      render: (_: any, row: IContent) => (
        <Styles.ColumnStyle>{row?.user?.name} </Styles.ColumnStyle>
      ),
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "assetStatus",
      key: "assetStatus",
      render: (_: any, row: IContent) => (
        <Styles.ColumnStyle>
          {renderAssetStatus(row?.assetStatus)}
        </Styles.ColumnStyle>
      ),
      width: 145,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 80,
      render: (_: any, record: IContent) => {
        if (editingKey === record.asset_id) {
          return (
            <Styles.ActionSave
              aria-disabled={
                record?.assetStatus !== AssetUploadStatus.COMPLETED
              }
            >
              <Styles.CheckOut onClick={() => save(record)} />
              <Styles.CloseOut onClick={() => setEditingKey("")} />
            </Styles.ActionSave>
          );
        } else {
          return (
            <Styles.SelectAction
              disabled={record?.assetStatus !== AssetUploadStatus.COMPLETED}
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "1",
                    icon: <Styles.EditButtton />,
                    onClick: () => {
                      edit(record);
                    },
                    disabled: editingKey !== "" ? true : false,
                    label: "Edit",
                  },
                  {
                    key: "2",
                    icon: <Styles.DeleteButtton />,
                    label: "Delete",
                    onClick: () => {
                      setIsDeleteModal(true);
                      setSelectedId(record.asset_id);
                    },
                  },
                ],
              }}
              placement="bottomRight"
              arrow={false}
              $status={record?.assetStatus}
            >
              <img src={IMAGES.actionMenu} alt="Dots Icon" />
            </Styles.SelectAction>
          );
        }
      },
    },
  ];

  const handleOnCancel = () => {
    setIsDeleteModal(false);
  };

  const isEditing = (record: IContent) => record.asset_id === editingKey;

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IContent) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleDeleteContent = async () => {
    setIsDeleteLoading(true);

    try {
      const res: any = await removeContent(
        selectedId,
        searchText,
        currentPage,
        pageLimit
      );
      if (res?.status === 200) {
        handleOnCancel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const handlePagination = (page: number, size: number) => {
    dispatch(setContentCurrentPage(page));
    dispatch(setContentPageLimit(size));
    fetchAllContent(searchText, page, size);
  };

  return (
    <Styles.ContentContainer>
      <Form form={form} component={false}>
        {loading ? (
          <SkeletonTable />
        ) : (
          <Styles.ContentTable
            bordered={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowKey={"asset_id"}
            dataSource={data?.length ? data : []}
            columns={mergedColumns as any}
            scroll={{ y: "calc(100vh - 312px)" }}
            pagination={false}
          />
        )}

        {data && data.length && !loading ? (
          <CustomPagination
            currentPage={currentPage}
            total={totalContentsCount ?? 0}
            handlePagination={handlePagination}
            pageSize={pageLimit}
            pageSizeOptions={[10, 20, 50, 100]}
          />
        ) : (
          <></>
        )}
      </Form>

      {/* Delete Content Modal */}
      <ConfirmationModal
        title="Delete"
        text="Are you sure you want to delete this?"
        isOpen={isDeleteModal}
        handleOk={handleDeleteContent}
        handleCancel={handleOnCancel}
        isLoading={isDeleteLoading}
        btnText="Delete"
      />
    </Styles.ContentContainer>
  );
};

export default ContentManagement;

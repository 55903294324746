import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { TimeRangePickerProps } from "antd";
import dayjs from "dayjs";
import { store } from "../store";
import configs from "../configs/api.configs";
import { setIsPdfShow, setPdfDetails } from "../store/pdfViewer/pdfViewerSlice";

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const removeCookie = () => {
  Cookies.remove("token_acnpa", { domain: configs.HOST });
  Cookies.remove("user_acnpa", { domain: configs.HOST });
};

export const formateDate = (date: string): string => {
  if (date) {
    const formatDate = new Date(date); // Convert received time to a JavaScript Date object

    const currentDate = new Date(); // Get the current date

    const month = formatDate?.toLocaleDateString("en-US", {
      month: "short",
    });

    if (
      formatDate.getDate() === currentDate.getDate() &&
      formatDate.getMonth() === currentDate.getMonth() &&
      formatDate.getFullYear() === currentDate.getFullYear()
    )
      return "Today";
    return `${month} ${formatDate.getDate()}, ${formatDate.getFullYear()}`;
  }
  return "Invalid Date";
};

export const clientGroupOptions = [
  {
    label: "Resources",
    options: [
      { label: "Energy", value: "energy" },
      {
        label: "Chemical and natural resources",
        value: "chemical and natural resources",
      },
      { label: "Utilities", value: "utilities" },
    ],
  },
  {
    label: "Products",
    options: [
      {
        label: "Consumer goods and services",
        value: "consumer goods and services",
      },
      { label: "IMT", value: "imt" },
    ],
  },
];

export const bidStageOptions = [
  {
    value: "Stage_0A",
    label: "Stage 0A",
  },
  {
    value: "Stage_1",
    label: "Stage 1",
  },
  {
    value: "Stage_2A",
    label: "Stage 2A",
  },
  {
    value: "Stage_2B",
    label: "Stage 2B",
  },
  {
    value: "Stage_3A",
    label: "Stage 3A",
  },
  {
    value: "Stage_3B",
    label: "Stage 3B",
  },
];

export const onDownloadPdf = async (
  pre_signed_url: string | null,
  setIsDownloading: (v: boolean) => void,
  fileName: string,
  fileId?: number,
  setFileId?: (v: number) => void
) => {
  if (pre_signed_url) {
    try {
      setIsDownloading(true);
      !!fileId && setFileId && setFileId(fileId);
      // Fetch the PDF file from the URL
      const response = await fetch(pre_signed_url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName); // Specify the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Failed to download file");
    } finally {
      setIsDownloading(false);
      setFileId && setFileId(-1);
    }
  } else {
    toast.error("Failed to download file");
    throw "Failed to download";
  }
};

// under 999
export const uniqueId = () => {
  // Generate a timestamp
  const timestamp = new Date().getTime();

  // Generate a random number
  const random = Math.floor(Math.random() * 1000);

  // Concatenate timestamp and random number
  const combinedId = `${timestamp}${random}`;

  // Convert the combined string to a number and take the modulus with 1000
  const numericId = parseInt(combinedId, 10) % 1000;

  return numericId;
};

export const getFileExtension = (filePath: string): string => {
  const lastDotIndex = filePath?.lastIndexOf(".");
  const fileType =
    lastDotIndex !== -1 ? filePath?.substring(lastDotIndex + 1) : "";

  return fileType ?? "csv";
};

export const handleOpenPdfDoc = (fileName: string, presignedUrl: string) => {
  store.dispatch(setIsPdfShow(true));

  store.dispatch(
    setPdfDetails({ fileName: fileName, presignedUrl: presignedUrl })
  );
};

export const getBarChartColor = (value: string) => {
  switch (value) {
    case "Unassigned":
      return "#8E4DFF";
    case "Qualified":
      return "#C22DD2";
    case "Non-Qualified":
      return "#740F92";
    default:
      return "#740F92";
  }
};

export const getFunnelChartColor = (value: string) => {
  switch (value) {
    case "0A":
      return "#740F92";
    case "1":
      return "#7A2BCC";
    case "2A":
      return "#AD7FFF";
    case "2B":
      return "#B817E8";
    case "3A":
      return "#CC4DD9";
    case "3B":
      return "#A100FF";
    default:
      return "#A100FF";
  }
};

export const getColumnChartColor = (value: string) => {
  switch (value) {
    case "Stage 0A":
      return "#740F92";
    case "Stage 1":
      return "#7A2BCC";
    case "Stage 2A":
      return "#AD7FFF";
    case "Stage 2B":
      return "#B817E8";
    case "Stage 3A":
      return "#CC4DD9";
    case "Stage 3B":
      return "#A100FF";
    default:
      return "#A100FF";
  }
};

export const rangePresets: TimeRangePickerProps["presets"] = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
];

export const projectOptions = [
  {
    value: "digital",
    label: "Digital",
  },
  {
    value: "tech",
    label: "Tech",
  },
  {
    value: "s&c",
    label: "S&C",
  },
  {
    value: "operations",
    label: "Operations",
  },
];

export const debounce = <T>(
  func: (value: T) => void,
  delay: number
): ((value: T) => void) => {
  let timeout: NodeJS.Timeout | null = null;

  return (value: T) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => func(value), delay);
  };
};

export const getCapitalizedStr = (str: string) => {
  if (str) {
    str = str.toLowerCase();
    const words = str.split(" ");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    return words.join(" ");
  }
  return "";
};

export const getUserRole = () => {
  const user = getCookie("user_acnpa");
  const parsedUser = user && JSON.parse(user);

  return parsedUser?.userRole ?? null;
};

export const getFormattedDateByType = (date: string, type: string): string => {
  if (date) {
    const formatDate = new Date(date);
    const month = formatDate?.toLocaleDateString("en-US", {
      month: "short",
    });

    if (type === "type1") {
      return `${formatDate.getDate()}th ${month} ${formatDate.getFullYear()}`;
    } else {
      return `${month} ${formatDate.getDate()}, ${formatDate.getFullYear()}`;
    }
  } else {
    return "";
  }
};

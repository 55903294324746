import styled from "styled-components";
import { Button } from "antd";

export const DashboardContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 65px);
  overflow-y: auto;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #e5eaf8;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled(Button)`
  background: #a100ff;
  color: white;
  width: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #a100ff !important;
    color: white !important;
  }
`;

export const TitleText = styled.span`
  color: ${({ theme }) => theme.colors.fontColorGamma};
  font-family: "GraphikMedium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
`;

export const FilterContainer = styled.div``;

export const FIlterLabel = styled.span`
  font-family: "GraphikRegular";
  font-size: 13px;
  font-style: normal;
  color: #333333;
  margin-right: 15px;
`;

export const ChartContainer = styled.div`
  padding: 20px;
`;

export const ChartCard = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #e5eaf8;
  margin-top: 2px;
`;

export const GroupChartCard = styled.div`
  margin-top: 20px;
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #e5eaf8;
`;

export const GraphWrapper = styled.div`
  border: 2px solid #f3f2f3;
`;

export const GraphStyle = styled.div`
  margin: auto;
  padding: 20px;
`;

export const GraphTitle = styled.p`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  position: relative;
  top: -12px;
  left: 20px;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  padding: 0 5px;
  font-size: 14px;
`;

export const GroupChartStyle = styled.div`
  margin: auto;
  padding: 20px;
`;

export const RFPDetails = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
`;

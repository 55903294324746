import { Skeleton, Spin } from "antd";
import styled from "styled-components";

export const AMContentContainer = styled.div`
  height: calc(100vh - 135px);
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: 100%;
  padding: 12px;
`;

export const Details = styled.div`
  margin-top: 20px;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  position: relative;
  margin-bottom: 16px;
  padding: 15px;
`;

export const TitlePage = styled.div`
  top: -27px;
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 10px;
  left: 20px;
  position: relative;
  padding: 0 5px;
  font-size: 13px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
`;

export const CardsContainerWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 0 20px;
  height: calc(100vh - 222px);
  overflow: auto;
  cursor: pointer;
`;

export const CardContainer = styled.div`
  border: 1px solid #e6e6e6;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  transition: border-color 1s ease;

  &:hover {
    border: 1px solid rgba(161, 0, 255, 0.5);
  }
`;

export const CardHeaderText = styled.div`
  width: 100%;
  font-size: 12px;
  height: 15px;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CardSubHeaderText = styled.div`
  font-size: 10px;
  height: 11px;
  letter-spacing: 0.015em;
  color: #909090;
`;

export const DownloadIcon = styled.img`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
`;

export const Loader = styled(Spin)`
  display: flex;
  align-self: flex-end;
`;

export const ButtonSkeleton = styled(Skeleton.Button)`
  width: 100% !important;
`;

export const EmptyStateWrap = styled(CardsContainerWrap)`
  grid-template-columns: 1fr;
  align-items: center;
  cursor: default;
`;

export const AIIcon = styled.span`
  margin-left: 5px;
`;

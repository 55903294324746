import { toast } from "react-toastify";
import { ExtractionStatus } from "../constants";
import { deleteRequest, get, post, put } from "./apiClient";

export const getAllProposalsDetails = async (rfpId: any) => {
  try {
    const res = await get(`apa/profile/${rfpId}/proposals`);
    return res;
  } catch (error: unknown) {
    throw error;
  }
};

export const updateRelevantPages = async (
  id: string | number,
  request: any,
  message: string
) => {
  try {
    const res = await put(`/apa/projectPlan/${id}/pages`, request);
    if (res.status === 200) {
      toast.success(message);
    }
  } catch (error: unknown) {
    console.log("Error updating project plan pages", error);
    throw error;
  }
};

export const extractProjectPlanDetails = async (
  id: number | string,
  fileName: string
) => {
  try {
    const res = await put(
      `/apa/projectPlan/${id}/extract-project-plan-details/test`,
      {},
      { params: { rfpFileName: fileName } }
    );
    return res;
  } catch (error: unknown) {
    console.log("extract Project Plan Details", error);
    throw error;
  }
};

export const getProjectPlanDetails = async (id: number) => {
  try {
    const res = await get(`/apa/projectPlan/${id}`);
    return res;
  } catch (error: unknown) {
    console.log("Get projectPlan Details", error);
    throw error;
  }
};

export const deleteExtractionFile = async (id: number) => {
  try {
    const res = await deleteRequest(`projectPlan/details-file/${id}`);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const generateProjectPlanDetails = async (
  aiRequest: {
    rfpId: number;
    contentType: string;
    preSignedUrl: string;
    prId: number;
  },
  generationStatus: string,
  formData: FormData,
  uploadedContentType: string
) => {
  try {
    if (generationStatus === ExtractionStatus.AI) {
      const res = await post(
        `/apa/projectPlan/generate-response/test`, // TODO: test need to be remove
        {},
        {
          params: {
            rfpId: aiRequest?.rfpId,
            contentType: aiRequest?.contentType,
            preSignedUrl: aiRequest?.preSignedUrl,
          },
        }
      );
      return res;
    } else {
      // TODO: test need to be remove
      const res = await post(`/apa/projectPlan/generate-response/test`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          rfpId: aiRequest?.rfpId,
          contentType: uploadedContentType,
        },
      });
      return res;
    }
  } catch (error: unknown) {
    console.log("generate Project Plan Details", error);
    throw error;
  }
};

import { useEffect, useRef, useState } from "react";
import { Link, useBlocker } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Empty, MenuProps, Tooltip } from "antd";
import { toast } from "react-toastify";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { RFPGenerationStatus } from "../../../constants";
import {
  IMAGES,
  PrimaryButton,
  SecondaryButton,
  uniqueId,
} from "../../../shared";
import { useOutsideClick } from "../../../shared/hooks/useOutsideClick";
import ConfirmationModal from "../../common/confirmModal/ConfirmationModal";
import { IResearchHistory } from "../../../store/investmentResercher/investmentResercher.interface";
import {
  deleteInvestmentResearchInfo,
  getInvestResearchSearch,
  getRecentHistory,
  stopInvestmentResearch,
  updateInevestResearchInfo,
} from "../../../services/investmentResearcher";
import {
  setSelectedResearch,
  setStopResearchAutoRefresh,
} from "../../../store/investmentResercher/investmentResercherSlice";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 479,
      settings: {
        slidesToShow: 1.3,
        slidesToScroll: 1,
      },
    },
  ],
};

const PromoterResearcher = () => {
  const [researchText, setResearchText] = useState<string>("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteModal, setDeleteModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<IResearchHistory | null>(null);
  const [isUserNavigate, setIsUserNavigate] = useState(false);
  const [isResearchHistoryOpen, setIsResearchHistoryOpen] =
    useState<boolean>(false);

  const researchHistoryRef = useRef(null);

  const dispatch = useDispatch();

  useOutsideClick(
    researchHistoryRef,
    () => setIsResearchHistoryOpen(false),
    isResearchHistoryOpen
  );

  const {
    isLoading,
    researchHistory,
    selectedResearch,
    researchIsLoading,
    researchDeleteLoading,
    researchUpdateLoading,
    stopResearchIsLoading,
  } = useSelector((state: RootState) => state.investmentResearcher);
  const { userId } = useSelector((state: RootState) => state.authSlice);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      selectedResearch?.status === RFPGenerationStatus?.PROCESSING &&
      currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    const recentHistory = async () => {
      await getRecentHistory(userId, true);

      dispatch(setSelectedResearch(null));
    };

    recentHistory();
    return () => {
      dispatch(setStopResearchAutoRefresh(true));
    };
  }, [userId]);

  useEffect(() => {
    if (selectedResearch?.status === RFPGenerationStatus?.COMPLETED) {
      setResearchText("");
    }
  }, [selectedResearch]);

  useEffect(() => {
    if (blocker?.state === "blocked") {
      setIsUserNavigate(true);
    }
  }, [blocker]);

  // Research History list
  const items: MenuProps["items"] = [
    {
      key: "rename",
      label: <span>Rename</span>,
      onClick: () => {
        handleEdit();
        setTimeout(() => {
          setIsResearchHistoryOpen(true);
        }, 0);
      },
    },
    {
      key: "delete",
      label: <span>Delete</span>,
      onClick: () => {
        setDeleteModalOpen(true);
        setTimeout(() => {
          setIsResearchHistoryOpen(true);
        }, 0);
      },
    },
  ];

  const handleEdit = (): void => {
    setIsEditOpen(true);
  };

  const handleResearch = () => {
    if (!researchText) {
      toast.error("Please enter Promoter name");
      return;
    }

    getInvestResearchSearch({
      entityName: researchText,
      userId: userId,
    });
  };

  const handleUpdate = async () => {
    await updateInevestResearchInfo({
      researchId: currentItem?.invest_research_id,
      entityName: currentItem?.entityName,
    });
    getRecentHistory(userId, false);
    setIsEditOpen(false);
    setCurrentItem(null);
  };

  const handleDelete = async () => {
    await deleteInvestmentResearchInfo(
      currentItem?.invest_research_id ?? -1,
      currentItem?.invest_research_id === selectedResearch?.invest_research_id
    );

    setDeleteModalOpen(false);
  };

  const handleStopSearch = async () => {
    if (!!selectedResearch?.invest_research_id) {
      const clearSearch = () => {
        setResearchText("");
        setCurrentItem(null);
        setIsUserNavigate(false);
        dispatch(setSelectedResearch(null));
      };

      await stopInvestmentResearch(
        selectedResearch?.invest_research_id,
        clearSearch
      );

      await getRecentHistory(userId, false);

      blocker.state === "blocked" && blocker.proceed();
    } else {
      toast.error("Failed to stop search");
      setIsUserNavigate(false);
    }
  };

  const handelCancelStopSearchModal = () => {
    setIsUserNavigate(false);
    blocker.state === "blocked" && blocker.reset();
  };

  const renderResearchHistory = () => {
    return (
      <Styles.HistoryDropdown ref={researchHistoryRef}>
        <Styles.TitleWrap>
          <Styles.WrapTitle>Recent history</Styles.WrapTitle>
        </Styles.TitleWrap>
        {researchHistory.length ? (
          <Styles.HistoryList>
            {researchHistory.map((v) => (
              <Styles.HistoryItem
                key={v?.invest_research_id}
                onClick={() => !isEditOpen && dispatch(setSelectedResearch(v))}
                $active={
                  selectedResearch?.invest_research_id === v.invest_research_id
                }
              >
                <Styles.LeftHistory>
                  {currentItem?.invest_research_id === v.invest_research_id &&
                  isEditOpen ? (
                    <Styles.HistoryInputWrap>
                      <Styles.HistoryInputField
                        placeholder="Type in name"
                        value={currentItem?.entityName}
                        onChange={(e) =>
                          setCurrentItem({
                            ...currentItem,
                            entityName: e.target.value,
                          })
                        }
                      />
                      <Styles.InputFieldAction>
                        <Styles.InputButton
                          onClick={handleUpdate}
                          className="check-outlined"
                          loading={researchUpdateLoading}
                        >
                          <CheckOutlined />
                        </Styles.InputButton>
                        <Styles.InputButton
                          onClick={() => [
                            setCurrentItem(null),
                            setIsEditOpen(false),
                          ]}
                          className="close-outlined"
                        >
                          <CloseOutlined />
                        </Styles.InputButton>
                      </Styles.InputFieldAction>
                    </Styles.HistoryInputWrap>
                  ) : (
                    <Styles.ItemTitle>{v.entityName}</Styles.ItemTitle>
                  )}
                </Styles.LeftHistory>
                {currentItem?.invest_research_id !== v.invest_research_id ||
                !isEditOpen ? (
                  <Styles.RightHistory>
                    <Dropdown
                      menu={{ items }}
                      trigger={["click"]}
                      onOpenChange={(_: boolean): void => {
                        setCurrentItem({ ...v });
                      }}
                      placement="bottomRight"
                      overlayClassName="history-action"
                    >
                      <img src={IMAGES.actionMenu} alt="Dots Icon" />
                    </Dropdown>
                  </Styles.RightHistory>
                ) : (
                  ""
                )}
              </Styles.HistoryItem>
            ))}
          </Styles.HistoryList>
        ) : (
          <Styles.EmptyHistory>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Styles.EmptyHistory>
        )}
      </Styles.HistoryDropdown>
    );
  };

  return (
    <>
      <Styles.scrollPage>
        {isLoading ? (
          <Styles.ResearchWrapEmpty>
            <Styles.PromoterResearcherSkeleton />
          </Styles.ResearchWrapEmpty>
        ) : (
          <Styles.ResearchWrap>
            <Styles.InputLabel>
              Enter the name of the Promoter
            </Styles.InputLabel>
            <Styles.InputWrap>
              <Styles.InputField
                placeholder="For eg: Apple"
                value={researchText}
                onChange={(e) => setResearchText(e.target.value)}
                suffix={<img className="search-icon" src={IMAGES.searchIcon} />}
              />
              {selectedResearch?.status === RFPGenerationStatus.PROCESSING && (
                <SecondaryButton
                  type="text"
                  shape="circle"
                  onClick={handleStopSearch}
                  loading={false}
                  icon={<img src={IMAGES.stop} alt="Stop" />}
                />
              )}

              <PrimaryButton
                className="research-btn"
                onClick={handleResearch}
                loading={
                  researchIsLoading ||
                  selectedResearch?.status === RFPGenerationStatus.PROCESSING
                }
              >
                Research
              </PrimaryButton>
            </Styles.InputWrap>
            <Styles.InputBoottmWrap>
              <Styles.Note>
                <b>Note :</b> No prompts required. Enter the name of single
                company.
              </Styles.Note>
              <Styles.History>
                <Styles.HistoryDropdownWrap>
                  <Tooltip
                    overlayClassName="research-history"
                    placement="top"
                    title="Research History"
                  >
                    <img
                      className="history-icon"
                      src={IMAGES.historyIcon}
                      onClick={() => setIsResearchHistoryOpen(true)}
                    />
                  </Tooltip>
                </Styles.HistoryDropdownWrap>
                {isResearchHistoryOpen && renderResearchHistory()}
              </Styles.History>
            </Styles.InputBoottmWrap>
          </Styles.ResearchWrap>
        )}

        <Styles.ShowingResultsWrap>
          <Styles.ShowingResultsHeader>
            <Styles.ShowingResultsTitle>
              {!isLoading && "Showing Results"}
            </Styles.ShowingResultsTitle>
            <Styles.ShowingResultsFilter></Styles.ShowingResultsFilter>
          </Styles.ShowingResultsHeader>

          {isLoading ||
          selectedResearch?.status === RFPGenerationStatus.PROCESSING ? (
            <Styles.ShowingResultsContent>
              <Styles.ResultsCard>
                <Styles.PromoterResearcherSkeleton active />
              </Styles.ResultsCard>
            </Styles.ShowingResultsContent>
          ) : (
            <Styles.ShowingResultsContent>
              {selectedResearch?.researchInfo &&
              selectedResearch?.researchInfo?.length ? (
                selectedResearch?.researchInfo?.map((v) => (
                  <Styles.ResultsCard key={uniqueId()}>
                    <Styles.ResultsTitle>
                      <img className="open-book" src={IMAGES.openBook} />
                      {v?.title}
                    </Styles.ResultsTitle>
                    <Styles.ResultsDescription>
                      <p>{v?.summary}</p>
                    </Styles.ResultsDescription>
                    <Styles.SliderCarousel {...settings}>
                      {v?.links?.map((link) => (
                        <Styles.ResultsSliderCard key={link?.searchLinkId}>
                          <Link to={link?.link} target="_blank">
                            <Styles.ResultsSliderTitle>
                              {link?.title}
                            </Styles.ResultsSliderTitle>
                            <Styles.ResultsSliderDescription>
                              {link?.link}
                            </Styles.ResultsSliderDescription>
                            <img className="viewLink" src={IMAGES.viewLink} />
                          </Link>
                        </Styles.ResultsSliderCard>
                      ))}
                    </Styles.SliderCarousel>
                  </Styles.ResultsCard>
                ))
              ) : (
                <Styles.EmptyStateWrap>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Styles.EmptyStateWrap>
              )}
            </Styles.ShowingResultsContent>
          )}
        </Styles.ShowingResultsWrap>
      </Styles.scrollPage>

      <ConfirmationModal
        title="Delete"
        text="Are you sure you want to delete this?"
        isOpen={isDeleteModal}
        handleOk={() => handleDelete()}
        isLoading={researchDeleteLoading}
        handleCancel={() => setDeleteModalOpen(false)}
        btnText="Delete"
      />

      <ConfirmationModal
        title="Are you sure you want to leave?"
        text="This will stop search"
        isOpen={isUserNavigate}
        handleOk={handleStopSearch}
        handleCancel={handelCancelStopSearchModal}
        isLoading={stopResearchIsLoading}
        btnText="Proceed"
      />
    </>
  );
};

export default PromoterResearcher;

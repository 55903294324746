import { Col, Form, Input, Row, Skeleton } from "antd";
import styled from "styled-components";

export const OverviewContainer = styled.div`
  overflow: auto;
  font-size: 14px;
  height: 100%;
`;

// Body Start
export const OverviewBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 230px;
  gap: 10px;
  height: 100%;
  overflow: hidden;
`;

export const OverviewDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 15px;
  overflow: auto;
`;

export const BodyHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const SaveDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

// export const ButtonCircle = styled(Button)`
//   &.ant-btn {
//     font-size: 4px;
//     padding: 0px;
//   }
// `;

export const RFPDetails = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  position: relative;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
`;

export const RFPTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  position: absolute;
  top: -13px;
  left: 20px;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  padding: 0 5px;
  font-size: 14px;
`;

export const AIIcon = styled.span`
  margin-left: 5px;
`;

export const AntForm = styled(Form)`
  &.ant-form-vertical .ant-form-item-label,
  :where(.css-dev-only-do-not-override-t26387).ant-col-24.ant-form-item-label,
  :where(
      .css-dev-only-do-not-override-t26387
    ).ant-col-xl-24.ant-form-item-label {
    padding: 0;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: 12px;
  }
  .ant-form-item .ant-form-item-control-input {
    min-height: 20px;
  }

  .ant-input-outlined {
    border: none;
    background: #f4f6fc;
    font-size: 12px;
    height: 32px;
  }
  .ant-input-outlined:focus-within {
    box-shadow: none;
  }
`;

export const AntFormItem = styled(Form.Item)``;

export const AntRow = styled(Row)`
  padding: 10px 10px 10px 20px;
`;

export const AntCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const AntColTitle = styled.div`
  font-size: 12px;
`;

export const AntColValue = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  padding: 8px 0px 5px 0px;
  font-size: 12px;
`;

export const AntColInput = styled(Input)``;

export const RFPSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 15px;
  margin-bottom: 10px;
  height: 100%;
  &:last-child {
    margin-bottom: 0px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const RFPSummaryText = styled.div`
  overflow: auto;
  white-space: pre-wrap;
  font-size: 12px;
`;
export const RFPSummaryMain = styled.div`
  position: relative;
  margin-top: 10px;
`;
export const RFPSummaryTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 20px;
  position: absolute;
  top: -9px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  font-size: 14px;
`;

export const InputSummary = styled(Input.TextArea)`
  &.ant-input {
    height: 100%;
    min-height: 180px;
    font-size: 13px;
    color: #333333;
    padding: 5px;
  }
`;

export const EditSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const PrepareRFP = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PrepareRFPTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  font-size: 13px;
  color: #45464e;
  height: fit-content;
  padding: 15px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  border-radius: 4px;
`;

export const PrepareRFPItem = styled.div<{ $isProcessing: boolean }>`
  font-family: "GraphikMedium", sans-serif;
  color: ${({ theme }) => theme.colors.fontColorGamma};
  height: fit-content;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "pointer")};
  border-radius: 2px;
  box-shadow: 0px 4px 4px 0px #e4e4e4;

  img {
    filter: ${({ $isProcessing }) => ($isProcessing ? "grayscale(1)" : "none")};
  }
`;

export const IconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const LableContainer = styled.div``;

export const ProcessingContainer = styled.div`
  font-family: "GraphikRegular", sans-serif;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;

  img {
    height: 12px;
  }

  span {
    color: ${({ theme }) => theme.colors.fontColorXi};
  }
`;

export const GenerateIconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  Button {
    width: 60px;
    height: 30px;
    font-size: 10px;
    color: #a100ff;
    background: #f4edf9;
  }
`;

export const ImgArrow = styled.img`
  height: 10px;
`;

export const CircleRound = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverViewForm = styled(Form)`
  height: 100%;
`;

export const RFPDetailsSkeleton = styled(Skeleton)`
  padding: 10px;
`;

// Body end

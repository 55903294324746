import { Button } from "antd";
import styled from "styled-components";
import { ActiveStatus } from "../../../types";

export const RfpDocumentsContainer = styled.div`
  overflow: auto;
  font-size: 14px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 15px;
`;

export const RfpDocumentsHeader = styled.div<ActiveStatus>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  img {
    cursor: pointer;
    transform: ${({ $active }) => ($active ? "rotate(180deg)" : "rotate(0)")};
    transition: transform 0.3s ease;
  }
`;

export const RfpHeaderText = styled.div`
  font-size: 16px;
  font-family: "GraphikMedium";
  line-height: 30px;
`;

export const ActionIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  margin-left: 10px;

  &:first-child img {
    cursor: pointer;
  }

  & img[alt="actionMenu"] {
    width: 5px;
  }

  & img[alt="download"] {
    width: 20px;
  }
`;

export const DownloadRfpButton = styled(Button)`
  background: none;
  border: none;

  :hover {
    background: none;
    color: unset;
  }

  :active {
    color: unset;
  }

  &.ant-btn-link {
    color: unset;

    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: none;
      color: unset;
    }
  }
`;

export const RfpBorder = styled.div`
  width: 100%;
  height: 2px;
  background: #e7e7e7;
  margin: 20px 0;
`;

export const AdditionalDocumentsContainer = styled.div``;

export const AdditionalDocumentsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  button {
    width: 140px;
  }
`;

export const AdditionalDocumentsText = styled.div`
  font-size: 14px;
  font-family: "GraphikBold";
  line-height: 21.18px;
  color: #6d7987;
`;

export const RfpTitleText = styled.div<ActiveStatus>`
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 20px;
  color: ${({ $active }) => ($active ? "#a100ff" : "unset")};
  cursor: pointer;
`;

export const RfpStatusWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
`;

export const CloseText = styled.div`
  color: #a100ff;
  cursor: pointer;
`;

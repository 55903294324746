import { Table, Tooltip } from "antd";
import styled from "styled-components";

export const RFPTableContainer = styled.div``;

export const RfpTable = styled(Table)`
  & {
    td {
      font-size: 12px;
    }
  }
  &.ant-table-wrapper .ant-table-cell,
  :where(.css-dev-only-do-not-override-pl41c).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-pl41c).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th,
  :where(.css-dev-only-do-not-override-pl41c).ant-table-wrapper
    .ant-table-tbody
    > tr
    > td,
  :where(.css-dev-only-do-not-override-pl41c).ant-table-wrapper tfoot > tr > th,
  :where(.css-dev-only-do-not-override-pl41c).ant-table-wrapper
    tfoot
    > tr
    > td {
    padding: 10px;
  }
`;

// Footer
export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ManualEditTooltip = styled(Tooltip)`
  &.ant-tooltip-inner {
    max-width: 300px;
    word-wrap: break-word;
  }
`;

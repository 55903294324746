import { useEffect, useRef, useState } from "react";
import { Empty, Skeleton } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import { RootState } from "../../../../store";
import { ExtractionStatus, ProposalCategory } from "../../../../constants";
import { IReleventPage } from "./PreQualification.interface";
import { updatePQGenerationStatus } from "../../../../store/pqtq/pqtqSlice";
import ConfirmationModal from "../../../common/confirmModal/ConfirmationModal";
import {
  IMAGES,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../../../../shared";
import {
  deletePQExtractedFile,
  extractPQDetails,
  generatePQResponse,
  getAllPQTQPages,
  stopPQExtraction,
  stopPQGeneration,
  updatePQPages,
} from "../../../../services/pqtq";
import {
  getFileExtension,
  onDownloadPdf,
  uniqueId,
} from "../../../../shared/helpers";

const PreQualification = () => {
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [editIndex, setEditIndex] = useState(-1);
  const [pqFile, setPQFile] = useState<File | null>(null);
  const [isAddRelevantPage, setIsAddRelevantPage] = useState(false);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const [pqFileName, setPQFileName] = useState<string | null>(null);
  const [relevantPages, setRelevantPages] = useState<IReleventPage[]>([]);
  const [pqSummeryDownloading, setPqSummeryDownloading] =
    useState<boolean>(false);
  const [pqGenerationDownloading, setPqGenerationDownloading] =
    useState<boolean>(false);

  const { isPQTQDetailsLoading, pqtqDetails, isDeleteExtractPQFileLoading } =
    useSelector((state: RootState) => state.pqtq);

  const { rfpId } = useParams();

  const dispatch = useDispatch();

  const PQTQPageId = !!pqtqDetails?.pqtqId ? pqtqDetails.pqtqId : -1;

  const currentRfpId = !!rfpId ? parseInt(rfpId) : -1;

  const inputClauseFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getAllPQTQPages(currentRfpId);
  }, [currentRfpId]);

  useEffect(() => {
    setRelevantPages(pqtqDetails?.pqRelevantPages ?? []);
  }, [pqtqDetails?.pqRelevantPages]);

  const handleClauseFileUploadButton = () => {
    if (inputClauseFile.current != null) {
      inputClauseFile.current.click();
    }
  };

  const handleClauseFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files && e.target.files[0];
    if (file) {
      setPQFile(file);

      const formData = new FormData();
      formData.append("file", file);

      // Get the file type from the uploaded file
      const uploadedFile = formData.get("file") as File;
      setPQFileName(uploadedFile.name);

      dispatch(
        updatePQGenerationStatus({
          status: ExtractionStatus.UPLOADED,
          url: "",
          fileName: "",
        })
      );
    }
  };

  const handleCancelProcessing = () => {
    dispatch(
      updatePQGenerationStatus({
        status: ExtractionStatus.NOT_STARTED,
        url: "",
        fileName: "",
      })
    );
    setPQFileName(null);
    setPQFile(null);
    if (inputClauseFile.current) {
      inputClauseFile.current.value = "";
    }
  };

  const handleStartProcessing = () => {
    const formData = new FormData();
    pqFile && formData.append("file", pqFile);

    // Get the file type from the uploaded file
    const uploadedFile = formData?.get("file") as File;
    const fileName = uploadedFile?.name;
    const fileType = fileName?.substring(fileName.lastIndexOf(".") + 1);

    generatePQResponse(
      {
        rfpId: currentRfpId,
        contentType: getFileExtension(pqtqDetails?.pqSectionSummaryFileName),
        preSignedUrl: pqtqDetails?.pqSectionSummaryFilePreSignedUrl,
        pqtqId: pqtqDetails?.pqtqId,
      },
      pqtqDetails?.pqGeneratedRespStatus,
      formData,
      fileType ?? "csv"
    );
  };

  const addRelevantPages = () => {
    setRelevantPages([...relevantPages, { start_page: 0, end_page: 0 }]);
    setEditIndex(relevantPages.length);
    setIsAddRelevantPage(true);
  };

  const handleEdit = (index: number) => {
    setStartPage(relevantPages[index].start_page);
    setEndPage(relevantPages[index].end_page);
    setEditIndex(index);
  };

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setStartPage(Number(value));
    }
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setEndPage(Number(value));
    }
  };

  const handleSaveRelevantPage = () => {
    let data = [...relevantPages];
    data[editIndex] = { end_page: endPage, start_page: startPage };
    setRelevantPages(data);
    setEditIndex(-1);
    setStartPage(0);
    setEndPage(0);
    setIsAddRelevantPage(false);
    updatePQPages(PQTQPageId, data, "PQ pages updated successfully");
  };

  const deleteRelevantPage = (index: number) => {
    const remainingPages = relevantPages.filter((_, i: number) => index !== i);
    updatePQPages(PQTQPageId, remainingPages, "PQ pages removed successfully");
    setRelevantPages(remainingPages);
  };

  const handleRelevantPageExtraction = () => {
    pqtqDetails?.fileName &&
      extractPQDetails({
        id: PQTQPageId,
        rfpFileName: pqtqDetails?.fileName,
      });
  };

  const handleStopExtraction = async () => {
    const request = {
      id: PQTQPageId,
      rfpId: currentRfpId,
      rfpFileName: pqtqDetails?.fileName,
      proposalsCategory: ProposalCategory.SOLUTION,
      proposalSubCategory: "PQ",
      isExtraction: 1,
    };

    await stopPQExtraction(request);
  };

  const handleDeletePQExtractionFile = () => {
    deletePQExtractedFile(PQTQPageId);
  };

  const handleStopGeneration = async () => {
    const request = {
      id: PQTQPageId,
      rfpId: currentRfpId,
      rfpFileName: pqtqDetails?.fileName,
      proposalsCategory: ProposalCategory.SOLUTION,
      proposalSubCategory: "PQ",
      isExtraction: 1,
    };

    await stopPQGeneration(request);
  };

  const renderBadge = () => {
    if (isPQTQDetailsLoading) {
      return (
        <Styles.skeletonLoadingWrap>
          {[...Array(2)].map(() => (
            <Skeleton paragraph={{ rows: 1 }} key={uniqueId()} />
          ))}
        </Styles.skeletonLoadingWrap>
      );
    }

    if (relevantPages?.length) {
      return (
        <Styles.PagesContainer>
          {relevantPages.map((item: IReleventPage, index: number) => {
            return (
              <Styles.BadgeContainer key={index}>
                <Styles.BadgeCustom
                  status="default"
                  count={
                    editIndex !== index ? (
                      <CloseOutlined
                        style={{ color: "#fff", fontSize: "10px" }}
                        onClick={() => {
                          deleteRelevantPage(index);
                        }}
                      />
                    ) : (
                      0
                    )
                  }
                >
                  <Styles.PageBox isedit={editIndex === index ? 1 : 0}>
                    {editIndex === index ? (
                      <>
                        <Styles.PageBoxEditContainer>
                          <Styles.PageBoxSubContainer>
                            <Styles.FromTO>From</Styles.FromTO>
                            <Styles.AntInput
                              value={startPage}
                              onChange={handleFromChange}
                            />
                          </Styles.PageBoxSubContainer>
                          <Styles.BorderRight></Styles.BorderRight>
                          <Styles.PageBoxSubContainer>
                            <Styles.FromTO>To</Styles.FromTO>
                            <Styles.AntInput
                              value={endPage}
                              onChange={handleToChange}
                            />
                          </Styles.PageBoxSubContainer>
                        </Styles.PageBoxEditContainer>
                        <Styles.ButtonLink
                          type="link"
                          disabled={startPage <= 0 || endPage <= 0}
                          onClick={handleSaveRelevantPage}
                        >
                          {isAddRelevantPage ? "Add" : "Save"}
                        </Styles.ButtonLink>
                      </>
                    ) : (
                      <Styles.PageBoxViewContainer>
                        <Styles.PageText>
                          {item?.start_page} to {item?.end_page}
                        </Styles.PageText>
                        <Styles.ButtonLink
                          type="link"
                          onClick={() => {
                            handleEdit(index);
                          }}
                          disabled={
                            pqtqDetails?.pqExtractionStatus !==
                            ExtractionStatus.NOT_STARTED
                          }
                        >
                          Edit
                        </Styles.ButtonLink>
                      </Styles.PageBoxViewContainer>
                    )}
                  </Styles.PageBox>
                </Styles.BadgeCustom>
              </Styles.BadgeContainer>
            );
          })}
        </Styles.PagesContainer>
      );
    } else return <Styles.EmptyState image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  const renderRelevantPageExtraction = () => {
    switch (pqtqDetails?.pqExtractionStatus) {
      case ExtractionStatus.NOT_STARTED:
      case ExtractionStatus.PROCESSING:
      case ExtractionStatus.STOPPING:
        return (
          <>
            {(pqtqDetails?.pqExtractionStatus === ExtractionStatus.PROCESSING ||
              pqtqDetails?.pqExtractionStatus ===
                ExtractionStatus.STOPPING) && (
              <Styles.ExtractLegalContainer>
                <img src={IMAGES.loadingSpinner} alt="loading" />
                <Styles.GenerateText>
                  {pqtqDetails?.pqExtractionStatus === ExtractionStatus.STOPPING
                    ? "Stopping Generation..."
                    : "Generating output..."}
                </Styles.GenerateText>
                <SecondaryButton
                  type="text"
                  shape="circle"
                  onClick={handleStopExtraction}
                  loading={
                    pqtqDetails?.pqExtractionStatus ===
                    ExtractionStatus.STOPPING
                  }
                  icon={<img src={IMAGES.stop} alt="Stop" />}
                />
              </Styles.ExtractLegalContainer>
            )}
            <Styles.ExtractButton
              onClick={handleRelevantPageExtraction}
              // loading={
              //   pqtqDetails?.pqExtractionStatus === ExtractionStatus.PROCESSING
              // }
              disabled={
                pqtqDetails?.pqExtractionStatus === ExtractionStatus.STOPPING
              }
            >
              <img src={IMAGES.submitProcess} alt="extract" />
              Extract PQ requirements
            </Styles.ExtractButton>
          </>
        );

      case ExtractionStatus.COMPLETED:
        return (
          <>
            <Styles.FileText>
              {pqtqDetails?.pqSectionSummaryFileName}
            </Styles.FileText>
            <PrimaryButton
              onClick={() =>
                onDownloadPdf(
                  pqtqDetails?.pqSectionSummaryFilePreSignedUrl,
                  setPqSummeryDownloading,
                  pqtqDetails?.pqSectionSummaryFileName
                )
              }
              loading={pqSummeryDownloading}
            >
              Download
            </PrimaryButton>
            <Styles.DeleteButton
              shape="circle"
              icon={<DeleteOutlined />}
              danger
              loading={isDeleteExtractPQFileLoading}
              onClick={() => {
                setIsDeleteOpenModal(true);
              }}
            />
          </>
        );

      default:
        return (
          <Styles.ExtractButton onClick={handleRelevantPageExtraction}>
            <img src={IMAGES.submitProcess} alt="extract" />
            Extract PQ requirements
          </Styles.ExtractButton>
        );
    }
  };

  const renderGenerateLegalResponse = () => {
    switch (pqtqDetails?.pqGeneratedRespStatus) {
      case ExtractionStatus.NOT_STARTED:
      default:
        return (
          <>
            <Styles.Description>
              {pqtqDetails?.pqGeneratedRespStatus !== ExtractionStatus.COMPLETED
                ? "You can directly upload your modified file to generate responses"
                : "The above file generated is an assistive step for your RFP legal clause extraction. To generate a response you can either use the above generated AI file or upload your own modified file"}
            </Styles.Description>
            <Styles.SubContainer>
              <PrimaryButton
                onClick={() =>
                  dispatch(
                    updatePQGenerationStatus({
                      status: ExtractionStatus.UPLOADING,
                      url: "",
                      fileName: "",
                    })
                  )
                }
                disabled={
                  pqtqDetails?.pqGeneratedRespStatus === ExtractionStatus.AI ||
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.PROCESSING ||
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.STOPPING
                }
              >
                Upload modified file
              </PrimaryButton>
              <span>Or</span>
              <Styles.AIFilesButton
                $active={
                  pqtqDetails?.pqGeneratedRespStatus === ExtractionStatus.AI ||
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.PROCESSING
                }
                disabled={
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.COMPLETED ||
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.STOPPING ||
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.PROCESSING ||
                  pqtqDetails?.pqExtractionStatus !== ExtractionStatus.COMPLETED
                }
                onClick={() =>
                  dispatch(
                    updatePQGenerationStatus({
                      status: ExtractionStatus.AI,
                      url: "",
                      fileName: "",
                    })
                  )
                }
              >
                Use AI generated file
              </Styles.AIFilesButton>
              <Styles.NoteContainer>
                <Styles.NoteTitle>Note:</Styles.NoteTitle>
                <Styles.NoteDescription>
                  Please verify all the documents before processing
                </Styles.NoteDescription>
              </Styles.NoteContainer>
            </Styles.SubContainer>
          </>
        );

      case ExtractionStatus.COMPLETED:
        return (
          <>
            <Styles.GenerateResponseSubText>
              Your file processing is successfully completed and is now
              available for download.
            </Styles.GenerateResponseSubText>
            <Styles.DownloadContainer>
              <PrimaryButton
                loading={pqGenerationDownloading}
                onClick={() => {
                  if (pqtqDetails?.pqGeneratedResponseFilePreSignedUrl)
                    onDownloadPdf(
                      pqtqDetails?.pqGeneratedResponseFilePreSignedUrl,
                      setPqGenerationDownloading,
                      pqtqDetails?.pqGeneratedResponseFileName
                    );
                }}
              >
                Download
              </PrimaryButton>
              <span>{pqtqDetails?.pqGeneratedResponseFileName}</span>
            </Styles.DownloadContainer>
          </>
        );

      case ExtractionStatus.UPLOADING:
      case ExtractionStatus.UPLOADED:
        return (
          <>
            <Styles.Description>
              Please note before uploading, ensure it is a{" "}
              <span>single column</span> file titled “Legal Clause” or similar.
            </Styles.Description>
            <Styles.UploadFileTitle>
              Upload your modified file here
            </Styles.UploadFileTitle>
            <Styles.UploadSubContainer>
              <Styles.UploadIconTitle>
                <img src={IMAGES.browse} alt="Browse" />
                <span>{pqFileName ?? "Browse"}</span>
              </Styles.UploadIconTitle>

              <PrimaryButton onClick={handleClauseFileUploadButton}>
                Browse
              </PrimaryButton>
              <input
                type="file"
                ref={inputClauseFile}
                accept=".xlsx, .xls, .csv"
                onChange={handleClauseFileUpload}
                hidden
              />
            </Styles.UploadSubContainer>
          </>
        );
    }
  };

  const renderStartStopGeneration = () => {
    return (
      <Styles.UploadFileContainer>
        <Styles.CancelStartContainer>
          {(pqtqDetails?.pqGeneratedRespStatus ===
            ExtractionStatus.PROCESSING ||
            pqtqDetails?.pqGeneratedRespStatus ===
              ExtractionStatus.STOPPING) && (
            <Styles.CancelStartContainer>
              <img src={IMAGES.loadingSpinner} alt="loading" />
              <Styles.GenerateText>
                {pqtqDetails?.pqGeneratedRespStatus ===
                ExtractionStatus.STOPPING
                  ? "Stopping Generation..."
                  : "Generating output..."}
              </Styles.GenerateText>
              <SecondaryButton
                type="text"
                shape="circle"
                onClick={() => {
                  handleStopGeneration();
                }}
                icon={<img src={IMAGES.stop} alt="Stop" />}
              />
            </Styles.CancelStartContainer>
          )}
          {(pqtqDetails?.pqGeneratedRespStatus ===
            ExtractionStatus.NOT_STARTED ||
            pqtqDetails?.pqGeneratedRespStatus === ExtractionStatus.AI ||
            pqtqDetails?.pqGeneratedRespStatus === ExtractionStatus.UPLOADED ||
            pqtqDetails?.pqGeneratedRespStatus ===
              ExtractionStatus.UPLOADING) && (
            <>
              <SecondaryButton
                disabled={
                  pqtqDetails?.pqGeneratedRespStatus ===
                  ExtractionStatus.NOT_STARTED
                }
                onClick={handleCancelProcessing}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={handleStartProcessing}
                disabled={
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.NOT_STARTED ||
                  pqtqDetails?.pqGeneratedRespStatus ===
                    ExtractionStatus.UPLOADING
                }
                // loading={
                //   pqtqDetails?.pqGeneratedRespStatus ===
                //   ExtractionStatus.PROCESSING
                // }
              >
                Start Processing
              </PrimaryButton>
            </>
          )}
        </Styles.CancelStartContainer>
      </Styles.UploadFileContainer>
    );
  };

  return (
    <Styles.QualificationContent>
      <Styles.Details>
        <Styles.TitlePage>
          Relevant pages for Pre-Qualification Criteria
          <Styles.AIIcon>
            <img src={IMAGES.aiTagLogo} alt="Logo" />
          </Styles.AIIcon>
        </Styles.TitlePage>
        {renderBadge()}
        <Styles.ReleventPagesFooter>
          <Styles.BtnStyling>
            <TertiaryButton
              icon={<PlusOutlined />}
              onClick={addRelevantPages}
              disabled={
                pqtqDetails?.pqExtractionStatus !== ExtractionStatus.NOT_STARTED
              }
            >
              Add pages
            </TertiaryButton>
          </Styles.BtnStyling>
          <Styles.FooterRightPart>
            {renderRelevantPageExtraction()}
          </Styles.FooterRightPart>
        </Styles.ReleventPagesFooter>
        <Styles.NoteContainer>
          <Styles.NoteTitle>Note:</Styles.NoteTitle>
          <Styles.NoteDescription>
            Please verify the above above pages before proceeding to the next
            step of extraction
          </Styles.NoteDescription>
        </Styles.NoteContainer>
      </Styles.Details>

      {/* Generate response */}

      <Styles.Details>
        <Styles.TitlePage>Generate response</Styles.TitlePage>
        {renderGenerateLegalResponse()}
        {renderStartStopGeneration()}
      </Styles.Details>

      <ConfirmationModal
        title="Delete File"
        text={"Are you sure you want to delete this file?"}
        isOpen={isDeleteOpenModal}
        isDanger={true}
        btnText="Delete"
        handleOk={() => {
          setIsDeleteOpenModal(false);
          handleDeletePQExtractionFile();
        }}
        handleCancel={() => {
          setIsDeleteOpenModal(false);
        }}
      />
    </Styles.QualificationContent>
  );
};

export default PreQualification;

import styled from "styled-components";
import { PrimaryButton } from "../../shared";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorAlpha};
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`;

export const HeaderText = styled.div`
  font-family: "GraphikRegular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.fontColorGamma};
`;

export const HeaderTabWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const AiTagContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 0.5px solid #a05eff;
  padding: 4px 8px;
  border-radius: 15px;
  filter: drop-shadow(0px 3px 4px rgba(127, 0, 201, 0.08));
`;

export const AiTagText = styled.div`
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  font-family: "GraphikMedium";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 12px;
`;

export const CurrentLink = styled.div`
  font-family: "GraphikMedium";
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  color: #45464e;
  text-transform: capitalize;
`;

export const Link = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
`;

export const UploadButton = styled(PrimaryButton)`
  height: 28px;
  font-size: 13px;

  img {
    width: 13px;
  }
`;

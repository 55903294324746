import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { editPolicy966 } from "../../../services/policy966";
import { MarkDownText } from "../../../shared/markDownText/MarkDownText";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../shared";

interface IPageData {
  id: number;
  key: string;
  question: string;
}

const AboutCompany = () => {
  const [isEdit, setIsEdit] = useState<number>(-1);

  // first page values
  const [companyOverview, setCompanyOverview] = useState<string>("");
  const [companyProfile, setCompanyProfile] = useState<string>("");
  const [
    geographicalCoverageOfOperations,
    setGeographicalCoverageOfOperations,
  ] = useState<string>("");

  // second page values
  const [ownershipDetails, setOwnershipDetails] = useState<string>("");
  const [compositionOfBoardOfDirectors, setCompositionOfBoardOfDirectors] =
    useState<string>("");
  const [governanceAndComplianceTeam, setGovernanceAndComplianceTeam] =
    useState<string>("");

  const { policy966Data, isEditPolicy966Loading, isPolicy966DataLoading } =
    useSelector((state: RootState) => state.policy966);
  const { getOverView } = useSelector((state: RootState) => state.overview);

  const { rfpId } = useParams();

  const currentRfpId: number = rfpId ? Number(rfpId) : -1;

  const setInitialValues = () => {
    setCompanyOverview(policy966Data?.companyOverview ?? "");
    setCompanyProfile(policy966Data?.companyProfile ?? "");
    setGeographicalCoverageOfOperations(
      policy966Data?.geographicalCoverageOfOperations ?? ""
    );

    setOwnershipDetails(policy966Data?.ownershipDetails ?? "");
    setCompositionOfBoardOfDirectors(
      policy966Data?.compositionOfBoardOfDirectors ?? ""
    );
    setGovernanceAndComplianceTeam(
      policy966Data?.governanceAndComplianceTeam ?? ""
    );
  };

  useEffect(() => {
    setInitialValues();
  }, [policy966Data]);

  const firstPageData: IPageData[] = [
    {
      id: 1,
      key: "Type of company:",
      question: companyOverview,
    },
    {
      id: 2,
      key: "Nature of business operations:",
      question: companyProfile,
    },
    {
      id: 3,
      key: "Geographical coverage of operations :",
      question: geographicalCoverageOfOperations,
    },
  ];

  const secondPageData: IPageData[] = [
    {
      id: 1,
      key: "Key Questions to be Answered:",
      question: ownershipDetails,
    },
    {
      id: 2,
      key: "Composition of the Board Of Directors ::",
      question: compositionOfBoardOfDirectors,
    },
    {
      id: 3,
      key: "Governance & Compliance Team, if any :",
      question: governanceAndComplianceTeam,
    },
  ];

  const handleEditPolicy = async () => {
    const editedPolicy = {
      ...policy966Data,
      companyOverview,
      companyProfile,
      ownershipDetails,
      governanceAndComplianceTeam,
      compositionOfBoardOfDirectors,
      geographicalCoverageOfOperations,
    };
    await editPolicy966(editedPolicy, currentRfpId);
    setIsEdit(-1);
  };

  const renderKeyQuestions = (
    question: string,
    value: string,
    setQuestion: (v: string) => void,
    pageNumber: number
  ) => {
    return (
      <Styles.QuestionsItem>
        <Styles.Question>{value}</Styles.Question>
        <Styles.Answered>
          {isPolicy966DataLoading ? (
            <Styles.SkeletonLoading active />
          ) : isEdit === pageNumber ? (
            <Styles.InputSummary
              value={question}
              onChange={(e) => {
                setQuestion(e?.target?.value);
                console.log(e?.target?.value, "onChange input");
              }}
              autoSize={{ minRows: 3, maxRows: 100 }}
            />
          ) : (
            <MarkDownText text={question} />
          )}
        </Styles.Answered>
      </Styles.QuestionsItem>
    );
  };

  const renderAboutCompany = (
    pageData: IPageData[],
    isKeyQuestionTextVisible: boolean,
    setFirstQuestion: (v: string) => void,
    setSecondQuestion: (v: string) => void,
    setThirdQuestion: (v: string) => void,
    pageNumber: number
  ) => {
    return (
      <Styles.PolicyNote>
        <Styles.PolicyNoteHeader>
          <Styles.AppLogo src={IMAGES.accentureLogo} alt="accentureLogo" />
          <Styles.PolicyNoteHeaderTitle>
            Policy 966 : Approval Note
          </Styles.PolicyNoteHeaderTitle>
          <Styles.PolicyNoteHeaderSubTitle>
            {getOverView?.client_name}
          </Styles.PolicyNoteHeaderSubTitle>
        </Styles.PolicyNoteHeader>
        <Styles.PolicyNoteContent>
          <Styles.PolicyNoteContentTitle>
            About the company
          </Styles.PolicyNoteContentTitle>
          {isEdit === pageNumber ? (
            <Styles.SaveDiv>
              <SecondaryButton
                onClick={() => {
                  setIsEdit(-1), setInitialValues();
                }}
                disabled={isEditPolicy966Loading}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={handleEditPolicy}
                loading={isEditPolicy966Loading}
              >
                Save
              </PrimaryButton>
            </Styles.SaveDiv>
          ) : (
            <Styles.EditButton
              disabled={isEditPolicy966Loading}
              onClick={() => {
                setInitialValues();
                setIsEdit(pageNumber);
              }}
            >
              Edit
            </Styles.EditButton>
          )}
          <Styles.QuestionsKey>
            {isKeyQuestionTextVisible && (
              <Styles.QuestionsKeyTitle>
                Key Questions to be Answered:
              </Styles.QuestionsKeyTitle>
            )}
            <Styles.QuestionsList>
              {renderKeyQuestions(
                pageData[0]?.question,
                pageData[0]?.key,
                setFirstQuestion,
                pageNumber
              )}

              {renderKeyQuestions(
                pageData[1]?.question,
                pageData[1]?.key,
                setSecondQuestion,
                pageNumber
              )}

              {renderKeyQuestions(
                pageData[2]?.question,
                pageData[2]?.key,
                setThirdQuestion,
                pageNumber
              )}
            </Styles.QuestionsList>
          </Styles.QuestionsKey>
        </Styles.PolicyNoteContent>
        <Styles.Footer>
          <Styles.FooterCopyright>
            © 2024 Accenture NBM. All Right Reserved.
          </Styles.FooterCopyright>
          <Styles.FooterRight>
            Selection of System Integrator (ERPSI) for Supply, Installation,
            Commissioning, Implementation and Support for Enterprise  Resource
            Planning (ERP) System in NBPDCL & SBPDCL
          </Styles.FooterRight>
        </Styles.Footer>
      </Styles.PolicyNote>
    );
  };

  return (
    <Styles.PolicyNoteContainer>
      {renderAboutCompany(
        firstPageData,
        true,
        setCompanyOverview,
        setCompanyProfile,
        setGeographicalCoverageOfOperations,
        1
      )}
      {renderAboutCompany(
        secondPageData,
        false,
        setOwnershipDetails,
        setCompositionOfBoardOfDirectors,
        setGovernanceAndComplianceTeam,
        2
      )}
    </Styles.PolicyNoteContainer>
  );
};

export default AboutCompany;

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown, MenuProps } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { persistStore } from "redux-persist";
import * as Styles from "./styles";
import { store } from "../../store";
import { IMAGES } from "../../shared";
import { PATHS, AdminTabs, Role } from "../../constants";
import { removeCookie, getUserRole } from "../../shared/helpers";
import { useDispatch } from "react-redux";
import {
  updateFilter,
  setResetRfpPagination,
  clearFilter,
} from "../../store/allRfps/allRfpSlice";
import {
  setResetUserPaginationAndSearch,
  setAdminTab,
} from "../../store/userManagement/usersSlice";
import { setResetContentPaginationAndSearch } from "../../store/contentManagement/contentManagementSlice";
import { setClearRequiredStateOnInvestementTab } from "../../store/investmentResercher/investmentResercherSlice";

const SideNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const items: MenuProps["items"] = [
    {
      key: "Reset",
      label: "Reset Password",
      onClick: () => {
        navigate(PATHS.resetPassword);
      },
      icon: <img src={IMAGES.resetPassword} />,
    },
    {
      key: "Logout",
      label: "Logout",
      onClick: () => {
        toast.success("Logout Successfully");
        persistStore(store).purge();
        removeCookie();
        navigate(PATHS.login);
      },
      icon: <LogoutOutlined />,
    },
  ];

  const handleNavigate = (path: PATHS) => {
    if (path === PATHS.rfps) {
      dispatch(setResetRfpPagination());
      dispatch(clearFilter());
    } else if (path === PATHS.user) {
      dispatch(setResetUserPaginationAndSearch());
      dispatch(setAdminTab(AdminTabs.User));
      dispatch(setResetContentPaginationAndSearch());
    } else if (path === PATHS.aiBulletin) {
      dispatch(setClearRequiredStateOnInvestementTab());
    }
    navigate(path);
  };

  const checkActiveStatus = (path: PATHS) => {
    if (path === PATHS.rfps && pathname.includes(PATHS.rfp)) return true;
    else if (pathname === path) return true;
    return false;
  };

  const renderNavIcon = (
    title: string,
    Icon: string,
    activeIcon: string,
    isActive: boolean,
    handleOnClick: () => void
  ) => {
    return (
      <Styles.NavIconTitleWrap onClick={handleOnClick} $active={isActive}>
        <img src={isActive ? activeIcon : Icon} alt={title} />
        <Styles.NavTitle>{title}</Styles.NavTitle>
      </Styles.NavIconTitleWrap>
    );
  };

  return (
    <Styles.SideNavContainer>
      <Styles.AppLogo src={IMAGES.accentureLogo} alt="accentureLogo" />

      <Styles.NavIconWrap>
        {renderNavIcon(
          "Home",
          IMAGES.homeIcon,
          IMAGES.activeHomeIcon,
          checkActiveStatus(PATHS.home),
          () => {
            dispatch(updateFilter({ field: "rfpName", value: "" }));
            dispatch(updateFilter({ field: "clientGroup", value: "" }));
            dispatch(updateFilter({ field: "bidStage", value: "" }));
            dispatch(updateFilter({ field: "startDate", value: "" }));
            dispatch(updateFilter({ field: "endDate", value: "" }));
            handleNavigate(PATHS.home);
          }
        )}
        {renderNavIcon(
          "Dashboard",
          IMAGES.dashboardIcon,
          IMAGES.activeDashboardIcon,
          checkActiveStatus(PATHS.dashboard),
          () => handleNavigate(PATHS.dashboard)
        )}
        {renderNavIcon(
          "RFPs",
          IMAGES.rfpsIcon,
          IMAGES.activeRfpsIcon,
          checkActiveStatus(PATHS.rfps),
          () => {
            if (pathname !== PATHS.rfps) {
              handleNavigate(PATHS.rfps);
            }
          }
        )}
        {renderNavIcon(
          "Investment researcher",
          IMAGES.aiBulletinIcon,
          IMAGES.activeAiBulletinIcon,
          checkActiveStatus(PATHS.aiBulletin),
          () => {
            if (pathname !== PATHS.aiBulletin) {
              handleNavigate(PATHS.aiBulletin);
            }
          }
        )}
      </Styles.NavIconWrap>

      <Styles.FooterWrapper>
        {getUserRole() === Role.Admim && (
          <Styles.AdminTextIconWrap
            onClick={() => {
              if (pathname !== PATHS.user) {
                handleNavigate(PATHS.user);
              }
            }}
            $active={checkActiveStatus(PATHS.user)}
          >
            <img src={IMAGES.usersIcons} alt="users" />
            <Styles.AdminText>Admin</Styles.AdminText>
          </Styles.AdminTextIconWrap>
        )}

        <Dropdown
          menu={{
            items,
          }}
          placement="top"
          trigger={["hover"]}
          arrow
        >
          <Styles.NavFooter>
            <img src={IMAGES.profileIcon} alt="profileIcon" />
            <Styles.ActiveIcon>
              <div />
            </Styles.ActiveIcon>
          </Styles.NavFooter>
        </Dropdown>
      </Styles.FooterWrapper>
    </Styles.SideNavContainer>
  );
};

export default SideNav;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, FormInstance, Input, InputRef } from "antd";
import * as Styles from "./styles";
import { EditableCellProps, EditableRowProps } from "../../../types";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: EditableCellProps) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values, dataIndex });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: "0" }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={toggleEdit} />
      </Form.Item>
    ) : (
      <Styles.EditableCellValueWrap
        onClick={(e) => {
          e.stopPropagation();
          toggleEdit();
        }}
      >
        {children}
      </Styles.EditableCellValueWrap>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  IMAGES,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../../../../shared";
import * as Styles from "./styles";
import { useEffect, useState } from "react";
import { IReleventPage } from "./PaymentTerms.interface";
import ConfirmationModal from "../../../common/confirmModal/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ExtractionStatus } from "../../../../constants";
import {
  deleteExtractionFile,
  extractpaymentTermsDetails,
  getAllProposalsDetails,
  getPaymentTermsDetails,
  updateRelevantPages,
} from "../../../../services/solutionPaymentTerms";
import { useParams } from "react-router-dom";
import { setPaymentTermsDetails } from "../../../../store/solutionPaymentTerms/paymentTermsSlice";
import { IAllRfp } from "../../../../store/allRfps/allRfp.interface";
import { toast } from "react-toastify";
import { stopRFPExtGen } from "../../../../services/stopRfpExtGen";
import { onDownloadPdf } from "../../../../shared/helpers";

let ExtractionInterval: ReturnType<typeof setInterval>;

const PaymentTerms = () => {
  const { paymentTermsDetails } = useSelector(
    (state: RootState) => state.solutionPaymentTerms
  );
  const { allRfps } = useSelector((state: RootState) => state.allRfps);
  const { rfpId } = useParams();
  const dispatch = useDispatch();
  const [selectedRFP, setSelectedRFP] = useState<IAllRfp>();
  const [relevantPages, setRelevantPages] = useState<IReleventPage[]>([]);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [editIndex, setEditIndex] = useState(-1);
  const [isAddRelevantPage, setIsAddRelevantPage] = useState(false);
  const [isStopGenerating, setIsStopGenerating] = useState(false);
  const [isExtractionLoading, setIsExtractionLoading] = useState(false);
  const [isRemainingExtraction, setIsRemainingExtraction] = useState(false);
  const [isExtractedFileDeleting, setIsExtractedFileDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [ppId, setPpId] = useState<string | number>();

  const fetchFinanceDetails = async () => {
    try {
      const response = await getAllProposalsDetails(rfpId);

      if (!!response?.paymentTerms) {
        dispatch(setPaymentTermsDetails(response?.paymentTerms));
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (rfpId) {
      fetchFinanceDetails();
      const findRFP = allRfps?.find((rfp) => rfp.rfp_id === Number(rfpId));
      if (findRFP) {
        setSelectedRFP(findRFP);
      }
    }
  }, [rfpId]);

  useEffect(() => {
    if (paymentTermsDetails?.relevantPages?.length) {
      setRelevantPages(paymentTermsDetails?.relevantPages);
      if (
        paymentTermsDetails?.extractionStatus === ExtractionStatus.PROCESSING ||
        paymentTermsDetails?.extractionStatus === ExtractionStatus.STOPPING
      ) {
        setIsRemainingExtraction(true);
      }
    }
  }, [paymentTermsDetails]);

  useEffect(() => {
    if (paymentTermsDetails?.ppId) {
      setPpId(paymentTermsDetails?.ppId);
    }
  }, [paymentTermsDetails]);

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setStartPage(Number(value));
    }
  };
  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setEndPage(Number(value));
    }
  };

  const deleteRelevantPage = (index: number) => {
    const remainingPages = relevantPages.filter(
      (_: any, i: number) => index !== i
    );
    updateRelevantPages(
      Number(ppId),
      remainingPages,
      "Payment terms pages deleted successfully"
    );
    setRelevantPages(remainingPages);
  };

  const handleSaveRelevantPage = () => {
    let data = [...relevantPages];
    const updatedPage = { end_page: endPage, start_page: startPage };
    data[editIndex] = updatedPage;
    setRelevantPages(data);
    setEditIndex(-1);
    setStartPage(0);
    setEndPage(0);
    setIsAddRelevantPage(false);
    updateRelevantPages(
      Number(ppId),
      data,
      "Payment terms pages updated successfully"
    );
  };

  const handleEdit = (index: number) => {
    setStartPage(relevantPages[index].start_page);
    setEndPage(relevantPages[index].end_page);
    setEditIndex(index);
  };

  const addRelevantPages = () => {
    setRelevantPages([...relevantPages, { start_page: 0, end_page: 0 }]);
    setEditIndex(relevantPages.length);
    setIsAddRelevantPage(true);
  };

  const handleExtractionClause = async () => {
    try {
      setIsExtractionLoading(true);
      if (paymentTermsDetails?.ppId && selectedRFP?.file_name) {
        const response = await extractpaymentTermsDetails(
          paymentTermsDetails?.ppId,
          selectedRFP?.file_name
        );
        if (response.status === 200) {
          FetchPayTermsDetails();
          setIsRemainingExtraction(true);
        }
      }
      setIsExtractionLoading(false);
    } catch (error) {
      setIsExtractionLoading(false);
      console.log("Error while extracting clause finane", error);
    }
  };

  useEffect(() => {
    if (isRemainingExtraction) {
      ExtractionInterval = setInterval(async () => {
        const res = await getPaymentTermsDetails(paymentTermsDetails?.ppId);
        if (
          res?.extractionStatus !== ExtractionStatus.PROCESSING &&
          res?.extractionStatus !== ExtractionStatus.STOPPING
        ) {
          dispatch(setPaymentTermsDetails(res));
          setIsRemainingExtraction(false);
          clearInterval(ExtractionInterval);
        }
      }, 10000);

      return () => {
        clearInterval(ExtractionInterval);
      };
    }
  }, [isRemainingExtraction]);

  const FetchPayTermsDetails = async () => {
    try {
      const response = await getPaymentTermsDetails(paymentTermsDetails?.ppId);
      if (response?.ppId) {
        dispatch(setPaymentTermsDetails(response));
      }
    } catch (error) {
      console.log("Error while getting PaymentTerms details", error);
    }
  };

  const handleDeleteExtractionFile = async () => {
    try {
      if (paymentTermsDetails?.ppId) {
        setIsExtractedFileDeleting(true);
        const response: any = await deleteExtractionFile(
          paymentTermsDetails?.ppId
        );
        if (response?.status === 200) {
          toast.success("File deleted successfully");
          FetchPayTermsDetails();
        }
      }
      setIsExtractedFileDeleting(false);
    } catch (error) {
      setIsExtractedFileDeleting(false);
      console.log("Error while deleting extracted file", error);
    }
  };

  const handleStopExtraction = async () => {
    try {
      if (Number(ppId) && Number(rfpId) && selectedRFP?.file_name) {
        setIsStopGenerating(true);
        const request = {
          id: Number(ppId),
          rfpId: Number(rfpId),
          rfpFileName: selectedRFP?.file_name,
          proposalsCategory: "FINANCE",
          isExtraction: 1,
        };
        const response = await stopRFPExtGen(request);
        if (response?.status === 200) {
          const { status } = response.data;
          if (status) {
            dispatch(
              setPaymentTermsDetails({
                ...paymentTermsDetails,
                extractionStatus: status,
              })
            );
          } else {
            toast.error(
              "we can't stop now. The Extraction has been completed successfully."
            );
            dispatch(
              setPaymentTermsDetails({
                ...paymentTermsDetails,
                extractionStatus: ExtractionStatus.STOPPING,
              })
            );
          }
        }
        setIsStopGenerating(false);
      }
    } catch (error) {
      setIsStopGenerating(false);
      console.log("Error while stop extracting file", error);
    }
  };

  const isDisabledExtractionBtn = () => {
    return (
      relevantPages?.length <= 0 ||
      paymentTermsDetails?.extractionStatus === ExtractionStatus.PROCESSING ||
      paymentTermsDetails?.extractionStatus === ExtractionStatus.STOPPING ||
      paymentTermsDetails?.extractionStatus === ExtractionStatus.COMPLETED
    );
  };

  return (
    <Styles.PaymentTermsContainer>
      <Styles.Details>
        <Styles.TitlePage>
          Relevant pages for Payment Terms
          <Styles.AIIcon>
            <img src={IMAGES.aiTagLogo} alt="Logo" />
          </Styles.AIIcon>
        </Styles.TitlePage>
        <Styles.PagesContainer>
          {relevantPages?.length > 0 &&
            relevantPages.map((item: IReleventPage, index: number) => {
              return (
                <Styles.BadgeContainer key={index}>
                  <Styles.BadgeCustom
                    status="default"
                    count={
                      editIndex !== index ? (
                        <SecondaryButton
                          disabled={isDisabledExtractionBtn()}
                          type="text"
                          shape="circle"
                          icon={
                            <CloseOutlined
                              style={{ color: "#fff", fontSize: "10px" }}
                              onClick={() => {
                                deleteRelevantPage(index);
                              }}
                            />
                          }
                        />
                      ) : (
                        0
                      )
                    }
                  >
                    <Styles.PageBox isedit={editIndex === index ? 1 : 0}>
                      {editIndex === index ? (
                        <>
                          <Styles.PageBoxEditContainer>
                            <Styles.PageBoxSubContainer>
                              <Styles.FromTO>From</Styles.FromTO>
                              <Styles.AntInput
                                value={startPage}
                                onChange={handleFromChange}
                              />
                            </Styles.PageBoxSubContainer>
                            <Styles.BorderRight></Styles.BorderRight>
                            <Styles.PageBoxSubContainer>
                              <Styles.FromTO>To</Styles.FromTO>
                              <Styles.AntInput
                                value={endPage}
                                onChange={handleToChange}
                              />
                            </Styles.PageBoxSubContainer>
                          </Styles.PageBoxEditContainer>
                          <Styles.ButtonLink
                            type="link"
                            disabled={
                              startPage <= 0 ||
                              endPage <= 0 ||
                              paymentTermsDetails?.extractionStatus ===
                                ExtractionStatus.COMPLETED
                            }
                            onClick={handleSaveRelevantPage}
                          >
                            {isAddRelevantPage ? "Add" : "Save"}
                          </Styles.ButtonLink>
                        </>
                      ) : (
                        <Styles.PageBoxViewContainer>
                          <Styles.PageText>
                            {item?.start_page} to {item?.end_page}
                          </Styles.PageText>
                          <Styles.ButtonLink
                            disabled={isDisabledExtractionBtn()}
                            type="link"
                            onClick={() => {
                              handleEdit(index);
                            }}
                          >
                            Edit
                          </Styles.ButtonLink>
                        </Styles.PageBoxViewContainer>
                      )}
                    </Styles.PageBox>
                  </Styles.BadgeCustom>
                </Styles.BadgeContainer>
              );
            })}
        </Styles.PagesContainer>

        <Styles.ReleventPagesFooter>
          <Styles.BtnStyling>
            <TertiaryButton
              disabled={
                paymentTermsDetails?.extractionStatus !==
                ExtractionStatus.NOT_STARTED
              }
              icon={<PlusOutlined />}
              onClick={addRelevantPages}
            >
              Add more pages
            </TertiaryButton>
          </Styles.BtnStyling>
          <Styles.FooterRightPart>
            {paymentTermsDetails?.extractionStatus ===
              ExtractionStatus.PROCESSING &&
              !isStopGenerating && (
                <Styles.ExtractLegalContainer>
                  <img src={IMAGES.loadingSpinner} alt="loading" />
                  <Styles.GenerateText>
                    Generating output...
                  </Styles.GenerateText>
                  <SecondaryButton
                    type="text"
                    shape="circle"
                    onClick={handleStopExtraction}
                    icon={<img src={IMAGES.stop} alt="Stop" />}
                  />
                </Styles.ExtractLegalContainer>
              )}
            {(paymentTermsDetails?.extractionStatus ===
              ExtractionStatus.STOPPING ||
              isStopGenerating) && (
              <Styles.ExtractLegalContainer>
                <img src={IMAGES.loadingSpinner} alt="loading" />
                <Styles.GenerateText>
                  Stopping Generation...
                </Styles.GenerateText>
              </Styles.ExtractLegalContainer>
            )}
            {paymentTermsDetails?.extractionStatus !==
              ExtractionStatus.COMPLETED && (
              <Styles.ExtractButton
                loading={isExtractionLoading}
                disabled={isDisabledExtractionBtn()}
                onClick={handleExtractionClause}
              >
                <img src={IMAGES.submitProcess} alt="AI" />
                Extract clauses
              </Styles.ExtractButton>
            )}
            {paymentTermsDetails?.extractionStatus ===
              ExtractionStatus.COMPLETED && (
              <>
                <Styles.FileText>
                  {paymentTermsDetails?.paymentTermsFileName}
                </Styles.FileText>
                <PrimaryButton
                  loading={isDownloading}
                  onClick={() => {
                    if (paymentTermsDetails?.paymentTermsFilePreSignedUrl) {
                      onDownloadPdf(
                        paymentTermsDetails?.paymentTermsFilePreSignedUrl,
                        setIsDownloading,
                        paymentTermsDetails?.paymentTermsFileName
                      );
                    }
                  }}
                >
                  Download
                </PrimaryButton>
                <Styles.DeleteButton
                  loading={isExtractedFileDeleting}
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  danger
                />
              </>
            )}
            {/* <img src={IMAGES.loading} alt="Logo" /> */}
          </Styles.FooterRightPart>
        </Styles.ReleventPagesFooter>
      </Styles.Details>
      <ConfirmationModal
        title="Delete File"
        text={"Are you sure you want to delete this file?"}
        isOpen={isOpenModal}
        isDanger={true}
        btnText="Delete"
        handleOk={() => {
          setIsOpenModal(false);
          handleDeleteExtractionFile();
        }}
        handleCancel={() => {
          setIsOpenModal(false);
        }}
      />
    </Styles.PaymentTermsContainer>
  );
};

export default PaymentTerms;

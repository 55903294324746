import { Button, Input, Popover } from "antd";
import styled, { css } from "styled-components";
import { IViewRFP } from "../../../types";

interface IInputReason {
  isEdit?: boolean;
}

export const RFPSummary = styled.div`
  font-size: 14px;
  font-weight: 400;

  span {
    font-family: "GraphikMedium";
    font-size: 14px;
    font-weight: 500;
  }
`;

export const StyledPopover = styled(Popover)`
  &.rfp-summary-popover {
    width: 100% !important;
    max-width: 750px;
    .ant-popover-inner {
      padding: 10px;
    }
  }
  .ant-popover-content {
    max-height: 250px;
    overflow-y: auto;
  }
`;

export const EditButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummaryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  .mark-down-text {
    max-height: 250px;
    overflow-y: auto;
  }
`;
export const SummaryContent = styled.div`
  flex: 1;
  overflow-y: auto;
  white-space: pre-wrap;
`;
export const InputSummary = styled(Input.TextArea)<IInputReason>`
  &.ant-input {
    resize: none;
    height: 250px;
    font-size: 13px;
    padding: 5px;
    ${(props) =>
      props.readOnly &&
      css`
        border: none;
        &:hover {
          border: none;
        }
      `};
    background-color: ${(props) => (props.isEdit ? "rgb(244, 246, 252)" : "")};
    border: none;
  }
`;

export const SummaryBtnContainer = styled.div`
  display: flex;
  margin-top: auto;
  align-self: flex-end;
  padding-top: 10px;
  gap: 5px;
  align-itmes: center;
`;

export const ViewRfp = styled.img<IViewRFP>`
  width: 20px;
  height: 20px;
  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "pointer")};

  filter: ${({ $active }) =>
    $active
      ? "invert(11%) sepia(93%) saturate(6771%) hue-rotate(279deg) brightness(102%) contrast(116%)"
      : "invert(65%) sepia(6%) saturate(17%) hue-rotate(345deg) brightness(105%) contrast(79%)"};
`;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IPdfDetails, IPdfViewer } from "./pdfViewer.interface";
import { PURGE } from "redux-persist";

const initialState: IPdfViewer = {
  isShowPDF: false,
  currentRfpIdForPdf: -1,
  pdfData: {},
  pdfDetails: { fileName: "", presignedUrl: "" },
};

interface IPayload {
  base64Data: string;
  fileName: string;
}

export const pdfViewerSlice = createSlice({
  name: "pdfViewerSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setIsPdfShow: (state, action: PayloadAction<boolean>) => {
      state.isShowPDF = action.payload;
    },

    setCurrentRfpIdForPdf: (state, action: PayloadAction<number>) => {
      state.currentRfpIdForPdf = action.payload;
    },

    setPDFData: (state, action: PayloadAction<IPayload>) => {
      const { fileName, base64Data } = action.payload;
      state.pdfData = { ...state.pdfData, [fileName]: base64Data };
    },

    setPdfDetails: (state, action: PayloadAction<IPdfDetails>) => {
      state.pdfDetails = action.payload;
    },
  },
});

export const {
  setPDFData,
  setIsPdfShow,
  setPdfDetails,
  setCurrentRfpIdForPdf,
} = pdfViewerSlice.actions;

export default pdfViewerSlice.reducer;

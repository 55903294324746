import styled from "styled-components";
import { PrimaryButton } from "../../../shared";
import { lightTheme } from "../../../theme";
import { Button, Input } from "antd";

export const WelcomeText = styled.div`
  color: #757575;
  font-family: "GraphikRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SignInText = styled.div`
  color: ${lightTheme.colors.fontColorDelta};
  font-family: "GraphikMedium";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
`;

export const EmailText = styled.div`
  color: ${lightTheme.colors.fontColorDelta};
  font-family: "GraphikRegular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
`;

export const InputField = styled(Input)`
  height: 40px;
  margin-top: 10px;
`;

export const PasswordField = styled(Input.Password)`
  height: 40px;
  margin-top: 10px;
`;

export const LoginButton = styled(PrimaryButton)`
  width: 100%;
  height: 47.28px;
  margin-top: 15px;
`;

export const LoginText = styled(EmailText)`
  color: ${lightTheme.colors.fontColorBeta};
  font-family: "GraphikMedium";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  align-self: center;
  margin-top: 0;
`;

export const ForgotPassContainet = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const ForgotPassLink = styled(Button)`
  &.ant-btn {
    padding: 4px 0px 4px 11px;
  }
  &.ant-btn-link {
    color: ${({ theme }) => theme.colors.fontColorOmega};
  }
  &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.fontColorOmega};
  }
`;

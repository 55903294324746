import * as Styles from "./style";
import { IMAGES } from "../../shared";
import { useEffect } from "react";
import { getRfpStatusCount } from "../../services/auth";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { PATHS } from "../../constants";
import { UploadDocModal } from "../../components";
import { useState } from "react";
import SearchRFP from "../../components/home/searchRFP/SearchRFP";

const Home = () => {
  useEffect(() => {
    getRfpStatusCount();
  }, []);

  const { RFPStatusCount } = useSelector((state: RootState) => state.authSlice);

  const [uploadModal, setUploadModal] = useState(false);

  const rfpCards = [
    {
      header: "All RFPs",
      subHeader: "Centralized portal for accessing and reviewing RFPs",
      viewText: <Styles.LinkAdded to={PATHS.rfps}>View all</Styles.LinkAdded>,
      bgColor: "rgba(161, 0, 255, 0.07)",
      image: IMAGES.allRfpIcon,
    },

    {
      header: "Review metrics",
      subHeader: "Dashboard for quick overview and project management",
      viewText: (
        <Styles.LinkAdded to={PATHS.dashboard}>View dashboard</Styles.LinkAdded>
      ),
      bgColor: "rgba(203, 52, 120, 0.07)",
      image: IMAGES.matrixReview,
    },

    {
      header: "Upload RFP",
      subHeader: "Efficiently upload multiple RFPs simultaneously in portal",
      viewText: (
        <Styles.ViewText onClick={() => setUploadModal(true)}>
          Get started
        </Styles.ViewText>
      ),
      bgColor: "#004DFF12",
      image: IMAGES.uploadRfpIcon,
    },

    {
      header: "Reach out",
      subHeader: "xyztest267@gmail.com",
      viewText: "Contact us",
      bgColor: "#027E5B12",
      image: IMAGES.reachOutIcon,
    },
  ];

  const renderRfpCards = () => {
    return (
      <Styles.RfpCardWrap>
        {rfpCards.map((v, index) => (
          <Styles.RfpCardContainer key={index}>
            <Styles.RfpCardIconWrap $bgcolor={v?.bgColor}>
              <img src={v?.image} alt="rfp" />
            </Styles.RfpCardIconWrap>
            <Styles.RfpCardTextWrap>
              <Styles.RfpCardHeader>{v?.header}</Styles.RfpCardHeader>
              <Styles.RfpCardSubHeader>{v?.subHeader}</Styles.RfpCardSubHeader>
              <Styles.RfpCardUrl>
                <span>{v?.viewText}</span>
                <img src={IMAGES.rightArrow} alt="arrow" />
              </Styles.RfpCardUrl>
            </Styles.RfpCardTextWrap>
          </Styles.RfpCardContainer>
        ))}
      </Styles.RfpCardWrap>
    );
  };

  const renderRfpStatusCount = (
    text: string,
    count: number,
    border: boolean,
    status?: string
  ) => {
    return (
      <Styles.RfpStatusCountBorderWrap>
        <Styles.RfpStatusCountTextWrap>
          <Styles.RfpStatusSubHeader>{text}</Styles.RfpStatusSubHeader>
          <Styles.RfpStatusPendingTextWrap>
            <Styles.RfpStatusCount>{count ?? 0}</Styles.RfpStatusCount>
            {!!status && (
              <Styles.RfpStatusPendingText>
                {status}
              </Styles.RfpStatusPendingText>
            )}
          </Styles.RfpStatusPendingTextWrap>
        </Styles.RfpStatusCountTextWrap>
        {border && <Styles.RfpStatusCountBorder />}
      </Styles.RfpStatusCountBorderWrap>
    );
  };

  const renderTrackRfpStatus = () => {
    return (
      <Styles.RfpStatusContainer>
        <Styles.RfpStatusHeader>
          Stay informed: Track your RFP status updates
        </Styles.RfpStatusHeader>
        <Styles.RfpStatusCountTextContainer>
          {renderRfpStatusCount("Total RFPs", RFPStatusCount?.totalRfps, true)}
          {renderRfpStatusCount(
            "Qualified RFPs",
            RFPStatusCount?.qualifiedRfps,
            true
          )}
          {renderRfpStatusCount(
            "Unassigned RFPs",
            RFPStatusCount?.unassignedRfps,
            true,
            "Pending"
          )}
          {renderRfpStatusCount(
            "In-progress RFPs",
            RFPStatusCount?.inProgressRfps,
            true
          )}
          {/* {renderRfpStatusCount(
            "Upcoming deadlines",
            RFPStatusCount?.upcomingDeadlines,
            false,
            "(Within 20 days)"
          )} */}
        </Styles.RfpStatusCountTextContainer>
      </Styles.RfpStatusContainer>
    );
  };

  const renderQuickStart = () => {
    return (
      <Styles.QuickStartContainer>
        <Styles.QuickStartHeader>Quick start guide</Styles.QuickStartHeader>
        <Styles.QuickStartCardsContainer>
          {[...Array(3)].map((_, i) => (
            <Styles.QuickStartCardWrap key={i}>
              <Styles.QuickStartCardImgWrap>
                <Styles.QuickStartCardImg
                  src={IMAGES.dummyQuickStart}
                  alt="quickStart"
                />
                <Styles.QuickStartCardPlayImg
                  src={IMAGES.playIcon}
                  alt="playIcon"
                />
              </Styles.QuickStartCardImgWrap>
              <Styles.QuickStartCardTextWrap>
                <Styles.QuickStartCardHeader>
                  Title {i + 1}
                </Styles.QuickStartCardHeader>
                <Styles.QuickStartCardSubHeader>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Styles.QuickStartCardSubHeader>
              </Styles.QuickStartCardTextWrap>
            </Styles.QuickStartCardWrap>
          ))}

          <Styles.QuickStartGuideCardWrap>
            <Styles.QuickStartGuideCardHeader>
              A quick start guide
            </Styles.QuickStartGuideCardHeader>
            <Styles.QuickStartGuideCardSubHeader>
              Effortlessly Register, Submit, and Manage Proposals. Learn to
              navigate the interface, create impactful RFPs, communicate with
              vendors, and track It is a long established fact that a reader
              will be distracted by the readable content of a page when looking
              at its layout. The point of using Lorem Ipsum is that it has a
              more-or-less normal distribution
            </Styles.QuickStartGuideCardSubHeader>
            <Styles.QuickStartGuideReadMore>
              Read more
            </Styles.QuickStartGuideReadMore>
          </Styles.QuickStartGuideCardWrap>
        </Styles.QuickStartCardsContainer>
      </Styles.QuickStartContainer>
    );
  };

  return (
    <Styles.HomeContainer>
      <Styles.HeaderComponentsWrap>
        <Styles.WelcomeText>Welcome 👋</Styles.WelcomeText>
        <Styles.DashboardHeader>
          Get started with your RFP’s
        </Styles.DashboardHeader>
        <SearchRFP />
      </Styles.HeaderComponentsWrap>

      <Styles.RfpComponentsContainer>
        {renderRfpCards()}
        {renderTrackRfpStatus()}
        {renderQuickStart()}

        <UploadDocModal
          isModalOpen={uploadModal}
          setUploadModal={setUploadModal}
        />
      </Styles.RfpComponentsContainer>
    </Styles.HomeContainer>
  );
};

export default Home;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAllUsers, IAuth, IRFPStatusCount } from "./auth.interface";
import { PURGE } from "redux-persist";

const initialState: IAuth = {
  loginLoading: false,
  userId: -1,
  RFPStatusCount: {
    totalLiveRfps: 0,
    unassignedRfps: 0,
    inProgressRfps: 0,
    upcomingDeadlines: 0,
    totalRfps: 0,
    qualifiedRfps: 0,
  },
  allUsers: [],
  isSendOtpLoading: false,
  isVerifyOtpLoading: false,
  userEmailAtForgetPassword: "",
  isCreatePasswordLoading: false,
  otpAtForgetPassword: -1,
  restPasswordLoading: false,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setLoginLoading: (state, action: PayloadAction<boolean>) => {
      state.loginLoading = action.payload;
    },

    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },

    setRFPStatusCount: (state, action: PayloadAction<IRFPStatusCount>) => {
      state.RFPStatusCount = action.payload;
    },

    setAllUsers: (state, action: PayloadAction<IAllUsers[]>) => {
      state.allUsers = action.payload;
    },

    setIsSendOtpLoading: (state, action: PayloadAction<boolean>) => {
      state.isSendOtpLoading = action.payload;
    },

    setIsVerifyOtpLoading: (state, action: PayloadAction<boolean>) => {
      state.isVerifyOtpLoading = action.payload;
    },

    setUserEmailAtForgetPassword: (state, action: PayloadAction<string>) => {
      state.userEmailAtForgetPassword = action.payload;
    },

    setIsCreatePasswordLoading: (state, action: PayloadAction<boolean>) => {
      state.isCreatePasswordLoading = action.payload;
    },

    setOtpAtForgetPassword: (state, action: PayloadAction<number>) => {
      state.otpAtForgetPassword = action.payload;
    },

    resetOtpPassword: (state, _) => {
      state.otpAtForgetPassword = -1;
      state.userEmailAtForgetPassword = "";
    },

    setRestPasswordLoading: (state, action: PayloadAction<boolean>) => {
      state.restPasswordLoading = action.payload;
    },
  },
});

export const {
  setUserId,
  setAllUsers,
  setLoginLoading,
  resetOtpPassword,
  setRFPStatusCount,
  setIsSendOtpLoading,
  setIsVerifyOtpLoading,
  setOtpAtForgetPassword,
  setIsCreatePasswordLoading,
  setUserEmailAtForgetPassword,
  setRestPasswordLoading,
} = authSlice.actions;

export default authSlice.reducer;

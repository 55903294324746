import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import * as Styles from "./styles";
import { IMAGES } from "../../../shared";
import { RootState } from "../../../store";
import { assetUpload } from "../../../services/contentManagement";
import { setAssetsFile } from "../../../store/contentManagement/contentManagementSlice";

interface UploadModalProps {
  isModalOpen: boolean;
  setUploadModal: (value: boolean) => void;
}

const UploadModal = (props: UploadModalProps) => {
  const { userId } = useSelector((state: RootState) => state.authSlice);
  const [fileUploadingPercentage, setFileUploadingPercentage] = useState(0);
  const [files, setFiles] = useState<File[]>([]);

  const { isModalOpen, setUploadModal } = props;

  const [form] = useForm();

  const dispatch = useDispatch();

  const inputMainFileRef = useRef<HTMLInputElement>(null);
  const { isFilesUploading } = useSelector((state: RootState) => state.content);

  const handleMainFileUploadButton = () => {
    if (inputMainFileRef.current != null) {
      inputMainFileRef.current.click();
    }
  };

  const handleMainFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList: FileList | null = e.target.files;
    if (fileList) {
      const fileArray: File[] = Array.from(fileList);
      setFiles(fileArray);
    }
  };

  const handleSubFileUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`file`, files[i]);
    }

    try {
      await assetUpload(setFileUploadingPercentage, formData, {
        userId: userId,
        content_type: "pdf",
      });
    } catch {
      console.log("failed to upload file");
    } finally {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setUploadModal(false);
    setFiles([]);
    setFileUploadingPercentage(0);
    if (inputMainFileRef.current) {
      inputMainFileRef.current.value = "";
    }
  };

  const renderBrowseFiles = () => {
    return (
      <Styles.UploadedRfpContainer>
        <Styles.UploadedRfpHeader>
          Uploaded RFP file
          <Styles.BrowseFilesContainer>
            <Styles.UploadedFileNameDeleteIconWrap>
              <Styles.UploadedFileNamePinIconWrap>
                <img src={IMAGES.pinIcon} alt="pin" />
                <Styles.UploadedFileName>Browse</Styles.UploadedFileName>
              </Styles.UploadedFileNamePinIconWrap>
              <Styles.BrowseFilesButton
                disabled={isFilesUploading}
                onClick={handleMainFileUploadButton}
              >
                Browse
              </Styles.BrowseFilesButton>
              <input
                type="file"
                multiple
                ref={inputMainFileRef}
                accept=".pdf"
                onChange={handleMainFileUpload}
                hidden
              />
            </Styles.UploadedFileNameDeleteIconWrap>
          </Styles.BrowseFilesContainer>
        </Styles.UploadedRfpHeader>
      </Styles.UploadedRfpContainer>
    );
  };

  const renderSelectedFiles = () => {
    if (files.length && !isFilesUploading) {
      return (
        <Styles.Uploaded>
          <Styles.UploadedFileNameContainer>
            {files?.map((file: File) => {
              return (
                <Styles.UploadedFileNameDeleteIconWrap>
                  <Styles.UploadedFileNamePinIconWrap>
                    <img src={IMAGES.pinIcon} alt="pin" />
                    <Styles.UploadedFileName>
                      {file?.name}
                    </Styles.UploadedFileName>
                  </Styles.UploadedFileNamePinIconWrap>
                  <Styles.DeleteSelectedFile
                    onClick={() => {
                      const remove = files.filter(
                        (v: File) => v.name !== file.name
                      );
                      setFiles(remove);
                    }}
                    src={IMAGES.deleteIcon}
                    alt="delete"
                  />
                </Styles.UploadedFileNameDeleteIconWrap>
              );
            })}
          </Styles.UploadedFileNameContainer>
        </Styles.Uploaded>
      );
    }
  };

  const renderUploadingFiles = () => {
    if (isFilesUploading) {
      return (
        <Styles.Uploading>
          Uploading...
          <div>
            {files.map((file: File) => {
              return (
                <>
                  <Styles.UploadingFilesContainer>
                    <Styles.UploadedFileName>
                      {file.name}
                    </Styles.UploadedFileName>
                    {/* <CloseOutlined
                      onClick={() => {
                        const remove = files.filter(
                          (v: File) => v.name !== file.name
                        );
                        setFiles(remove);
                        dispatch(setAssetsFile(null));
                      }}
                    /> */}
                  </Styles.UploadingFilesContainer>
                  <Styles.ProgressBar
                    percent={fileUploadingPercentage}
                    strokeColor={"#A100FF"}
                    showInfo={false}
                  />
                </>
              );
            })}
          </div>
        </Styles.Uploading>
      );
    }
  };

  const renderUploadFooter = () => {
    return (
      <Styles.UploadFooter>
        <Styles.SubmitButton
          onClick={handleSubFileUpload}
          loading={isFilesUploading}
        >
          Upload
        </Styles.SubmitButton>
      </Styles.UploadFooter>
    );
  };

  const renderModal = () => {
    return (
      <Styles.UploadModalContainer>
        {renderBrowseFiles()}
        {renderUploadingFiles()}
        {renderSelectedFiles()}
        <Styles.FooterBorder />
        {renderUploadFooter()}
      </Styles.UploadModalContainer>
    );
  };

  return (
    <Styles.ModalContainer
      title={<Styles.UploadModalHeader>Upload RFPs</Styles.UploadModalHeader>}
      open={isModalOpen}
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      footer={null}
      confirmLoading={false}
      afterClose={() => {
        setUploadModal(false);
        dispatch(setAssetsFile(null));
      }}
      closeIcon={<img src={IMAGES.CloseModalIcon} alt="Close" />}
      style={{
        padding: 0,
        top: "auto",
      }}
    >
      {renderModal()}
    </Styles.ModalContainer>
  );
};

export default UploadModal;

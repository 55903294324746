import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  colors: {
    fontColorAlpha: "#000000", //black
    fontColorBeta: "#ffffff", //white
    fontColorGamma: "#111", //black
    fontColorEpsilon: "#7f00c9", //purple
    fontColorTheta: "#027E5B", // green
    fontColorDelta: "#333333", //black navText
    fontColorRho: "#5c5b5b", //Darker-grey
    fontColorPi: "#5c5c5c", //light-grey
    fontColorOmega: "#a100ff", //purple
    fontColorXi: "#cb3478", //red

    backgroundColorAlpha: "#FFFFFF", //white
    backgroundColorBeta: "#a100ff", //purple
    backgroundColoGamma: "#F4F6FC",
    backgroundColorTheta: "#00B8844D",

    borderColorAlpha: "#E6E4F0",
    borderColorEta: "#b466e038",

    // upload compoenet start
    backgroundColorGamma: "#E5E5E5",
    fontColorZeta: "#5C5B5B",
    fontColorEta: "#a7a7a7",
    borderColorGamma: "#e4e4e4",
    // upload componet end
    borderColorBeta: "#a100ff", //purple
    borderColoRho: "#e5eaf8", //light blue
    borderColoPi: "#5c5c5c", //light-grey

    backgroundColoZeta: "#f4f6fc",
    fontColorIota: " #194dea",
    borderColorTheta: "#6200EE",
    strokeAlpha: "#6200ee",
  },
};

import { toast } from "react-toastify";
import { put } from "./apiClient";

export interface IStopRFPExtGen {
  id: number; // The id of the stop operation is [FINANCE, LEGAL]
  rfpId: number;
  rfpFileName: string;
  proposalsCategory: string;
  proposalSubCategory?: string; // [PQ,TQ,FRS,POLICY_REQUIREMENTS]
}

export const stopRFPExtGen = async (request: IStopRFPExtGen) => {
  try {
    const res = await put(`/apa/profile/stop`, request);
    return res;
  } catch (error: any) {
    console.log("Error while putting stop", error);
    toast.error(error?.errorMessage ?? "Failed to stop generation.");
    throw error;
  }
};

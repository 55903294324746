import { Spin } from "antd";
import { useEffect, useState } from "react";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import {
  LeftOutlined,
  DoubleLeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  setCurrentRfpIdForPdf,
  setIsPdfShow,
  setPDFData,
  setPdfDetails,
} from "../../../store/pdfViewer/pdfViewerSlice";

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js`;

const PDFViewer = () => {
  const [url, setUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isShowPDF, pdfData, pdfDetails } = useSelector(
    (state: RootState) => state.pdfViewer
  );

  const dispatch = useDispatch();

  const zoomPluginInstance = zoomPlugin();

  const toolbarPluginInstance = toolbarPlugin();

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const {
    CurrentPageLabel,
    GoToFirstPage,
    GoToLastPage,
    GoToNextPage,
    GoToPreviousPage,
  } = pageNavigationPluginInstance;

  const { ZoomIn, ZoomOut, CurrentScale } = zoomPluginInstance;

  useEffect(() => {
    if (
      pdfDetails?.fileName &&
      pdfData &&
      Object.keys(pdfData).includes(pdfDetails?.fileName)
    ) {
      if (url !== pdfData[pdfDetails?.fileName]) {
        setUrl(pdfData[pdfDetails?.fileName]);
        setIsError(false);
      }
    } else {
      fetchURL();
    }
  }, []);

  const fetchURL = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(pdfDetails?.presignedUrl);

      if (response?.status === 200) {
        setIsError(false);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data: any = reader.result;
          dispatch(
            setPDFData({
              fileName: pdfDetails?.fileName,
              base64Data: base64data,
            })
          );
          setUrl(base64data);
        };
        reader.readAsDataURL(blob);
      } else {
        setIsError(true);
        setUrl("");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while fetching url", error);
    }
  };

  const openLinksInNewTab = () => {
    const containers = document.querySelectorAll(".rpv-core__annotation--link");
    containers?.forEach((container) => {
      const links = container?.querySelectorAll("a");

      links?.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const url = link.getAttribute("href");
          if (url) {
            window.open(url, "_blank");
          }
        });
      });
    });
  };

  return (
    <Styles.CustomDrawer
      closable={false}
      size="large"
      open={isShowPDF}
      title={
        <Styles.PDFHeaderContainer>
          <Styles.PDFHeaderSubContainer>
            <ZoomIn>
              {(props) => (
                <Styles.ButtonCircle
                  onClick={props.onClick}
                  type="text"
                  shape="circle"
                >
                  <ZoomInOutlined />
                </Styles.ButtonCircle>
              )}
            </ZoomIn>
            <Styles.PDFZoomScaleText>
              <CurrentScale />
            </Styles.PDFZoomScaleText>
            <ZoomOut>
              {(props) => (
                <Styles.ButtonCircle
                  onClick={props.onClick}
                  type="text"
                  shape="circle"
                >
                  <ZoomOutOutlined />
                </Styles.ButtonCircle>
              )}
            </ZoomOut>
          </Styles.PDFHeaderSubContainer>
          <Styles.PDFHeaderSubContainer>
            <GoToFirstPage>
              {(props) => (
                <Styles.ButtonCircle
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle"
                >
                  <DoubleLeftOutlined />
                </Styles.ButtonCircle>
              )}
            </GoToFirstPage>
            <GoToPreviousPage>
              {(props) => (
                <Styles.ButtonCircle
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle"
                >
                  <LeftOutlined />
                </Styles.ButtonCircle>
              )}
            </GoToPreviousPage>
            <CurrentPageLabel>
              {(props) => (
                <Styles.PDFPageNumberText>
                  {`Page ${props.currentPage + 1} of ${props.numberOfPages}`}
                </Styles.PDFPageNumberText>
              )}
            </CurrentPageLabel>
            <GoToNextPage>
              {(props) => (
                <Styles.ButtonCircle
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle"
                >
                  <RightOutlined />
                </Styles.ButtonCircle>
              )}
            </GoToNextPage>
            <GoToLastPage>
              {(props) => (
                <Styles.ButtonCircle
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle"
                >
                  <DoubleRightOutlined />
                </Styles.ButtonCircle>
              )}
            </GoToLastPage>
          </Styles.PDFHeaderSubContainer>
          <Styles.ButtonCircle
            type="text"
            shape="circle"
            onClick={() => {
              dispatch(setIsPdfShow(false));
              dispatch(setCurrentRfpIdForPdf(-1));
              dispatch(
                setPdfDetails({
                  fileName: "",
                  presignedUrl: "",
                })
              );
            }}
          >
            <CloseOutlined />
          </Styles.ButtonCircle>
        </Styles.PDFHeaderContainer>
      }
    >
      {isLoading && (
        <div className="loading-pdf">
          <Spin />
        </div>
      )}
      {isError ? (
        <div className="error-document">
          Document not found. Please try again
        </div>
      ) : (
        <></>
      )}
      {url && !isError ? (
        <Worker workerUrl={GlobalWorkerOptions.workerSrc}>
          <Viewer
            fileUrl={url}
            defaultScale={1}
            onDocumentLoad={() => {
              setTimeout(() => {
                openLinksInNewTab();
              }, 1000);
            }}
            plugins={[
              toolbarPluginInstance,
              pageNavigationPluginInstance,
              zoomPluginInstance,
            ]}
          />
        </Worker>
      ) : (
        <></>
      )}
    </Styles.CustomDrawer>
  );
};

export default PDFViewer;

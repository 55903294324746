import { Outlet, useLocation } from "react-router-dom";
import * as Styles from "./styles";
import { Header } from "../../components/rfp/header";
import useRFPStatusCheck from "../../shared/hooks/useRFPStatusCheck";

const RFPLayout = () => {
  useRFPStatusCheck();

  const { pathname } = useLocation();

  return (
    <Styles.RFPContainer>
      {!pathname?.includes("organogram") && <Header />}
      <Outlet />
    </Styles.RFPContainer>
  );
};

export default RFPLayout;

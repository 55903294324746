import { toast } from "react-toastify";
import { store } from "../store";
import { deleteRequest, get, post, put } from "./apiClient";
import { RFPGenerationStatus } from "../constants";
import { IResearchHistory } from "../store/investmentResercher/investmentResercher.interface";
import {
  addResearchHistory,
  autoRefreshResearchStatus,
  setIsLoading,
  setPromoterAddLoading,
  setPromoterDeleteLoading,
  setPromoterListByDate,
  setResearchDeleteLoading,
  setResearchHistory,
  setResearchIsLoading,
  setResearchUpdateLoading,
  setStopResearchAutoRefresh,
  updateResearchHistory,
  setIsSubsUnSubsLoading,
  setIsGetPromoterLoading,
  setSubscribeStatus,
  deleteResearchHistory,
  stoppingResearchHistory,
  setPromotorsList,
  setSelectedResearch,
  setStopResearchIsLoading,
  setFailedPromotersDataByDate,
} from "../store/investmentResercher/investmentResercherSlice";

export const getRecentHistory = async (
  id: number,
  isLoading: boolean
): Promise<IResearchHistory[]> => {
  try {
    isLoading && store.dispatch(setIsLoading(true));
    store.dispatch(setStopResearchAutoRefresh(false));

    const res: IResearchHistory[] = await get(
      `apa/investResearch/getInfoList/${id}`
    );

    !!res &&
      res.map((v) => {
        v.status === RFPGenerationStatus.PROCESSING &&
          store.dispatch(
            autoRefreshResearchStatus({
              researchId: v?.invest_research_id,
            })
          );
      });

    store.dispatch(setResearchHistory(res));
    return res;
  } catch (err) {
    console.log("getAllAMContent api error", err);
    store.dispatch(setResearchHistory([]));
    throw err;
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

export const getInvestResearchSearch = async (params: {
  entityName: string;
  userId: number;
}) => {
  try {
    store.dispatch(setResearchIsLoading(true));
    store.dispatch(setStopResearchAutoRefresh(false));
    const res: IResearchHistory = await get(`apa/investResearch/search`, {
      params,
    });

    store.dispatch(addResearchHistory(res));
    store.dispatch(setSelectedResearch(res));

    !!res &&
      res?.status === RFPGenerationStatus.PROCESSING &&
      store.dispatch(
        autoRefreshResearchStatus({
          researchId: res?.invest_research_id,
        })
      );
  } catch (err: any) {
    console.log("getAllAMContent api error", err);
    toast.error((err.errorMessage || err.error) ?? "failed to search");
    throw err;
  } finally {
    store.dispatch(setResearchIsLoading(false));
  }
};

export const getInvestResearchSearchById = async (
  researchId: number
): Promise<IResearchHistory> => {
  try {
    const res = await get(`apa/investResearch/getInfoById/${researchId}`);
    store.dispatch(updateResearchHistory(res));
    return res;
  } catch (err) {
    console.log("getAllAMContent api error", err);
    store.dispatch(stoppingResearchHistory(researchId));
    throw err;
  }
};

export const deleteInvestmentResearchInfo = async (
  id: number,
  deleteCurrent: boolean
) => {
  try {
    store.dispatch(setResearchDeleteLoading(true));
    await deleteRequest(`apa/investResearch/deleteInfoById/${id}`);
    store.dispatch(
      deleteResearchHistory({ researchId: id, deleteCurrent: deleteCurrent })
    );
    toast.success("Deleted successfully.");
  } catch (err: any) {
    toast.error((err.message || err.error) ?? "failed to delete");
    throw err;
  } finally {
    store.dispatch(setResearchDeleteLoading(false));
  }
};

export const updateInevestResearchInfo = async (params: {
  researchId?: number;
  entityName?: string;
}) => {
  try {
    store.dispatch(setResearchUpdateLoading(true));
    await put(
      `apa/investResearch/editTitle?researchId=${params.researchId}&entityName=${params.entityName}`,
      {}
    );
    toast.success("Updated successfully.");
  } catch (err: any) {
    toast.error(err.message || err.error);
    throw err;
  } finally {
    store.dispatch(setResearchUpdateLoading(false));
  }
};

export const getPromotersListByDate = async (bulletinDate: string) => {
  try {
    store.dispatch(setIsGetPromoterLoading(true));
    const res = await get(
      `apa/promoter/getPromoterListByDate?date=${bulletinDate}`
    );

    store.dispatch(setPromoterListByDate(res?.bulletinList));
    store.dispatch(setFailedPromotersDataByDate(res?.promoterList));
    return res;
  } catch (err: any) {
    store.dispatch(setPromoterListByDate([]));
    return err;
  } finally {
    store.dispatch(setIsGetPromoterLoading(false));
  }
};

export const addPromoter = async (payload: {
  promoter_name: string;
}): Promise<void> => {
  try {
    setPromoterAddLoading(true);
    const res = await post(`apa/promoter/addPromoterInfo`, payload);
    await getPromotersLists(false);
    if (typeof res.data === "string") toast.error(res.data);
    else toast.success("Promoter added successfully.");
  } catch (err: any) {
    toast.error(err.message || err.error);
    throw err;
  } finally {
    store.dispatch(setPromoterAddLoading(false));
  }
};

export const deletePromoter = async (id: number | null): Promise<void> => {
  try {
    store.dispatch(setPromoterDeleteLoading(true));
    await deleteRequest(`apa/promoter/deletePromoterInfo/${id}`);
    await getPromotersLists(false);
    toast.success("Promoter deleted successfully.");
  } catch (err: any) {
    toast.error(err.message || err.error);
    throw err;
  } finally {
    store.dispatch(setPromoterDeleteLoading(false));
  }
};

export const subsUnsubsBulletin = async (
  isSubscribed: string,
  userId: number
) => {
  try {
    store.dispatch(setIsSubsUnSubsLoading(true));
    const res = await post(
      `apa/promoter/subscription?isSubscribed=${isSubscribed}&userId=${userId}`
    );
    getsubscriptionStatus(userId);

    if (isSubscribed === "FALSE") {
      toast.success("AI bulletin successfully unsubscribed");
    }
    return res;
  } catch (err: any) {
    if (isSubscribed === "TRUE") {
      toast.success("AI bulletin failed to subscribe");
    } else {
      toast.success("AI bulletin failed to unsubscrib");
    }
    throw err;
  } finally {
    store.dispatch(setIsSubsUnSubsLoading(false));
  }
};

export const getsubscriptionStatus = async (userId: number) => {
  try {
    store.dispatch(setIsGetPromoterLoading(true));
    const res = await get(`apa/promoter/subscriptionStatus/${userId}`);

    store.dispatch(setSubscribeStatus(res?.isSubscribed || ""));
    return res;
  } catch (err: any) {
    store.dispatch(setSubscribeStatus(""));
    return err;
  } finally {
    store.dispatch(setIsGetPromoterLoading(false));
  }
};

export const getPromotersLists = async (isLoading: boolean) => {
  try {
    isLoading && store.dispatch(setIsGetPromoterLoading(true));
    const res = await get(`apa/promoter/getPromoterList`);

    store.dispatch(setPromotorsList(res));
    return res;
  } catch (err: any) {
    store.dispatch(setPromotorsList([]));
    return err;
  } finally {
    store.dispatch(setIsGetPromoterLoading(false));
  }
};

export const stopInvestmentResearch = async (
  researchId: number,
  clearSearch: () => void
) => {
  try {
    store.dispatch(setStopResearchIsLoading(true));
    await put(`apa/investResearch/stopSearch/${researchId}`, {});
    clearSearch();
    store.dispatch(stoppingResearchHistory(researchId));
    toast.success("Search stopped successfully.");
  } catch (err: any) {
    toast.error((err.message || err.error) ?? "Failed to stop search");
    console.error(err.message || err.error);
  } finally {
    store.dispatch(setStopResearchIsLoading(false));
  }
};

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Styles from "./styles";
import { RootState } from "../../store";
import { IUser } from "../../store/userManagement/user.interface";
import { AddEditUser } from "../../components/userManagement/addEditUser";
import {
  fetchAllUsers,
  getUsersStatus,
  removeUser,
} from "../../services/userManagement";
import { IMAGES } from "../../shared";
import { ConfirmationModal, CustomPagination } from "../../components";
import {
  setUserCurrentPage,
  setUserPageLimit,
} from "../../store/userManagement/usersSlice";
import SkeletonTable from "../../components/userManagement/skeletonTable/SkeletonTable";

interface IUserManagement {
  isModalOpen: boolean;
  setIsModalOpen: (v: boolean) => void;
}

const UserManagement = ({ isModalOpen, setIsModalOpen }: IUserManagement) => {
  const dispatch = useDispatch();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const {
    users,
    isAllUsersLoading,
    totalUsersCount,
    currentPage,
    pageLimit,
    searchText,
  } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    fetchAllUsers(searchText, currentPage, pageLimit);
  }, []);

  const onStatusChange = async (checked: boolean, row: any) => {
    const statusType: any = checked ? "enable" : "disable";
    await getUsersStatus(row.id, statusType, currentPage, pageLimit);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (row: string) => {
        return <Styles.ColumnStyle>{row}</Styles.ColumnStyle>;
      },
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      render: (row: string) => {
        return <Styles.ColumnStyle>{row}</Styles.ColumnStyle>;
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_: string, user: IUser) => {
        const role = user?.userPermissions[0]?.role;
        if (role === "ROLE_ADMIN")
          return <Styles.AdminRole>Admin</Styles.AdminRole>;
        else return <Styles.Role>User</Styles.Role>;
      },
      width: 300,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (_: any, row: any) => {
        const status: any = row?.status === "A" ? true : false;
        return (
          <Styles.UserSwitch
            checked={status}
            onChange={(e) => onStatusChange(e, row)}
          />
        );
      },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",

      render: (_: string, user: IUser) => {
        return (
          <Styles.SelectAction
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Edit",
                  onClick: () => {
                    setSelectedUser(user);
                    setIsModalOpen(true);
                  },
                },
                // {
                //   key: "2",
                //   label: "Delete",
                //   onClick: () => {
                //     setSelectedUser(user);
                //     setIsDeleteModal(true);
                //   },
                // },
              ],
            }}
            placement="bottomRight"
            arrow={false}
          >
            <img src={IMAGES.actionMenu} alt="Dots Icon" />
          </Styles.SelectAction>
        );
      },
      width: 90,
    },
  ];

  const handleOnCancel = () => {
    setSelectedUser(undefined);
    setIsModalOpen(false);
    setIsDeleteModal(false);
  };

  const handleDeleteUser = async () => {
    if (!selectedUser?.id) return;

    try {
      setIsDeleteLoading(true);
      const response: any = await removeUser(selectedUser?.id);
      if (response?.status === 200) {
        handleOnCancel();
        fetchAllUsers(searchText, currentPage, pageLimit);
      }
      setIsDeleteLoading(false);
    } catch (error) {
      setIsDeleteLoading(false);
    }
  };

  const handlePagination = (page: number, size: number) => {
    dispatch(setUserCurrentPage(page));
    dispatch(setUserPageLimit(size));
    fetchAllUsers(searchText, page, size);
  };
  return (
    <Styles.UserContainer>
      {isAllUsersLoading ? (
        <SkeletonTable />
      ) : (
        <Styles.UserTable
          rowKey={"id"}
          dataSource={users?.length ? users : []}
          columns={columns as any}
          scroll={{ y: "calc(100vh - 312px)" }}
          pagination={false}
        />
      )}

      {users && users.length && !isAllUsersLoading ? (
        <CustomPagination
          currentPage={currentPage}
          total={totalUsersCount ?? 0}
          handlePagination={handlePagination}
          pageSize={pageLimit}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      ) : (
        <></>
      )}

      {!!isModalOpen && (
        <AddEditUser
          user={selectedUser}
          onCancel={handleOnCancel}
          isModalOpen={isModalOpen}
          title={selectedUser?.id ? "Edit user" : "Add user"}
        />
      )}

      {/* Delete User Modal */}
      {!!isDeleteModal && selectedUser?.id && (
        <ConfirmationModal
          title="Delete"
          text="Are you sure you want to delete this user?"
          isOpen={isDeleteModal}
          handleOk={handleDeleteUser}
          handleCancel={handleOnCancel}
          isLoading={isDeleteLoading}
          btnText="Delete"
        />
      )}
    </Styles.UserContainer>
  );
};

export default UserManagement;

import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  REHYDRATE,
  PERSIST,
  REGISTER,
  FLUSH,
  PAUSE,
  PURGE,
} from "redux-persist";
import localforage from "localforage";
import frsSlice from "./frs/frsSlice";
import atiSlice from "./ati/atiSlice";
import authSlice from "./auth/authSlice";
import pqtqSlice from "./pqtq/pqtqSlice";
import legalSlice from "./legal/legalSlice";
import amContentSlice from "./a&m/a&mSlice";
import nbmContentSlice from "./nbm/nbmSlice";
import allRfpSlice from "./allRfps/allRfpSlice";
import financeSlice from "./finance/financeSlice";
import policy966Slice from "./policy966/policy966";
import usersSlice from "./userManagement/usersSlice";
import overviewSlice from "./overview/overviewSlice";
import rfpUploadSlice from "./rfpUpload/rfpUploadSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import pdfViewerSlice from "./pdfViewer/pdfViewerSlice";
import organogramSlice from "./organogram/organogramSlice";
import projectPlanSlice from "./projectPlan/projectPlanSlice";
import paymentTermsSlice from "./paymentTerms/paymentTermsSlice";
import solutionPaymentTerms from "./solutionPaymentTerms/paymentTermsSlice";
import contentManagementSlice from "./contentManagement/contentManagementSlice";
import policyRequirementsSlice from "./policyRequirements/policyRequirementsSlice";
import investmentResearcherSlice from "./investmentResercher/investmentResercherSlice";

// Define your persist configuration
const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: [
    "rfp",
    "frs",
    "pqtq",
    "legal",
    "allRfps",
    "overview",
    "authSlice",
    "pdfViewer",
    "policyRequirements",
    "users",
    "content",
    "investmentResearcher",
  ],
};

// Combine your reducers
const appReducer = combineReducers({
  ati: atiSlice,
  frs: frsSlice,
  pqtq: pqtqSlice,
  users: usersSlice,
  legal: legalSlice,
  authSlice: authSlice,
  allRfps: allRfpSlice,
  nbm: nbmContentSlice,
  finance: financeSlice,
  overview: overviewSlice,
  pdfViewer: pdfViewerSlice,
  dashboard: dashboardSlice,
  rfpUpload: rfpUploadSlice,
  amContent: amContentSlice,
  policy966: policy966Slice,
  organogram: organogramSlice,
  projectPlan: projectPlanSlice,
  content: contentManagementSlice,
  paymentTerms: paymentTermsSlice,
  solutionPaymentTerms: solutionPaymentTerms,
  policyRequirements: policyRequirementsSlice,
  investmentResearcher: investmentResearcherSlice,
});

// Define the type for your root reducer
type RootStateTemp = ReturnType<typeof appReducer>;

// Define the action type for the "initializationStore" action
type InitializationAction = { type: "initializationStore" };

// Create your rootReducer
const rootReducer = (
  state: RootStateTemp | undefined,
  action: AnyAction | InitializationAction
): RootStateTemp => {
  if (action.type === "initializationStore") {
    return appReducer(undefined, { type: "initializationStore" });
  }
  return appReducer(state, action);
};

// Persist your root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure your store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [REHYDRATE, PERSIST, REGISTER, FLUSH, PAUSE, PURGE],
      },
    }),
});

// Create a persistor for your store
export const persistor = persistStore(store);

// Export types for convenience
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

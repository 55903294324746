import { Outlet } from "react-router-dom";
import { Header, SideNav } from "../../components";
import * as Styles from "./styles";
import PDFViewer from "../../components/common/pdf/PDFViewer";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const AppLayout = () => {
  const { isShowPDF } = useSelector((state: RootState) => state.pdfViewer);
  return (
    <Styles.AppContainer>
      <SideNav />
      <Styles.HeaderOutletWrap>
        <Header />
        <Outlet />
      </Styles.HeaderOutletWrap>
      {!!isShowPDF && <PDFViewer />}
    </Styles.AppContainer>
  );
};

export default AppLayout;

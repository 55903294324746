import { Outlet } from "react-router-dom";
import { IMAGES } from "../../shared";
import * as Styles from "./styles";

const AuthLayout = () => {
  return (
    <Styles.AuthContainer>
      <Styles.BackgroundImageContainer>
        <Styles.AccentureLogoContainer>
          <Styles.AccentureLogo src={IMAGES.accentureLogo} preview={false} />
          <Styles.AccentureText>accenture</Styles.AccentureText>
        </Styles.AccentureLogoContainer>
        <Styles.BackgroundImageTextOverlay>
          Transform your RFP process using Generative AI
        </Styles.BackgroundImageTextOverlay>
      </Styles.BackgroundImageContainer>

      <Styles.AuthBox>
        <Styles.AuthHeadingContainer>
          <Styles.AuthHeadingText>Proposal Accelerator</Styles.AuthHeadingText>
          <Styles.AuthSubHeadingText>
            India Markets Unit (IMU)
            <Styles.aiTagImage src={IMAGES.aiTagLogo} preview={false} />
          </Styles.AuthSubHeadingText>
        </Styles.AuthHeadingContainer>

        <Styles.SubContainer>
          <Outlet />
        </Styles.SubContainer>
      </Styles.AuthBox>
    </Styles.AuthContainer>
  );
};

export default AuthLayout;

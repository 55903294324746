import { Button, Drawer } from "antd";
import styled from "styled-components";

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 10px 15px;
    background-color: ${({ theme }) => theme.colors.fontColorAlpha};
    color: #fff !important;
  }
  .ant-drawer-body {
    padding: 10px 15px;
  }
  .ant-drawer-footer {
    padding: 10px 15px;
  }
`;
export const PDFHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PDFHeaderSubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const PDFZoomScaleText = styled.div`
  color: ${({ theme }) => theme.colors.fontColorBeta};
`;
export const PDFPageNumberText = styled.p`
  color: ${({ theme }) => theme.colors.fontColorBeta};
`;
export const PDFFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// Button

export const ButtonCircle = styled(Button)`
  .anticon {
    color: ${({ theme }) => theme.colors.fontColorBeta};
    font-size: 16px;
  }
  &.ant-btn-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

import * as Styles from "./style";

interface TabItem {
  tab: React.ReactNode;
  tabId: number;
  handleTabClick?: () => void;
  isActive: boolean;
}

interface TabProps {
  tabs: TabItem[];
}

const Tabs = (props: TabProps) => {
  const { tabs } = props;

  const renderTabs = () => {
    return tabs.map((v) => (
      <Styles.TabContainerItem
        $active={v.isActive}
        key={v.tabId}
        onClick={v.handleTabClick}
      >
        {v.tab}
      </Styles.TabContainerItem>
    ));
  };

  return (
    <>
      <Styles.TabContainer>{renderTabs()}</Styles.TabContainer>
      <Styles.TabLine />
    </>
  );
};

export default Tabs;

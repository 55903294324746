import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { IMAGES, TertiaryButton, debounce } from "../../../shared";
import {
  fetchAllContent,
  getContentExportCSV,
} from "../../../services/contentManagement";
import {
  setSearchText,
  setContentCurrentPage,
} from "../../../store/contentManagement/contentManagementSlice";

interface IHeader {
  setIsUploadModalOpen: (value: boolean) => void;
}

const ContentHeader = ({ setIsUploadModalOpen }: IHeader) => {
  const dispatch = useDispatch();
  const { searchText, currentPage, pageLimit, isExportCSVLoading } =
    useSelector((state: RootState) => state.content);

  const saveToServer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();

    if (trimmedSearchText.length >= 3) {
      fetchAllContent(trimmedSearchText, currentPage, pageLimit);
    }
    if (e.target.value.length === 0) {
      fetchAllContent("", currentPage, pageLimit);
    }
  };

  const debouncedSave = debounce(saveToServer, 900);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSave(e);
    dispatch(setSearchText(e.target.value));
    dispatch(setContentCurrentPage(1));
  };

  const handleExportCSV = async () => {
    await getContentExportCSV();
  };

  return (
    <Styles.Header className="header-card">
      <Styles.SubHeader>
        <Styles.Spaced.Compact>
          <Styles.InputSearch
            placeholder="Search assets…"
            prefix={<img src={IMAGES.searchIcon} alt="search" />}
            onChange={handleChangeSearch}
            value={searchText}
            allowClear
          />
        </Styles.Spaced.Compact>
        <Styles.ExportUploadButtonWrap>
          <TertiaryButton
            icon={<DownloadOutlined />}
            loading={isExportCSVLoading}
            onClick={handleExportCSV}
          >
            Export CSV
          </TertiaryButton>
          <Styles.UploadButton
            onClick={() => setIsUploadModalOpen(true)}
            icon={<img src={IMAGES.UploadIcon} alt="upload" />}
          >
            Upload Assets
          </Styles.UploadButton>
        </Styles.ExportUploadButtonWrap>
      </Styles.SubHeader>
    </Styles.Header>
  );
};

export default ContentHeader;

import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import * as Styles from "./styles";
import { PATHS } from "../../../constants";
import { SecondaryButton } from "../../../shared";
import { resetPassword } from "../../../services/auth";
import { RootState } from "../../../store";
import { useLayoutEffect } from "react";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { userId } = useSelector((state: RootState) => state.authSlice);
  const { restPasswordLoading } = useSelector(
    (state: RootState) => state.authSlice
  );

  useLayoutEffect(() => {
    if (userId < 0) {
      navigate(PATHS.login);
      window.location.reload();
    }
  }, [userId]);

  const onFinish = (values: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    resetPassword({ ...values, userId }, navigate);
  };
  return (
    <Styles.ResetPasswordContainer>
      <Styles.HeadingText>Set a new password</Styles.HeadingText>
      <Styles.SubHeadingText>
        Your new password must be different to previously used passwords.
      </Styles.SubHeadingText>
      <Form
        name="resetPassword"
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: "Please Enter Old Password",
            },
          ]}
        >
          <Styles.InputField placeholder="Old Password" />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please Enter New Password",
            },
          ]}
        >
          <Styles.InputField placeholder="New Password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please Enter Confirm Password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "The Confirm Password That You Entered Does Not Match!"
                  )
                );
              },
            }),
          ]}
        >
          <Styles.InputField placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item style={{ marginTop: "15px" }}>
          <Styles.UpdatePasswordButton
            htmlType="submit"
            loading={restPasswordLoading}
          >
            Update Password
          </Styles.UpdatePasswordButton>
        </Form.Item>
      </Form>
      <Styles.BackToLoginContainer>
        <SecondaryButton
          icon={<LeftOutlined />}
          onClick={() => {
            navigate(PATHS.home);
          }}
        >
          Back to Home
        </SecondaryButton>
      </Styles.BackToLoginContainer>
    </Styles.ResetPasswordContainer>
  );
};

export default ResetPassword;

import * as Styles from "./styles";

interface ICustomPagination {
  currentPage: number;
  total: number;
  handlePagination: (page: number, size: number) => void;
  pageSize: number;
  pageSizeOptions: number[];
}

const CustomPagination = ({
  currentPage,
  total,
  handlePagination,
  pageSize,
  pageSizeOptions,
}: ICustomPagination) => {
  return (
    <Styles.PaginationWrapper>
      <Styles.CustomPagination
        current={currentPage}
        total={total}
        onChange={handlePagination}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        showSizeChanger={false}
      />
    </Styles.PaginationWrapper>
  );
};

export default CustomPagination;

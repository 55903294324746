import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { persistStore } from "redux-persist";
import { useNavigate } from "react-router-dom";
import * as Styles from "./styles";
import { PATHS, Role } from "../../../constants";
import { RootState, store } from "../../../store";
import { IUser } from "../../../store/userManagement/user.interface";
import {
  getCapitalizedStr,
  getCookie,
  IMAGES,
  removeCookie,
} from "../../../shared";
import {
  addUser,
  fetchAllUsers,
  updateUser,
} from "../../../services/userManagement";

interface IModal {
  user: IUser | undefined;
  isModalOpen: boolean;
  onCancel: any;
  title: string;
}

const AddEditUser = ({ user, isModalOpen, onCancel, title }: IModal) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [roleChangeError, setRoleChangeError] = useState(false);

  const navigate = useNavigate();

  const { currentPage, pageLimit, searchText } = useSelector(
    (state: RootState) => state.users
  );

  useEffect(() => {
    if (user && user?.id !== -1) {
      form.setFieldsValue({
        ...user,
        role: user?.userPermissions[0]?.role,
      });
    }
  }, [user]);

  const getUserId = () => {
    const user = getCookie("user_acnpa");
    const parsedUser = user && JSON.parse(user);

    return parsedUser?.id ?? null;
  };

  const checkedLoginUser = useCallback((): boolean => {
    if (user && user?.id && getUserId()) {
      if (user?.id === getUserId() && roleChangeError) {
        return true;
      } else return false;
    }
    return false;
  }, [roleChangeError]);

  const handleSelectRole = (value: any) => {
    if (value === "ROLE_ADMIN") {
      setRoleChangeError(false);
    } else setRoleChangeError(true);
  };

  const handleLogout = () => {
    toast.success("Logout Successfully");
    persistStore(store).purge();
    removeCookie();
    navigate(PATHS.login);
  };
  const onFinish = async (values: any) => {
    setIsLoading(true);
    const request = {
      ...values,
      name: getCapitalizedStr(values.name),
      userPermission: [
        {
          configSettingId: 1,
          roleName: values.role,
        },
      ],
    };
    if (user && user?.id !== -1) {
      Object.assign(request, { userId: user.id });
    }
    try {
      let response: any;
      if (user) {
        response = await updateUser(request);
      } else {
        response = await addUser(request);
      }

      if (response.status === 200) {
        form.resetFields();
        fetchAllUsers(searchText, currentPage, pageLimit);
        onCancel();
        checkedLoginUser() && handleLogout();
      } else {
        if (typeof response === "string") {
          toast.error(response);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Styles.UserModalConatiner
      title={title}
      afterClose={() => form.resetFields()}
      width={400}
      footer={
        <Styles.ModalFooter>
          <Styles.PrimaryButtons
            loading={isLoading}
            onClick={() => form.submit()}
            htmlType="submit"
          >
            {title}
          </Styles.PrimaryButtons>
        </Styles.ModalFooter>
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      open={isModalOpen}
    >
      <Styles.UserForm
        form={form}
        initialValues={{ fullName: "", email: "", password: "", role: "" }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Styles.UserFromItem
          name="name"
          rules={[
            {
              required: true,
              message: "Full name is required",
            },
            {
              pattern: new RegExp(/^[a-zA-Z\s]*$/),
              message: "Name field only accept alphabets",
            },
          ]}
          label="Full name"
        >
          <Styles.UserInput />
        </Styles.UserFromItem>
        <Styles.UserFromItem
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Email is required",
            },
          ]}
          label="Email"
        >
          <Styles.UserInput />
        </Styles.UserFromItem>
        {!user && (
          <Styles.UserFromItem
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
            label="Password"
          >
            <Styles.UserInput.Password />
          </Styles.UserFromItem>
        )}
        <Styles.UserFromItem
          name="role"
          rules={[{ required: true, message: "Role is required" }]}
          label="Role"
        >
          <Styles.RoleSelect
            options={[
              { value: Role.Admim, label: "Admin" },
              { value: Role.User, label: "User" },
            ]}
            onChange={(e) => handleSelectRole(e)}
          />
        </Styles.UserFromItem>

        {checkedLoginUser() && (
          <Styles.RoleAlert>
            <img src={IMAGES.alertIcon} />
            <span>Changing your role will log you out from the app.</span>
          </Styles.RoleAlert>
        )}
      </Styles.UserForm>
    </Styles.UserModalConatiner>
  );
};

export default AddEditUser;

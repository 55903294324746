import { toast } from "react-toastify";
import { store } from "../store";
import { get, post, put } from "./apiClient";
import { RFPGenerationKey, RFPGenerationStatus } from "../constants";
import { IPolicy966Data } from "../store/policy966/policy966.interface";
import {
  setStopPolicy966NavigationCheck,
  updateRfpGenerationStatus,
} from "../store/overview/overviewSlice";
import {
  autoRefreshPolicyStatus,
  setIsEditPolicy966Loading,
  setIsExportPolicyLoading,
  setIsPolicy966DataLoading,
  setPolicy966Data,
  setStopPolicy966AutoRefresh,
} from "../store/policy966/policy966";

export const generatePolicy966 = async (RFPId: number) => {
  try {
    store.dispatch(setStopPolicy966AutoRefresh(false)),
      store.dispatch(
        updateRfpGenerationStatus({
          key: RFPGenerationKey.RfpId,
          value: RFPId.toString(),
        })
      );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: RFPGenerationStatus.PROCESSING,
      })
    );

    const { data } = await post(`apa/policy966/generate/${RFPId}`);

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: data.generationStatus,
      })
    );
    store.dispatch(autoRefreshPolicyStatus({ rfpId: RFPId }));
  } catch (error: any) {
    console.log(error, "generateAti api error");
    toast.error(error?.error ?? "Failed to generate");

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: RFPId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
  } finally {
    store.dispatch(setStopPolicy966NavigationCheck(true));
  }
};

export const stopGeneratePolicy966 = async (
  policyId: number,
  rfpId: number,
  rfpName: string
) => {
  try {
    store.dispatch(setStopPolicy966AutoRefresh(false)),
      store.dispatch(
        updateRfpGenerationStatus({
          key: RFPGenerationKey.RfpId,
          value: rfpId.toString(),
        })
      );

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: RFPGenerationStatus.STOPPING_FE,
      })
    );

    const { data } = await put(`apa/profile/stop`, {
      id: policyId,
      rfpId: rfpId,
      rfpFileName: rfpName,
      proposalsCategory: "POLICY_966",
    });

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: data.generationStatus,
      })
    );
    store.dispatch(autoRefreshPolicyStatus({ rfpId: rfpId }));
  } catch (error: any) {
    console.log(error, "generateAti api error");
    toast.error(error?.error ?? "Failed to stop generation");

    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: rfpId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: RFPGenerationStatus.COMPLETED,
      })
    );
  } finally {
    // store.dispatch(setStopPolicy966NavigationCheck(true));
  }
};

export const getGeneratedPolicy966 = async (
  RFPId: number,
  isLoading: boolean
): Promise<IPolicy966Data> => {
  try {
    isLoading && store.dispatch(setIsPolicy966DataLoading(true));
    const res = await get(`apa/policy966/${RFPId}/rfp`);

    store.dispatch(setPolicy966Data(res));
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: RFPId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: res?.generationStatus,
      })
    );

    return res;
  } catch (error: unknown) {
    console.log("getGeneratedPolicy966 api error", error);
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.RfpId,
        value: RFPId.toString(),
      })
    );
    store.dispatch(
      updateRfpGenerationStatus({
        key: RFPGenerationKey.Policy966,
        value: RFPGenerationStatus.NOT_STARTED,
      })
    );
    throw error;
  } finally {
    store.dispatch(setIsPolicy966DataLoading(false));
  }
};

export const exportPolicy966 = async (
  policy966Id: number,
  rfpId: number,
  rfpFileName: string
) => {
  try {
    store.dispatch(setIsExportPolicyLoading(true));
    const res = await get(`apa/policy966/${policy966Id}/export/${rfpId}`, {
      responseType: "blob",
    });

    const fileName = !!rfpFileName
      ? `Policy966_${rfpFileName}.pptx`
      : "Policy966.pptx";

    const blobUrl = URL.createObjectURL(res);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", fileName); // Specify the filename for the downloaded file
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (err: any) {
    console.log("exportPolicy966 api error", err);
    toast.error(err?.error ?? "Failed to export policy966.");
  } finally {
    store.dispatch(setIsExportPolicyLoading(false));
  }
};

export const editPolicy966 = async (request: IPolicy966Data, rfpId: number) => {
  try {
    store.dispatch(setIsEditPolicy966Loading(true));
    await put(`apa/policy966/update`, request);
    getGeneratedPolicy966(rfpId, false);
    toast.success("Policy966 updated successfully");
  } catch (error: any) {
    console.log("editPolicy966 api error", error);
    toast.error(error?.message ?? "Failed to update Policy966");
  } finally {
    store.dispatch(setIsEditPolicy966Loading(false));
  }
};

import styled from "styled-components";
import { IMAGES, SecondaryButton } from "../../shared";
import { Button, Input, Select } from "antd";

export const RFPSContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 65px);
  display: grid;
  grid-template-rows: 150px 1fr;
  background: #f2f2f2;
`;

export const RFPHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-image: url(${IMAGES.homeBackground});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const RfpSearch = styled(Input)`
  width: 80%;
  height: 45px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.borderColorBeta};

  img {
    width: 20px;
    height: 20px;
  }
`;

export const SelectToggleWrap = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextToggleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SearchRfpText = styled.div`
  color: #fff;
  font-family: "GraphikRegular";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
`;

export const SelectWrap = styled.div`
  display: flex;
  gap: 15px;
`;

export const RFPTableContainer = styled.div`
  /* height: calc(100% - 20px); */
  /* overflow-y: auto; */
  margin: 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  .ant-table-wrapper {
    height: calc(100vh - 338px);
    overflow-y: auto;
    /* .ant-table-cell {
      padding: 10px 5px;
    } */
    .ant-table-thead > tr {
      & > th {
        background: none;
        padding-top: 30px;
        div {
          font-size: 13px;
        }
        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody > tr {
      &:hover > td {
        background: #f7ecff;
      }
    }
    .ant-table-tbody > tr {
      & > td {
        padding-top: 12px;
        padding-bottom: 12px;
        background: #fbfcff;
        font-size: 13px;
      }
    }
  }
`;

export const RFPHeaderBorderWrap = styled.div`
  padding: 15px 15px 0 15px;
`;

export const RFPTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebecf2;
`;

export const RFPTableSubContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkButton = styled(Button)`
  &.ant-btn-link {
    color: ${({ theme }) => theme.colors.fontColorEpsilon};
  }
  &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.fontColorEpsilon};
  }
`;

export const RFPLabelWrap = styled.div`
  display: flex;
  gap: 15px;
`;

export const RFPLabelContainer = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.backgroundColoGamma};
  padding: 6px 12px;
  height: fit-content;
  cursor: pointer;
  img {
    width: 14px;
  }
`;

export const RFPLabelText = styled.div`
  color: ${({ theme }) => theme.colors.fontColorDelta};
  font-family: "GraphikBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  span {
    font-family: "GraphikRegular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-left: 5px;
  }
`;

export const EditColumnButton = styled(SecondaryButton)`
  border: 1px solid ${({ theme }) => theme.colors.fontColorPi};
  color: ${({ theme }) => theme.colors.fontColorPi};
`;

export const RPFHeaderBorder = styled.div`
  border-bottom: 1px solid #ebecf2;
  margin-top: 15px;
`;

export const StyledSelect = styled(Select)`
  &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #403379;
    border-color: #403379;
  }
  &.ant-select-single {
    width: 130px;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.fontColorBeta};
  }
  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.fontColorBeta};
  }
  &.ant-select-single .ant-select-selector {
    color: ${({ theme }) => theme.colors.fontColorBeta};
  }
`;

import { Button, Carousel, Input, Skeleton } from "antd";
import styled from "styled-components";
import { ActiveStatus } from "../../../types";

export const scrollPage = styled.div`
  overflow: hidden;
  overflow-y: auto;
  padding-right: 4px;
  height: calc(100vh - 168px);
`;

export const ResearchWrap = styled.div`
  height: 141px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  border: 1px solid #d1d1d19e;
  border-top: 0px;
  padding: 14px 45px 20px 26px;
`;

export const InputLabel = styled.label`
  font-family: "GraphikRegularfont";
  font-size: 12px;
  font-weight: normal;
  line-height: 13.2px;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontColorDelta};
  margin-bottom: 7px;
  display: block;
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .research-btn {
    height: 40px;
    border: 0px;
    width: 140px;
    border-radius: 0px;
  }
`;

export const InputField = styled(Input)`
  &.ant-input-affix-wrapper {
    padding: 0px;
    position: relative;
    width: calc(100% - 140px);
    border: 0px;
    box-shadow: none;
    .ant-input-suffix {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 18px;
      }
    }
    input.ant-input {
      background: ${({ theme }) => theme.colors.backgroundColoGamma};
      height: 40px;
      padding: 10px 35px;
      text-overflow: ellipsis;
      font-family: "GraphikRegularfont";
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const InputBoottmWrap = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Note = styled.div`
  border: 0.5px solid #f1d6ff;
  padding: 5px 45px 5px 12px;
  width: auto;
  display: inline-block;
  background-color: #f8ebff;
  border-radius: 2px;
  box-shadow: 0px 4px 4px 0px #fbf3ff;
  font-family: "GraphikRegularfont";
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontColorDelta};

  b {
    font-family: "GraphikMediumfont";
    font-weight: normal;
    color: ${({ theme }) => theme.colors.fontColorOmega};
  }
`;
export const History = styled.div`
  display: block;
  position: relative;
  .history-icon {
    display: block;
    cursor: pointer;
  }
`;

export const HistoryDropdownWrap = styled.span``;

export const HistoryDropdown = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 20px 15px;
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  position: absolute;
  right: 0px;
  top: 111%;
  z-index: 1;
`;
export const TitleWrap = styled.div``;

export const WrapTitle = styled.h3`
  font-family: "GraphikMediumfont";
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 8px;
  margin: 0 0 8px;
  border-bottom: 1px solid rgb(186, 197, 216, 0.35);
`;

export const HistoryList = styled.ul`
  padding: 0px;
  display: block;
  overflow-x: auto;
  max-height: 250px;
  margin: 0 -15px;
  box-shadow: none;
`;

export const EmptyHistory = styled.div`
  & .ant-empty-image {
    height: 50px;
  }
`;

export const HistoryItem = styled.li<ActiveStatus>`
  padding: 11px 15px 11px 15px;
  position: relative;
  display: block;
  background: ${({ $active }) => ($active ? "#f7ecff" : "unset")};

  &::after {
    content: "";
    border-bottom: 0.7px solid #bac5d8;
    display: block;
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    bottom: 0px;
  }

  &:last-child::after {
    display: none;
  }

  &:hover {
    background: #f7ecff;
  }
`;

export const LeftHistory = styled.span``;

export const ItemTitle = styled.span`
  font-family: "GraphikRegularfont";
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  padding-right: 15px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RightHistory = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 4px;
    cursor: pointer;
  }
`;

export const HistoryInputWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 8px;
`;

export const HistoryInputField = styled(Input)`
  padding-right: 60px;
  text-overflow: ellipsis;
  font-family: "GraphikRegularfont";
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  padding: 5px 50px 5px 5px;
`;

export const InputFieldAction = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

export const InputButton = styled(Button)`
  border: 0px;
  padding: 0px;
  background: none;
  line-height: normal;
  box-shadow: none;
  height: auto;
  font-size: 12px;

  &.check-outlined {
    color: ${({ theme }) => theme.colors.fontColorOmega};
    margin-right: 5px;
  }

  .anticon {
    display: block;
  }
`;

export const ShowingResultsWrap = styled.div`
  border: 1px solid #d1d1d19e;
  margin-top: 15px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const ShowingResultsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const ShowingResultsTitle = styled.h2`
  font-family: "GraphikMediumfont";
  font-size: 16px;
  font-weight: normal;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0px;
`;

export const ShowingResultsFilter = styled.div``;

export const ShowingResultsContent = styled.div``;

export const ResultsCard = styled.div`
  border: 2px solid #f2f2f2;
  padding: 15px 40px;
  margin-bottom: 15px;
  position: relative;
  &:last-child {
    margin-bottom: 0px;
  }
  .open-book {
    position: absolute;
    left: 12px;
  }
`;

export const EmptyStateWrap = styled.div`
  margin-top: 100px;
`;

export const ResultsTitle = styled.h3`
  font-family: "GraphikMediumfont";
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.015em;
  text-align: left;
  color: ${({ theme }) => theme.colors.fontColorAlpha};
  margin: 0px 0px 10px 0px;
`;

export const ResultsDescription = styled.div`
  * {
    color: ${({ theme }) => theme.colors.fontColorAlpha};
    font-family: "GraphikRegularfont";
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-align: left;
  }
  p:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const ErrorDescription = styled(ResultsDescription)`
  p {
    color: ${({ theme }) => theme.colors.fontColorXi};
  }
`;

export const SliderCarousel = styled(Carousel)`
  width: 200px;
  min-width: 100%;
  max-width: 100%;
  margin-top: 25px;
  &.slick-slider {
    .slick-arrow {
      width: 11px;
      height: 18px;
      &::before {
        content: "";
        background-image: url(/assets/images/slick-arrow.png);
        background-size: 9px;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        display: block;
      }
      &.slick-prev {
        &::before {
          transform: rotate(-180deg);
        }
      }
      &.slick-next {
        inset-inline-end: -20px;
      }
    }
  }
`;

export const ResultsSliderCard = styled.div`
  width: auto !important;
  display: block !important;
  padding: 7px 26px 2px 8px;
  border: 0.5px solid #b5c7ff;
  box-shadow: 0px 2px 4px 0px #e5eaf8;
  background: linear-gradient(180deg, #fcf7ff 0%, rgba(255, 255, 255, 0) 100%);
  /* width: calc(12.5% - 10px); */
  margin-right: 10px;
  border-radius: 2px;
  position: relative;
  .viewLink {
    position: absolute;
    right: 0px;
    top: 0px;
  }
`;

export const ResultsSliderTitle = styled.h4`
  font-family: "GraphikRegularfont";
  color: #111111;
  font-size: 11px;
  font-weight: normal;
  text-transform: capitalize;
  line-height: 14px;
  letter-spacing: 0.015em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ResultsSliderDescription = styled.p`
  font-family: "GraphikRegularfont";
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  color: #004dff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const PromoterResearcherSkeleton = styled(Skeleton)`
  padding: 10px;
`;

export const ResearchWrapEmpty = styled.div`
  height: 165px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  border: 1px solid #d1d1d19e;
  border-top: 0px;
  padding: 14px 45px 20px 26px;
`;

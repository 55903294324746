import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { store } from "../../store";
import { INbmContent, nbmData } from "./nbm.interface";
import { RFPGenerationStatus } from "../../constants";
import { getGeneratedNbmById } from "../../services/nbm";

export const nbmInitialData = {
  qualification: [],
  client_profile: [],
  payments_terms: [],
  pre_qualification: [],
  broad_scope_of_work: [],
  technical_qualification: [],
  winning_themes_for_accenture: [],
};

const initialNbm: nbmData = {
  nbmId: -1,
  data: null,
  competitiveLandscapeFileIds: null,
  uploadedOn: "",
  lastUpdatedOn: "",
  generationStatus: "",
  rfpFileName: "",
  nbmData: nbmInitialData,
};

const initialState: INbmContent = {
  nbmData: initialNbm,
  isNbmDataLoading: false,
  isNbmUpdateLoading: false,
  isNbmExportLoading: false,
  stopNbmAutoRefresh: false,
};

export const nbmContentSlice = createSlice({
  name: "nbmContentSlice",
  initialState,
  reducers: {
    setStopNbmAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.stopNbmAutoRefresh = action.payload;
    },

    setIsNbmDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isNbmDataLoading = action.payload;
    },

    setIsNbmUpdateLoading: (state, action: PayloadAction<boolean>) => {
      state.isNbmUpdateLoading = action.payload;
    },

    setNbmData: (state, action: PayloadAction<nbmData>) => {
      state.nbmData = action.payload;
    },

    autoRefreshNbmStatus: (_, action: PayloadAction<{ rfpId: number }>) => {
      let res: nbmData;
      const interval = setInterval(async () => {
        res = await getGeneratedNbmById(action.payload.rfpId, false);

        store.getState().nbm.stopNbmAutoRefresh &&
          store.getState().nbm.stopNbmAutoRefresh === true &&
          clearInterval(interval);

        !!res &&
          res?.generationStatus === RFPGenerationStatus.COMPLETED &&
          clearInterval(interval);

        !!res &&
          res?.generationStatus === RFPGenerationStatus.STOPPING &&
          clearInterval(interval);

        !!res &&
          res.generationStatus === RFPGenerationStatus.FAILED &&
          clearInterval(interval);
      }, 5000);
    },

    setIsNbmExportLoading: (state, action: PayloadAction<boolean>) => {
      state.isNbmExportLoading = action.payload;
    },
  },
});

export const {
  setNbmData,
  setIsNbmDataLoading,
  autoRefreshNbmStatus,
  setStopNbmAutoRefresh,
  setIsNbmUpdateLoading,
  setIsNbmExportLoading,
} = nbmContentSlice.actions;

export default nbmContentSlice.reducer;

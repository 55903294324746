import { Input, Skeleton } from "antd";
import styled, { css } from "styled-components";
import { ActiveStatus } from "../../../types";

export const AtiContainer = styled.div`
  overflow: auto;
  font-size: 14px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  padding: 10px 10px 14px 10px;
  .ant-tabs-nav {
    margin: 0 -10px;
    padding-left: 10px;
    .ant-tabs-nav-wrap {
      &::before,
      &::after {
        display: none !important;
      }
    }
  }
  .ant-tabs .ant-tabs-tab {
    & + .ant-tabs-tab {
      margin: 0 0 0 5px;
    }
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    &:nth-last-child(2) {
      margin-right: 10px;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-content {
    padding: 10px 0;
  }
`;

export const NbmTableItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const NBMItemsContainer = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 30% 1fr;
`;

export const NbmFormHeader = styled.div`
  font-family: "GraphikMedium";
  font-size: 16px;
  line-height: 19.8px;
  color: #45464e;
  margin-left: 5px;
`;

export const LeftSide = styled.div``;

export const RightSide = styled.div`
  display: flex;
  justify-content: start;
`;

export const LeftTextNumberAlign = styled.div`
  flex-grow: 1;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  padding: 20px;
  height: 100%;
`;

export const LeftTextNumberAlignLoading = styled(LeftTextNumberAlign)`
  padding: 0;
`;

export const RightTextNumberAlign = styled(LeftTextNumberAlign)`
  padding: 0px;
`;

export const FinanceDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 5px;
`;

export const SerialNum = styled.div`
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-family: GraphikMedium;
  font-weight: 500;
  border-radius: 50px;
  border: 0.1px solid #f4edf9;
  background-color: #f4edf9;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FTitle = styled.div`
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  font-size: 14px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
`;

export const AtiSkeleton = styled(Skeleton)`
  padding: 10px;
  height: 100px;
`;

export const NBMItemsEditablePointsPoints = styled.div`
  display: flex;
  gap: 5px;
  padding: 5px;
`;

export const NBMItemsEditablePointsHeaderWrap = styled.div``;

export const NBMItemsEditablePointsHeader = styled.div`
  padding: 20px;
  background: #f4f6fc;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #5c5b5b;

  font-size: 12px;
`;

export const InputSummary = styled(Input.TextArea)`
  &.ant-input {
    height: 100px;
    resize: none;
    border: none;
    box-shadow: none;
    font-size: 13px;
    padding: 5px;
    ${(props) =>
      props.readOnly &&
      css`
        border: none;
        &:hover {
          border: none;
        }
      `}
  }
`;

export const InputFooterButtonWrap = styled.div`
  justify-content: end;

  button {
    padding: 0px;
    width: 27px;
    height: 27px;
    svg {
      color: #a100ff;
    }
  }
`;

export const InputFooterWrap = styled.div<ActiveStatus>`
  width: 100%;
  border: ${({ $active }) => ($active ? "1px solid #b829ff" : "none")};

  ${InputFooterButtonWrap} {
    display: ${({ $active }) => ($active ? "flex" : "none")};
  }
`;

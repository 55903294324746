import styled from "styled-components";

export const AtiInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  .ant-tooltip .ant-tooltip-inner {
    min-height: auto;
    padding: 10px;
  }

  span {
    font-family: "GraphikMedium";
    font-size: 14px;
    font-weight: 500;
  }
`;

export const AtiInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 300px;
  width: 100%;
  overflow-x: auto;
`;

export const Title = styled.h2`
  font-family: "GraphikMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 10px;
  text-align: center;
`;
export const Content = styled.p`
  font-family: "GraphikRegular";
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 10px;
`;
export const Title2 = styled.h4`
  font-family: "GraphikMedium";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 5px;
`;
export const ContentUl = styled.ul`
  padding-left: 20px;
  margin: 10px 0;
`;
export const Contentli = styled.li`
  font-family: "GraphikRegular";
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 5px;
`;

import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./styles";
import { PATHS } from "../../../constants";
import { RootState } from "../../../store";
import { sendOtp } from "../../../services/auth";
import { SecondaryButton } from "../../../shared";
import {
  resetOtpPassword,
  setUserEmailAtForgetPassword,
} from "../../../store/auth/authSlice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isSendOtpLoading } = useSelector(
    (state: RootState) => state.authSlice
  );

  const onFinish = (value: { email: string }) => {
    const { email } = value;

    dispatch(setUserEmailAtForgetPassword(email));
    sendOtp({ email }, navigate);
  };

  return (
    <>
      <Styles.HeadingText>Forgot password?</Styles.HeadingText>
      <Styles.SubHeadingText>
        Enter your email below and we'll send you a link to reset your password.
      </Styles.SubHeadingText>
      <Form
        name="forgotPassword"
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please Enter Your Email",
            },
          ]}
        >
          <Styles.InputField placeholder="Email" />
        </Form.Item>

        <Form.Item style={{ marginTop: "15px" }}>
          <Styles.SendLinkButton htmlType="submit" loading={isSendOtpLoading}>
            Send Link
          </Styles.SendLinkButton>
        </Form.Item>
      </Form>
      <Styles.BackToLoginContainer>
        <SecondaryButton
          icon={<LeftOutlined />}
          onClick={() => {
            navigate(PATHS.login);
            dispatch(resetOtpPassword([]));
          }}
        >
          Back to login
        </SecondaryButton>
      </Styles.BackToLoginContainer>
    </>
  );
};

export default ForgotPassword;

import styled from "styled-components";
import { Button } from "antd";

interface IButton {
  borderRadius?: string;
}

export const PrimaryButton = styled(Button)<IButton>`
  font-family: "GraphikMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "2px"};
`;

export const SecondaryButton = styled(Button)<IButton>`
  font-family: "GraphikMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "2px"};

  &.ant-btn.ant-btn-circle.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-btn-default {
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      color: ${({ theme }) => theme.colors.fontColorAlpha};
    }
  }

  &:hover {
    .anticon {
      color: ${({ theme }) => theme.colors.fontColorEpsilon};
    }
  }
`;

export const TertiaryButton = styled(SecondaryButton)<IButton>`
  border: 1px solid ${({ theme }) => theme.colors.borderColorBeta};
  color: ${({ theme }) => theme.colors.fontColorOmega};

  svg {
    fill: ${({ theme }) => theme.colors.backgroundColorBeta};
  }
`;

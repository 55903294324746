import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Empty } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Styles from "./styles";
import { RootState } from "../../../../store";
import { stopRFPExtGen } from "../../../../services/stopRfpExtGen";
import { deleteExtractionFile } from "../../../../services/allRfps";
import { MarkDownText } from "../../../../shared/markDownText/MarkDownText";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../../shared";
import { IProposalFiles } from "../../../../store/finance/finance.interface";
import ConfirmationModal from "../../../common/confirmModal/ConfirmationModal";
import {
  ExtractionStatus,
  PATHS,
  RFPGenerationStatus,
} from "../../../../constants";
import {
  handleOpenPdfDoc,
  onDownloadPdf,
  uniqueId,
} from "../../../../shared/helpers";
import {
  ReGenerateFinanceDetails,
  UpdateFinanceInputs,
  getFinanceDetails,
} from "../../../../services/finance";
import {
  setFinanceDetails,
  setStopFinanceAutoRefresh,
} from "../../../../store/finance/financeSlice";

interface IFormSummery {
  moneyDepositSummary: string;
  performanceBankGuaranteeSummary: string;
  paymentTermsSummary: string;
  slasSummary: string;
  feeAtRiskSummary: string;
  timelinesForBillingsSummary: string;
}

const initialFormSummery: IFormSummery = {
  moneyDepositSummary: "",
  performanceBankGuaranteeSummary: "",
  paymentTermsSummary: "",
  slasSummary: "",
  feeAtRiskSummary: "",
  timelinesForBillingsSummary: "",
};

const disabledGenerateFinanceDetails = (status: string): boolean => {
  switch (status) {
    case ExtractionStatus.AI_BUSY:
    case ExtractionStatus.PROCESSING:
    case ExtractionStatus.REGENERATING:
      return true;

    default:
      return false;
  }
};

const getSummeryKey = (summeryKey: keyof typeof initialFormSummery) => {
  switch (summeryKey) {
    case "moneyDepositSummary":
      return "EBD";
    case "performanceBankGuaranteeSummary":
      return "PBG";
    case "paymentTermsSummary":
      return "PAYMENT_TERMS";
    case "slasSummary":
      return "SLAs";
    case "feeAtRiskSummary":
      return "FEE_AT_RISK";
    case "timelinesForBillingsSummary":
      return "TIMELINES_FOR_BILLING";
    default:
      return "";
  }
};

const Finance = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentSummery, setCurrentSummery] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [isStopGenerating, setIsStopGenerating] = useState(false);
  const [isSummaryUpdating, setIsSummaryUpdating] = useState(false);
  const [_, setIsExtractedFileDeleting] = useState(false);
  const [editFormSummery, setEditFormSummery] = useState<number>(-1);
  const [formSummery, setFormSummery] =
    useState<IFormSummery>(initialFormSummery);
  const [isDocumentDownloading, setIsDocumentDownloading] =
    useState<boolean>(false);
  const [currentDocumentDownloading, setCurrentDocumentDownloading] =
    useState<number>(-1);

  const { rfpId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const currentRfpId: number = !!rfpId ? Number(rfpId) : -1;

  const {
    financeDetails,
    isGenerateFinanceLoading,
    isFinanceDetailsLoading,
    stopFinanceNavigationCheck,
  } = useSelector((state: RootState) => state.finance);

  useEffect(() => {
    if (
      financeDetails?.extractionStatus !== RFPGenerationStatus.COMPLETED &&
      financeDetails?.extractionStatus !== RFPGenerationStatus.REGENERATING &&
      pathname.includes(PATHS.finance) &&
      stopFinanceNavigationCheck
    ) {
      navigate(`/rfp/${currentRfpId}/${PATHS.overview}`);
    }
  }, [financeDetails, pathname, stopFinanceNavigationCheck]);

  useEffect(() => {
    dispatch(setStopFinanceAutoRefresh(false));
    getFinanceDetails(currentRfpId, true);

    return () => {
      dispatch(setStopFinanceAutoRefresh(true));
    };
  }, [currentRfpId]);

  useEffect(() => {
    setFormSummery({
      moneyDepositSummary: financeDetails?.moneyDepositSummary,
      performanceBankGuaranteeSummary:
        financeDetails?.performanceBankGuaranteeSummary,
      paymentTermsSummary: financeDetails?.paymentTermsSummary,
      slasSummary: financeDetails?.slasSummary,
      feeAtRiskSummary: financeDetails?.feeAtRiskSummary,
      timelinesForBillingsSummary: financeDetails?.timelinesForBillingsSummary,
    });
  }, [financeDetails]);

  const handleGenerateFinance = async () => {
    await ReGenerateFinanceDetails(currentRfpId);
  };

  const handleDownloadGeneratedFile = async () => {
    if (!!financeDetails?.financeSectionSummaryFilePreSignedUrl) {
      onDownloadPdf(
        financeDetails?.financeSectionSummaryFilePreSignedUrl,
        setIsDownloading,
        financeDetails?.financeSectionSummaryFileName
      );
    } else {
      toast.error("Failed to download File");
    }
  };

  const handleDeleteExtractionFile = async () => {
    try {
      if (financeDetails?.fid) {
        setIsExtractedFileDeleting(true);
        const response: any = await deleteExtractionFile(financeDetails?.fid);
        if (response?.status === 200) {
          toast.success("File deleted successfully");
          dispatch(
            setFinanceDetails({
              ...financeDetails,
              extractionStatus: ExtractionStatus.NOT_STARTED,
            })
          );
        }
      }
      setIsExtractedFileDeleting(false);
    } catch (error) {
      setIsExtractedFileDeleting(false);
      console.log("Error while deleting extracted file", error);
    }
  };

  const handleStopGeneration = async () => {
    try {
      if (financeDetails?.fid && Number(rfpId)) {
        setIsStopGenerating(true);
        dispatch(
          setFinanceDetails({
            ...financeDetails,
            extractionStatus: ExtractionStatus.STOP,
          })
        );
        const request = {
          id: financeDetails?.fid,
          rfpId: Number(rfpId),
          rfpFileName: financeDetails?.rfpFileName,
          proposalsCategory: "FINANCE",
        };
        const response = await stopRFPExtGen(request);
        if (response?.status === 200) {
          const { status } = response.data;
          if (status) {
            dispatch(
              setFinanceDetails({ ...financeDetails, extractionStatus: status })
            );
          } else {
            toast.error(
              "we can't stop now. The Extraction has been completed successfully."
            );
          }
        }
        setIsStopGenerating(false);
      }
    } catch (error) {
      setIsStopGenerating(false);
      console.log("Error while stop extracting file", error);
    }
  };

  const handleSummeryOnChange = (
    value: string,
    summeryKey: keyof typeof initialFormSummery
  ) => {
    setCurrentSummery(value),
      setFormSummery({ ...formSummery, [summeryKey]: value });
  };

  const handleSubmitFormSummery = async (
    summeryKey: keyof typeof initialFormSummery
  ) => {
    try {
      setIsSummaryUpdating(true);

      await UpdateFinanceInputs(
        Number(financeDetails?.fid),
        getSummeryKey(summeryKey),
        {
          summary: formSummery[summeryKey],
        }
      );

      await getFinanceDetails(currentRfpId, false);
    } catch (err) {
    } finally {
      setIsSummaryUpdating(false);
      setEditFormSummery(-1);
      setCurrentSummery("");
    }
  };

  const handleCancelFormSummery = () => {
    setEditFormSummery(-1);
    setFormSummery({
      moneyDepositSummary: financeDetails?.moneyDepositSummary,
      performanceBankGuaranteeSummary:
        financeDetails?.performanceBankGuaranteeSummary,
      paymentTermsSummary: financeDetails?.paymentTermsSummary,
      slasSummary: financeDetails?.slasSummary,
      feeAtRiskSummary: financeDetails?.feeAtRiskSummary,
      timelinesForBillingsSummary: financeDetails?.timelinesForBillingsSummary,
    });
  };

  const handelOpenRfp = (
    fileName: string,
    url: string,
    convertedFileUrl: string
  ) => {
    if (!!fileName && !!url) {
      const currentUrl = convertedFileUrl === "" ? url : convertedFileUrl;
      handleOpenPdfDoc(fileName, currentUrl);
    } else {
      toast.error("Failed to open PDF file");
    }
  };

  const handleDownloadFile = (
    fileName: string,
    url: string,
    fileId: number
  ) => {
    if (!!fileName && !!url && !!fileId) {
      onDownloadPdf(
        url,
        setIsDocumentDownloading,
        fileName,
        fileId,
        setCurrentDocumentDownloading
      );
    } else {
      toast.error("Failed to download file");
    }
  };

  const renderFormSummery = (
    serialNumber: number,
    title: string,
    summery: string,
    summeryKey: keyof typeof initialFormSummery
  ) => {
    return (
      <Styles.LeftSide>
        <Styles.FinanceHeader>
          <Styles.FinanceDetails>
            <Styles.Heading>
              <Styles.SerialNum>{serialNumber}</Styles.SerialNum>
              <Styles.FTitle>{title}</Styles.FTitle>
            </Styles.Heading>
            <Styles.Action>
              {editFormSummery === serialNumber ? (
                <Styles.SaveDiv>
                  <SecondaryButton onClick={handleCancelFormSummery}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    loading={isSummaryUpdating}
                    onClick={() => handleSubmitFormSummery(summeryKey)}
                  >
                    Save
                  </PrimaryButton>
                </Styles.SaveDiv>
              ) : (
                <SecondaryButton
                  className="secondary-button"
                  disabled={
                    financeDetails?.extractionStatus ===
                    ExtractionStatus.PROCESSING
                  }
                  onClick={() => {
                    handleCancelFormSummery();
                    setCurrentSummery(summery);
                    setEditFormSummery(serialNumber);
                  }}
                >
                  Edit
                </SecondaryButton>
              )}
            </Styles.Action>
          </Styles.FinanceDetails>

          {editFormSummery === serialNumber ? (
            <Styles.InputSummary
              readOnly={false}
              value={currentSummery}
              onChange={(e) =>
                handleSummeryOnChange(e?.target?.value, summeryKey)
              }
            />
          ) : (
            <Styles.Text>
              <MarkDownText text={summery} />
            </Styles.Text>
          )}
        </Styles.FinanceHeader>
      </Styles.LeftSide>
    );
  };

  const renderFormDocuments = (files: IProposalFiles[]) => {
    return (
      <Styles.RightSide>
        {!!files && files.length ? (
          <Styles.FinanceHeader>
            <Styles.FileNameContainer>
              {files.map((v) => (
                <Styles.FileNameWrap key={uniqueId()}>
                  <Styles.FileName>{v?.originalFileName}</Styles.FileName>
                  <Styles.FileIconsWrap>
                    <Styles.StyledTooltip title={"View Document"}>
                      <Styles.ViewRfp
                        src={IMAGES.allRfpIcon}
                        alt="viewRfp"
                        $isProcessing={false}
                        $active={false}
                        onClick={() =>
                          handelOpenRfp(
                            v?.originalFileName,
                            v?.fileUrl,
                            v?.convertedFileUrl
                          )
                        }
                      />
                    </Styles.StyledTooltip>
                    <Styles.StyledTooltip title={"Download"}>
                      <Button
                        loading={
                          currentDocumentDownloading === v?.proposalFileId
                        }
                        disabled={isDocumentDownloading}
                        type="link"
                        icon={<img src={IMAGES.download} alt="view" />}
                        onClick={() =>
                          handleDownloadFile(
                            v?.originalFileName,
                            v?.fileUrl,
                            v?.proposalFileId
                          )
                        }
                      />
                    </Styles.StyledTooltip>
                  </Styles.FileIconsWrap>
                </Styles.FileNameWrap>
              ))}
            </Styles.FileNameContainer>
          </Styles.FinanceHeader>
        ) : (
          <Styles.EmptyFiles>
            <Styles.FileNameContainer>
              <Empty />
            </Styles.FileNameContainer>
          </Styles.EmptyFiles>
        )}
      </Styles.RightSide>
    );
  };

  return (
    <Styles.OverviewDetails>
      <Styles.Details>
        <Styles.TitlePage>
          Extract requirements
          <Styles.AIIcon>
            <img src={IMAGES.aiTagLogo} alt="Logo" />
          </Styles.AIIcon>
        </Styles.TitlePage>

        <Styles.ReleventPagesFooter>
          <Styles.FooterRightPart>
            <Styles.ExtractButton
              loading={
                isGenerateFinanceLoading ||
                isFinanceDetailsLoading ||
                financeDetails?.extractionStatus ===
                  ExtractionStatus.REGENERATING
              }
              disabled={disabledGenerateFinanceDetails(
                financeDetails?.extractionStatus
              )}
              onClick={handleGenerateFinance}
            >
              <img src={IMAGES.submitProcess} alt="AI" />
              {financeDetails?.extractionStatus ===
                ExtractionStatus.COMPLETED ||
              financeDetails?.extractionStatus === ExtractionStatus.REGENERATING
                ? "Regenerate"
                : "Generate"}
            </Styles.ExtractButton>

            {financeDetails?.extractionStatus === ExtractionStatus.PROCESSING &&
              !isStopGenerating && (
                <Styles.ExtractLegalContainer>
                  <img src={IMAGES.loadingSpinner} alt="loading" />
                  <Styles.GenerateText>
                    Generating output...
                  </Styles.GenerateText>
                  <SecondaryButton
                    type="text"
                    shape="circle"
                    onClick={handleStopGeneration}
                    icon={<img src={IMAGES.stop} alt="Stop" />}
                  />
                </Styles.ExtractLegalContainer>
              )}
            {(financeDetails?.extractionStatus === ExtractionStatus.STOP ||
              isStopGenerating) && (
              <Styles.ExtractLegalContainer>
                <img src={IMAGES.loadingSpinner} alt="loading" />
                <Styles.GenerateText>
                  Stopping Generation...
                </Styles.GenerateText>
              </Styles.ExtractLegalContainer>
            )}

            {financeDetails?.extractionStatus ===
              ExtractionStatus.COMPLETED && (
              <>
                <Styles.FileText>
                  {financeDetails?.financeSectionSummaryFileName}
                </Styles.FileText>
                <PrimaryButton
                  loading={isDownloading}
                  onClick={handleDownloadGeneratedFile}
                >
                  Download
                </PrimaryButton>
                {/* <Styles.DeleteButton
                  loading={isExtractedFileDeleting}
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  danger
                /> */}
              </>
            )}
          </Styles.FooterRightPart>
        </Styles.ReleventPagesFooter>
      </Styles.Details>

      {(financeDetails?.extractionStatus === ExtractionStatus.COMPLETED ||
        financeDetails?.extractionStatus === ExtractionStatus.REGENERATING) && (
        <>
          <Styles.TitleIconWrap>
            <Styles.Title>Financial input summary</Styles.Title>
            <img src={IMAGES.aiTagLogo} alt="AI Image" />
          </Styles.TitleIconWrap>
          <Styles.FinanceInputRequired>
            <Styles.FinanceTitle>
              <Styles.TitleText></Styles.TitleText>
              <Styles.TitleText>Relevant pages & forms</Styles.TitleText>
            </Styles.FinanceTitle>
            <Styles.Body>
              {renderFormSummery(
                1,
                "Earnest Money Deposit",
                formSummery?.moneyDepositSummary,
                "moneyDepositSummary"
              )}
              {renderFormDocuments(financeDetails?.map?.["EBD"])}
              {renderFormSummery(
                2,
                "Performance Bank Guarantee",
                formSummery?.performanceBankGuaranteeSummary,
                "performanceBankGuaranteeSummary"
              )}
              {renderFormDocuments(financeDetails?.map?.["PBG"])}
              {renderFormSummery(
                3,
                "Payment Terms",
                formSummery?.paymentTermsSummary,
                "paymentTermsSummary"
              )}
              {renderFormDocuments(financeDetails?.map?.["PAYMENT_TERMS"])}
              {renderFormSummery(
                4,
                "SLAs",
                formSummery?.slasSummary,
                "slasSummary"
              )}
              {renderFormDocuments(financeDetails?.map?.["SLAs"])}
              {renderFormSummery(
                5,
                "Fee At Risk",
                formSummery?.feeAtRiskSummary,
                "feeAtRiskSummary"
              )}
              {renderFormDocuments(financeDetails?.map?.["FEE_AT_RISK"])}
              {renderFormSummery(
                6,
                "Timelines for Billing",
                formSummery?.timelinesForBillingsSummary,
                "timelinesForBillingsSummary"
              )}
              {renderFormDocuments(
                financeDetails?.map?.["TIMELINES_FOR_BILLING"]
              )}
            </Styles.Body>
          </Styles.FinanceInputRequired>
        </>
      )}

      <ConfirmationModal
        title="Delete File"
        text={"Are you sure you want to delete this file?"}
        isOpen={isOpenModal}
        isDanger={true}
        btnText="Delete"
        handleOk={() => {
          setIsOpenModal(false);
          handleDeleteExtractionFile();
        }}
        handleCancel={() => {
          setIsOpenModal(false);
        }}
      />
    </Styles.OverviewDetails>
  );
};

export default Finance;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input } from "antd";
import { useParams } from "react-router-dom";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { Policy966Engagement } from "../../../constants";
import { editPolicy966 } from "../../../services/policy966";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../shared";
import { MarkDownText } from "../../../shared/markDownText/MarkDownText";
import { IPolicy966Data } from "../../../store/policy966/policy966.interface";

interface IQuestionToBeAnswered {
  companyIsStrategic: string;
  newOpportunity: string;
  ongoingEngagements: string;
}

interface INewOpportunityDetails {
  QAD: string;
  bidProcess: string;
  expectedDeal: string;
  leadershipTeam: string;
  notRejected: string;
  refNo: string;
  releaseDate: string;
  scope: string;
}

const EngagementMPPGCL = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newOpportunityDetails, setNewOpportunityDetails] =
    useState<INewOpportunityDetails>({
      QAD: "",
      bidProcess: "",
      expectedDeal: "",
      leadershipTeam: "",
      notRejected: "",
      refNo: "",
      releaseDate: "",
      scope: "",
    });
  const [questionToBeAnswered, setQuestionToBeAnswered] =
    useState<IQuestionToBeAnswered>({
      companyIsStrategic: "",
      newOpportunity: "",
      ongoingEngagements: "",
    });

  const [OpportunityForm] = Form.useForm();
  const [questionToBeAnsweredForm] = Form.useForm();

  const { policy966Data, isEditPolicy966Loading, isPolicy966DataLoading } =
    useSelector((state: RootState) => state.policy966);

  const { getOverView } = useSelector((state: RootState) => state.overview);

  const { rfpId } = useParams();

  const currentRfpId: number = rfpId ? Number(rfpId) : -1;

  const initialOpportunityDetails: INewOpportunityDetails = {
    QAD: policy966Data?.newOpportunityDetails?.qad,
    scope: policy966Data?.newOpportunityDetails?.scopeAndTimeLines,
    releaseDate: policy966Data?.newOpportunityDetails?.rfpReleaseDate,
    notRejected: policy966Data?.newOpportunityDetails?.leadershipTeam,
    refNo: policy966Data?.newOpportunityDetails?.refNoAndSubmissionDate,
    expectedDeal: policy966Data?.newOpportunityDetails?.expectedDealSize,
    leadershipTeam: policy966Data?.newOpportunityDetails?.leadershipTeam,
    bidProcess: policy966Data?.newOpportunityDetails?.bidProcessCompetitive,
  };

  const initialQuestionToBeAnswered: IQuestionToBeAnswered = {
    companyIsStrategic: policy966Data?.isCompanyStrategic,
    newOpportunity: policy966Data?.newOpportunityImportance,
    ongoingEngagements: policy966Data?.ongoingEngagementsWithCompany,
  };

  useEffect(() => {
    setNewOpportunityDetails(initialOpportunityDetails);
    setQuestionToBeAnswered(initialQuestionToBeAnswered);

    OpportunityForm.setFieldsValue(initialOpportunityDetails);
    questionToBeAnsweredForm.setFieldsValue(initialQuestionToBeAnswered);
  }, [policy966Data]);

  const handleCancel = () => {
    setNewOpportunityDetails(initialOpportunityDetails);
    setQuestionToBeAnswered(initialQuestionToBeAnswered);

    OpportunityForm.setFieldsValue(initialOpportunityDetails);
    questionToBeAnsweredForm.setFieldsValue(initialQuestionToBeAnswered);
    setIsEdit(false);
  };

  const handleEditPolicy = async () => {
    const updatedPolicy966Data: IPolicy966Data = {
      ...policy966Data,
      isCompanyStrategic: questionToBeAnswered?.companyIsStrategic,
      newOpportunityImportance: questionToBeAnswered?.newOpportunity,
      ongoingEngagementsWithCompany: questionToBeAnswered?.ongoingEngagements,
      newOpportunityDetails: {
        rfpReleaseDate: newOpportunityDetails?.releaseDate,
        bidProcessCompetitive: newOpportunityDetails?.bidProcess,
        expectedDealSize: newOpportunityDetails?.expectedDeal,
        leadershipTeam: newOpportunityDetails?.leadershipTeam,
        notRejected: newOpportunityDetails?.notRejected,
        qad: newOpportunityDetails?.QAD,
        refNoAndSubmissionDate: newOpportunityDetails?.refNo,
        scopeAndTimeLines: newOpportunityDetails?.scope,
      },
    };

    await editPolicy966(updatedPolicy966Data, currentRfpId);
    setIsEdit(false);
  };

  const handleOpportunityFormChange = (
    _: any,
    allValues: INewOpportunityDetails
  ) => {
    setNewOpportunityDetails(allValues);
  };

  const handleQuestionToBeAnsweredFormChange = (
    _: any,
    allValues: IQuestionToBeAnswered
  ) => {
    setQuestionToBeAnswered(allValues);
  };

  const renderQuestionToBeAnswered = () => {
    return (
      <Styles.Answered>
        <Form
          name="questionToBeAnsweredForm"
          layout="vertical"
          requiredMark={false}
          form={questionToBeAnsweredForm}
          onValuesChange={handleQuestionToBeAnsweredFormChange}
        >
          <Styles.AntdFormItem
            label="Why do we believe that the company is Strategic? "
            name="companyIsStrategic"
          >
            {isPolicy966DataLoading ? (
              <Styles.InputSummarySkeleton active paragraph={{ rows: 8 }} />
            ) : isEdit ? (
              <Styles.InputSummary
                autoSize={{ maxRows: 100, minRows: 3 }}
                placeholder="Please enter why do we believe that the company is Strategic?"
              />
            ) : (
              <MarkDownText
                text={
                  questionToBeAnsweredForm.getFieldValue(
                    "companyIsStrategic"
                  ) || "NA"
                }
              />
            )}
          </Styles.AntdFormItem>

          <Styles.AntdFormItem
            label="Why is new opportunity important?"
            name="newOpportunity"
          >
            {isPolicy966DataLoading ? (
              <Styles.InputSummarySkeleton active paragraph={{ rows: 8 }} />
            ) : isEdit ? (
              <Styles.InputSummary
                autoSize={{ maxRows: 100, minRows: 3 }}
                placeholder="Please enter why is new opportunity important?"
              />
            ) : (
              <MarkDownText
                text={
                  questionToBeAnsweredForm.getFieldValue("newOpportunity") ||
                  "NA"
                }
              />
            )}
          </Styles.AntdFormItem>

          <Styles.AntdFormItem
            label="What are the ongoing engagements with the company ?"
            name="ongoingEngagements"
          >
            {isPolicy966DataLoading ? (
              <Styles.InputSummarySkeleton active paragraph={{ rows: 8 }} />
            ) : isEdit ? (
              <Styles.InputSummary
                autoSize={{ maxRows: 100, minRows: 3 }}
                placeholder="Please enter what are the ongoing engagements with the company ?"
              />
            ) : (
              <MarkDownText
                text={
                  questionToBeAnsweredForm.getFieldValue(
                    "ongoingEngagements"
                  ) || "NA"
                }
              />
            )}
          </Styles.AntdFormItem>
        </Form>
      </Styles.Answered>
    );
  };

  const renderNewOpportunityDetails = () => {
    if (isPolicy966DataLoading)
      return <Styles.InputSummarySkeleton active paragraph={{ rows: 8 }} />;
    else {
      return (
        <Styles.Answered>
          <Form
            name="OpportunityForm"
            layout="horizontal"
            requiredMark={false}
            form={OpportunityForm}
            onValuesChange={handleOpportunityFormChange}
          >
            <Styles.NewOpportunityFormItem
              label="RFP release date"
              name="releaseDate"
              $active={isEdit}
            >
              {isEdit ? (
                <Input
                  type="date"
                  placeholder="Please enter RFP release date"
                />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("releaseDate") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>

            <Styles.NewOpportunityFormItem
              label="Scope & Timelines"
              name="scope"
              $active={isEdit}
            >
              {isEdit ? (
                <Input placeholder="Please enter scope & Timelines" />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("scope") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>

            <Styles.NewOpportunityFormItem
              label="Expected Deal-Size"
              name="expectedDeal"
              $active={isEdit}
            >
              {isEdit ? (
                <Input placeholder="Please enter expected Deal-Size" />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("expectedDeal") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>

            <Styles.NewOpportunityFormItem
              label="Bid Process – Competitive"
              name="bidProcess"
              $active={isEdit}
            >
              {isEdit ? (
                <Input placeholder="Please enter bid Process – competitive" />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("bidProcess") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>

            <Styles.NewOpportunityFormItem
              label="QAD"
              name="QAD"
              $active={isEdit}
            >
              {isEdit ? (
                <Input placeholder="Please enter QAD" />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("QAD") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>

            <Styles.NewOpportunityFormItem
              label="Leadership team"
              name="leadershipTeam"
              $active={isEdit}
            >
              {isEdit ? (
                <Input placeholder="Please enter leadership team name" />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("leadershipTeam") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>

            <Styles.NewOpportunityFormItem
              label="Ref No. & Submission date for Review under Policy 966"
              name="refNo"
              $active={isEdit}
            >
              {isEdit ? (
                <Input placeholder="Please enter ref No. & submission date for review under policy 966" />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("refNo") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>

            <Styles.NewOpportunityFormItem
              label="Not rejected"
              name="notRejected"
              $active={isEdit}
            >
              {isEdit ? (
                <Input placeholder="Please enter not rejected" />
              ) : (
                <MarkDownText
                  text={OpportunityForm.getFieldValue("notRejected") || ""}
                />
              )}
            </Styles.NewOpportunityFormItem>
          </Form>
        </Styles.Answered>
      );
    }
  };

  const renderPolicyItems = (
    title: string,
    renderItem: Policy966Engagement
  ) => {
    return (
      <>
        <Styles.Question>{title}</Styles.Question>
        {renderItem === Policy966Engagement.KEYQUESTIONS
          ? renderQuestionToBeAnswered()
          : renderNewOpportunityDetails()}
      </>
    );
  };

  return (
    <Styles.EngagementMPPGCLContainer>
      <Styles.PolicyNote>
        <Styles.PolicyNoteHeader>
          <Styles.AppLogo src={IMAGES.accentureLogo} alt="accentureLogo" />
          <Styles.PolicyNoteHeaderTitle>
            Policy 966 : Approval Note
          </Styles.PolicyNoteHeaderTitle>
          <Styles.PolicyNoteHeaderSubTitle>
            {getOverView?.client_name}
          </Styles.PolicyNoteHeaderSubTitle>
        </Styles.PolicyNoteHeader>
        <Styles.PolicyNoteContent>
          <Styles.PolicyNoteContentTitle>
            About the company
          </Styles.PolicyNoteContentTitle>

          {isEdit ? (
            <Styles.SaveDiv>
              <SecondaryButton
                onClick={handleCancel}
                disabled={isEditPolicy966Loading}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={handleEditPolicy}
                loading={isEditPolicy966Loading}
              >
                Save
              </PrimaryButton>
            </Styles.SaveDiv>
          ) : (
            <Styles.EditButton
              disabled={isEditPolicy966Loading}
              onClick={() => setIsEdit(true)}
            >
              Edit
            </Styles.EditButton>
          )}

          <Styles.QuestionsKey>
            <Styles.QuestionsList>
              <Styles.QuestionsItem>
                {renderPolicyItems(
                  "Key Questions to be Answered:",
                  Policy966Engagement.KEYQUESTIONS
                )}
                {renderPolicyItems(
                  "New opportunity details:",
                  Policy966Engagement.NEWOPPORTUNITY
                )}
              </Styles.QuestionsItem>
            </Styles.QuestionsList>
          </Styles.QuestionsKey>
        </Styles.PolicyNoteContent>
        <Styles.Footer>
          <Styles.FooterCopyright>
            © 2024 Accenture NBM. All Right Reserved.
          </Styles.FooterCopyright>
          <Styles.FooterRight>
            Selection of System Integrator (ERPSI) for Supply, Installation,
            Commissioning, Implementation and Support for Enterprise  Resource
            Planning (ERP) System in NBPDCL & SBPDCL
          </Styles.FooterRight>
        </Styles.Footer>
      </Styles.PolicyNote>
    </Styles.EngagementMPPGCLContainer>
  );
};

export default EngagementMPPGCL;

import { TimeRangePickerProps } from "antd";
import { Form } from "antd";
import {
  IMAGES,
  PrimarySwitch,
  SecondaryDateRangePicker,
} from "../../../shared";
import { bidStageOptions, clientGroupOptions } from "../../../shared/helpers";
import * as Styles from "./styles";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilter,
  setResetRfpPagination,
} from "../../../store/allRfps/allRfpSlice";
import { RootState } from "../../../store";
import { useEffect } from "react";
import { useDebounce } from "../../../shared/hooks/useDebounce";
import { DownOutlined } from "@ant-design/icons";

const SearchRFP = () => {
  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { rfpFilters } = useSelector((state: RootState) => state.allRfps);

  const { clientGroup, bidStage, startDate, endDate } = rfpFilters;

  useEffect(() => {
    form.setFieldsValue({
      search_text: rfpFilters.rfpName,
    });
  }, [rfpFilters]);

  const debouncedSearch = useDebounce(() => {
    form.submit();
  }, 500);

  const onFinish = (values: any) => {
    const searchText = values?.search_text || "";

    if ((searchText && searchText.trim() !== "") || searchText === "") {
      dispatch(updateFilter({ field: "rfpName", value: searchText.trim() }));
      dispatch(setResetRfpPagination());

      if (pathname === "/") {
        navigate(PATHS.rfps);
      }
    }
  };

  const handleSearchChange = () => {
    const fieldValue = form.getFieldValue("search_text") || "";
    if (
      pathname !== "/" &&
      ((fieldValue && fieldValue.trim() !== "") || fieldValue === "")
    ) {
      debouncedSearch();
    }
  };

  const onChangeClientGroup = (value: string) => {
    dispatch(updateFilter({ field: "clientGroup", value: value }));
    dispatch(setResetRfpPagination());
  };

  const onChangeBidStage = (value: string) => {
    dispatch(updateFilter({ field: "bidStage", value: value }));
    dispatch(setResetRfpPagination());
  };

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      const formattedStartDate = dates[0]
        ? dayjs(dates[0]).format("YYYY-MM-DD")
        : "";
      const formattedEndDate = dates[1]
        ? dayjs(dates[1]).format("YYYY-MM-DD")
        : "";
      if (formattedStartDate && formattedEndDate) {
        dispatch(
          updateFilter({
            field: "startDate",
            value: `${formattedStartDate} 00:00:00.000000`,
          })
        );
        dispatch(
          updateFilter({
            field: "endDate",
            value: `${formattedEndDate} 23:59:00.000000`,
          })
        );
      }
      dispatch(setResetRfpPagination());
    }
  };

  const renderSelect = () => {
    return (
      <Styles.SelectWrap>
        <Styles.StyledSelect
          placeholder={
            <>
              <Styles.PrefixIcon>
                <img src={IMAGES.clientGroup} alt="clientGroup" />
              </Styles.PrefixIcon>
              &nbsp; Client Group
            </>
          }
          loading={false}
          value={clientGroup === "" ? undefined : clientGroup}
          options={clientGroupOptions}
          onChange={(value: any) => onChangeClientGroup(value as string)}
          popupClassName="ui-dropdown-default"
        />
        <Styles.StyledSelect
          placeholder={
            <>
              <Styles.PrefixIcon>
                <img src={IMAGES.bidStage} alt="bidStage" />
              </Styles.PrefixIcon>
              &nbsp; Bid Stage
            </>
          }
          loading={false}
          value={bidStage === "" ? undefined : bidStage}
          options={bidStageOptions}
          onChange={(value: any) => onChangeBidStage(value as string)}
          popupClassName="ui-dropdown-default"
        />
        <SecondaryDateRangePicker
          presets={rangePresets}
          placeholder={["Start date", "End date"]}
          format={"YYYY-MM-DD"}
          value={
            startDate && endDate
              ? [dayjs(startDate, "YYYY-MM-DD"), dayjs(endDate, "YYYY-MM-DD")]
              : null
          }
          allowClear={false}
          onChange={onRangeChange}
          suffixIcon={<DownOutlined style={{ color: "#FFF" }} />}
          icon={<img src={IMAGES.dateRangeIcon} alt="dateRange" />}
        />
      </Styles.SelectWrap>
    );
  };

  return (
    <Styles.SearchRFPContainer>
      <Styles.RfpForm form={form} onFinish={onFinish} autoComplete="off">
        <Styles.StyledFormItem name="search_text">
          <Styles.RfpSearch
            placeholder="Search RFPs…"
            prefix={<img src={IMAGES.searchIcon} alt="search" />}
            onChange={handleSearchChange}
            onPressEnter={() => {
              const fieldValue = form.getFieldValue("search_text");
              if (fieldValue && fieldValue.trim() !== "") {
                form.submit();
              }
            }}
          />
        </Styles.StyledFormItem>
      </Styles.RfpForm>
      <Styles.SelectToggleWrap>
        {renderSelect()}
        <Styles.TextToggleWrap>
          <Styles.SearchRfpText>Search in my RFPs</Styles.SearchRfpText>
          <PrimarySwitch />
        </Styles.TextToggleWrap>
      </Styles.SelectToggleWrap>
    </Styles.SearchRFPContainer>
  );
};

export default SearchRFP;

import { Badge, Button, Empty, Input } from "antd";
import styled from "styled-components";
import { ActiveStatus } from "../../../../types";
import { SecondaryButton } from "../../../../shared";

export const QualificationContent = styled.div`
  height: calc(100vh - 135px);
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: 100%;
  padding: 12px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
`;

export const Details = styled.div`
  margin-top: 20px;
  border-radius: 2px;
  border: 1px solid #af68d947;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  box-shadow: 0px 4px 4px 0px #f3f2f3;
  position: relative;
  padding: 15px;
`;

export const TitlePage = styled.div`
  top: -25px;
  font-family: "GraphikMedium", sans-serif;
  color: #45464e;
  margin-left: 10px;
  position: relative;
  padding: 0 5px;
  font-size: 13px;
  background-color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  width: fit-content;
  display: flex;
  gap: 10px;
`;

export const ReleventPagesFooter = styled.div`
  display: flex;
  align-items: center;
  font-family: GraphikMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  justify-content: space-between;
  margin: 15px 0px;
`;

export const FooterRightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BadgeContainer = styled.div`
  margin: 2px 2px 2px 2px;
`;

export const BadgeCustom = styled(Badge)`
  background: #f4f6fc;
  .anticon {
    background: #9f9f9f;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
  }
`;
export const PageText = styled.span`
  font-family: "GraphikMedium", sans-serif;
  font-size: 12px;
`;

export const ButtonLink = styled(Button)`
  &.ant-btn {
    padding: 0;
    font-size: 12px;
  }
  &.ant-btn > span {
    text-decoration: underline;
  }
  &.ant-btn-link {
    color: #194dea;
  }
`;

export const PageBoxSubContainer = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  // gap: 4px;
`;

export const BorderRight = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.fontColorEpsilon};
`;

export const FromTO = styled.span`
  color: #5c5b5b;
  font-size: 10px;
`;

export const AntInput = styled(Input)`
  &.ant-input {
    padding: 0px;
    width: 45px;
    font-family: "GraphikMedium", sans-serif;
  }
  &.ant-input-outlined {
    background: transparent;
    border: none;
  }
  &.ant-input-outlined:focus,
  :where(
      .css-dev-only-do-not-override-1v67itz
    ).ant-input-outlined:focus-within {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  &.ant-input-outlined:hover {
    background: transparent;
  }
`;

export const PageBox = styled.div<any>`
  border: 1px solid
    ${({ theme, isedit }) =>
      isedit ? theme.colors.fontColorEpsilon : "#d2deff"};
  display: flex;
  align-items: center;
  padding: 0px 8px;
  gap: 20px;
`;

export const PageBoxViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0px;
  width: 150px;
  height: 52px;
`;

export const PageBoxEditContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 102px;
`;

export const PagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 10px;
`;

export const FileText = styled.div`
  font-family: "GraphikMedium";
  font-size: 12px;
`;

export const BtnStyling = styled.div`
  // margin: 14px 0px 0px 29px;
`;

export const DeleteIcons = styled.span``;

export const DeleteButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoteContainer = styled.div`
  // margin-top: 15px;
  border-radius: 2px;
  border: 0.5px solid #f1d6ff;
  background: #f8ebff;
  box-shadow: 0px 4px 4px 0px #fbf3ff;
  font-size: 10px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const NoteTitle = styled.span`
  font-family: "GraphikMedium", sans-serif;
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  padding-right: 5px;
`;

export const NoteDescription = styled.div``;

export const ExtractButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.colors.backgroundColorAlpha};
  background: linear-gradient(107deg, #cf26eb 33.23%, #6d39f5 87.55%);
  flex-shrink: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.backgroundColorAlpha}!important;
  }

  img {
    height: 14px;
  }
`;

export const RigthSide = styled.div`
  display: flex;
  justify-content: start;
`;

export const ExtractLegalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GenerateText = styled.span`
  font-size: 12px;
`;

export const GenerateResponseSubText = styled.div`
  font-size: 13px;
`;

export const skeletonLoadingWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding-bottom: 20px;
`;

export const EmptyState = styled(Empty)`
  margin-block: 0;
  font-size: 12px;

  &.ant-empty-image {
    height: 30px;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  margin-top: 20px;
`;

export const Description = styled.div`
  padding-bottom: 20px;
  font-size: 12px;

  span {
    font-family: "GraphikMedium";
  }
`;

export const UploadFileTitle = styled.div`
  color: ${({ theme }) => theme.colors.fontColorEpsilon};
  padding-bottom: 5px;
  font-size: 12px;
`;

export const UploadSubContainer = styled.div`
  border-radius: 5px;
  border: 1px solid #e3e4e5;
  background: ${({ theme }) => theme.colors.backgroundColorAlpha};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  padding: 5px;
`;

export const UploadIconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  overflow: hidden;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const AIFilesButton = styled(SecondaryButton)<ActiveStatus>`
  color: ${({ theme, $active }) => $active && theme.colors.fontColorEpsilon};
  border-color: ${({ theme, $active }) =>
    $active && theme.colors.borderColorBeta};
`;

// Upload file
export const UploadFileContainer = styled.div`
  padding: 10px 0px;
`;

export const CancelStartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

export const AIIcon = styled.span`
  margin-left: 5px;
`;

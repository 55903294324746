import { toast } from "react-toastify";
import { store } from "../store";
import {
  Frs,
  IExtractFrsClauses,
  IGenerateFrsResponse,
  ISolutionRelevantPages,
} from "../store/frs/frs.interface";
import {
  setFrsDetails,
  setExtractFrsClauses,
  setIsFrsDetailsLoading,
  setFrsExtractStatus,
  setGenerateFrsResponseStatus,
  setGenerateFrsResponse,
  setIsDeleteExtractFrsFileLoading,
} from "../store/frs/frsSlice";
import { deleteRequest, get, post, put } from "./apiClient";
import { ExtractionStatus } from "../constants";

export const getAllFrsPages = async (rfpId: number) => {
  try {
    store.dispatch(setIsFrsDetailsLoading(true));
    const res = await get(`apa/profile/${rfpId}/proposals`);

    const FrsRes: Frs = {
      docId: res?.solution?.sid,
      relevantPages: res?.solution?.frs?.relevantPages,
      fileName: res?.rfp?.file_name,
      frsId: res?.solution?.frs?.frsId,
      rfpId: res?.rfpId,
      status: res?.solution?.frs?.extractionStatus,
    };
    store.dispatch(setFrsDetails(FrsRes));

    const extractRes: IExtractFrsClauses = {
      status: null,
      frsSectionSummaryFilePreSignedUrl:
        res?.solution?.frs?.frsSectionSummaryFilePreSignedUrl,
      extractionStatus: res?.solution?.frs?.extractionStatus,
      frsSectionSummaryFileName: res?.solution?.frs?.frsSectionSummaryFileName,
    };

    store.dispatch(setExtractFrsClauses(extractRes));

    const generatedRes: IGenerateFrsResponse = {
      status: res?.solution?.frs?.status,
      generatedResponseFileName: res?.solution?.frs?.generatedResponseFileName,
      generatedResponseFilePreSignedUrl:
        res?.solution?.frs?.generateResponseFilePreSignedUrl,
      generatedResponseStatus: res?.solution?.frs?.generatedResponseStatus,
    };

    if (!!res?.solution?.frs?.generatedResponseFileName) {
      store.dispatch(setGenerateFrsResponse(generatedRes));
    } else {
      const initialState = {
        status: null,
        generatedResponseFilePreSignedUrl: "",
        generatedResponseFileName: "",
        generatedResponseStatus: ExtractionStatus.NOT_STARTED,
      };
      store.dispatch(setGenerateFrsResponse(initialState));
    }
  } catch (error: any) {
    console.log(error, "getAllFrsPages api error");
  } finally {
    store.dispatch(setIsFrsDetailsLoading(false));
  }
};

export const updateSolutionPages = async (
  id: number,
  request: ISolutionRelevantPages[],
  message: string
) => {
  try {
    await put(`/apa/frs/${id}/pages`, request);
    toast.success(message);
  } catch (error: any) {
    console.log(error, "updateSolutionPages api error");
    toast.error(error?.message ?? "Failed to update solution pages");
  }
};

export const getFrsPageDetailById = async (lId: number) => {
  try {
    const res = await get(`/apa/frs/${lId}`);
    return res;
  } catch (error: any) {
    console.log("getFrsPageDetailById", error);
  }
};

export const extractFRSDetails = async (
  request: { rfpFileName: string },
  frsId: number
) => {
  try {
    store.dispatch(setFrsExtractStatus(ExtractionStatus.PROCESSING));

    await put(`/apa/frs/${frsId}/extract-frs-details`, {}, { params: request });

    let interval = setInterval(async () => {
      try {
        const frsByIdRes = await getFrsPageDetailById(frsId);
        store.dispatch(setExtractFrsClauses(frsByIdRes));
        !!frsByIdRes &&
          frsByIdRes.extractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      } catch {
        clearInterval(interval);
        store.dispatch(setFrsExtractStatus(ExtractionStatus.FAILED));
      }
    }, 10000);
  } catch (error: any) {
    console.log("extractFrsDetails", error);
    toast.error(error?.error ?? "Failed to extract FRS Details");
    store.dispatch(setFrsExtractStatus(ExtractionStatus.NOT_STARTED));
  }
};

export const generateResponse = async (
  aiRequest: {
    rfpId: number;
    contentType: string;
    preSignedUrl: string;
    frsId: number;
  },
  legalProcessingStatus: ExtractionStatus,
  formData: FormData,
  uploadedContentType: string
) => {
  try {
    store.dispatch(setGenerateFrsResponseStatus(ExtractionStatus.PROCESSING));
    legalProcessingStatus === ExtractionStatus.AI
      ? await post(
          `/apa/frs/generate-response`,
          {},
          {
            params: {
              rfpId: aiRequest?.rfpId,
              contentType: aiRequest?.contentType,
              preSignedUrl: aiRequest?.preSignedUrl,
            },
          }
        )
      : await post(`/apa/frs/generate-response`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            rfpId: aiRequest?.rfpId,
            contentType: uploadedContentType,
          },
        });

    let interval = setInterval(async () => {
      try {
        const generatedRes = await getFrsPageDetailById(aiRequest?.frsId);
        store.dispatch(setGenerateFrsResponse(generatedRes));

        !!generatedRes &&
          generatedRes.extractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);
      } catch {
        clearInterval(interval);
        store.dispatch(setGenerateFrsResponseStatus(ExtractionStatus.FAILED));
      }
    }, 10000);
  } catch (error: any) {
    console.log("generateResponse", error);
    toast.error(error?.error ?? "Failed to generate response");
    store.dispatch(setGenerateFrsResponseStatus(ExtractionStatus.NOT_STARTED));
  }
};

export const deleteSolutionExtractedFile = async (frs: number) => {
  try {
    store.dispatch(setIsDeleteExtractFrsFileLoading(true));

    await deleteRequest(`/apa/frs/details-file/${frs}`);

    let interval = setInterval(async () => {
      try {
        const frsByIdRes = await getFrsPageDetailById(frs);
        store.dispatch(setExtractFrsClauses(frsByIdRes));
        store.dispatch(
          setGenerateFrsResponse({
            generatedResponseStatus: ExtractionStatus.NOT_STARTED,
            generatedResponseFileName: "",
            status: null,
            generatedResponseFilePreSignedUrl: "",
          })
        );

        !!frsByIdRes &&
          frsByIdRes.extractionStatus !== ExtractionStatus.PROCESSING &&
          clearInterval(interval);

        toast.success("File deleted successfully");
      } catch {
        clearInterval(interval);
        store.dispatch(setFrsExtractStatus(ExtractionStatus.NOT_STARTED));
      } finally {
        store.dispatch(setIsDeleteExtractFrsFileLoading(false));
      }
    }, 10000);
  } catch (error: any) {
    console.log("deleteSolutionExtractedFile", error?.error);
    toast.error(error?.error ?? "Failed to delete frs file");
    store.dispatch(setFrsExtractStatus(ExtractionStatus.NOT_STARTED));
  }
};

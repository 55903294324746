import { ReactNode, useState } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import * as Styles from "./styles";
import { IMAGES } from "../../shared";
import { BreadcrumbText, PATHS } from "../../constants";
import { UploadDocModal } from "../../components";
import { Filter as DashboardFilter } from "../dashboard";

const Header = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const { pathname } = useLocation();

  const itemRender = (route: any) => {
    return <Link to={route?.href}>{route?.title}</Link>;
  };

  const headerBreadcrumb = () => {
    if (pathname.includes(PATHS.rfp) && pathname !== PATHS.rfps) {
      const items: { href: string; title: ReactNode }[] = [
        {
          title: <Styles.Link>All RFPs</Styles.Link>,
          href: PATHS.rfps,
        },
      ];

      if (pathname === PATHS.rfp) {
        items.push({
          title: <Styles.CurrentLink>Overview</Styles.CurrentLink>,
          href: pathname,
        });
      }

      const getsubUrl = pathname === PATHS.rfp ? [] : pathname.split(PATHS.rfp);

      if (getsubUrl.length > 0) {
        const urlTwo = getsubUrl[1].split("/")[2];

        items.push({
          title: (
            <Styles.CurrentLink>
              {BreadcrumbText[urlTwo as keyof typeof BreadcrumbText]}
            </Styles.CurrentLink>
          ),
          href: pathname,
        });
      }

      return <Breadcrumb separator=">" itemRender={itemRender} items={items} />;
    } else {
      return (
        <Styles.HeaderTabWrap>
          <Styles.HeaderText>
            Accenture AI Proposal Accelerator
          </Styles.HeaderText>
          <Styles.AiTagContainer>
            <img src={IMAGES.aiTagLogo} alt="aiTag" />
            <Styles.AiTagText>BETA</Styles.AiTagText>
          </Styles.AiTagContainer>
        </Styles.HeaderTabWrap>
      );
    }
  };

  return (
    <>
      <Styles.HeaderContainer>
        {headerBreadcrumb()}
        {pathname === PATHS.dashboard ? (
          <DashboardFilter />
        ) : (
          <Styles.UploadButton
            onClick={() => setUploadModal(true)}
            icon={<img src={IMAGES.UploadIcon} alt="upload" />}
          >
            Upload
          </Styles.UploadButton>
        )}
      </Styles.HeaderContainer>

      <UploadDocModal
        isModalOpen={uploadModal}
        setUploadModal={setUploadModal}
      />
    </>
  );
};

export default Header;

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DeleteOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { ExtractionStatus, PATHS } from "../../../constants";
import { stopRFPExtGen } from "../../../services/stopRfpExtGen";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../shared";
import ConfirmationModal from "../../common/confirmModal/ConfirmationModal";
import { getFileExtension, onDownloadPdf } from "../../../shared/helpers";
import {
  deleteLegalExtractedFile,
  extractLegalDetails,
  generateResponse,
  getAllLegalPages,
} from "../../../services/legal";
import {
  setExtractionStatus,
  setGenerateLegalResponseStatus,
  setStopLegalAutoRefresh,
} from "../../../store/legal/legalSlice";

const Legal = () => {
  const [legalFile, setLegalFile] = useState<File | null>(null);
  const [isStopExtracting, setIsStopExtracting] = useState(false);
  const [isStopGeneration, setIsStopGeneration] = useState(false);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const [legalFileName, setLegalFileName] = useState<string | null>(null);
  const [downloadingClauses, setDownloadingClauses] = useState<boolean>(false);
  const [isNavigationCheckEnable, setIsNavigationCheckEnable] =
    useState<boolean>(false);
  const [downloadingResponse, setDownloadingResponse] =
    useState<boolean>(false);

  const { rfpId } = useParams();

  const dispatch = useDispatch();

  const currentRfpId = rfpId ? parseInt(rfpId) : -1;

  const {
    legalDetails,
    extractLegalClauses,
    isLegalDetailsLoading,
    generateLegalResponse,
    isDeleteExtractLegalFileLoading,
  } = useSelector((state: RootState) => state.legal);

  const legalPageId = legalDetails?.lid ? legalDetails.lid : -1;

  const inputClauseFile = useRef<HTMLInputElement>(null);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const legalPageApiCall = async () => {
      await getAllLegalPages(currentRfpId);
      setIsNavigationCheckEnable(true);
    };
    legalPageApiCall();
    return () => {
      dispatch(setStopLegalAutoRefresh(true));
    };
  }, [currentRfpId]);

  useEffect(() => {
    if (
      isNavigationCheckEnable &&
      extractLegalClauses?.extractionStatus !== ExtractionStatus.COMPLETED &&
      pathname.includes(PATHS.legal)
    ) {
      navigate(`/rfp/${currentRfpId}/${PATHS.overview}`);
    }
  }, [extractLegalClauses, pathname, isNavigationCheckEnable]);

  const handleClauseFileUploadButton = () => {
    if (inputClauseFile.current != null) {
      inputClauseFile.current.click();
    }
  };

  const handleClauseFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files && e.target.files[0];
    if (file) {
      setLegalFile(file);

      const formData = new FormData();
      formData.append("file", file);

      // Get the file type from the uploaded file
      const uploadedFile = formData.get("file") as File;
      setLegalFileName(uploadedFile.name);

      dispatch(setGenerateLegalResponseStatus(ExtractionStatus.UPLOADED));
    }
  };

  const handleDeleteExtractionFile = () => {
    deleteLegalExtractedFile(legalDetails?.lid);
  };

  const handleExtractLegalDetails = () => {
    if (legalDetails?.fileName) {
      extractLegalDetails(
        { fileName: legalDetails?.fileName },
        legalDetails?.lid,
        currentRfpId
      );
    } else {
      toast.error("Failed to extract legal response");
    }
  };

  const handleStartProcessing = () => {
    const formData = new FormData();
    legalFile && formData.append("file", legalFile);

    // Get the file type from the uploaded file
    const uploadedFile = formData?.get("file") as File;
    const fileName = uploadedFile?.name;
    const fileType = fileName?.substring(fileName.lastIndexOf(".") + 1);

    generateResponse(
      {
        rfpId: currentRfpId,
        contentType: getFileExtension(
          extractLegalClauses?.legalSectionSummaryFileName
        ),
        preSignedUrl: extractLegalClauses?.legalSectionSummaryFilePreSignedUrl,
        lId: legalDetails?.lid,
      },
      generateLegalResponse?.generatedResponseFileStatus,
      formData,
      fileType ?? "csv"
    );
  };

  const handleCancelProcessing = () => {
    dispatch(setGenerateLegalResponseStatus(ExtractionStatus.NOT_STARTED));
    setLegalFileName(null);
    setLegalFile(null);
    if (inputClauseFile.current) {
      inputClauseFile.current.value = "";
    }
  };

  const handleStopExtGen = async (type: "Extraction" | "Generation") => {
    try {
      if (!legalPageId || !Number(rfpId) || !legalDetails?.fileName) return;

      const isExtraction = type === "Extraction" ? 1 : 0;
      const isStopState =
        type === "Extraction" ? setIsStopExtracting : setIsStopGeneration;

      isStopState(true);

      const request = {
        id: Number(legalPageId),
        rfpId: Number(rfpId),
        rfpFileName: legalDetails.fileName,
        proposalsCategory: "LEGAL",
        isExtraction,
      };

      const response = await stopRFPExtGen(request);

      if (response?.status === 200) {
        const { status } = response.data;
        if (status) {
          type === "Extraction"
            ? dispatch(setExtractionStatus(status))
            : dispatch(setGenerateLegalResponseStatus(status));
        } else {
          type === "Extraction"
            ? dispatch(setExtractionStatus(ExtractionStatus.STOPPING))
            : dispatch(
                setGenerateLegalResponseStatus(ExtractionStatus.STOPPING)
              );
          toast.error(
            `we can't stop now. The ${type} has been completed successfully.`
          );
        }
      }

      isStopState(false);
    } catch (error) {
      type === "Extraction"
        ? setIsStopExtracting(false)
        : setIsStopGeneration(false);
      console.log(`Error while stop ${type} file`, error);
    }
  };

  const renderExtractClauses = () => {
    if (extractLegalClauses?.extractionStatus === ExtractionStatus.COMPLETED) {
      return (
        <>
          <Styles.FileText>
            {extractLegalClauses?.legalSectionSummaryFileName}
          </Styles.FileText>
          <PrimaryButton
            loading={downloadingClauses}
            onClick={() => {
              if (extractLegalClauses?.legalSectionSummaryFilePreSignedUrl)
                onDownloadPdf(
                  extractLegalClauses?.legalSectionSummaryFilePreSignedUrl,
                  setDownloadingClauses,
                  extractLegalClauses?.legalSectionSummaryFileName
                );
            }}
          >
            Download
          </PrimaryButton>
          <Styles.DeleteButton
            loading={isDeleteExtractLegalFileLoading}
            onClick={() => {
              setIsDeleteOpenModal(true);
            }}
            shape="circle"
            icon={<DeleteOutlined />}
            danger
          />
        </>
      );
    }

    return (
      <>
        <Styles.ExtractButton
          disabled={
            extractLegalClauses?.extractionStatus ===
            ExtractionStatus.PROCESSING
          }
          onClick={handleExtractLegalDetails}
          loading={isLegalDetailsLoading}
        >
          <img src={IMAGES.submitProcess} alt="Logo" />
          Generate
        </Styles.ExtractButton>

        {extractLegalClauses?.extractionStatus ===
          ExtractionStatus.PROCESSING &&
          !isStopExtracting && (
            <>
              <img src={IMAGES.loadingSpinner} alt="loading" />
              <Styles.GenerateText>Generating output...</Styles.GenerateText>
              <SecondaryButton
                type="text"
                shape="circle"
                onClick={() => {
                  handleStopExtGen("Extraction");
                }}
                icon={<img src={IMAGES.stop} alt="Stop" />}
              />
            </>
          )}
        {extractLegalClauses?.extractionStatus === ExtractionStatus.STOPPING &&
          isStopExtracting && (
            <>
              <img src={IMAGES.loadingSpinner} alt="loading" />
              <Styles.GenerateText>Stopping Generation...</Styles.GenerateText>
            </>
          )}
      </>
    );
  };

  const renderGenerateLegalResponse = () => {
    switch (generateLegalResponse?.generatedResponseFileStatus) {
      case ExtractionStatus.NOT_STARTED:
      case ExtractionStatus.AI:
      default:
        return (
          <>
            <Styles.Description>
              {extractLegalClauses?.extractionStatus !==
              ExtractionStatus.COMPLETED
                ? "You can directly upload your modified file to generate responses"
                : "The above file generated is an assistive step for your RFP legal clause extraction. To generate a response you can either use the above generated AI file or upload your own modified file"}
            </Styles.Description>
            <Styles.SubContainer>
              <PrimaryButton
                onClick={() =>
                  dispatch(
                    setGenerateLegalResponseStatus(ExtractionStatus.UPLOADING)
                  )
                }
                disabled={
                  generateLegalResponse?.generatedResponseFileStatus ===
                    ExtractionStatus.AI ||
                  generateLegalResponse?.generatedResponseFileStatus ===
                    ExtractionStatus.PROCESSING ||
                  generateLegalResponse?.generatedResponseFileStatus ===
                    ExtractionStatus.STOPPING
                }
              >
                Upload modified file
              </PrimaryButton>
              <Styles.Span>Or</Styles.Span>
              <Styles.AIFilesButton
                $active={
                  generateLegalResponse?.generatedResponseFileStatus ===
                  ExtractionStatus.AI
                }
                disabled={
                  extractLegalClauses?.extractionStatus !==
                    ExtractionStatus.COMPLETED ||
                  generateLegalResponse?.generatedResponseFileStatus ===
                    ExtractionStatus.PROCESSING ||
                  generateLegalResponse?.generatedResponseFileStatus ===
                    ExtractionStatus.STOPPING
                }
                onClick={() =>
                  dispatch(setGenerateLegalResponseStatus(ExtractionStatus.AI))
                }
              >
                Use AI generated file
              </Styles.AIFilesButton>
              <Styles.NoteContainer>
                <Styles.NoteTitle>Note:</Styles.NoteTitle>
                <Styles.NoteDescription>
                  Please verify all the documents before processing
                </Styles.NoteDescription>
              </Styles.NoteContainer>
            </Styles.SubContainer>
          </>
        );
      case ExtractionStatus.COMPLETED:
        return (
          <>
            <Styles.Description>
              Your file processing is successfully completed and is now
              available for download.
            </Styles.Description>
            <Styles.DownloadContainer>
              <PrimaryButton
                loading={downloadingResponse}
                onClick={() => {
                  if (generateLegalResponse?.generatedResponseFilePreSignedUrl)
                    onDownloadPdf(
                      generateLegalResponse?.generatedResponseFilePreSignedUrl,
                      setDownloadingResponse,
                      generateLegalResponse?.generatedResponseFileName
                    );
                }}
              >
                Download
              </PrimaryButton>
              <Styles.Span>
                {generateLegalResponse?.generatedResponseFileName}
              </Styles.Span>
            </Styles.DownloadContainer>
          </>
        );

      case ExtractionStatus.UPLOADING:
      case ExtractionStatus.UPLOADED:
        return (
          <>
            <Styles.Description>
              Please note before uploading, ensure it is a{" "}
              <span>single column</span> file titled “Legal Clause” or similar.
            </Styles.Description>
            <Styles.UploadFileTitle>
              Upload your modified file here
            </Styles.UploadFileTitle>
            <Styles.UploadSubContainer>
              <Styles.UploadIconTitle>
                <img src={IMAGES.browse} alt="Browse" />
                <Styles.Span>{legalFileName ?? "Browse"}</Styles.Span>
              </Styles.UploadIconTitle>

              <PrimaryButton onClick={handleClauseFileUploadButton}>
                Browse
              </PrimaryButton>
              <input
                type="file"
                ref={inputClauseFile}
                accept=".xlsx, .xls, .csv"
                onChange={handleClauseFileUpload}
                hidden
              />
            </Styles.UploadSubContainer>
          </>
        );
    }
  };

  return (
    <>
      <Styles.LegalContainer>
        {/* Relevant page start */}
        <Styles.RelevantPageContainer>
          <Styles.RelevantPageTitle>
            Extract legal clauses{" "}
            <Styles.AIIcon>
              <img src={IMAGES.aiTagLogo} alt="Logo" />
            </Styles.AIIcon>
          </Styles.RelevantPageTitle>
          <Styles.RelevantPageBody>
            {/* {renderBadge()} */}
            <Styles.BtnContainer>
              <Styles.ExtractLegalContainer>
                {renderExtractClauses()}
              </Styles.ExtractLegalContainer>
            </Styles.BtnContainer>
          </Styles.RelevantPageBody>
        </Styles.RelevantPageContainer>
        {/* Relevant page end */}

        <Styles.GenerateResponseContainer>
          <Styles.GenerateTitle>Generate legal response</Styles.GenerateTitle>
          <Styles.ResponseBody>
            {renderGenerateLegalResponse()}
            <Styles.UploadFileContainer>
              {generateLegalResponse?.generatedResponseFileStatus ===
                ExtractionStatus.PROCESSING &&
                !isStopGeneration && (
                  <Styles.CancelStartContainer>
                    <img src={IMAGES.loadingSpinner} alt="loading" />
                    <Styles.GenerateText>
                      Generating output...
                    </Styles.GenerateText>
                    <SecondaryButton
                      type="text"
                      shape="circle"
                      onClick={() => {
                        handleStopExtGen("Generation");
                      }}
                      icon={<img src={IMAGES.stop} alt="Stop" />}
                    />
                  </Styles.CancelStartContainer>
                )}
              {generateLegalResponse?.generatedResponseFileStatus ===
                ExtractionStatus.STOPPING &&
                isStopGeneration && (
                  <Styles.CancelStartContainer>
                    <img src={IMAGES.loadingSpinner} alt="loading" />
                    <Styles.GenerateText>
                      Stopping Generation...
                    </Styles.GenerateText>
                  </Styles.CancelStartContainer>
                )}
              {(generateLegalResponse?.generatedResponseFileStatus ===
                ExtractionStatus.NOT_STARTED ||
                generateLegalResponse?.generatedResponseFileStatus ===
                  ExtractionStatus.AI ||
                generateLegalResponse?.generatedResponseFileStatus ===
                  ExtractionStatus.UPLOADED ||
                generateLegalResponse?.generatedResponseFileStatus ===
                  ExtractionStatus.UPLOADING) &&
                !isStopGeneration && (
                  <Styles.CancelStartContainer>
                    <SecondaryButton
                      disabled={
                        generateLegalResponse?.generatedResponseFileStatus ===
                        ExtractionStatus.NOT_STARTED
                      }
                      onClick={handleCancelProcessing}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={handleStartProcessing}
                      disabled={
                        generateLegalResponse?.generatedResponseFileStatus ===
                          ExtractionStatus.NOT_STARTED ||
                        generateLegalResponse?.generatedResponseFileStatus ===
                          ExtractionStatus.UPLOADING
                      }
                      // loading={
                      //   generateLegalResponse?.generatedResponseFileStatus ===
                      //   ExtractionStatus.PROCESSING
                      // }
                    >
                      Start Processing
                    </PrimaryButton>
                  </Styles.CancelStartContainer>
                )}
            </Styles.UploadFileContainer>
          </Styles.ResponseBody>
        </Styles.GenerateResponseContainer>
      </Styles.LegalContainer>

      <ConfirmationModal
        title="Delete File"
        text={"Are you sure you want to delete this file?"}
        isOpen={isDeleteOpenModal}
        isDanger={true}
        btnText="Delete"
        handleOk={() => {
          setIsDeleteOpenModal(false);
          handleDeleteExtractionFile();
        }}
        handleCancel={() => {
          setIsDeleteOpenModal(false);
        }}
      />
    </>
  );
};

export default Legal;

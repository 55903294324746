import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import * as Styles from "./styles";
import { RootState } from "../../../store";
import { uniqueId } from "../../../shared/helpers";
import { getAllUsers } from "../../../services/auth";
import { IMAGES, PrimaryButton, SecondaryButton } from "../../../shared";
import { OrganogramFieldLevels } from "../../../constants";
import {
  getOrganogram,
  saveOrganogram,
  updateOrganogram,
} from "../../../services/organogram";
import { ConfirmationModal } from "../../../components";

interface IChild {
  id: number;
  label: string;
  value: string;
  userId: number;
}

interface IParent {
  id: number;
  label: string;
  value: string | undefined;
  userId: number;
  child: IChild[];
}

const Organogram = () => {
  const { rfpId } = useParams();

  const [form] = useForm();

  const navigate = useNavigate();

  const { allUsers } = useSelector((state: RootState) => state.authSlice);
  const {
    organogram,
    saveOrganogramLoading,
    getOrganogramLoading,
    organogramTitle,
  } = useSelector((state: RootState) => state.organogram);

  const currentRfpId: number = rfpId ? parseInt(rfpId) : -1;

  const [parentLabelError, setParentLabelError] = useState<number[]>([]);
  const [parentValueError, setParentValueError] = useState<number[]>([]);
  const [childLabelError, setChildLabelError] = useState<number[]>([]);
  const [childValueError, setChildValueError] = useState<number[]>([]);
  const [fields, setFields] = useState<IParent[]>([
    {
      id: 1,
      label: "",
      value: undefined,
      userId: -1,
      child: [],
    },
  ]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({
    parentId: -1,
    childId: -1,
    isDelete: false,
    level: OrganogramFieldLevels.none,
  });
  const [prevFields, setPrevFields] = useState<IParent[]>([
    {
      id: 1,
      label: "",
      value: undefined,
      userId: -1,
      child: [],
    },
  ]);
  const [prevOrganogramFields, setPrevOrganogramFields] = useState<any>(null);
  const [isOrganogramFormChanged, setIsOrganogramFormChanged] =
    useState<boolean>(false);

  const getInitialValueByFieldId = (fieldName: string): number | undefined => {
    const field = organogram?.organogram?.find((v) => v?.role === fieldName);
    const name = field?.userId ?? undefined;
    return name;
  };
  const isDynamicFormChanged =
    JSON.stringify(prevFields) !== JSON.stringify(fields);

  useEffect(() => {
    getOrganogram(currentRfpId);
    getAllUsers();
  }, []);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  //set initial form value
  useEffect(() => {
    const formInitialValues = {
      AllianceLead: getInitialValueByFieldId("AllianceLead"),
      ProcurementLead: getInitialValueByFieldId("ProcurementLead"),
      accountLead: getInitialValueByFieldId("accountLead"),
      bidManager: getInitialValueByFieldId("bidManager"),
      deliveryLead: getInitialValueByFieldId("deliveryLead"),
      financeLead: getInitialValueByFieldId("financeLead"),
      leadSolutionArchitects: getInitialValueByFieldId(
        "leadSolutionArchitects"
      ),
      legal: getInitialValueByFieldId("legal"),
      proposalManager: getInitialValueByFieldId("proposalManager"),
      saleCaptureLead: getInitialValueByFieldId("saleCaptureLead"),
    };

    setPrevOrganogramFields(formInitialValues);
    form.setFieldsValue(formInitialValues);
  }, [organogram?.organogram, form]);

  //set initial values for dynamic inputs
  useEffect(() => {
    const sliceOrganogram = organogram?.organogram?.slice(10);

    const preValues: IParent[] = sliceOrganogram.map((item) => ({
      id: uniqueId(),
      label: item?.role,
      value: item?.name,
      userId: item?.userId,
      child: item.subordinates.map((v) => ({
        id: uniqueId(),
        label: v?.role,
        value: v?.name,
        userId: v?.userId,
      })),
    }));

    if (sliceOrganogram.length) {
      setFields(preValues);
      setPrevFields(preValues);
    } else {
      setFields([
        {
          id: 1,
          label: "",
          value: undefined,
          userId: -1,
          child: [],
        },
      ]);
      setPrevFields([
        {
          id: 1,
          label: "",
          value: undefined,
          userId: -1,
          child: [],
        },
      ]);
    }
  }, [organogram?.organogram]);

  const handleRemoveField = (id: number) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const handleRemoveChildField = (parentId: number, childId: number) => {
    setFields((pre) => {
      return pre.map((field) => {
        if (field.id === parentId) {
          const updatedChild = field.child.filter(
            (child) => child.id !== childId
          );
          return { ...field, child: updatedChild };
        }
        return field;
      });
    });
  };

  const dynamicFieldsValidation = (): boolean => {
    const emptyParentRoleIds = fields
      .filter((item) => item.label === "")
      .map((item) => item.id);

    setParentLabelError(emptyParentRoleIds);

    const emptyParentValueIds = fields
      .filter((item) => item.value === "")
      .map((item) => item.id);

    setParentValueError(emptyParentValueIds);

    const emptyChildValueIds = fields.reduce((acc, item) => {
      item.child?.forEach((v) => {
        if (v.value === "") {
          acc.push(v.id);
        }
      });
      return acc;
    }, [] as number[]);

    setChildValueError(emptyChildValueIds);

    const emptyChildLabelIds = fields.reduce((acc, item) => {
      item.child?.forEach((v) => {
        if (v.label === "") {
          acc.push(v.id);
        }
      });
      return acc;
    }, [] as number[]);

    setChildLabelError(emptyChildLabelIds);

    if (
      emptyParentRoleIds?.length === 0 &&
      emptyParentValueIds?.length === 0 &&
      emptyChildValueIds?.length === 0 &&
      emptyChildLabelIds?.length === 0
    ) {
      return true;
    } else return false;
  };

  const onFinish = async (values: any) => {
    const valuesInArray = Object.entries(values).map(([role, name], _) => {
      const userName = allUsers.find((user) => user.id === name);
      return {
        role,
        name: userName?.name ?? -1,
        userId: name,
      };
    });

    const dynamicFieldsArray = fields?.map(
      ({ label, value, child, userId }) => ({
        role: label,
        name: value,
        userId: userId,
        subordinates: child?.map(({ label, value, userId }) => ({
          role: label,
          name: value,
          userId: userId,
        })),
      })
    );

    const mergeArrays = [...valuesInArray, ...dynamicFieldsArray];
    const stringifyArrays = JSON.stringify({
      organogram: mergeArrays,
    });

    if (dynamicFieldsValidation()) {
      if (!!organogram?.organogram && organogram?.organogram.length > 0) {
        await updateOrganogram({
          rfpId: currentRfpId,
          organogramJson: stringifyArrays,
        });
      } else {
        await saveOrganogram({
          rfpId: currentRfpId,
          organogramJson: stringifyArrays,
        });
      }
    }

    setPrevOrganogramFields(form.getFieldsValue());
    setPrevFields(fields);
    setIsOrganogramFormChanged(false);
  };

  const renderOrgHeader = () => {
    return (
      <Styles.OrgHeader>
        <Styles.OrgSubHeader onClick={() => navigate(-1)}>
          <img src={IMAGES.leftArrowWithBG} alt="Icon" />
          <Styles.OrgTitle>Organogram</Styles.OrgTitle>
        </Styles.OrgSubHeader>
      </Styles.OrgHeader>
    );
  };

  const renderAntFormItem = (label: string, name: string) => {
    return (
      <Styles.AntFormItem
        label={label}
        name={name}
        rules={[{ required: true, message: "Please enter full name" }]}
      >
        <Styles.AntSelectWrap
          showSearch
          allowClear
          suffixIcon={null}
          placeholder="Search for a user"
          optionFilterProp="children"
          defaultValue={undefined}
        >
          {allUsers?.map((user) => (
            <Select.Option key={user?.id} value={user?.id}>
              {user.name}
            </Select.Option>
          ))}
        </Styles.AntSelectWrap>
      </Styles.AntFormItem>
    );
  };

  const renderDynamicInputs = () => {
    const handleAddField = () => {
      const newField = {
        id: uniqueId(),
        label: "",
        value: "",
        userId: -1,
        child: [],
      };

      setFields([...fields, newField]);
    };

    const handleAddChild = (parentId: number) => {
      const newChild = {
        id: uniqueId(),
        label: "",
        value: "",
        userId: -1,
      };

      setFields((prevFields) => {
        const updatedFields = prevFields.map((field) => {
          if (field.id === parentId) {
            return { ...field, child: [...field.child, newChild] };
          }
          return field;
        });

        return updatedFields;
      });
    };

    const handleLabelChange = (id: number, label: string) => {
      setFields(
        fields.map((field) => (field.id === id ? { ...field, label } : field))
      );

      //shows error message
      handLeLabelOnBlur(label, id);
    };

    const handLeLabelOnBlur = (label: string, fieldId: number) => {
      if (label.length) {
        setParentLabelError((pre) => {
          return pre.filter((v) => v !== fieldId);
        });
      } else {
        setParentLabelError((pre) => {
          return [...pre, fieldId];
        });
      }
    };

    const handLeValueOnBlur = (value: string | undefined, fieldId: number) => {
      if (value !== undefined && value.length) {
        if (
          !allUsers.some(
            (user) => user.name.toUpperCase() === value.toUpperCase()
          )
        ) {
          setParentValueError((pre) => {
            return [...pre, fieldId];
          });
        } else
          setParentValueError((pre) => {
            return pre.filter((v) => v !== fieldId);
          });
      } else {
        setParentValueError((pre) => {
          return [...pre, fieldId];
        });
      }
    };

    const handleValueChange = (id: number, option: any) => {
      const value: string = option?.children ?? "";
      const userId: number = option?.value ?? -1;

      setFields(
        fields.map((field) =>
          field.id === id ? { ...field, value, userId: userId } : field
        )
      );

      //shows error message
      handLeValueOnBlur(value, id);
    };

    const handleChildLabelChange = (childId: number, label: string) => {
      setFields((pre) => {
        return pre.map((field) => {
          const updatedChild = field.child.map((child) => {
            if (child?.id === childId) {
              return { ...child, label: label };
            }
            return child;
          });
          return { ...field, child: updatedChild };
        });
      });

      //show error message
      handLeChildLabelOnBlur(label, childId);
    };

    const handleChildValueChange = (childId: number, option: any) => {
      const value: string = option?.children ?? "";
      const userId: number = option?.value ?? -1;

      setFields((pre) => {
        return pre.map((field) => {
          const updatedChild = field?.child?.map((child) => {
            if (child?.id === childId) {
              return { ...child, value: value, userId: userId };
            }
            return child;
          });
          return { ...field, child: updatedChild };
        });
      });

      //shows error message
      handLeChildValueOnBlur(value, childId);
    };

    const handLeChildLabelOnBlur = (label: string, fieldId: number) => {
      if (label.length) {
        setChildLabelError((pre) => {
          return pre.filter((v) => v !== fieldId);
        });
      } else {
        setChildLabelError((pre) => {
          return [...pre, fieldId];
        });
      }
    };

    const handLeChildValueOnBlur = (value: string, fieldId: number) => {
      if (value.length) {
        if (
          !allUsers.some(
            (user) => user.name.toUpperCase() === value.toUpperCase()
          )
        ) {
          setChildValueError((pre) => {
            return [...pre, fieldId];
          });
        } else
          setChildValueError((pre) => {
            return pre.filter((v) => v !== fieldId);
          });
      } else {
        setChildValueError((pre) => {
          return [...pre, fieldId];
        });
      }
    };

    return (
      <>
        {fields.map((field) => (
          <Styles.DynamicInputParentContainer
            key={field.id}
            $isChildExists={field?.child?.length > 0}
          >
            <Styles.DynamicInputParentWrap>
              <div>
                <Styles.AntRoleInput
                  type="text"
                  value={field?.label}
                  onChange={(e) => handleLabelChange(field?.id, e.target.value)}
                  onBlur={() => handLeLabelOnBlur(field?.label, field?.id)}
                  placeholder="Add role"
                  status={
                    parentLabelError.includes(field?.id) ? "error" : undefined
                  }
                />

                <Styles.Error $active={parentLabelError.includes(field?.id)}>
                  {"Please enter role"}
                </Styles.Error>
              </div>

              <Styles.AddFieldButton
                type="primary"
                shape="circle"
                icon={
                  <Styles.RemoveFieldIcon
                    src={IMAGES.addFieldIcon}
                    alt="remove"
                  />
                }
                onClick={() =>
                  setIsDeleteModalOpen({
                    parentId: field.id,
                    childId: -1,
                    isDelete: true,
                    level: OrganogramFieldLevels.parent,
                  })
                }
              />
              <div>
                <Styles.AntAutoCompleteWrap
                  $active={parentValueError.includes(field?.id)}
                >
                  <Styles.AntSelectWrap
                    showSearch
                    allowClear
                    suffixIcon={null}
                    placeholder="Search for a user"
                    optionFilterProp="children"
                    value={field?.value}
                    onChange={(_, option) =>
                      handleValueChange(field.id, option)
                    }
                    onBlur={() => handLeValueOnBlur(field?.value, field?.id)}
                  >
                    {allUsers?.map((user) => (
                      <Select.Option key={user?.id} value={user?.id}>
                        {user.name}
                      </Select.Option>
                    ))}
                  </Styles.AntSelectWrap>
                </Styles.AntAutoCompleteWrap>

                <Styles.Error $active={parentValueError.includes(field?.id)}>
                  {"Please enter full name"}
                </Styles.Error>
              </div>
              <Styles.AddChildFieldButton
                type="primary"
                shape="circle"
                icon={<img src={IMAGES.addFieldIcon} alt="add" />}
                onClick={() => handleAddChild(field.id)}
              />
            </Styles.DynamicInputParentWrap>

            <div
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {field.child.map((v) => (
                <Styles.DynamicInputChildWrap key={v?.id}>
                  <div>
                    <Styles.AntInput
                      type="text"
                      value={v.label}
                      onChange={(e) =>
                        handleChildLabelChange(v?.id, e.target.value)
                      }
                      onBlur={() => handLeChildLabelOnBlur(v?.label, v?.id)}
                      placeholder="Add role"
                      status={
                        childLabelError.includes(v?.id) ? "error" : undefined
                      }
                    />

                    <Styles.Error $active={childLabelError.includes(v?.id)}>
                      {"Please enter role"}
                    </Styles.Error>
                  </div>

                  <Styles.AddFieldButton
                    type="primary"
                    shape="circle"
                    icon={
                      <Styles.RemoveFieldIcon
                        src={IMAGES.addFieldIcon}
                        alt="remove"
                      />
                    }
                    onClick={() =>
                      setIsDeleteModalOpen({
                        parentId: field?.id,
                        childId: v?.id,
                        isDelete: true,
                        level: OrganogramFieldLevels.Child,
                      })
                    }
                  />
                  <div>
                    <Styles.AntAutoCompleteWrap
                      $active={childValueError.includes(v?.id)}
                    >
                      <Styles.AntSelectWrap
                        showSearch
                        allowClear
                        value={v.value}
                        suffixIcon={null}
                        placeholder="Search for a user"
                        optionFilterProp="children"
                        onChange={(_, option) =>
                          handleChildValueChange(v?.id, option)
                        }
                        onBlur={() => handLeChildValueOnBlur(v?.value, v?.id)}
                      >
                        {allUsers?.map((user) => (
                          <Select.Option key={user?.id} value={user?.id}>
                            {user.name}
                          </Select.Option>
                        ))}
                      </Styles.AntSelectWrap>
                    </Styles.AntAutoCompleteWrap>

                    <Styles.Error $active={childValueError.includes(v?.id)}>
                      {"Please enter full name"}
                    </Styles.Error>
                  </div>
                </Styles.DynamicInputChildWrap>
              ))}
            </div>
          </Styles.DynamicInputParentContainer>
        ))}

        <Styles.AddFieldButton
          type="primary"
          shape="circle"
          icon={<img src={IMAGES.addFieldIcon} alt="add" />}
          onClick={handleAddField}
        />
      </>
    );
  };

  const handleFieldValueChange = () => {
    if (
      prevOrganogramFields?.AllianceLead !==
        form.getFieldValue("AllianceLead") ||
      prevOrganogramFields?.ProcurementLead !==
        form.getFieldValue("ProcurementLead") ||
      prevOrganogramFields?.accountLead !== form.getFieldValue("accountLead") ||
      prevOrganogramFields?.bidManager !== form.getFieldValue("bidManager") ||
      prevOrganogramFields?.deliveryLead !==
        form.getFieldValue("deliveryLead") ||
      prevOrganogramFields?.financeLead !== form.getFieldValue("financeLead") ||
      prevOrganogramFields?.leadSolutionArchitects !==
        form.getFieldValue("leadSolutionArchitects") ||
      prevOrganogramFields?.legal !== form.getFieldValue("legal") ||
      prevOrganogramFields?.proposalManager !==
        form.getFieldValue("proposalManager") ||
      prevOrganogramFields?.saleCaptureLead !==
        form.getFieldValue("saleCaptureLead")
    ) {
      setIsOrganogramFormChanged(true);
    } else {
      setIsOrganogramFormChanged(false);
    }
  };

  const handleCancel = () => {
    form.setFieldsValue(prevOrganogramFields);
    setFields(prevFields);
    setIsOrganogramFormChanged(false);
  };

  const renderOrganogramForm = () => {
    if (getOrganogramLoading) {
      return <Styles.CustomSkeleton active paragraph={{ rows: 8 }} />;
    }
    return (
      <Styles.AntForm
        layout="vertical"
        name="basic"
        autoComplete="off"
        requiredMark={false}
        onFinish={onFinish}
        form={form}
        onFinishFailed={dynamicFieldsValidation}
        onValuesChange={handleFieldValueChange}
      >
        <Styles.AntInputWrap>
          {renderAntFormItem("Client account lead", "accountLead")}

          {renderAntFormItem("Contract delivery lead", "deliveryLead")}

          {renderAntFormItem("Bid manager", "bidManager")}

          {renderAntFormItem("Proposal manager", "proposalManager")}

          {renderAntFormItem("Legal", "legal")}

          {renderAntFormItem("Procurement lead", "ProcurementLead")}

          {renderAntFormItem("Alliance lead", "AllianceLead")}

          {renderAntFormItem("Finance lead", "financeLead")}

          {renderAntFormItem("Sales capture lead", "saleCaptureLead")}

          {renderAntFormItem(
            "Lead solution architects",
            "leadSolutionArchitects"
          )}
        </Styles.AntInputWrap>

        <Styles.DynamicInputContainer>
          {renderDynamicInputs()}
        </Styles.DynamicInputContainer>

        {(isDynamicFormChanged || isOrganogramFormChanged) && (
          <Styles.BtnContainer>
            <Styles.CancelBtnContainer>
              <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
            </Styles.CancelBtnContainer>

            <PrimaryButton htmlType="submit" loading={saveOrganogramLoading}>
              Save
            </PrimaryButton>
          </Styles.BtnContainer>
        )}
      </Styles.AntForm>
    );
  };

  return (
    <>
      <Styles.OrgContainer>
        {renderOrgHeader()}
        <Styles.OrgBody>
          <Styles.OrgBodyTitle>{organogramTitle}</Styles.OrgBodyTitle>
          {renderOrganogramForm()}
        </Styles.OrgBody>
      </Styles.OrgContainer>

      {/* render delete modal */}
      <ConfirmationModal
        title="Delete"
        text="Are you sure you want to delete?"
        isOpen={isDeleteModalOpen.isDelete}
        handleCancel={() =>
          setIsDeleteModalOpen({
            parentId: -1,
            childId: -1,
            isDelete: false,
            level: OrganogramFieldLevels.none,
          })
        }
        handleOk={() => {
          isDeleteModalOpen.level === OrganogramFieldLevels.parent
            ? handleRemoveField(isDeleteModalOpen.parentId)
            : handleRemoveChildField(
                isDeleteModalOpen.parentId,
                isDeleteModalOpen.childId
              );
          setIsDeleteModalOpen({
            parentId: -1,
            childId: -1,
            isDelete: false,
            level: OrganogramFieldLevels.none,
          });
        }}
        isLoading={false}
        btnText="Delete"
      />
    </>
  );
};

export default Organogram;
